import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Tabs } from 'antd'

import moment from 'moment'

import { closingCalculationPeriodService } from './../../redux/services'

import { UIDrawerForm } from './../../components'

class Show extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    external: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      uuid: 0,
      item: {},
      general_key: null,
    }
  }

  onOpen = (uuid, key) => {
    this.setState({
      isLoading: true,
      uuid: uuid,
      item: {},
      general_key: key,
    })

    closingCalculationPeriodService
      .show({ uuid })
      .then((response) => {
        let item = response.data.data

        this.setState(
          {
            isLoading: false,
            item: item,
          },
        )
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
          onOk: () => {
            // Force close
            return this.onClose()
          },
        })
      })
  }

  resetFields = () => {
    this.setState({
      item: {},
    })
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  render() {
    const { visible } = this.props
    const { isLoading, item } = this.state

    return (
      <UIDrawerForm
        visible={visible}
        width={700}
        onClose={this.onClose}
        isLoading={isLoading}
        showBtnSave={false}
        title={`Visualizar Fechamento do Período de Cálculo`}
      >
        <Form layout="vertical">
          <Tabs
            defaultActiveKey={
              this.state.general_key != null
                ? this.state.general_key
                : 'general'
            }
          >
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item label="Empresa(s)">
                    {item.empresas?.length > 0
                      ? item.empresas.map((empresa, index) => {
                          return (
                            <span key={empresa.id}>
                              {empresa.nome}{' '}
                              {index < item.empresas?.length - 1 ? <br /> : ''}
                            </span>
                          )
                        })
                      : 'Todas'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Departamento(s)">
                    {item.departamentos?.length > 0
                      ? item.departamentos.map((departamento, index) => {
                          return (
                            <span key={departamento.id}>
                              {departamento.nome}{' '}
                              {index < item.departamentos?.length - 1 ? (
                                <br />
                              ) : (
                                ''
                              )}
                            </span>
                          )
                        })
                      : 'Todos'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Funcionário(s)">
                    {item.funcionarios?.length > 0
                      ? item.funcionarios.map((funcionario, index) => {
                          return (
                            <span key={funcionario.id}>
                              {funcionario.nome}{' '}
                              {index < item.funcionarios?.length - 1 ? (
                                <br />
                              ) : (
                                ''
                              )}
                            </span>
                          )
                        })
                      : 'Todos'}
                  </Form.Item>
                </Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Data Início">
                    {moment(item.data_inicio).format('DD/MM/YYYY') ?? '-'}
                  </Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Data Fim">
                    {moment(item.data_fim).format('DD/MM/YYYY') ?? '-'}
                  </Form.Item>
								</Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Criação">
                    {moment(item.created_at).calendar() ?? '-'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Última Atualização">
                    {moment(item.updated_at).calendar() ?? '-'}
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

export default Show
