import React, { Component, Fragment } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, Button, message, Modal, Row, Select, Spin, Switch } from "antd";

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { employeesContractsService, companiesService, functionsService, departmentsService, journeysService, annualLeaveService } from "../../../redux/services";

import { debounce } from 'lodash'; 

import {
	DatePickerWithMask,
	UIDrawerForm,
} from "../../../components";

import ModalRecalculate from "./ModalRecalculate/index";

import ModalDepartmentCreate from "../departmentCreate";
import ModalFunctionsCreate from "../functionCreate";
import ModalJourneyShow from "../../Journeys/show";
import ModalAnnualLeaveShow from "../../AnnualLeave/show";
import ModalDepartmentsHistory from "../departmentsHistoryShow";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			isContractArchived: false,
			disableEdit: false,
			uuid: 0,

			data: [],

			vinculo_funcionario_banco_hora: null,
			vinculo_departamento_id: null,
			selectedAnnualLeave: { descricao: null, id: null, uuid: null },
			selectedJourney: { descricao: null, id: null, uuid: null },
			selectedFunction: null,
			selectedDepartment: null,
			originalDepartment: null,
			originalInitialDateCard: '',

			departmentsHistory: [],
			annualLeave: [],
			annualLeaveIsLoading: false,
			companies: [],
			companiesIsLoading: [],
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			functions: [],
			functionsIsLoading: false,
			journeys: [],
			journeysIsLoading: false,

			recalcularModalVisible: false,
			fieldTimeHasChange: false
		};

		this._axiosCancelToken = null;
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		employeesContractsService.show({ uuid })
			.then((response) => {
				let item = response.data.data;



				let companies = [];
				let departments = [];
				let functions = [];
				let journeys = [];
				let annualLeave = [];

				if (item.empresa) {
					companies.push({
						id: item.empresa.id,
						nome: item.empresa.nome,
					});
				}

				if (item.funcoes) {
					functions.push({
						id: item.funcoes.id,
						nome: item.funcoes.nome,
					});
				}

				if (item.departamento) {
					departments.push({
						id: item.departamento.id,
						nome: item.departamento.nome,
					});
				}

				// if (item.jornada) {
				// 	journeys.push({
				// 		id: item.jornada.id,
				// 		nome: item.jornada.descricao,
				// 	});
				// }

				// if (item.vinculo_funcionario_banco_hora) {
				// 	annualLeave.push({
				// 		id: item.vinculo_funcionario_banco_hora.length != 0 ? item.vinculo_funcionario_banco_hora[0].banco_horas_id : -1,
				// 		nome: item.vinculo_funcionario_banco_hora.length != 0 ? item.vinculo_funcionario_banco_hora[0].banco_horas.descricao : "Não vincular banco de horas",
				// 	});
				// }

				this.setState({
					originalInitialDateCard: item.data_inicio_cartao,
				})

				this.setState({
					departmentsHistory: response.data.historico_departamentos ?? [],
					originalDepartment: item.departamento ? item.departamento.id : null,
					isContractArchived: item.arquivar,
					vinculo_departamento_id: item.vinculo_departamento?.id,
					isLoading: false,
					companies: companies,
					departments: departments,
					functions: functions,
					journeys: journeys,
					annualLeave: annualLeave,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	/**
 * Journey Show
 *
 * @param uuid
 */
	journeyShowOpen = ({ uuid }) => {
		this.setState({ journeyShowModalVisible: true });

		// On open screen
		this.journeyShowScreen.onOpen(this.state.selectedJourney.uuid);
	};

	journeyShowOnClose = () => {
		this.setState({ journeyShowModalVisible: false });
	};

	/**
	* Departments History
	*/

	departmentsHistoryShowOpen = () => {
		this.setState({ departmentsHistoryShowModalVisible: true });

		// On open screen
		this.departmentsHistoryShowScreen.onOpen(this.state.departmentsHistory);
	};

	departmentsHistoryShowOnClose = () => {
		this.setState({ departmentsHistoryShowModalVisible: false });
	};

	/**
	 * AnnualLeave Show
	 *
	 * @param uuid
	 */
	annualLeaveShowOpen = ({ uuid }) => {
		this.setState({ annualLeaveShowModalVisible: true });

		// On open screen
		this.annualLeaveShowScreen.onOpen(this.state.selectedAnnualLeave.uuid);
	};

	annualLeaveShowOnClose = () => {
		this.setState({ annualLeaveShowModalVisible: false });
	};

	/**
		* Department Create
		*/
	departmentCreateOpen = (contract) => {
		this.setState({ departmentCreateModalVisible: true });

		// On open screen
		this.departmentCreateScreen.onOpen(contract ?? null);
	};

	departmentCreateOnClose = () => {
		this.setState({ departmentCreateModalVisible: false });
		this.departamento_id && this.departamento_id.focus();

	};

	departmentCreateOnComplete = (item, contract) => {
		this.setState({ departmentCreateModalVisible: false });

		this.departamento_id && this.departamento_id.focus();
		this.form.setFieldsValue({ departamento_id: item.id })
		this.setState({ selectedDepartment: item.nome })

	};

	/**
		* Function Create
		*/
	functionCreateOpen = () => {
		this.setState({ functionCreateModalVisible: true });

		// On open screen
		this.functionCreateScreen.onOpen();
	};

	functionCreateOnClose = () => {
		this.setState({ functionCreateModalVisible: false });
		this.funcao_id && this.funcao_id.focus();
	};

	functionCreateOnComplete = (item) => {
		this.setState({ functionCreateModalVisible: false });

		this.funcao_id && this.funcao_id.focus();
		this.form.setFieldsValue({ funcao_id: item.id })
		this.setState({ selectedFunction: item.nome })
	};

	fetchCompanies = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				companiesIsLoading: false,
				companies: [],
			});

			return false;
		}

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			ativo: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})

			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartmentsSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 500);

	fetchFunctions = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			functionsIsLoading: true,
		});

		functionsService.getAll({
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					functionsIsLoading: false,
					functions: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					functionsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchFunctionsSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				functionsIsLoading: false,
				functions: [],
			});

			return false;
		}

		this.setState({
			functionsIsLoading: true,
		});

		functionsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					functionsIsLoading: false,
					functions: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					functionsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 500);

	fetchAnnualLeave = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			annualLeaveIsLoading: true,
		});

		annualLeaveService.getAll({
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					annualLeaveIsLoading: false,
					annualLeave: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					annualLeaveIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchAnnualLeaveSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				annualLeaveIsLoading: false,
				annualLeave: [],
			});

			return false;
		}

		this.setState({
			annualLeaveIsLoading: true,
		});

		annualLeaveService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					annualLeaveIsLoading: false,
					annualLeave: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					annualLeaveIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 500);

	fetchJourneys = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			journeysIsLoading: true,
		});

		journeysService.getAll({
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					journeystenantsIsLoading: false,
					journeys: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchJourneysSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				journeysIsLoading: false,
				journeys: [],
			});

			return false;
		}

		this.setState({
			journeysIsLoading: true,
		});

		journeysService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					journeystenantsIsLoading: false,
					journeys: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 500);

	fillForm = (data) => {
		this.form.setFieldsValue({
			arquivar: data.arquivar ?? null,
			banco_horas_id: (data.vinculo_funcionario_banco_hora && data.vinculo_funcionario_banco_hora.length > 0) ? data.vinculo_funcionario_banco_hora[data.vinculo_funcionario_banco_hora.length - 1]?.banco_horas_id : -1,
			empresa_id: data.empresa ? data.empresa.id : null,
			funcao_id: data.funcoes ? data.funcoes.id : null,
			departamento_id: data.departamento ? data.departamento.id : null,
			jornada_id: data.jornada ? data.jornada.id : null,
			data_admissao: data.data_admissao ? moment(data.data_admissao) : null,
			data_demissao: data.data_demissao ? moment(data.data_demissao) : null,
			data_inicio_cartao: data.data_inicio_cartao ? moment(data.data_inicio_cartao) : null,
		});

		this.setState({
			vinculo_funcionario_banco_hora: data.vinculo_funcionario_banco_hora,
			selectedFunction: data.funcoes ? data.funcoes.id : null,
			selectedDepartment: data.departamento ? data.departamento.id : null,
			selectedJourney: { descricao: data.jornada.descricao, id: null, uuid: data.jornada ? data.jornada?.uuid : null },
			selectedAnnualLeave: { descricao: (data.vinculo_funcionario_banco_hora && data.vinculo_funcionario_banco_hora.length > 0) ? data.vinculo_funcionario_banco_hora[data.vinculo_funcionario_banco_hora.length - 1]?.banco_horas?.descricao : (data.nao_usa_banco_horas == 1 ? "Não vincular banco de horas" : null), id: null, uuid: (data.vinculo_funcionario_banco_hora && data.vinculo_funcionario_banco_hora.length > 0) ? data.vinculo_funcionario_banco_hora[data.vinculo_funcionario_banco_hora.length - 1]?.banco_horas?.uuid : null },
		})

	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		}

		if (data.data_demissao) {
			data.data_demissao = data.data_demissao.format("YYYY-MM-DD");
		}

		if (data.data_admissao) {
			data.data_admissao = data.data_admissao.format("YYYY-MM-DD");
		}

		if (data.data_inicio_cartao) {
			data.data_inicio_cartao = data.data_inicio_cartao.format("YYYY-MM-DD");
		}

		data.vinculo_departamento_id = this.state.vinculo_departamento_id;

		data.uuid = uuid;

		this.setState({
			data: data,
		});

		// Call recalculate modal

		const editedInitialDateCard = data.data_inicio_cartao;

		if (editedInitialDateCard !== this.state.originalInitialDateCard) {
			this.recalculateOpen(data);
			return;
		}

		if (this.state.fieldTimeHasChange) {
			this.recalculateOpen(data);
			return;
		}

		employeesContractsService.edit(data)
			.then((response) => {

				if (this.state.selectedDepartment !== this.state.originalDepartment) {
					message.success("Departamento atualizado com sucesso.");
				}

				if (this.state.isContractArchived !== data.arquivar) {
					message.success("Contrato atualizado com sucesso.");
				}

				this.setState({
					isLoading: false,
					isSending: false,
				})

				this.props.onComplete()
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	recalculateOpen = ({ uuid }) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Registro atualizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.props.onComplete();
	}

	onDateLayOffChange = (value) => {
		this.handleSwitchArchive(value)
	}

	handleDropdownVisibleChange = (entity, fetchFunction) => {
		fetchFunction();
	};

	handleSwitchArchive = (value) => {
		const archive = !!value;
		this.form.setFieldValue('arquivar', archive);
		this.setState({
			arquivar: archive
		});
	}

	render() {
		const { visible } = this.props;

		const {
			isLoading,
			isSending,
			annualLeave,
			annualLeaveIsLoading,
			companies,
			companiesIsLoading,
			departments,
			departmentsIsLoading,
			functions,
			functionsIsLoading,
			journeys,
			journeysIsLoading,
			recalcularModalVisible
		} = this.state;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar Contrato`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						initialValues={{
						}}>
						<Row gutter={16}>
							<Col xs={12}>
								<Form.Item name="empresa_id" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Select
										filterOption={false}
										allowClear
										placeholder="Escolha a empresa"
										notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onSearch={this.fetchCompanies}
										showSearch
										disabled
										onChange={(value) => this.form.setFieldsValue({ novo_contrato_empresa_id: value })}
									>
										{companies.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item name="departamento_id" hasFeedback label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Input.Group compact>
										{this.state.departmentsHistory.length <= 1 ? (
											<>
												<Select
													filterOption={false}
													allowClear
													ref={el => this.departamento_id = el}
													style={{ width: 'calc(100% - 50px)' }}
													placeholder="Escolha o departamento"
													notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchDepartments)}
													onSearch={this.fetchDepartmentsSearch}
													showSearch
													onChange={(value) => {
														this.setState({ selectedDepartment: value })
														this.form.setFieldsValue({ departamento_id: value })
													}}
													value={this.state.selectedDepartment}
												>
													{departments.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
												<Button onClick={this.departmentCreateOpen} type="primary">+</Button>
											</>
										) : (
											<>
												<Select
													filterOption={false}
													allowClear
													ref={el => this.departamento_id = el}
													style={{ width: 'calc(100% - 98px)' }}
													placeholder="Escolha o departamento"
													notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchDepartments)}
													onSearch={this.fetchDepartmentsSearch}
													showSearch
													onChange={(value) => {
														this.setState({ selectedDepartment: value })
														this.form.setFieldsValue({ departamento_id: value })
													}}
													value={this.state.selectedDepartment}
												>
													{departments.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
												<Button onClick={this.departmentCreateOpen} type="primary">+</Button>
												<Button onClick={this.departmentsHistoryShowOpen} type="primary">|||</Button>
											</>
										)}
									</Input.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={12}>
								<Form.Item name="funcao_id" label="Função" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Input.Group compact>
										<Select
											filterOption={false}
											allowClear
											ref={el => this.funcao_id = el}
											style={{ width: 'calc(100% - 50px)' }}
											placeholder="Escolha a função"
											notFoundContent={functionsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchFunctions)}
											onSearch={this.fetchFunctionsSearch}
											showSearch
											onChange={(value) => {
												this.setState({ selectedFunction: value })
												this.form.setFieldsValue({ funcao_id: value })
											}}
											value={this.state.selectedFunction}
										>
											{functions.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.nome}
												</Select.Option>
											))}
										</Select>
										<Button onClick={this.functionCreateOpen} type="primary">+</Button>
									</Input.Group>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item name="jornada_id" label="Horário" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Input.Group compact>
										<Select
											filterOption={false}
											allowClear
											style={{ width: 'calc(100% - 50px)' }}
											placeholder="Escolha o horário"
											notFoundContent={journeysIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchJourneys)}
											onSearch={this.fetchJourneysSearch}
											onChange={(value) => {
												if (!value) {
													this.setState({ selectedJourney: { id: null, uuid: null, descricao: null } })
													this.form.setFieldsValue({ jornada_id: null })
												} else {
													this.setState({ selectedJourney: { id: value, uuid: journeys.filter((journey) => journey.id == value)[0].uuid }, descricao: null })
													this.form.setFieldsValue({ jornada_id: value })
												}

												this.setState({
													fieldTimeHasChange: true
												})
											}}
											showSearch
											value={this.state.selectedJourney.id ?? this.state.selectedJourney.descricao}
										>
											{journeys.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.descricao}
												</Select.Option>
											))}
										</Select>
										<Button disabled={!this.state.selectedJourney.uuid ? true : false} onClick={this.journeyShowOpen} type="primary">|||</Button>
									</Input.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={12}>
								<Form.Item name="banco_horas_id" label="Banco de horas" hasFeedback >
									<Input.Group compact>
										<Select
											ref={el => this.banco_horas_id = el}
											filterOption={false}
											allowClear
											style={{ width: 'calc(100% - 50px)' }}
											placeholder="Escolha o banco de horas"
											notFoundContent={annualLeaveIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchAnnualLeave)}
											onSearch={this.fetchAnnualLeaveSearch}
											onChange={(value) => {
												if (!value) {
													this.setState({ selectedAnnualLeave: { id: null, uuid: null, descricao: null } })
													this.form.setFieldsValue({ banco_horas_id: null })
												} else {
													if (value != -1) {
														this.setState({ selectedAnnualLeave: { id: value, uuid: annualLeave.filter((annual) => annual.id == value)[0].uuid } })
													} else {
														this.setState({ selectedAnnualLeave: { id: value, uuid: null } })
													}

													this.form.setFieldsValue({ banco_horas_id: value })
												}
												this.setState({
													fieldTimeHasChange: true
												})
											}
											}
											value={this.state.selectedAnnualLeave.id ?? this.state.selectedAnnualLeave.descricao}
											showSearch
										>
											<Select.Option key={-1} value={-1}>
												Não vincular banco de horas
											</Select.Option>
											{annualLeave.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.descricao}
												</Select.Option>
											))}
										</Select>
										<Button disabled={!this.state.selectedAnnualLeave.uuid ? true : false} onClick={this.annualLeaveShowOpen} type="primary">|||</Button>
									</Input.Group>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<DatePickerWithMask name="data_admissao" label="Data de admissão" required={true} />
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={12}>
								<DatePickerWithMask
									name="data_demissao"
									label="Data de Demissão"
									onChange={this.onDateLayOffChange}
								/>
							</Col>
							<Col xs={12}>
								<DatePickerWithMask name="data_inicio_cartao" label="Data Início Cartão Ponto" required={true} />
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={24}>
								<Form.Item name="arquivar" label={<div className="inputInfo"><p>Arquivar</p><span>(Não mostrar o contrato no cartão ponto e relatórios)</span></div>} valuePropName="checked">
									<Switch />
								</Form.Item>
							</Col>
						</Row>
					</Form>
					<ModalDepartmentCreate
						ref={el => this.departmentCreateScreen = el}
						visible={this.state.departmentCreateModalVisible}
						onComplete={this.departmentCreateOnComplete}
						onClose={this.departmentCreateOnClose}
					/>
					<ModalFunctionsCreate
						ref={el => this.functionCreateScreen = el}
						visible={this.state.functionCreateModalVisible}
						onComplete={this.functionCreateOnComplete}
						onClose={this.functionCreateOnClose}
					/>
					<ModalJourneyShow
						ref={el => this.journeyShowScreen = el}
						visible={this.state.journeyShowModalVisible}
						onClose={this.journeyShowOnClose}
					/>
					<ModalAnnualLeaveShow
						ref={el => this.annualLeaveShowScreen = el}
						visible={this.state.annualLeaveShowModalVisible}
						onClose={this.annualLeaveShowOnClose}
					/>
					<ModalDepartmentsHistory
						ref={el => this.departmentsHistoryShowScreen = el}
						visible={this.state.departmentsHistoryShowModalVisible}
						onClose={this.departmentsHistoryShowOnClose}
					/>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
				/>
			</Fragment>
		)
	}
}

export default Edit;
