import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Col, Form, Input, Row, Modal } from "antd";
import QueueAnim from "rc-queue-anim";

import { routeLogin } from "./../../config/api";

import { authActions } from "./../../redux/actions";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSending         : false,
			error             : "",
			redirectToReferrer: false,
		};
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		routeLogin(
			{ 
				action: 'login',
				...values,
			}, 
			(response) => {
				this.setState({
					isSending: false,
				});
				const { type, ...success } =response;
				// Do Login
				this.props.doLogin(success);
			}, 
			(error) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(error),
				});
			}
		);
	};

	render() {
		const {referrer}           = this.props.location.state || {referrer: {pathname: "/"}};
		const {redirectToReferrer} = this.state;

		if( redirectToReferrer )
		{
			return <Redirect to={referrer} />
		}

		return (
			<QueueAnim className="site-content-inner page-login">
				<div className="page-content" key="1">
					<h5>Acesso ao sistema.</h5>
					<Form
						ref={el => this.form = el}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}>
						<Form.Item name="email" rules={[{required: true, message: "Campo obrigatório."}, {type: "email", message: "Informe um e-mail válido."}]}>
							<Input prefix={<i className="fal fa-envelope" />} placeholder="E-mail" />
						</Form.Item>
						<Form.Item name="password" rules={[{required: true, message: "Campo obrigatório."}]}>
							<Input.Password prefix={<i className="fal fa-lock" />} type="password" placeholder="Senha" />
						</Form.Item>
						<Row gutter={16} align="middle">
							<Col xs={14} sm={12}>
								<Link to="/recovery-password">Esqueci minha senha</Link>
							</Col>
							<Col xs={10} sm={12}>
								<Button type="primary" htmlType="submit" size="large" block loading={this.state.isSending}>Entrar</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</QueueAnim>
		)
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		doLogin: (data) => {
			dispatch(authActions.login(data));
		}
	}
};

export default connect(null, mapDispatchToProps)(Login);
