import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Form, Select, message } from "antd";

import { timeBankMaintenanceService } from "../../../redux/services";

import {
	HourPickerWithMask,
} from "../../../components";

import moment from "moment";

class ModalBancoAjustes extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		empresa_id: PropTypes.arrayOf(PropTypes.number).isRequired,
		funcionario_id: PropTypes.arrayOf(PropTypes.number).isRequired,
		currentEmployeeDepartment: PropTypes.arrayOf(PropTypes.number).isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			item: null,
			isSending: false,
			optionValue: 'lancar_ajuste',
		};

		this.formRef = React.createRef();
		this.hourPickerRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      setTimeout(() => {
				if (this.hourPickerRef.current) {
					this.hourPickerRef.current.inputElement.focus();
				}
			}, 100)
    }
  }

	resetFields = () => {
		this.formRef.current.resetFields();
		this.setState({
			item: null,
			optionValue: 'lancar_ajuste',
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = (item) => {
		this.setState({ item });
		setTimeout(() => {
			this.formRef.current.setFieldsValue({
				horas: item.horas_banco_ajustes === '00:00' ? '' : item?.horas_banco_ajustes?.replace("-", ""),
				sinal: String(item.horas_banco_ajustes).includes("-") ? "negativo" : "positivo",
				acao: 'lancar_ajuste',
			});
		}, 100);
	}

	onSubmit = () => {
		this.formRef.current.validateFields().then((values) => {
			const { empresa_id, funcionario_id, currentEmployeeDepartment } = this.props;
			const { item, optionValue } = this.state;

			const data = { ...values };

			data.empresas = [empresa_id];
			data.departamentos = currentEmployeeDepartment ? [currentEmployeeDepartment] : [item?.contrato?.departamento_id];
			data.funcionarios = funcionario_id ? [funcionario_id] : [item?.funcionario?.id];

			data.data = moment(data.data).format("YYYY-MM-DD");

			this.setState({
				isSending: true,
			});
	
			data.status = true;

			if (!data.horas) data.horas = '00:00:00';

			if (data.horas && optionValue === 'limpar_ajuste') {
				delete data.horas;
			}
	
			data.data = moment(item.data).format("YYYY-MM-DD");

			timeBankMaintenanceService
				.create(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});
	
					this.resetFields();

					// Success message
					message.success('Registro atualizado com sucesso.');

					// Callback
					this.props.onComplete(response.data.funcionarios[0]);
					this.onClose();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});
	
					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	};

	onActionSelect = (value) => {
		this.setState({ optionValue: value }, () => {
				if (value === 'lancar_ajuste') this.percentual && this.percentual.focus();
			}
		);
	};

	render() {
		const { visible } = this.props;

		const { isSending, optionValue = '0' } = this.state;
	
		return (
			<Modal
				visible={visible}
				title="Editar Banco de Horas Ajuste"
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={380}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{ disabled: isSending }}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={false}
				closable={!isSending}
				centered
			>
				<Form
					ref={this.formRef}
					layout="vertical"
					scrollToFirstError
					initialValues={{ acao: 'lancar_ajuste' }}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="acao"
								label="Ação"
								hasFeedback
								rules={[{ required: true, message: "Campo obrigatório." }]}
							>
								<Select
									ref={(el) => (this.acao = el)}
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									allowClear
									placeholder="Selecionar ação"
									style={{ width: "100%" }}
									showSearch
									onChange={this.onActionSelect}
								>
									<Select.Option value="lancar_ajuste">
										Lançar ajuste
									</Select.Option>
									<Select.Option value="limpar_ajuste">
										Limpar ajuste
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
						<Col span={24}>
							<HourPickerWithMask
								name="horas"
								label="Horário"
								threeDigitMask
								disabled={optionValue === 'limpar_ajuste'}
								ref={this.hourPickerRef}
								onKeyUp={(e) => {
									if (e.key === 'Enter') {
										this.onSubmit();
									}
								}}
							/>
						</Col>
						</Col>
						<Col span={24}>
							<Form.Item
								name="sinal"
								label="Sinal"
								disabled={optionValue === 'limpar_ajuste'}
							>
								<Select
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									placeholder="Selecionar sinal"
									style={{ width: "100%" }}
									showSearch
									disabled={optionValue === 'limpar_ajuste'}
								>
									<Select.Option value="positivo">Positivo</Select.Option>
									<Select.Option value="negativo">Negativo</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default ModalBancoAjustes;
