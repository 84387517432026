import { api } from "./../../config/api";
import { toFormData, toQueryString } from "../../helpers/form";

const basePath = "manutencao-banco-hora";

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
	return api.post(basePath, options);
};