import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal } from "antd";

import moment from "moment";

class ModalMap extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			avatar: null,
		};
	}

	resetFields = () => {};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = (avatar) => {
		this.setState({
			avatar: avatar
		});
	};

	render() {
		const { avatar } = this.state;
		const { visible } = this.props;

		return (
			<Modal
				visible={visible}
				title={`Imagem [${moment(this.props.dataDia).format("DD/MM/YYYY - HH:mm:ss")}]`}
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={500}
				onCancel={this.onClose}
				centered
				footer={null}
			>
				<div style={{ width: "100%" }}>
					<img src={avatar} width="100%" />
				</div>
			</Modal>
		);
	}
}

export default ModalMap;