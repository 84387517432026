import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Button, Table } from "antd";

import { timeCardService } from "./../../../../redux/services";

import moment from "moment";

import ModalCreate from "./modalCreate";
import ModalEdit from "./modalEdit";

class ModalJustificativa extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			createModalVisible: false,
			editModalVisible: false,
			data: [],
		}
	}

	fetchGetAll = () => {
		this.setState({ isLoading: true });

		timeCardService
			.justificativaGetAll({
				funcionario_id: this.props.funcionario.id,
				empresa_id: this.props.empresa?.id ?? 0,
			})
			.then((response) => {
				this.setState({
					isLoading: false,
					data     : response.data.data,
				});
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	createOpen = () => {
		this.setState({ createModalVisible: true });

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({ createModalVisible: false });
	}

	createOnComplete = () => {
		this.setState({ createModalVisible: false });
		this.props.onComplete();
	}

	/**
	 * Edit
	 *
	 * @param uuid
	 */
	editOpen = (uuid) => {
		this.setState({ editModalVisible: true });

		// On open screen
		this.editScreen.onOpen(uuid);
	};

	editOnClose = () => {
		this.setState({ editModalVisible: false });
	};

	editOnComplete = () => {
		this.setState({ editModalVisible: false });
		this.props.onComplete();
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = (uuid) => {
		Modal.confirm({
			title: "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText: "Excluir",
			autoFocusButton: null,
			onOk: () => {
				return this.deleteConfirmed(uuid);
			},
		});
	};

	deleteConfirmed = (uuid) => {
		return timeCardService
			.justificativaDestroy({ uuid })
			.then((response) => {
				this.props.onComplete();
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {
		this.fetchGetAll();
	}

	render() {
		const { visible } = this.props;

		const { isLoading, data } = this.state;

		const columns = [
			{
				title: "Data",
				dataIndex: "data",
				key: "data",
				width: 150,
				render: (_, { data }) => (
					<div>{moment(data).format("DD/MM/YYYY")}</div>
				),
			},
			{
				title: "Início",
				dataIndex: "inicio",
				key: "inicio",
				width: 120,
				sorter: (a, b) => moment(a.hora_inicio, 'HH:mm:ss').unix() - moment(b.hora_inicio, 'HH:mm:ss').unix(),
				render: (_, { hora_inicio }) => (
					<div>{hora_inicio.slice(0, 5)}</div>
				),
			},
			{
				title: "Fim",
				dataIndex: "fim",
				key: "fim",
				width: 120,
				sorter: (a, b) => moment(a.hora_fim, 'HH:mm:ss').unix() - moment(b.hora_fim, 'HH:mm:ss').unix(),
				render: (_, { hora_fim }) => (
					<div>{hora_fim.slice(0, 5)}</div>
				),
			},
			{
				title: "Justificativa",
				dataIndex: "justificativa",
				key: "justificativa",
				ellipsis: true,
				sorter: (a, b) => a.justificativa?.nome.localeCompare(b.justificativa?.nome),
				render: (_, { justificativa }) => (
					<Fragment>{justificativa?.nome}</Fragment>
				),
			},
			{
				title: "Empresa",
				dataIndex: "empresa",
				key: "empresa",
				width: 150,
				ellipsis: true,
				render: (_, { empresas }) => (
					<Fragment>{empresas[0]?.nome}</Fragment>
				),
			},
			{
				title: "Departamento",
				dataIndex: "departamento",
				key: "departamento",
				width: 150,
				ellipsis: true,
				render: (_, { departamentos }) => (
					<Fragment>{departamentos[0]?.nome}</Fragment>
				),
			},
			{
				title: "Funcionário",
				dataIndex: "funcionario",
				key: "funcionario",
				width: 150,
				ellipsis: true,
				render: (_, { funcionarios }) => (
					<Fragment>{funcionarios[0]?.nome}</Fragment>
				),
			},
			{
				title: "Ações",
				dataIndex: "acoes",
				key: "acoes",
				width: 120,
				render: (_, { uuid }) => (
					<Row gutter={5}>
						<Col span={12}>
							<Button
								ghost
								type="primary"
								shape="circle"
								icon={<i className="fal fa-edit"></i>}
								onClick={() => this.editOpen(uuid)}
							/>
						</Col>
						<Col span={12}>
							<Button
								danger
								shape="circle"
								icon={<i className="fal fa-trash"></i>}
								onClick={() => this.deleteConfirm(uuid)}
							/>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Modal
				visible={visible}
				title={`Justificativa parcial [${moment(this.props.dataDia).format("DD/MM/YYYY")}]`}
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={1180}
				onCancel={this.onClose}
				footer={null}
				centered
				bodyStyle={{ minHeight: "80vh" }}
			>
				<div style={{ marginBottom: 16, textAlign: "right" }}>
					<Button type="primary" shape="round" onClick={this.createOpen} icon={<i className="fal fa-plus" />} disabled={isLoading}>Incluir</Button>
				</div>
				<Table loading={{ spinning: isLoading, indicator: <i className="fad fa-spinner-third fa-spin fa-2x"/> }} rowKey="uuid" locale={{ emptyText: "Nenhum registro encontrado." }} columns={columns} dataSource={data} pagination={false} />
				<ModalCreate
					ref={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
					dataDia={this.props.dataDia}
					empresa={this.props.empresa}
					departamento={this.props.departamento}
					funcionario={this.props.funcionario}
				/>
				<ModalEdit
					ref={(el) => (this.editScreen = el)}
					visible={this.state.editModalVisible}
					onComplete={this.editOnComplete}
					onClose={this.editOnClose}
					empresa={this.props.empresa}
					departamento={this.props.departamento}
					funcionario={this.props.funcionario}
				/>
			</Modal>
		)
	}
}

export default ModalJustificativa;