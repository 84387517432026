import React, { Component } from "react";
import { Col, Row, Select, Button, Popconfirm, Input, Form, Tooltip, Modal } from "antd";
import { overtimeService } from './../../redux/services';

const ColumnEnums = {};

class EventColumnTable extends Component {
	formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      rows: props.eventData ? this.transformData(props.eventData) : [{ column: '', eventCode: '' }],
    };
  }

  componentDidMount() {
    this.getFormData();
  };

  transformData = (eventData) => {
    return Object.entries(eventData).map(([key, value]) => ({
      column: key,
      eventCode: value,
    }));
  };

  handleAddRow = () => {
    this.setState((prevState) => ({
      rows: [...prevState.rows, { column: null, eventCode: "" }],
    }));
  };

  handleRemoveRow = (index) => {
    this.setState((prevState) => ({
      rows: prevState.rows.filter((_, i) => i !== index),
    }));
  };

  handleColumnChange = (value, index) => {
    this.setState((prevState) => ({
      rows: prevState.rows.map((row, i) =>
        i === index ? { ...row, column: value } : row
      ),
    }));
  };

  handleEventCodeChange = (index, event) => {
    const { value } = event.target;

    this.setState((prevState) => ({
      rows: prevState.rows.map((row, i) =>
        i === index ? { ...row, eventCode: value } : row
      ),
    }));
  };

  getFormData = () => {
    const { rows } = this.state;
    const data = {
      is_active: 1
    }
    overtimeService.getColumnItems(data)
		.then((response) => {
      const horasExtras = response?.data;
        horasExtras.forEach((item, index) => {
          if (item.name) {
            ColumnEnums[index] = item.value;
          }
        });
		})
		.catch((data) => {
      Modal.error({
        title: "Ocorreu um erro ao buscar Horas Extras!",
        content: String(data),
      });
		});

    return rows.map(row => ({
      column: row.column,
      eventCode: row.eventCode
    }));
  };

  render() {
    const { rows } = this.state;
    const { readOnly } = this.props;

    return (
      <>
      <Form ref={this.formRef}>
        <>
          <Row gutter={[16, 16]} style={{ marginBottom: "5px" }}>
            <Col span={8}>
              <strong style={{ color: "#4C4C4C", fontSize: "14px" }}>
                <span style={{ color: "#ff4d4f", fontSize: "14px" }}>*</span>{" "}
                Coluna
              </strong>
            </Col>
            <Col span={8}>
              <strong style={{ color: "#4C4C4C", fontSize: "14px" }}>
                <span style={{ color: "#ff4d4f", fontSize: "14px" }}>*</span>{" "}
                Código do Evento
              </strong>
            </Col>
          </Row>
          {rows.map((row, index) => (
            <Row
              gutter={[16, 16]}
              key={index}
              style={{
                marginBottom: index === rows.length - 1 ? "10px" : "5px",
              }}
            >
              <Col span={8}>
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    marginBottom:
                      index === rows.length - 1 ? "5px" : "10px",
                  }}
                  placeholder="Selecione uma coluna"
                  value={row.column}
                  onChange={(value) => this.handleColumnChange(value, index)}
                  disabled={readOnly} 
                >
                  {Object.values(ColumnEnums).map((item, key) => (
                    <Select.Option key={key} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <Input
                  placeholder="Código do evento"
                  value={row.eventCode}
                  maxLength={4}
                  onChange={(event) =>
                    this.handleEventCodeChange(index, event)
                  }
                  readOnly={readOnly}
                />
              </Col>
              <Col span={8}>
                {!readOnly && index > 0 && (
                  <Popconfirm
                    title="Deseja excluir essa linha?"
                    onConfirm={() => this.handleRemoveRow(index)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button
                      style={{ margin: "5px" }}
                      danger
                      shape="circle"
                      icon={<i className="fal fa-trash"></i>}
                    />
                  </Popconfirm>
                )}
                {!readOnly && index === rows.length - 1 && (
                  <Tooltip placement="top" title="Incluir nova linha">
                    <Button
                      onClick={this.handleAddRow}
                      type="secondary"
                      shape="circle"
                      icon={<i className="fas fa-plus"></i>}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
          ))}
        </>
      </Form>
      </>
    );
  }
}

export default EventColumnTable;
