import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
} from 'antd';

import { API_ERRO_TYPE_CANCEL } from './../../config/general';

import {
  integrationsService,
  companiesService,
  departmentsService,
  employeesService,
} from '../../redux/services';

import { DatePickerWithMask, UIDrawerForm } from './../../components';
import TextArea from 'antd/lib/input/TextArea';
import EventColumnTable from './table';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSending: false,
      type: null,
      autocomplete: null,
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      justificationsIsLoading: false,
      justifications: [],
    }

    this._axiosCancelCompaniesToken = null
    this._axiosCancelDepartmentsToken = null
    this._axiosCancelEmployeesToken = null
    this._axiosCancelJustificationsToken = null
  }

  eventColumnTableRef = React.createRef();

  fetchCompanies = () => {
    if (this._axiosCancelCompaniesToken) {
      this._axiosCancelCompaniesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelCompaniesToken = axios.CancelToken.source()

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAutocomplete({
        orderBy: 'nome:asc',
        ativo: 1,
        cancelToken: this._axiosCancelCompaniesToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelDepartmentsToken) {
      this._axiosCancelDepartmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelDepartmentsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelDepartmentsToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
    }

    departmentsService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelEmployeesToken) {
      this._axiosCancelEmployeesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEmployeesToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      })

      return false
    }

    this.setState({
      employeesIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelEmployeesToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
      departamentos_ids: this.form
        .getFieldValue('departamentos')
        .includes('todos')
        ? []
        : this.form.getFieldValue('departamentos'),
    }

    employeesService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          employeesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onCompanyChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'empresas',
        value.filter((item) => item !== 'todos')
      )
    }

    this.setState({
      employees: [],
      employeesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
    })

    this.form.setFieldValue('departamentos', ['todos'])
    this.form.setFieldValue('funcionarios', ['todos'])
  }

  onCompanySelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('empresas', ['todos'])
    }
  }

  onDepartmentChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'departamentos',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onDepartmentSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('departamentos', ['todos'])
    }
  }

  onEmployeeChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'funcionarios',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onEmployeeSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('funcionarios', ['todos'])
    }
  }

  onOpen = () => {
    if (!this.state.companies.length) {
      this.fetchCompanies()
    }

    setTimeout(() => {
      this.nome && this.nome.focus()
      this.form &&
        this.form.setFieldsValue({
          status: 1,
        })
    }, 100)
  }

  resetFields = () => {
    this.setState({
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      justifications: [],
      justificationsIsLoading: false,
    })
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  validateEventColumnData = (eventColumnData) => {
    for (let item of eventColumnData) {
      if (item.column === null || item.column === '' || item.eventCode === null || item.eventCode === '') {
        return false;
      }
    }
    return true; 
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const eventColumnData = this.eventColumnTableRef.current.getFormData();

    const data = { ...values, eventColumnData }

    data.eventColumnData;

    if (!this.validateEventColumnData(eventColumnData)) {
      message.error('Preencha os dados de Coluna e/ou Código corretamente!');
      this.setState({
        isSending: false,
      });
      return;
    }

    if (!(data.empresas instanceof Array)) {
      data.empresas = [data.empresas]
    }

    if (!(data.departamentos instanceof Array)) {
      data.departamentos = [data.departamentos]
    }

    if (!(data.funcionarios instanceof Array)) {
      data.funcionarios = [data.funcionarios]
    }

    if (data.empresas.includes('todos')) {
      data.empresas = null
    }

    if (data.departamentos.includes('todos')) {
      data.departamentos = null
    }

    if (data.funcionarios.includes('todos')) {
      data.funcionarios = null
    }

    data.data_inicio = moment(data.data_inicio).format('YYYY-MM-DD')
    data.data_fim = moment(data.data_fim).format('YYYY-MM-DD')

    integrationsService
      .create(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        // Success message
        message.success('Integração cadastrada com sucesso.')

        // Callback
        this.props.onComplete()
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  render() {
    const { visible } = this.props

    const {
      isLoading,
      isSending,
      companiesIsLoading,
      companies,
      departments,
      departmentsIsLoading,
      employees,
      employeesIsLoading,
    } = this.state

    return (
      <UIDrawerForm
        visible={visible}
        width={700}
        onClose={this.onClose}
        isLoading={isLoading}
        isSending={isSending}
        formId={formId}
        title="Incluir Nova Integração"
      >
        <Form
          ref={(el) => (this.form = el)}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
          initialValues={{
            required: true,
            status: true,
            empresas: ['todos'],
            departamentos: ['todos'],
            funcionarios: ['todos'],
            layout_id: 1,
          }}
        >
          <Tabs defaultActiveKey="general">
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name="empresas"
                    label="Empresa"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      ref={(el) => (this.empresas = el)}
                      mode="multiple"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (typeof option.children === 'string'
                          ? option.children
                          : option.children.props.children
                        )
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Selecione a(s) empresa(s)"
                      notFoundContent={
                        companiesIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onChange={this.onCompanyChange}
                      onSelect={this.onCompanySelect}
                      showSearch
                    >
                      <Select.Option value="todos">Todas</Select.Option>
                      {companies.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="departamentos"
                    label="Departamento"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (typeof option.children === 'string'
                          ? option.children
                          : option.children.props.children
                        )
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Selecione o(s) departamento(s)"
                      notFoundContent={
                        departmentsIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onSearch={this.fetchDepartments}
                      onChange={this.onDepartmentChange}
                      onSelect={this.onDepartmentSelect}
                      showSearch
                    >
                      <Select.Option value="todos">Todos</Select.Option>
                      {departments.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="funcionarios"
                    label="Funcionário"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      mode="multiple"
                      filterOption={false}
                      allowClear
                      placeholder="Selecione o(s) funcionário(s)"
                      notFoundContent={
                        employeesIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onSearch={this.fetchEmployees}
                      onChange={this.onEmployeeChange}
                      onSelect={this.onEmployeeSelect}
                      showSearch
                    >
                      <Select.Option value="todos">Todos</Select.Option>
                      {employees.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="layout_id"
                    label="Layout"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      filterOption={false}
                      allowClear
                      placeholder="Selecione o layout"
                    >
                      <Select.Option value={1}>Domínio</Select.Option>
                    </Select>
                  </Form.Item>
                  <Row gutter={16}>
										<Col xs={24}>
                     <EventColumnTable ref={this.eventColumnTableRef} />
										</Col>
									</Row>
                  <Form.Item name="description" label="Descrição" hasFeedback>
                    <TextArea
                      ref={(el) => (this.motivo = el)}
                      placeholder="Descrição"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <DatePickerWithMask
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 || e.keyCode === 9) {
                        this.form.data_fim.focus()
                      }
                    }}
                    label="Data inicial"
                    name="data_inicio"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório.',
                      },
                      {
                        validator: async (_, data_inicio) => {
                          var data_fim = this.form.getFieldValue('data_fim')
                          if (data_fim !== null) {
                            if (data_inicio > data_fim) {
                              return Promise.reject(
                                'A data inicial deve ser menor ou igual a data final.'
                              )
                            }
                          }
                        },
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <DatePickerWithMask
                    label="Data final"
                    name="data_fim"
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 || e.keyCode === 9) {
                        this.form.data_inicio.focus()
                      }
                    }}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório.',
                      },
                      {
                        validator: async (_, data_fim) => {
                          var data_inicio =
                            this.form.getFieldValue('data_inicio')
                          if (data_inicio !== null) {
                            if (data_fim < data_inicio) {
                              return Promise.reject(
                                'A data final deve ser maior ou igual a data inicial.'
                              )
                            }
                          }
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

export default Create
