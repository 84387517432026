import { api } from "../../config/api";
import { toFormData, toQueryString } from "../../helpers/form";

const basePath = "cartaoponto";

/**
 * Get all daily points
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAllDailyPoint = (options) => {
	return api.post(`${basePath}/gridcartaodiario`, options);
};

/**
 * Get all time cards
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAllTimeCards = (options) => {
	return api.post(`${basePath}/gridcartaoponto`, options);
};

/**
 * Restart time card
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const restart = (options) => {
	return api.post(`${basePath}/voltar`, options);
};

/**
 * Gerenciar ajuste
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const gerenciarAjuste = (options) => {
	return api.post(`${basePath}/gerenciarAjuste`, toFormData(options));
};

/**
 * Autocomplete jornada
 *
 * @param {Object} options
 * @param  cancelToken
 *
 * @returns {Promise<T>}
 */
export const autocompleteJornada = (options, cancelToken = null) => {
	return api.post(`${basePath}/autocompleto/jornada`, options, {cancelToken});
};

/**
 * Autocomplete função
 *
 * @param {Object} options
 * @param  cancelToken
 *
 * @returns {Promise<T>}
 */
export const autocompleteFuncao = (options, cancelToken = null) => {
	return api.post(`${basePath}/autocompleto/funcao`, options, {cancelToken});
};

/**
 * Autocomplete feriado
 *
 * @param {Object} options
 * @param  cancelToken
 *
 * @returns {Promise<T>}
 */
export const autocompleteFeriado = (options, cancelToken = null) => {
	return api.post(`${basePath}/autocompleto/feriado`, options, {cancelToken});
};

/**
 * Autocomplete horas extras
 *
 * @param {Object} options
 * @param  cancelToken
 *
 * @returns {Promise<T>}
 */
export const autocompleteHorasExtras = (options, cancelToken = null) => {
	return api.post(`${basePath}/autocompleto/horaextra`, options, {cancelToken});
};

/**
 * Exceção de horário
 *
 * @param {Object} options
 * @param  cancelToken
 *
 * @returns {Promise<T>}
 */
export const excecaoHorario = (options) => {
	return api.post(`${basePath}/execaojornada`, options);
};

/**
 * Total do ponto diário
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getTotalDailyPoint = (options) => {
	return api.post(`${basePath}/total`, options);
};

/**
 * Total do cartão ponto
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getTotalTimeCard = (options) => {
	return api.post(`${basePath}/totalCartao`, options);
};

/**
 * Alterar tipo de cálculo
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const alterarCalculo = (options) => {
	return api.post(`${basePath}/alterarcalculo`, options);
};

/**
 * Detalhes cartão ponto
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getDetalhesPonto = (options) => {
	return api.post(`${basePath}/gridcartaodetalhe`, toFormData(options));
};

/**
 * Alterar folga
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const alterarFolga = (options) => {
	return api.post(`${basePath}/folga`, toFormData(options));
};

/**
 * Alterar feriado
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const alterarFeriado = (options) => {
	return api.post(`${basePath}/feriado`, toFormData(options));
};

/**
 * Alterar dia especial
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const alterarDiaEspecial = (options) => {
	return api.post(`${basePath}/especial`, toFormData(options));
};

/**
 * Alterar banco de horas
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const alterarBancoHoras = (options) => {
	return api.post(`${basePath}/bancohoras`, toFormData(options));
};

// Afastamentos
/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const afastamentoGetAll = (options) => {
	return api.get(`${basePath}/afastamento/show/${options.funcionario_id}/${options.data_inicial}/${options.data_final}/${options.empresa_id}`);
};

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const afastamentoCreate = (options) => {
	return api.post(`${basePath}/afastamento`, toFormData(options));
};

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const afastamentoEdit = (options) => {
	return api.post(`${basePath}/afastamento/dados/${options.uuid}`, toFormData(options));
};

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const afastamentoShow = (options) => {
	return api.get(`${basePath}/afastamento/item/${options.uuid}`);
};

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const afastamentoDestroy = (options) => {
	return api.delete(`${basePath}/afastamento/${options.uuid}/${options.cartaoPontoUuid}`);
};

// Justificativa Parcial
/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificativaGetAll = (options) => {
	return api.get(`${basePath}/justificativaparcial/show/${options.funcionario_id}/${options.empresa_id}`);
};

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificativaCreate = (options) => {
	return api.post(`${basePath}/justificativaparcial`, toFormData(options));
};

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificativaEdit = (options) => {
	return api.post(`${basePath}/justificativaparcial/daods/${options.uuid}`, toFormData(options));
};

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificativaShow = (options) => {
	return api.get(`${basePath}/justificativaparcial/item/${options.uuid}`);
};

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificativaDestroy = (options) => {
	return api.delete(`${basePath}/justificativaparcial/${options.uuid}`);
};

// Observação
/**
 * Update
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const updateObservacao = (options) => {
	return api.post(`${basePath}/observacao/${options.cartao_ponto_id}`, options);
};

/**
 * Get
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getObservacao = (options) => {
	return api.get(`${basePath}/observacao/${options.cartao_ponto_id}`);
};

/**
 * Criar horário de batida
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#10-incluir-novo-cartao-de-batida
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const batidaHorarioCreate = (options) => {
	return api.post(`${basePath}/parbatida`, toFormData(options));
};

/**
 * Editar horário de batida
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#10a-alterar-horario-cartao-de-batida
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const batidaHorarioEdit = (options) => {
	return api.post(`${basePath}/batida`, toFormData(options));
};

/**
 * Descartar horário de batida
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#descartarbatida
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const batidaHorarioDiscard = (options) => {
	return api.post(`${basePath}/descartarbatida`, toFormData(options));
};

/**
 * Recarregar cartao ponto
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#17d-show-grid-cartao-por-cartao-ponto
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const recarregarCartaoPonto = (options) => {
	return api.post(`${basePath}/gridcartaopontos`, toFormData(options));
};

/**
 * Movimentar horarios entre Pontos Batidas
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#10b-movimentar-horarios-entre-pontos-batidas
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const movimentarBatida = (options) => {
	return api.post(`${basePath}/movintobatida`, toFormData(options));
};

/**
 * Justificar Falta
 *
 * https://api-empresa.byponto.com.br/documentation/v1/admin/#14-justificar-falta
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const justificarFalta = (options) => {
	return api.post(`${basePath}/falta`, toFormData(options));
};
