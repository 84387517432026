import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { employeesAdressesService, webserviceService } from "../../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "../../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			disableEdit: false,
			uuid: 0,
			isSendingZipcode: false,
			cities: [],
			citiesIsLoading: false,
			cep: null,
		};

		this._axiosCancelToken = null;
	}

	fetchCities = (value) => {
		if (this._axiosCancelToken) {
			this._axiosCancelToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				citiesIsLoading: false,
				cities: [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search: value,
			cancelToken: this._axiosCancelToken.token,
		})
			.then((response) => {
				this.setState({
					citiesIsLoading: false,
					cities: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					citiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		setTimeout(() => {
			this.cep && this.cep.inputElement && this.cep.inputElement.focus()

		}, 100)

		employeesAdressesService.show({ client_uuid: this.props.client_uuid, uuid })
			.then((response) => {
				let item = response.data.data;

				let cities = [];

				if (item.cidade) {
					cities.push({
						id: item.cidade.id,
						uuid: item.cidade.uuid,
						name: item.cidade.name,
						full_name: item.cidade.name,
						state_id: item.cidade.estado.uuid,
						state_abbr: item.cidade.estado.abbr,
						state_name: item.cidade.estado.name,
					});
				}

				this.setState({
					isLoading: false,
					cities: cities,
					cep: item.cep,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			cep: data.cep,
			endereco_principal: data.endereco_principal ?? false,
			numero: data.numero,
			complemento: data.complemento,
		});

		this.zipcodeKeyUp({currentTarget: {value: data.cep}}, true);

		this.setState({
			disableEdit: data.endereco_principal
		})

	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		// const file = this.upload.getFiles();

		// if( file.hasError )
		// {
		// 	Modal.error({
		// 		title  : "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		}

		// uuid
		data.uuid = uuid;

		employeesAdressesService.edit(this.props.client_uuid, data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	zipcodeKeyUp = (e, initial) => {
		// Ctrl + A, Ctrl + C, 9 tab, 16 shift, 18 alt, 17 Ctrl, 37 left, 39 right, 38 up, 40 down
		if ((e.ctrlKey && e.keyCode === 65) || (e.ctrlKey && e.keyCode === 67) || e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 18 || e.keyCode === 17 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
			return false;
		}

		let zipcode = e.currentTarget.value.replace(/[^0-9]/g, "");

		if (zipcode.length < 8) {
			return false;
		}

		this.setState({
			isSendingZipcode: true,
		});

		webserviceService.findZipcode({
			zipcode: zipcode
		})
			.then((response) => {
				this.setState({
					isSendingZipcode: false,
					cep: zipcode,
					cities: [
						{
							id: response.data.city_id,
							state_id: response.data.state_id,
							name: response.data.name,
							full_name: response.data.city_full_name,
							state_abbr: response.data.state_abbr,
							state_name: response.data.state_name,
						}
					],
				}, () => {
					this.form.setFieldsValue({
						logradouro: response.data.street,
						bairro: response.data.district,
						cidade_id: response.data.city_id,
					});

					if (initial == false) {
						this.numero.focus();
					}
				});
			})
			.catch((data) => {
				this.setState({
					isSendingZipcode: false,
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { uuid, isLoading, isSending, isSendingZipcode, cities, citiesIsLoading, cep } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Endereço`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Row gutter={16}>
						<Col xs={24} sm={6} xl={6}>
							<Form.Item name="cep" label="CEP" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<MaskedInput
									mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
									className="ant-input"
									onKeyUp={this.zipcodeKeyUp}
									tabIndex={1}
									ref={el => this.cep = el}
								/>
							</Form.Item>
							{/* {isSendingZipcode && <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} style={{ position: "absolute", top: "37px", left: "92%" }} />} */}
						</Col>
						<Col xs={24} sm={18}>
							<Form.Item name="logradouro" label="Logradouro" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input tabIndex={cep > 0 ? 3 : 2} />
							</Form.Item>
						</Col>

					</Row>
					<Row gutter={16}>
						<Col xs={10} sm={6}>
							<Form.Item name="numero" label="Número" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<InputNumber ref={el => this.numero = el} tabIndex={cep > 0 ? 2 : 3} min={1} maxLength={7} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={18}>
							<Form.Item name="complemento" label="Complemento">
								<Input tabIndex={4} maxLength={50} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={10}>
							<Form.Item name="bairro" label="Bairro" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input tabIndex={5} maxLength={50} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={14}>
							<Form.Item name="cidade_id" label="Cidade" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Select
									tabIndex={6}
									filterOption={false}
									allowClear
									placeholder="Pesquise a cidade"
									notFoundContent={citiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
									onSearch={this.fetchCities}
									showSearch>
									{cities.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.full_name}</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col md={12}>
							<Form.Item name="endereco_principal" label="Endereço principal" valuePropName="checked">
								<Switch disabled={this.state.disableEdit} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
