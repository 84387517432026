import { api } from './../../config/api'
import { appendToFormData } from '../../helpers/form'

const basePath = 'justificativa'

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
  const options_default = {}

  // Merge config
  options = Object.assign({}, options_default, options)

  let params = []
  let params_qs = ''

  if (options.hasOwnProperty('page')) {
    params.push(`page=${options.page}`)
  }

  if (options.hasOwnProperty('limit')) {
    params.push(`limit=${options.limit}`)
  }

  if (options.hasOwnProperty('search')) {
    params.push(`search=${options.search}`)
  }

  if (options.hasOwnProperty('orderBy')) {
    params.push(`orderBy=${options.orderBy}`)
  }

  if (options.hasOwnProperty('status')) {
    params.push(`status=${options.status}`)
  }

  if (options.hasOwnProperty('tipo_contrato')) {
    params.push(`tipo_contrato=${options.tipo_contrato}`)
  }

  if (options.hasOwnProperty('empresa_uuid')) {
    params.push(`empresa_uuid=${options.empresa_uuid}`)
  }

  if (options.hasOwnProperty('state_id')) {
    params.push(`state_id=${options.state_id}`)
  }

  if (options.hasOwnProperty('ativo')) {
    params.push(`ativo=${options.ativo}`)
  }

  if (options.hasOwnProperty('created_at')) {
    options.created_at.forEach((item, index) => {
      params.push(`created_at[${index}]=${item}`)
    })
  }

  if (params.length) {
    params_qs = `?${params.join('&')}`
  }

	let data = {};

	if( options.hasOwnProperty("cancelToken") )
	{
		data.cancelToken = options.cancelToken;
	}

  if (options.exportItems) {
    return api.get(`${basePath}/export${params_qs}`, data)
  } else {
    return api.get(`${basePath}${params_qs}`, data)
  }
}

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
  const formData = new FormData()

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key])
    }
  }

  return api.post(basePath, formData)
}

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
  const formData = new FormData()

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key])
    }
  }

  return api.post(`${basePath}/${options.uuid}`, formData)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
  return api.get(`${basePath}/${options.uuid}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
  return api.delete(`${basePath}/${options.uuid}`)
}
