import React from 'react'
import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import { DatePickerWithMask } from '../../../../components'
import MaskedInput from 'react-text-mask'

const IDClass = ({ communicationType, handleClearDateTime, handleClearTimeZones }) => {
  const [atualizar, setAtualizar] = React.useState(false)
  const [habilitarHorarioVerao, setHabilitarHorarioVerao] =
    React.useState(false)

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="numero_serial_rep"
              label="Número de série"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Número de série" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="ultimo_nsr"
              label="Último NSR"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Último NSR"
                min="1"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[ip]"
              label="IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="IP" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[porta_tcp_ip]"
              label="Porta TCP/IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Porta TCP/IP"
                min="1"
                style={{ width: '100%' }}
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="configuracao_idclass[gateway]" label="Gateway">
              <Input placeholder="Gateway" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[mascara_sub_rede]"
              label="Máscara de sub rede"
            >
              <Input placeholder="Máscara de sub rede" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_idclass[cpf_comunicacao]"
              label="CPF da comunicação"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                className="ant-input"
                placeholder="CPF da comunicação"
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_idclass[usuario]"
              label="Usuário"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Usuário" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_idclass[senha]"
              label="Senha"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input
                type="password"
                placeholder="Senha"
                autoComplete="new-password"
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              name="configuracao_idclass[tipo_documento]"
              label="Tipo de comunicação"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                placeholder="Selecione o tipo de comunicação"
              >
                <Select.Option value="cpf">CPF</Select.Option>
                <Select.Option value="pis">PIS/PASEP</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Outros">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[atualizar_data_hora]"
              label="Atualizar data e hora do relógio"
              valuePropName="checked"
            >
              <Switch
                value={atualizar}
                onChange={() => {
                  setAtualizar(!atualizar)
                  handleClearDateTime()
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Data e hora"
              name="configuracao_idclass[data_hora]"
              showTime={true}
              disabled={!atualizar}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[digito_barras]"
              label="Dígito para barras "
            >
              <Input placeholder="Dígito para barras " />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idclass[formato_rfid]"
              label="Formato RFID"
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Formato RFID"
                showSearch
              >
                <Select.Option value="decimal">Decimal</Select.Option>
                <Select.Option value="simples">
                  Simples (com virgula)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="configuracao_idclass[fuso_horario]"
              label="Fuso horário"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Selecione o fuso horário"
                showSearch
              >
                <Select.Option value="+00:00">GMT</Select.Option>
                <Select.Option value="-01:00">GMT-1</Select.Option>
                <Select.Option value="-02:00">
                  GMT-2 Horário de Fernando de Noronha
                </Select.Option>
                <Select.Option value="-03:00">
                  GMT-3 Horário de Brasília
                </Select.Option>
                <Select.Option value="-04:00">
                  GMT-4 Horário da Amazônia
                </Select.Option>
                <Select.Option value="-05:00">
                  GMT-5 Horário do Acre
                </Select.Option>
                <Select.Option value="-06:00">GMT-6</Select.Option>
                <Select.Option value="-07:00">GMT-7</Select.Option>
                <Select.Option value="-08:00">GMT-8</Select.Option>
                <Select.Option value="-09:00">GMT-9</Select.Option>
                <Select.Option value="-10:00">GMT-10</Select.Option>
                <Select.Option value="-11:00">GMT-11</Select.Option>
                <Select.Option value="-12:00">GMT-12</Select.Option>
                <Select.Option value="+01:00">GMT+1</Select.Option>
                <Select.Option value="+02:00">GMT+2</Select.Option>
                <Select.Option value="+03:00">GMT+3</Select.Option>
                <Select.Option value="+04:00">GMT+4</Select.Option>
                <Select.Option value="+05:00">GMT+5</Select.Option>
                <Select.Option value="+06:00">GMT+6</Select.Option>
                <Select.Option value="+07:00">GMT+7</Select.Option>
                <Select.Option value="+08:00">GMT+8</Select.Option>
                <Select.Option value="+09:00">GMT+9</Select.Option>
                <Select.Option value="+10:00">GMT+10</Select.Option>
                <Select.Option value="+11:00">GMT+11</Select.Option>
                <Select.Option value="+12:00">GMT+12</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Horário de verão">
        <Form.Item
          name="configuracao_idclass[habilitar_horario_verao]"
          label="Habilitar horário de verão"
          valuePropName="checked"
        >
          <Switch
            value={habilitarHorarioVerao}
            onChange={() => {
              setHabilitarHorarioVerao(!habilitarHorarioVerao)
              handleClearTimeZones()
            }}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Início do horário de verão"
              name="configuracao_idclass[inicio_horario_verao]"
              disabled={!habilitarHorarioVerao}
              disableDateBefore={true}
              customDisable="day+1"
            />
          </Col>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Fim do horário de verão"
              name="configuracao_idclass[fim_horario_verao]"
              disabled={!habilitarHorarioVerao}
              disableDateBefore={true}
              customDisable="day+1"
            />
          </Col>
        </Row>
      </Card>
    </Space>
  )
}

export default IDClass
