import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin, message } from 'antd'
import { DatePickerWithMask } from '../../components'
import QueueAnim from 'rc-queue-anim'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import { API_ERRO_TYPE_CANCEL } from './../../config/general'

import {
	companiesService,
	departmentsService,
	employeesService,
	journeysService,
	reportService,
} from '../../redux/services'
import { employeeNotFound, generalError } from '../../enum/errorMessages'

class Index extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isSending           : false,
			companies           : [],
			companiesIsLoading  : false,
			departments         : [],
			departmentsIsLoading: [],
			employees           : [],
			employeesIsLoading  : false,
			journeys            : [],
			journeysIsLoading   : false,
			generatingXLS       : false,
			generatingPDF       : false,
		}

		this._axiosCancelCompaniesToken   = null
		this._axiosCancelDepartmentsToken = null
		this._axiosCancelEmployeesToken   = null
		this._axiosCancelJourneysToken    = null
	}

	fetchCompanies = () => {
		if( this._axiosCancelCompaniesToken )
		{
			this._axiosCancelCompaniesToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source()

		this.setState({
			companiesIsLoading: true,
		})

		companiesService.getAutocomplete({
			orderBy    : 'nome:asc',
			ativo      : 1,
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
		.then((response) => {
			this.setState({
				companiesIsLoading: false,
				companies         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				companiesIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchDepartments = (value) => {
		if( this._axiosCancelDepartmentsToken )
		{
			this._axiosCancelDepartmentsToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				departmentsIsLoading: false,
				departments         : [],
			})

			return false
		}

		this.setState({
			departmentsIsLoading: true,
		})

		let params = {
			search      : value,
			is_active   : 1,
			orderBy     : 'nome:asc',
			cancelToken : this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue('empresas').includes('todos') ? [] : this.form.getFieldValue('empresas'),
		}

		departmentsService.getAutocomplete(params)
		.then((response) => {
			this.setState({
				departmentsIsLoading: false,
				departments         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				departmentsIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchEmployees = (value) => {
		if( this._axiosCancelEmployeesToken )
		{
			this._axiosCancelEmployeesToken.cancel(
				'Only one request allowed at a time.'
			)
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				employeesIsLoading: false,
				employees         : [],
			})

			return false
		}

		this.setState({
			employeesIsLoading: true,
		})

		let params = {
			search           : value,
			ativo            : 1,
			orderBy          : 'nome:asc',
			cancelToken      : this._axiosCancelEmployeesToken.token,
			empresas_ids     : this.form.getFieldValue('empresas').includes('todos') ? [] : this.form.getFieldValue('empresas'),
			departamentos_ids: this.form.getFieldValue('departamentos').includes('todos') ? [] : this.form.getFieldValue('departamentos'),
		}

		employeesService.getAutocomplete(params)
		.then((response) => {
			if (response.data.length === 0) {
				Modal.warning({
				  title: 'Aviso',
				  content: 'Nenhum funcionário encontrado.',
				});
			}
			this.setState({
				employeesIsLoading: false,
				employees         : response.data.data ?? [],
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				employeesIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchJourneys = (value) => {
		if( this._axiosCancelJourneysToken )
		{
			this._axiosCancelJourneysToken.cancel(
				'Only one request allowed at a time.'
			)
		}

		this._axiosCancelJourneysToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				journeysIsLoading: false,
				journeys         : [],
			})

			return false
		}

		this.setState({
			journeysIsLoading: true,
		})

		let params = {
			search     : value,
			is_active  : 1,
			orderBy    : 'descricao:asc',
			cancelToken: this._axiosCancelJourneysToken.token,
		}

		journeysService.getAll(params)
		.then((response) => {
			this.setState({
				journeysIsLoading: false,
				journeys         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				journeysIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	onCompanyChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('empresas', value.filter((item) => item !== 'todos'))
		}

		this.setState({
			employees           : [],
			employeesIsLoading  : false,
			departments         : [],
			departmentsIsLoading: false,
		})

		this.form.setFieldValue('departamentos', ['todos'])
		this.form.setFieldValue('funcionarios', ['todos'])
	}

	onCompanySelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('empresas', ['todos'])
		}
	}

	onDepartmentChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('departamentos', value.filter((item) => item !== 'todos'))
		}
	}

	onDepartmentSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('departamentos', ['todos'])
		}
	}

	onEmployeeChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('funcionarios', value.filter((item) => item !== 'todos'))
		}
	}

	onEmployeeSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('funcionarios', ['todos'])
		}
	}

	componentDidMount() {
		if( !this.state.companies.length )
		{
			this.fetchCompanies()
		}
	}

	resetFields = () => {
		this.setState({
			companies           : [],
			companiesIsLoading  : false,
			departments         : [],
			departmentsIsLoading: false,
			employees           : [],
			employeesIsLoading  : false,
		})
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});
	
		const data = {
			tipo_relatorio: values.tipo_relatorio,
			generate: values.generate,
		};
	
		const optionalFields = ['empresas', 'departamentos', 'funcionarios'];
		optionalFields.forEach(field => {
			if (values[field] instanceof Array && values[field].length && !values[field].includes('todos')) {
				data[field] = values[field];
			}
		});
	
		if (values.jornada_id) {
			data.jornadas = [values.jornada_id];
		}
	
		const dateFields = ['data_admissao_inicial', 'data_admissao_final', 'data_aniversario_inicial', 'data_aniversario_final'];
		dateFields.forEach(field => {
			if (values[field]) {
				data[field] = moment(values[field]).format('YYYY-MM-DD');
			}
		});
	
		if (values.status && values.status !== 'todos') {
			data.status = values.status;
		}
	
		reportService.employee(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});
	
				const blob = new Blob([response.data], { type: response.headers['content-type'] });
				const url = URL.createObjectURL(blob);
	
				const a = document.createElement('a');
				a.href = url;
				a.download = 'Funcionarios';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
	
				this.resetFields();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});
				const { error } = data || {};

				let errorMessage = generalError;
				let modalType = 'error';
				let modalTitle = 'Erro';

				if (error.response && error.response.status === 400) {
					errorMessage = employeeNotFound;
					modalType = 'warning';
					modalTitle = 'Aviso';
				}
	
				Modal[modalType]({
					title: modalTitle,
					content: errorMessage,
				});
			});
	}	

	onGenerateReportForFormat = (formato) => {
		const values = this.form.getFieldsValue();
		const newValues = { ...values, generate: formato };
		this.onFinish(newValues);
	}

	onGenerateReport = () => {
		const { getFieldValue } = this.form;

		const formato = getFieldValue('formato');
		const funcionarios = getFieldValue('funcionarios');

		if (!formato || !['xls', 'pdf'].includes(formato)) {
			message.error("Formato não selecionado ou inválido");
			return;
		}

		if(!funcionarios || !funcionarios.length) {
			message.error('Nenhum funcionário encontrado.');
			return;
		}
	
		this.onGenerateReportForFormat(formato.toUpperCase());
	}	

	render() {
		const {
				  isSending,
				  companies,
				  companiesIsLoading,
				  departments,
				  departmentsIsLoading,
				  employees,
				  employeesIsLoading,
				  journeys,
				  journeysIsLoading,
				  generatingXLS,
				  generatingPDF,
			  } = this.state

		return (
			<QueueAnim className="site-content-inner alternative-wrapper">
				<div className="page-content fixed-header" key="1">
					<h1 className="page-title">Funcionários</h1>
					<Form
						ref={(el) => (this.form = el)}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onGenerateReport}
						initialValues={{
							empresas      : ['todos'],
							departamentos : ['todos'],
							funcionarios  : ['todos'],
							jornada_id    : null,
							status        : 'todos',
							tipo_relatorio: 'simples',
						}}>
						<Row gutter={16} align="middle">
							<Col xs={24} md={6}>
								<Form.Item
									name="empresas"
									label="Empresa"
									hasFeedback
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										ref={(el) => (this.empresas = el)}
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione a(s) empresa(s)"
										notFoundContent={
											companiesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onChange={this.onCompanyChange}
										onSelect={this.onCompanySelect}
										showSearch
									>
										<Select.Option value="todos">Todas</Select.Option>
										{companies.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item
									name="departamentos"
									label="Departamento"
									hasFeedback
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o(s) departamento(s)"
										notFoundContent={
											departmentsIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchDepartments}
										onChange={this.onDepartmentChange}
										onSelect={this.onDepartmentSelect}
										showSearch
									>
										<Select.Option value="todos">Todos</Select.Option>
										{departments.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item
									name="funcionarios"
									label="Funcionário"
									hasFeedback
									rules={[{ required: true, message: 'Campo obrigatório.' }]}
								>
									<Select
										mode="multiple"
										filterOption={false}
										allowClear
										placeholder="Selecione o(s) funcionário(s)"
										notFoundContent={
											employeesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchEmployees}
										onChange={this.onEmployeeChange}
										onSelect={this.onEmployeeSelect}
										showSearch
									>
										<Select.Option value="todos">Todos</Select.Option>
										{employees.map((item) => (
											<Select.Option key={item.id} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item name="jornada_id" label="Horário">
									<Select
										filterOption={false}
										allowClear
										placeholder="Selecione o horário (jornada)"
										notFoundContent={
											journeysIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchJourneys}
										showSearch
									>
										{journeys.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.descricao}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<DatePickerWithMask
									onKeyUp={(e) => {
										if( e.keyCode === 13 || e.keyCode === 9 )
										{
											this.form.data_admissao_final.focus()
										}
									}}
									label="Data admissão inicial"
									name="data_admissao_inicial"
								/>
							</Col>
							<Col xs={24} md={6}>
								<DatePickerWithMask
									label="Data admissão final"
									name="data_admissao_final"
									onKeyUp={(e) => {
										if( e.keyCode === 13 || e.keyCode === 9 )
										{
											this.form.data_admissao_inicial.focus()
										}
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<DatePickerWithMask
									onKeyUp={(e) => {
										if( e.keyCode === 13 || e.keyCode === 9 )
										{
											this.form.data_aniversario_final.focus()
										}
									}}
									label="Data aniversário inicial"
									name="data_aniversario_inicial"
								/>
							</Col>
							<Col xs={24} md={6}>
								<DatePickerWithMask
									label="Data aniversário final"
									name="data_aniversario_final"
									onKeyUp={(e) => {
										if( e.keyCode === 13 || e.keyCode === 9 )
										{
											this.form.data_aniversario_inicial.focus()
										}
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item name="status" label="Status do funcionário">
									<Select
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o tipo de arquivo"
										showSearch
									>
										<Select.Option value="todos">Todos</Select.Option>
										<Select.Option value="1">Ativo</Select.Option>
										<Select.Option value="0">Inativo</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item name="tipo_relatorio" label="Tipo de relatório">
									<Select
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o tipo de relatório"
										showSearch
									>
										<Select.Option value="simples">Simples</Select.Option>
										<Select.Option value="aniversariantes">Aniversariantes</Select.Option>
										<Select.Option value="completo">Completo</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item 
									name="formato" 
									label="Formato"
									hasFeedback
									rules={[{ required: true, message: 'Campo obrigatório.' }]}
								>
									<Select
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o formato do arquivo"
										showSearch
									>
										<Select.Option value="xls">XLS</Select.Option>
										<Select.Option value="pdf">PDF</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginLeft: '10px' }}>
								<div>
									<Button
										type="primary"
										shape="round"
										className="btn-save"
										icon={<i className="far fa-check" />}
										loading={isSending}
										disabled={isSending || generatingPDF || generatingXLS}
										block
										onClick={this.onGenerateReport}
									>
										{isSending ? 'Carregando' : 'Gerar'}
									</Button>
								</div>
							</div>
						</Row>
					</Form>
				</div>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	}
}

export default connect(mapStateToProps)(Index)
