import { api } from "./../../config/api";
import { toFormData } from "../../helpers/form";

const basePath = "relatorio";

/**
 * Create frequency
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const frequency = (options) => {
	return api.post(`${basePath}/frequencia`, options);
};

/**
 * Create totals
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const totals = (options) => {
	return api.post(`${basePath}/totais`, toFormData(options));
};

/**
 * Create mirror point
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const mirrorPoint = (options) => {
	return api.post(`${basePath}/espelhoponto`, toFormData(options));
};

/**
 * Create tax files
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const taxFiles = (options) => {
	return api.post(`${basePath}/arquivosfiscais`, toFormData(options));
};

/**
 * Create employee
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const employee = (options) => {
	return api.post(`${basePath}/funcionario`, options, { responseType: 'blob' });
};

/**
 * Create bank statement hours
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const bankStatementHours = (options) => {
	return api.post(`${basePath}/extratobancohoras`, toFormData(options));
};

/**
 * Create occurrences
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const occurrences = (options) => {
	return api.post(`${basePath}/occurrences`, toFormData(options));
};

/**
  * Create bank statement hours
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const absenteeism = (options) => {
	return api.post(`${basePath}/absenteeism`, toFormData(options));
};
