import { api } from "./../../config/api";
import { appendToFormData, toFormData } from "../../helpers/form";

const basePath = "recalcular";

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
 export const create = (options) => {
	const formData = new FormData();

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key]);
		}
	}

	return api.post(basePath, formData);
};

/**
 * Recalculate
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const recalculate = (options) => {
	return api.post(`${basePath}/periodo`, toFormData(options));
};
