import React from 'react'
import { Card, Form, message, Space, Switch, Typography } from 'antd'
const { Text } = Typography
import moment from 'moment/moment'
import { copyTextToClipboard } from '../../../../helpers/text'

const ADV = ({ item }) => {
  const { configuracao, numero_serial_rep, ultimo_nsr, token } = item

  const handleTokenCopyClick = (target) => {
    copyTextToClipboard(target.innerText).then(() => {
      message.info('Token copiado para área de transferência.')
    })
  }

  const renderTcpIpMode = (type) => {
    if (type === 'servidor') return 'Servidor'
    else return 'Cliente'
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Form.Item label="Número de série">{numero_serial_rep}</Form.Item>
        <Form.Item label="Último NSR">{ultimo_nsr}</Form.Item>
        <Form.Item label="IP">{configuracao.ip}</Form.Item>
        <Form.Item label="Modo TCP/IP">
          {renderTcpIpMode(configuracao.modo_tcp_ip)}
        </Form.Item>
        <Form.Item label="IP do servidor">{configuracao.ip_servidor}</Form.Item>
        <Form.Item label="Porta TCP/IP">{configuracao.porta_tcp_ip}</Form.Item>
        <Form.Item label="Máscara de sub rede">
          {configuracao.mascara_sub_rede}
        </Form.Item>
        <Form.Item label="Gateway">{configuracao.gateway}</Form.Item>
        <Form.Item label="DNS">{configuracao.dns}</Form.Item>
        <Form.Item label="MAC">{configuracao.mac}</Form.Item>
        <Form.Item label="Desativar verificação ICMP neste equipamento">
          <Switch disabled checked={configuracao.desativar_icmp} />
        </Form.Item>
        <Form.Item label="Gravar fluxo de comunicação em arquivo log">
          <Switch disabled checked={configuracao.gravar_comunicacao_log} />
        </Form.Item>
      </Card>
      <Card title="Configurações de reconexão">
        <Form.Item label="Quantidade de tentativas">
          {configuracao.quantidade_tentativa}
        </Form.Item>
        <Form.Item label="Reconectar após tentativa">
          {configuracao.reconectar_apos_tentativa}
        </Form.Item>
        <Form.Item label="Tempo entre Tempo entre tentativas (seg)">
          {configuracao.tempo_entre_tentativa}
        </Form.Item>
      </Card>
      <Card title="Segurança">
        <Form.Item label="Habilitar conexão segura">
          <Switch 
            disabled 
            checked={configuracao.habilitar_conexao_segura} 
          />
        </Form.Item>
        <Form.Item label="IP da conexão segura">
          {configuracao.ip_conexao_segura}
        </Form.Item>
      </Card>
      <Card title="Autenticação">
        <Form.Item label="Usuário">{configuracao.usuario}</Form.Item>
      </Card>
      <Card title="Leitoras">
        <Form.Item label="Tipo de leitora">
          {configuracao.tipo_leitora}
        </Form.Item>
        <Form.Item label="Módulo biométrico">
          {configuracao.modulo_biometrico}
        </Form.Item>
      </Card>
      <Card title="Outros">
        <Form.Item label="Token">
          <Text
            onClick={({ target }) => handleTokenCopyClick(target)}
            style={{ cursor: 'pointer' }}
          >
            {token}
          </Text>
        </Form.Item>
        <Form.Item label="Fuso horário">{configuracao.fuso_horario}</Form.Item>
        <Form.Item label="Data e horário">
          {moment(configuracao?.atualizar_data_hora).format(
            'DD/MM/YYYY HH:mm:ss'
          )}
        </Form.Item>
      </Card>
      <Card title="Horário de verão">
        <Form.Item label="Início do horário de verão">
          {moment(configuracao.inicio_horario_verao).format('DD/MM/YYYY')}
        </Form.Item>
        <Form.Item label="Fim do horário de verão">
          {moment(configuracao.fim_horario_verao).format('DD/MM/YYYY')}
        </Form.Item>
      </Card>
    </Space>
  )
}

export default ADV
