import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { debounce } from 'lodash';
import { Button, Dropdown, Menu, message, Modal, Tag, Typography } from 'antd'
const { Text } = Typography;
import QueueAnim from 'rc-queue-anim'

import { API_ERRO_TYPE_CANCEL } from './../../config/general'

import { generalActions } from './../../redux/actions'

import { equipmentsService } from './../../redux/services'

import { UIPageListing } from './../../components'

import { copyTextToClipboard } from './../../helpers/text'

import ModalCreate from './create'
import ModalEdit from './edit'
import ModalShow from './show'
import ModalFilters from './filters'
import { formatTzDate } from '../../utils/formatTzDate';

const config = {
  title: 'Equipamentos',
  permissionPrefix: 'equipamentos',
  list: 'equipamentos',
  searchPlaceholder: 'Buscar por modelo ou marca',
  orders: [
    {
      label: 'Mais recentes',
      field: 'created_at',
      sort: 'desc',
      default: true,
    },
  ],
}

class Index extends Component {
  constructor(props) {
    super(props)

    const defaultOrder = config.orders.find((o) => o.default)

    this.state = {
      isLoading: false,
      listType: 'list',
      data: [],
      defaultActiveKey: 'general',
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
      orderByLabel: defaultOrder.label,
      orderByField: defaultOrder.field,
      orderBySort: defaultOrder.sort,
      search: '',
      // Actions
      createModalVisible: false,
      editModalVisible: false,
      showModalVisible: false,
      filtersModalVisible: false,
      isExporting: false,
      // Filters
      filters: {
        is_active: 1,
      },
    }

    this._cancelToken = null
  }

  static getDerivedStateFromProps(props, state) {
    if (props.listType && state.listType !== props.listType) {
      return {
        listType: props.listType,
      }
    }

    return null
  }

  componentDidMount() {
    // Fetch all
    this.fetchGetAll(true)
    this.debouncedSearch = debounce(this.fetchGetAll, 500);
  }

  componentWillUnmount() {
    this._cancelToken &&
      this._cancelToken.cancel('Landing Component got unmounted')
  }

  menuItem = (item) => (
    <Menu className="actions-dropdown-menu">
      {this.props.permissions.includes(config.permissionPrefix + '.show') && (
        <Menu.Item key="show">
          <a onClick={() => this.showOpen(item)}>
            <i className="fal fa-file" />
            Visualizar
          </a>
        </Menu.Item>
      )}
      {this.props.permissions.includes(config.permissionPrefix + '.show') && (
        <Menu.Item key="edit">
          <a onClick={() => this.editOpen(item)}>
            <i className="fal fa-pen" />
            Editar
          </a>
        </Menu.Item>
      )}
      {this.props.permissions.includes(config.permissionPrefix + '.show') && item.tipo_comunicacao === 'pendrive' && (
        <Menu.Item key="export" className="divider">
          <a onClick={() => this.exportConfirm(item)}>
            <i class="fa fa-file-export"></i>
            Exportar
          </a>
        </Menu.Item>
      )}
      {this.props.permissions.includes(config.permissionPrefix + '.delete') && (
        <Menu.Item key="delete" className="divider btn-delete">
          <a onClick={() => this.deleteConfirm(item)}>
            <i className="fal fa-trash" />
            Excluir
          </a>
        </Menu.Item>
      )}
    </Menu>
  )

  handleTokenCopyClick = (token) => {
    copyTextToClipboard(token).then(() => {
      message.info('Token copiado para área de transferência.')
    })
  }

  columns = () => {
    const listTypeCard = this.state.listType === 'card'

    return [
      {
        title: 'Descrição',
        render: (item) =>
          listTypeCard ? (
            <h3>{item.descricao}</h3>
          ) : (
            item.descricao
          ),
      },
      {
        title: 'Número de série',
        width: 200,
        render: (item) =>
          listTypeCard ? (
            <h3>{item.numero_serial_rep}</h3>
          ) : (
            item.numero_serial_rep
          ),
      },
      {
        title: 'Marca - Modelo',
        width: 200,
        render: (item) =>
          listTypeCard ? (
            <h3>{item.marca + ' - ' + item.modelo}</h3>
          ) : (
            item.marca + ' - ' + item.modelo
          ),
      },
      {
        title: 'Comunicação',
        width: 120,
        render: (item) => item.tipo_comunicacao_nome,
      },
      {
        title: 'Último NSR',
        width: 120,
        render: (item) => item.ultimo_nsr,
      },      
      {
        title: 'Token',
        width: 200,
        render: (item) => (
          <Text
            onClick={() => this.handleTokenCopyClick(item.token)}
            ellipsis={true}
            style={{ cursor: 'pointer' }}
          >
            <i className="far fa-copy" /> {item.token}
          </Text>
        ),
      },
      {
        title: 'Data Últ. conexão',
        className: 'datetime',
        render: (item) => {
          const fuso_horario = item?.configuracao?.fuso_horario || '-03:00';
          if (listTypeCard) {
            return (
              <Fragment>
                <i className="fal fa-plus-circle" style={{ marginRight: 5 }} />
                {formatTzDate(item.status_conexao, fuso_horario)}
              </Fragment>
            )
          }

          return formatTzDate(item.status_conexao, fuso_horario);
        },
      },
      {
        title: 'Status',
        className: 'active no-ellipsis',
        render: (item) => (
          <Tag color={item.ativo ? '#0acf97' : '#fa5c7c'}>
            {item.ativo ? 'Ativo' : 'Inativo'}
          </Tag>
        ),
      },
      {
        title: 'Ações',
        className: 'actions no-ellipsis',
        visible:
          this.props.permissions.includes(config.permissionPrefix + '.show') ||
          this.props.permissions.includes(config.permissionPrefix + '.edit') ||
          this.props.permissions.includes(config.permissionPrefix + '.delete'),
        render: (item) => (
          <Dropdown
            overlay={this.menuItem(item)}
            className="actions-dropdown"
            placement="bottomRight"
            trigger={['click']}
          >
            <Button icon={<i className="fal fa-ellipsis-v" />} />
          </Dropdown>
        ),
      },
    ]
  }

  fetchGetAll = (init = false, exportItems = false) => {
    if (this._cancelToken) {
      this._cancelToken.cancel('Only one request allowed at a time.')
    }

    this._cancelToken = axios.CancelToken.source()

    const { pagination, orderByField, orderBySort, search, filters } =
      this.state

    if (exportItems) {
      this.setState({
        isExporting: true,
      })
    } else {
      this.setState({
        isLoading: true,
      })
    }

    let data = {
      orderBy: `${orderByField}:${orderBySort}`,
      search: search,
    }

    if (exportItems) {
      data.exportItems = true
    } else {
      data.page = init ? 1 : pagination.current
      data.limit = pagination.pageSize
    }

    if (filters.is_active !== null) {
      data.is_active = filters.is_active
    }

    equipmentsService
      .getAll(data, this._cancelToken.token)
      .then((response) => {
        if (exportItems) {
          this.setState({
            isExporting: false,
          })

          window.open(response.data.file_url, '_blank')
        } else {
          this.setState((state) => ({
            isLoading: false,
            data: response.data.data,
            pagination: {
              ...state.pagination,
              current: response.data.meta.current_page,
              total: response.data.meta.total,
            },
          }))
        }
      })
      .catch((data) => {
        if (data?.error_type === API_ERRO_TYPE_CANCEL) return null

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onListTypeChange = (type) => {
    this.props.onChangeListType(type)
  }

  onPaginationChange = (page) => {
    this.setState(
      (state) => ({
        pagination: {
          ...state.pagination,
          current: page,
        },
      }),
      () => {
        this.fetchGetAll()
      }
    )
  }

  onSearch = (value) => {
    this.setState(
      {
        search: value,
      },
      () => {
        this.fetchGetAll(true)
      }
    )
  }

  onSearchChange = (e) => {
    const { value } = e.target;

    this.setState({
      search: value,
    });

    this.debouncedSearch(true);
  }

  /**
   * Create
   */
  createOpen = () => {
    this.setState({ createModalVisible: true })

    // On open screen
    this.createScreen.onOpen()
  }

  createOnClose = () => {
    this.setState({ createModalVisible: false })
  }

  createOnComplete = () => {
    this.setState({ createModalVisible: false })

    // Fetch all
    this.fetchGetAll(true)
  }

  /**
   * Edit
   *
   * @param uuid
   */
  editOpen = ({ uuid }) => {
    this.setState({ editModalVisible: true })

    // On open screen
    this.editScreen.onOpen(uuid)
  }

  exportConfirm = (data) => {
    this.setState({ defaultActiveKey: 'export-employees' }, () => this.editOpen(data));
  }

  editOnClose = () => {
    this.setState({ editModalVisible: false, defaultActiveKey: 'genenal' })
  }

  editOnComplete = () => {
    this.setState({ editModalVisible: false })

    // Fetch all
    this.fetchGetAll()
  }

  /**
   * Show
   *
   * @param uuid
   */
  showOpen = ({ uuid }) => {
    this.setState({ showModalVisible: true })

    // On open screen
    this.showScreen.onOpen(uuid)
  }

  showOnClose = () => {
    this.setState({ showModalVisible: false })
  }

  /**
   * Delete
   *
   * @param uuid
   */
  deleteConfirm = ({ uuid }) => {
    Modal.confirm({
      title: 'Confirmar exclusão!',
      content: 'Tem certeza de que deseja excluir este registro?',
      okText: 'Excluir',
      autoFocusButton: null,
      onOk: () => {
        return this.deleteConfirmed(uuid)
      },
    })
  }

  deleteConfirmed = (uuid) => {
    return equipmentsService
      .destroy({ uuid })
      .then((response) => {
        // Fetch all
        this.fetchGetAll()
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  /**
   * Filter
   */
  filtersOpen = () => {
    this.setState({ filtersModalVisible: true })

    // On open screen
    this.filtersScreen.onOpen({ ...this.state.filters })
  }

  filtersOnClose = () => {
    this.setState({ filtersModalVisible: false })
  }

  filtersOnComplete = (filters) => {
    this.setState({ filtersModalVisible: false })

    this.setState(
      {
        filters: filters,
      },
      () => {
        // Fetch all
        this.fetchGetAll(true)
      }
    )
  }

  render() {
    return (
      <QueueAnim className="site-content-inner">
        <div className="page-content fixed-header" key="1">
          <h1 className="page-title">{config.title}</h1>
          <UIPageListing
            onSearch={this.onSearch}
            onSearchChange={this.onSearchChange}
            onPaginationChange={this.onPaginationChange}
            onListTypeChange={this.onListTypeChange}
            onFiltersClick={this.filtersOpen}
            isLoading={this.state.isLoading}
            listType={this.state.listType}
            orderByField={this.state.orderByField}
            orderBySort={this.state.orderBySort}
            search={this.state.search}
            searchPlaceholder={config.searchPlaceholder}
            data={this.state.data}
            pagination={this.state.pagination}
            columns={this.columns()}
            showFilters={true}
            buttons={[
              {
                visible: this.props.permissions.includes(
                  config.permissionPrefix + '.create'
                ),
                onClick: this.createOpen,
                title: 'Incluir',
                icon: <i className="far fa-plus" />,
              },
              {
                // TODO liberar opção de exportar
                visible: false, //this.props.permissions.includes(config.permissionPrefix + ".export"),
                onClick: () => this.fetchGetAll(true, true),
                title: this.state.isExporting ? 'Exportando' : 'Exportar',
                icon: <i className="fal fa-file-export" />,
                loading: this.state.isExporting,
              },
            ]}
          />
        </div>
        <ModalCreate
          ref={(el) => (this.createScreen = el)}
          visible={this.state.createModalVisible}
          onComplete={this.createOnComplete}
          onClose={this.createOnClose}
        />
        <ModalEdit
          ref={(el) => (this.editScreen = el)}
          visible={this.state.editModalVisible}
          onComplete={this.editOnComplete}
          onClose={this.editOnClose}
          defaultActiveKey={this.state.defaultActiveKey}
        />
        <ModalShow
          ref={(el) => (this.showScreen = el)}
          visible={this.state.showModalVisible}
          onClose={this.showOnClose}
        />
        <ModalFilters
          ref={(el) => (this.filtersScreen = el)}
          visible={this.state.filtersModalVisible}
          onComplete={this.filtersOnComplete}
          onClose={this.filtersOnClose}
        />
      </QueueAnim>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
    listType: state.general.listType[config.list],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeListType: (type) => {
      dispatch(generalActions.changeListType(config.list, type))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
