/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'

import { Menu, Modal, Dropdown } from 'antd'

import { UIPageListing } from '../../../components'

import { employeesService } from '../../../redux/services'
import { equipmentsService } from '../../../redux/services'

const config = {
  searchPlaceholder: 'Buscar funcionário',
}

const items = [
  {
    key: 'cpf',
    label: 'CPF',
  },
  {
    key: 'pis',
    label: 'PIS',
  },
];

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      listType: 'list',
      dataAll: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 0,
      },
      search: '',
      // Owner item
      ownerItem: {},
      // Actions
      editModalVisible: false,

      isExporting: false,
    }
  }

  init = (ownerItem) => {
    this.setState({
      ownerItem: ownerItem,
    }, () => {
      this.fetchGetAll()
    })
  }

  /**
   * Create
   */
  createOpen = () => {
    this.setState({ createModalVisible: true })

    // On open screen
    this.createScreen.onOpen()
  }

  createOnClose = () => {
    this.setState({ createModalVisible: false })
  }

  createOnComplete = () => {
    this.setState({ createModalVisible: false })

    // Fetch all
    this.fetchGetAll()
  }

  /**
   * Edit
   *
   * @param uuid
   */
  editOpen = ({ uuid }) => {
    this.setState({ editModalVisible: true })

    // On open screen
    this.editScreen.onOpen(uuid)
  }

  /**
   * Delete
   *
   * @param uuid
   */
  deleteConfirm = ({ uuid }) => {
    Modal.confirm({
      title: 'Confirmar exclusão!',
      content: 'Tem certeza de que deseja excluir este registro?',
      okText: 'Excluir',
      autoFocusButton: null,
      onOk: () => {
        return this.deleteConfirmed(uuid)
      },
    })
  }

  deleteConfirmed = (uuid) => {
    return employeesService
      .destroy(this.state.ownerItem.uuid, uuid)
      .then((response) => {
        // Fetch all
        this.fetchGetAll()
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  editOnComplete = () => {
    this.setState({ editModalVisible: false })

    // Fetch all
    this.fetchGetAll()
  }

  menuItem = (item) => (
    <Menu className="actions-dropdown-menu">
      <Menu.Item key="edit">
        <a onClick={() => this.editOpen(item)}>
          <i className="fal fa-pen" />
          Editar
        </a>
      </Menu.Item>
      <Menu.Item key="delete" className="divider btn-delete">
        <a onClick={() => this.deleteConfirm(item)}>
          <i className="fal fa-trash" />
          Excluir
        </a>
      </Menu.Item>
    </Menu>
  )

  columns = () => [
    {
      title: 'Nome',
      render: (item) => item?.nome ?? '-',
    },
    {
      title: 'Departamento',
      render: (item) => item?.contrato[0]?.departamento?.nome ?? '-',
    },
    {
      title: 'Função',
      render: (item) => item?.contrato[0]?.funcoes?.nome ?? '-',
    },
  ]

  export = (format) => {
    const { ownerItem } = this.state
    this.setState({ isExporting: true }, () => {
      equipmentsService    
      .exportFuncionarioAll(ownerItem.uuid, { format })
      .then((response) => {
        const { file_url } = response?.data || {};
        const link = document.createElement('a');
          link.href = file_url;
          const file_name = file_url.substr(file_url.lastIndexOf('/') + 1);
          link.download = file_name;
          link.setAttribute('download', file_name);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
      .finally(() => {
        this.setState({ isExporting: false });
      });
    })

    // TODO Thiago
    
    // formato / data    
    
  }

  fetchGetAll = (init = false, exportItems = false) => {
    const { pagination, search, ownerItem } = this.state

    this.setState({
      isLoading: true,
    })

    const data = {
      limit: 30,
      nome: this.state.search,
      equipamento_id: ownerItem.uuid,
    }

    if (exportItems) {
      data.exportItems = true
    } else {
      data.page = init ? 1 : pagination.current
      data.limit = pagination.pageSize
    }

    equipmentsService
      .getEmployeesEquipment(data)
      .then((response) => {
        this.setState((state) => ({
          isLoading: false,
          data: response.data.data,
          dataAll: response.data.data,
          pagination: {
            ...state.pagination,
            current: response.data.meta.current_page,
            total: response.data.meta.total,
          },
        }))
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  searchItems = () => {
    const { search } = this.state
    const newData = [...this.state.dataAll]

    this.setState({
      data: search
        ? newData.filter(
            (item) =>
              item?.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              item?.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
        : newData,
    })
  }

  editOnClose = () => {
    this.setState({ editModalVisible: false })
  }

  onSearch = (value) => {
    this.setState(
      {
        search: value,
      },
      () => {
        this.searchItems()
      }
    )
  }

  onSearchChange = (e) => {
    // If it does not have type then it's cleaning
    if (!e.hasOwnProperty('type')) {
      const { search } = this.state

      this.setState(
        {
          search: e.target.value,
        },
        () => {
          if (search) {
            this.searchItems()
          }
        }
      )
    }
  }

  onMenuClick = (e) => this.export(e.key);

  render() {
    return (
      <>
        <UIPageListing
          onSearch={this.onSearch}
          onSearchChange={this.onSearchChange}
          listType={this.state.listType}
          search={this.state.search}
          searchPlaceholder={config.searchPlaceholder}
          showListTypeChange={false}
          data={this.state.data}
          columns={this.columns()}
          /*
          buttons={[
            {
              visible: true,
              onClick: () => this.export(),
              title: this.state.isExporting ? 'Exportando' : 'Exportar',
              icon: <i className="fal fa-file-export" />,
              loading: this.state.isExporting,
            },
          ]}
          */
          actions={
            <Dropdown.Button 
              type="primary"
              className="action-btn"
              icon={<i className="fal fa-file-export" />} 
              menu={{ items, onClick: this.onMenuClick }}
              loading={this.state.isExporting}
              disabled={this.state.isExporting}
              onClick={() => this.export('cpf')}
            >
              {this.state.isExporting ? 'Exportando' : 'Exportar'}
            </Dropdown.Button>
          }
        />
      </>
    )
  }
}

export default Index
