import React, { Component } from 'react'
import * as PropTypes from "prop-types";

import { Form, Modal, Radio, Row, Col, Progress } from "antd";
import { DatePickerWithMask } from '../../../components';

import moment from 'moment';

import { annualLeaveService, recalculateSchedulesService } from '../../../redux/services';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Recalculate extends Component {
  static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		recalculateError: PropTypes.func,
    data: PropTypes.array.isRequired,
    create: PropTypes.any,
    deletedAt: PropTypes.bool,
    dataDeleted: PropTypes.object,
    acao: PropTypes.string,
	};

  constructor(props) {
		super(props);

		this.state = {
      isSending: false,
      recalcular: true,
      progress: 0,
      horario: []
    };
	}

  onOpen = (uuid) => {
    annualLeaveService.show({ uuid })
      .then((response) => {
        const item = response.data.data;

        this.setState({
          horario: [item.id],
        })
      })
      .catch((data) => {
        Modal.error({
          title: "Ocorreu um erro!",
          content: String(data),
          onOk: () => {
            // Force close
            return this.onClose();
          }
        });
      });
  };

  onClose = () => {
		// Callback
		this.props.onComplete();
	};

  onFinish = async () => {
    this.startProgress();

    try {
      const values = await this.form.validateFields();
  
      this.setState({ isSending: true });
  
      const { recalcular, horario } = this.state;
      const data = { ...values };
      const { data: dados, create, deletedAt, dataDeleted } = this.props;
  
      if (!create && !deletedAt) {
        try {
          await annualLeaveService.edit(dados);
          if (!recalcular) {
            this.props.onCancel?.();
          }
        } catch (error) {
          Modal.error({
            title: "Ocorreu um erro!",
            content: String(error),
          });
        } finally {
          this.setState({ isSending: false });
        }
      }
  
      if (dataDeleted && dataDeleted.id) {
        if (!Array.isArray(data.banco_horas)) {
          data.banco_horas = [];
        }
        data.banco_horas[0] = dataDeleted.id;
        data.acao = this.props.acao;
      }
  
      if (recalcular) {
        const errorEmployeeMessage = 
          'Não foi encontrado nenhum funcionário nesse horário entre o intervalo selecionado!';

        this.setState({ isSending: true });
        data.data_inicial = moment(data.data_inicial).format('YYYY-MM-DD');
        data.data_final = moment(data.data_final).format('YYYY-MM-DD');
        data.banco_horas = horario;
  
        const response = await recalculateSchedulesService.recalculate(data);
        if (response.data.includes('Concluído com sucesso!')) {
          this.setState({ isSending: false });
          // Callback
          this.props.onComplete();
        } else if (response.data === errorEmployeeMessage) {
          Modal.error({
            title: "Ocorreu um erro!",
            content: errorEmployeeMessage,
          });
        }
      } else {
        // Callback
        this.props.onClose();
      }
    } catch (error) {
      this.setState({ isSending: false });
  
      if (error instanceof Error) {
        Modal.error({
          title: "Ocorreu um erro!",
          content: error.message || String(error),
        });
      }

      this.props.recalculateError();
    } finally {
      this.setState({ isSending: false });
    }
  }; 

  startProgress = () => {
    let steps = [5, 10, 15, 25, 30, 40, 50, 75, 90, 99];
    let index = 0;

    this.setState({ progress: 0, isSending: true });

    this.progressInterval = setInterval(() => {
      if (index < steps.length) {
        this.setState({ progress: steps[index] });
        index++;
      } else {
        clearInterval(this.progressInterval);
      }
    }, 5000);
  };  

  validateEndDate = async (_, value) => {
    const dataInicial = this.form.getFieldValue('data_inicial');
    if (!value || !dataInicial || value.endOf('day').isSameOrAfter(dataInicial)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Data final deve ser maior ou igual à data inicial.'));
  };

  validateStartDate = async (_, value) => {
    const dataFinal = this.form.getFieldValue('data_final');
    if (!value || !dataFinal || value.startOf('day').isSameOrBefore(dataFinal)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Data inicial deve ser maior ou igual à data final.'));
  };

  render() {
    const { visible, create } = this.props;

    const { isSending } = this.state;

    return (
      <Modal
        visible={visible}
        title="Recalcular Horários"
        keyboard={false}
        centered
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        width={500}
        okText={this.state.recalcular ? "Recalcular" : "Finalizar"}
        onOk={this.onFinish}
        confirmLoading={isSending}
        cancelButtonProps={create ? { style: { display: 'none' } } : null}
        onCancel={() => this.props.onCancel?.()}
        className="modal-recalcular"
      >
        <Form
          ref={el => this.form = el}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <p>
            Atenção: A modificação que você fez aqui pode alterar cálculos de
            horas de dias anteriores. O que deseja fazer?
          </p>
          <Radio
            checked={this.state.recalcular}
            onChange={() => this.setState({ recalcular: true })}
          >
            Recalcular marcações de dias anteriores.
          </Radio>
          <Row gutter={16}>
            <Col xs={12}>
              <DatePickerWithMask
                name="data_inicial"
                label="Data Início"
                rules={[
                  { required: this.state.recalcular, message: 'Campo obrigatório.' },
                  { validator: this.validateStartDate }
                ]}
              />
            </Col>
            <Col xs={12}>
              <DatePickerWithMask
                name="data_final"
                label="Data Fim"
                rules={[
                  { required: this.state.recalcular, message: 'Campo obrigatório.' },
                  { validator: this.validateEndDate }
                ]}
              />
            </Col>
          </Row>
          <Radio
            checked={!this.state.recalcular}
            onChange={() => this.setState({ recalcular: false })}
          >
            Aplicar esta modificação somente para as novas marcações.
          </Radio>
          {isSending && <Progress percent={this.state.progress} />}
        </Form>
      </Modal>
    )
  }
}

export default Recalculate;
