import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, Checkbox, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { partialJustificationsService, justificationsService,companiesService, departmentsService, employeesService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
	HourPickerWithMask,
	UIUpload,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			justifications: [],
			justificationsIsLoading: false,
		};

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;
		this._axiosCancelEmployeesToken = null;
		this._axiosCancelJustificationsToken = null;
	}

	fetchJustifications = (value) => {
		if (this._axiosCancelJustificationsToken) {
			this._axiosCancelJustificationsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelJustificationsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				justificationsIsLoading: false,
				justifications: [],
			});

			return false;
		}

		this.setState({
			justificationsIsLoading: true,
		});

		justificationsService.getAll({
			search: value,
			// orderBy: "name:asc",
			ativo: 1,
			cancelToken: this._axiosCancelJustificationsToken.token,
		})
			.then((response) => {
				this.setState({
					justificationsIsLoading: false,
					justifications: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					justificationsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchCompanies = () => {
		if( this._axiosCancelCompaniesToken )
		{
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy    : "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
		.then((response) => {
			this.setState({
				companiesIsLoading: false,
				companies         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				companiesIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	fetchDepartments = (value) => {
		if( this._axiosCancelDepartmentsToken )
		{
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				departmentsIsLoading: false,
				departments         : [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			search      : value,
			orderBy     : "nome:asc",
			cancelToken : this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos") ? [] : this.form.getFieldValue("empresas"),
		}

		departmentsService.getAutocomplete({
			...params
		})
		.then((response) => {
			this.setState({
				departmentsIsLoading: false,
				departments         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				departmentsIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	fetchEmployees = (value) => {
		if( this._axiosCancelEmployeesToken )
		{
			this._axiosCancelEmployeesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				employeesIsLoading: false,
				employees         : [],
			});

			return false;
		}

		this.setState({
			employeesIsLoading: true,
		});

		let params = {
			search           : value,
			orderBy          : "nome:asc",
			cancelToken      : this._axiosCancelEmployeesToken.token,
			empresas_ids     : this.form.getFieldValue("empresas").includes("todos") ? [] : this.form.getFieldValue("empresas"),
			departamentos_ids: this.form.getFieldValue("departamentos").includes("todos") ? [] : this.form.getFieldValue("departamentos"),
		};

		employeesService.getAutocomplete({
			...params,
		})
		.then((response) => {
			this.setState({
				employeesIsLoading: false,
				employees         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				employeesIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onJustificationChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("justificativas", ...value.filter((item) => item !== "todos"));
		}
	};

	onJustificationSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("justificativas", ["todos"]);
		}
	};

	onCompanyChange = (value) => {
		if(value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("empresas", value.filter((item) => item !== "todos"));
		}

		this.setState({
			departments         : [],
			departmentsIsLoading: false,
			employees           : [],
			employeesIsLoading  : false,
		});

		this.form.setFieldValue("departamentos", ["todos"]);
		this.form.setFieldValue("funcionarios", ["todos"]);
	};

	onCompanySelect = (value) => {
		if(value === "todos") {
			this.form.setFieldValue("empresas", ["todos"]);
		}
	};

	onDepartmentChange = (value) => {
		if(value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("departamentos", value.filter((item) => item !== "todos"));
		}
	};

	onDepartmentSelect = (value) => {
		if(value === "todos") {
			this.form.setFieldValue("departamentos", ["todos"]);
		}
	};

	onEmployeeChange = (value) => {
		if(value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("funcionarios", value.filter((item) => item !== "todos"));
		}
	};

	onEmployeeSelect = (value) => {
		if(value === "todos") {
			this.form.setFieldValue("funcionarios", ["todos"]);
		}
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		if( !this.state.companies.length )
		{
			this.fetchCompanies();
		}

		partialJustificationsService.show({ uuid })
			.then((response) => {
				let item = response.data;

				let justifications = [];

				if( item.justificativa )
				{
					justifications.push({
						id  : item.justificativa.id,
						nome: item.justificativa.nome,
					});
				}

				this.setState({
					isLoading: false,
					employees: item.funcionarios ?? [],
					justifications: justifications,
					departments: item.departamentos ?? [],
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.setState({
			recorrente: data.recorrente,
		});
		this.form.setFieldsValue({
			data: moment(data.data),
			empresas: data.empresas.length > 0 ? data.empresas.map((item) => item.id) : ["todos"],
			departamentos: data.departamentos.length > 0 ? data.departamentos.map((item) => item.id) : ["todos"],
			funcionarios: data.funcionarios.length > 0 ? data.funcionarios.map((item) => item.id) : ["todos"],
			justificativas: data.justificativa_id,
			status: data.status,
			hora_inicio: data.hora_inicio.substr(0,5),
			hora_fim: data.hora_fim.substr(0,5),
		});
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			recorrente: false,
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = { ...values };


		// uuid
		data.uuid = uuid;

		data.data = data.data.format("YYYY-MM-DD");
		data.hora_inicio = data.hora_inicio + ":00";
		data.hora_fim = data.hora_fim + ":00";

		if(!(data.empresas instanceof Array)) {
			data.empresas = [data.empresas];
		}

		if(!(data.departamentos instanceof Array)) {
			data.departamentos = [data.departamentos];
		}

		if(!(data.funcionarios instanceof Array)) {
			data.funcionarios = [data.funcionarios];
		}

		if (!(data.justificativas instanceof Array)) {
			data.justificativas = [data.justificativas];
		}

		if( data.empresas.includes("todos") ) {
			data.empresas = null;
		}

		if( data.departamentos.includes("todos") ) {
			data.departamentos = null;
		}

		if( data.funcionarios.includes("todos") ) {
			data.funcionarios = null;
		}

		if( data.justificativas.includes("todos") ) {
			data.justificativas = null;
		}

		partialJustificationsService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, companies, companiesIsLoading, departments, justifications, justificationsIsLoading, employees, employeesIsLoading } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Justificativa Parcial`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
						<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="empresas" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											ref={el => this.empresas = el}
											mode="multiple"
											optionFilterProp="children"
											filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											placeholder="Selecione a(s) empresa(s)"
											notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onChange={this.onCompanyChange}
											onSelect={this.onCompanySelect}
											showSearch>
											<Select.Option value="todos">Todas</Select.Option>
											{companies.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.nome}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="departamentos" label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											mode="multiple"
											optionFilterProp="children"
											filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											placeholder="Selecione o(s) departamento(s)"
											notFoundContent={employeesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchDepartments}
											onChange={this.onDepartmentChange}
											onSelect={this.onDepartmentSelect}
											showSearch>
											<Select.Option value="todos">Todos</Select.Option>
											{departments.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="funcionarios" label="Funcionário" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											mode="multiple"
											filterOption={false}
											allowClear
											placeholder="Selecione o(s) funcionário(s)"
											notFoundContent={employeesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchEmployees}
											onChange={this.onEmployeeChange}
											onSelect={this.onEmployeeSelect}
											showSearch>
											<Select.Option value="todos">Todos</Select.Option>
											{employees.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="justificativas" label="Justificativa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											filterOption={false}
											allowClear
											placeholder="Selecione a justificativas"
											notFoundContent={justificationsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchJustifications}
											showSearch>
											{justifications.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} md={8}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.form.hora_inicio.focus()
										}
									}} label="Data" name="data" required={true} />
								</Col>
								<Col xs={24} md={8}>
									<HourPickerWithMask name="hora_inicio" label="Horário início" required={true} />
								</Col>
								<Col xs={24} md={8}>
									<HourPickerWithMask name="hora_fim" label="Horário fim" required={true} customRoles={[
										({getFieldValue}) => ({
											validator(rule, value) {
												if (value.length < 5) {
													return Promise.resolve();
												}

												if ( parseInt(getFieldValue('hora_inicio').replace(/[^0-9,]/g, "")) < parseInt(value.replace(/[^0-9,]/g, "")) )
												{
													return Promise.resolve();
												}
												return Promise.reject("O horário final final dever ser maior que o inicial");

											},
										}),
									]} />
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item name="status" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
