import React, { Component, Fragment } from 'react'
import { Modal, Spin, Empty, Button, Row, Col } from 'antd'
import moment from 'moment'
import qs from 'querystring'
import { connect } from "react-redux";
import { formatTime } from '../Helpers'

import {
  timeCardService,
  columnsService,
  reportService
} from './../../../redux/services'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading           : false,
      maxSequence         : 0,
      data                : [],
      dataTotal           : {},
      columnsData: {},
      joinColumns         : [],
      dynamicColumns      : [],
      overtimeColumns     : [],

      // Total
      dynamicTotalColumns : [],
      overtimeTotalColumns: [],

      columnsList         : [],
      maxJoinColumns      : 0,

      dataDia             : '',
      empresas            : [],
      empresa_id          : 0,
      empresa_nome        : '',
      funcionario_nome    : '',
      data_admissao       : '',
      departamento        : '',
    }
  }

  componentDidMount() {
    document.body.classList.add('page-print', 'page-print-timecard')
    this.fetchGetAll();
  }

  componentWillUnmount() {
    document.body.classList.remove('page-print', 'page-print-timecard')
  }

  fetchGetAll = () => {
    this.setState({
      isLoading: true,
    })

    const params = qs.parse(this.props.location.search.replace('?', ''))

    const data = {}

    if (params?.empresa_id && params?.empresa_id !== "todos") {
      this.setState({
        empresa_id: params?.empresa_id
      })

      data.empresas = params?.empresa_id;

      data.empresas = Array.isArray(params?.empresa_id)
        ? params?.empresa_id
        : [params?.empresa_id];
    }

    if (params?.datainicial) {
      data.data_inicio = params?.datainicial;
      this.setState({
        dataInicial: params?.datainicial,
      })
    } else {
      Modal.error({
        title: 'Ocorreu um erro!',
        content: 'Data inicial não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
          window.close();
        },
      })
      return false
    }

    if (params?.datafinal) {
      data.data_fim = params?.datafinal;
      this.setState({
        dataFinal: params?.datafinal,
      })
    } else {
      Modal.error({
        title: 'Ocorreu um erro!',
        content: 'Data final não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
          window.close();
        },
      })
      return false
    }

    if (params?.departamento_id && params?.departamento_id !== "todos") {
      data.departamentos = Array.isArray(params?.departamento_id)
        ? params?.departamento_id
        : [params?.departamento_id];
    }

    if (params?.status) {
      data.status = params?.status;
    }

    if (params?.jornada_id) {
      data.jornada_id = Array.isArray(params?.jornada_id)
        ? params?.jornada_id
        : [params?.jornada_id];
    }

    if (params?.funcao_id) {
      data.funcao_id = params?.funcao_id;

      data.funcao_id = Array.isArray(params?.funcao_id)
        ? params?.funcao_id
        : [params?.funcao_id];
    }

    if (params?.funcionario_id) {
      data.funcionarios = Array.isArray(params?.funcionario_id)
        ? params?.funcionario_id
        : [params?.funcionario_id];
    }

    columnsService
      .show({ empresa_id: this.state.empresa_id || params?.empresa_id })
      .then((response) => {
        this.setState(
          {
            columnsData: response.data.data[0],
          },
        )
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        })
      }),

    reportService
      .frequency(data)
      .then((response) => {
        const filteredData = (response.data.cartao || []).filter(entry => entry.cartao && entry.cartao.length > 0);
        this.setState({
          data        : filteredData,
          maxSequence : response.data.maior_sequencia,
          isLoading   : false
        }, () => {
          setTimeout(() => {
            // Print
            document.title = 'Cartao Ponto';

            if( this.state.data.length > 0 ) {
              window.print();
              // window.close();
            }
          }, 1000);
        })
      })

    return;
    timeCardService
      .getAllTimeCards(data)
      .then((response) => {
        const contratos     = response.data.data.map((item) => item.contrato)
        const empresas      = contratos.map((contrato) => contrato.empresa);
        const funcionarios  = response.data.data.map((item) => item.funcionario);
        const departamentos = response.data.data.map((item) => item.departamento);

        const _empresas      = [];
        const _funcionarios  = [];
        const _admissao      = [];
        const _departamentos = [];

        for (let i = 0; i < empresas.length; i++) {
          const empresa = empresas[i].nome;
          if (_empresas.indexOf(empresa) === -1) {
            _empresas.push(empresa);
          }
        }

        for (let i = 0; i < contratos.length; i++) {
          const data_admissao = contratos[i].data_admissao;
          if (_admissao.indexOf(data_admissao) === -1) {
            _admissao.push(data_admissao);
          }
        }

        for (let i = 0; i < funcionarios.length; i++) {
          const funcionario = funcionarios[i].nome;
          if (_funcionarios.indexOf(funcionario) === -1) {
            _funcionarios.push(funcionario);
          }
        }

        for (let i = 0; i < departamentos.length; i++) {
          const departamento = departamentos[i].nome;
          if (_departamentos.indexOf(departamento) === - 1) {
            _departamentos.push(departamento);
          }
        }

        console.log('_empresas ', _empresas);
        console.log('_admissao ', _admissao);
        console.log('_funcionarios ', _funcionarios);
        console.log('_departamentos ', _departamentos);

        // return;
        this.setState({
            isLoading       : false,
            empresas        : empresas,
            empresa_nome    : _empresas.length > 1 ? '' : _empresas[0],
            funcionario_nome: _funcionarios.length > 1 ? '' : _funcionarios[0],
            data_admissao   : _admissao.length > 1 ? '' : _admissao[0],
            departamento    : _departamentos.length > 1 ? '' : _departamentos[0],
            data            : response.data.data.map((item) => ({
              ...item,
              _ajuste_horas_falta: formatTime(item.ajuste_horas_falta),
            })),
          },
          () => {
            columnsService
              .show({
                empresa_id:
                  this.state.empresa_id ||
                  response.data.data[0]?.contrato?.empresa_id,
              })
              .then((response) => {
                this.setState(
                  {
                    columnsList: response.data.data[0],
                  },
                  () => {
                    this.getJoinColumns()
                    this.getDynamicColumns()
                    this.getOvertimeColumns()

                    reportService
                      .frequency({
                        empresas      : [data.empresa_id],
                        departamentos : [data.departamento_id],
                        funcionario_id: [data.funcionario_id],
                        data_inicio   : data.datainicial,
                        data_fim      : data.datafinal,
                      })
                      .then((response) => {
                        this.setState({
                          frequency: response.data.data,
                        })
                      })
                      .catch((error) => {
                        console.log(error)
                      })

                    // Total
                    timeCardService
                      .getTotalTimeCard(data)
                      .then((response) => {
                        this.setState(
                          {
                            dataTotal: {
                              ...response.data,
                              _total_ajustado: formatTime(
                                response.data.total_ajustado
                              ),
                            },
                          },
                          () => {
                            this.getDynamicTotalColumns()
                            this.getOvertimeTotalColumns()

                            console.log(this.state.data)

                            return
                            setTimeout(() => {
                              // Print
                              if (this.state.data.length > 0) {
                                window.print()
                                window.close()
                              }
                            }, 1000)
                          }
                        )
                      })
                      .catch((data) => {
                        Modal.error({
                          title: 'Ocorreu um erro!',
                          content: String(data),
                        })
                      })
                  }
                )
              })
              .catch((data) => {
                Modal.error({
                  title: 'Ocorreu um erro!',
                  content: String(data),
                })
              })
          }
        )
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        })
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  formatBatidaStatus = (item, batida) => {
    // Status de dia todo
    if (item?.pontoajuste?.justificativa_id) {
      return item?.pontoajuste?.justificativa?.nome ?? 'Justificativa'
    } else if (item.folga) {
      return 'Folga'
    } else if (item?.feriado_id) {
      return 'Feriado'
    } else if (item?.dia_especial) {
      return 'Dia especial'
    }
    
    // Status individuais
    if (batida) {
      if (batida?.ponto_ajustes_justificativa_falta_id) {
        return (
          batida?.ponto_ajustes_justificativa_falta?.justificativa?.nome ??
          'Justificativa'
          )
      }
    }

    if (item?.afastamento?.justificativa) {
      return item?.afastamento?.justificativa?.nome ?? 'Justificativa'
    }

    return null
  }

  getJoinColumns = () => {
    const {data} = this.state;

		let columns = [];
		let max = 0;

		data.forEach((item) => {
			let totalBatidasJornada = item.jornadadiasemana?.jornada_batidas?.length ?? 0;
			let totalBatidasCartao  = item.cartaopontobatida?.length ?? 0;

			if( totalBatidasJornada )
			{
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxJornada = item.jornadadiasemana.jornada_batidas[totalBatidasJornada - 1].sequencia;

				if( maxJornada > max )
				{
					max = maxJornada;
				}
			}

			if( totalBatidasCartao )
			{
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxBatida = item.cartaopontobatida[totalBatidasCartao - 1].sequencia;

				if( maxBatida > max )
				{
					max = maxBatida;
				}
			}
		});

    this.setState({
      maxJoinColumns: max,
    });

    [...Array(max).keys()].map((index) => {
      columns.push(
        {
          title: 'Entrada ' + (index + 1),
          width: 80,
          className: 'no-ellipsis no-padding-horizontal text-center',
          render: (item, row_index) => {
            const batidaIndex = item.cartaopontobatida.findIndex(
              (batida) => batida.sequencia === index + 1
            )
            const status = this.formatBatidaStatus(
              item,
              batidaIndex === -1 ? null : item[batidaIndex]
            )

            return (
              <div>
                {status ?? item.cartaopontobatida[index]?.entrada_batida ?? ''}
              </div>
            )
          },
        },
        {
          title: 'Saída ' + (index + 1),
          width: 80,
          className: 'no-ellipsis no-padding-horizontal text-center',
          render: (item, row_index) => {
            const batidaIndex = item.cartaopontobatida.findIndex(
              (batida) => batida.sequencia === index + 1
            )
            const status = this.formatBatidaStatus(
              item,
              batidaIndex === -1 ? null : item[batidaIndex]
            )

            return (
              <div>
                {status ?? item.cartaopontobatida[index]?.saida_batida ?? ''}
              </div>
            )
          },
        }
      )
    })

    this.setState({
      joinColumns: columns,
    })
  }

  getDynamicColumns = () => {
    const { columnsList } = this.state
    let columns = []

    if (columnsList?.apuracao_normal) {
      columns.push({
        title: 'Normal',
        width: 80,
        className: 'text-center',
        render: (item) => (
          <div>{item.horas_normal === '00:00' ? '' : item.horas_normal}</div>
        ),
      })
    }

    if (columnsList?.apuracao_faltas) {
      columns.push({
        title: 'Faltas',
        width: 80,
        className: 'text-center',
        render: (item) => (
          <div>{item.horas_falta === '00:00' ? '' : item.horas_falta}</div>
        ),
      })
    }

    if (columnsList?.apuracao_carga) {
      columns.push({
        title: 'Carga',
        width: 80,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.carga_horaria_jornada === '00:00'
              ? ''
              : item.carga_horaria_jornada}
          </div>
        ),
      })
    }

    // if (columnsList?.apuracao_atraso) {
    //   columns.push({
    //     title: 'Atraso',
    //     width: 80,
    //     className: 'text-center',
    //     render: (item) => (
    //       <div>{item.horas_atraso == '00:00' ? '' : item.horas_atraso}</div>
    //     ),
    //   })
    // }

    if (columnsList?.apuracao_falta_dia) {
      columns.push({
        title: 'Falta/Dia',
        width: 80,
        className: 'text-center',
        render: (item) => <div>{item.falta_dia_inteiro ? '1' : ''}</div>,
      })
    }

    if (columnsList?.apuracao_ajuste) {
      columns.push({
        title: 'Ajuste',
        width: 80,
        className: 'text-center',
        render: (item) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
              onClick={() => this.ajustesOpen(item)}
            >
              {item._ajuste_horas_falta === '00:00'
                ? ''
                : item._ajuste_horas_falta}
            </div>
          )
        },
      })
    }

    // if (columnsList?.apuracao_dsr) {
    //   columns.push({
    //     title: 'Dsr',
    //     width: 80,
    //     className: 'text-center',
    //     render: (item) => (
    //       <div>{item.dsr_valor == '00:00' ? '' : item.dsr_valor}</div>
    //     ),
    //   })
    // }

    // if (columnsList?.apuracao_dsr_debito) {
    //   columns.push({
    //     title: 'Dsr Déb.',
    //     width: 80,
    //     className: 'text-center',
    //     render: (item) => (
    //       <div>{item.dsr_debito == '00:00' ? '' : item.dsr_debito}</div>
    //     ),
    //   })
    // }

    if (columnsList?.apuracao_adiconal_noturno) {
      columns.push({
        title: 'Adic. Noturno',
        width: 120,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.adicional_noturno === '00:00' ? '' : item.adicional_noturno}
          </div>
        ),
      })
    }

    if (columnsList?.apuracao_abono) {
      columns.push({
        title: 'Abono',
        width: 80,
        className: 'text-center',
        render: (item) => (
          <div>{item.horas_abono === '00:00' ? '' : item.horas_abono}</div>
        ),
      })
    }

    // if (columnsList?.apuracao_extra) {
    //   columns.push({
    //     title: 'Extra',
    //     width: 80,
    //     className: 'text-center',
    //     render: (item) => (
    //       <div>{item.horas_extra == '00:00' ? '' : item.horas_extra}</div>
    //     ),
    //   })
    // }

    this.setState({
      dynamicColumns: columns,
    })
  }

  getOvertimeColumns = () => {
    const { data, columnsList } = this.state
    let columns = []

    // hora_extra_diurna
    // hora_extra_intervalo
    // hora_extra_noturna

    data.forEach((item) => {
      ;(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {
        dados.hora_extra_faixas.forEach((faixa) => {
          const index = columns.findIndex(
            (column) =>
              column.percentual === faixa.percentual &&
              column.periodo === dados.periodo
          )

          if (index === -1) {
            if (
              (columnsList?.hora_extra_diurna && dados.periodo === 'diurno') ||
              (columnsList?.hora_extra_intervalo &&
                dados.periodo === 'intervalo') ||
              (columnsList?.hora_extra_noturna && dados.periodo === 'noturno')
            ) {
              columns.push({
                idsFaixa: [faixa.id],
                percentual: faixa.percentual,
                periodo: dados.periodo,
                title:
                  'Extra ' +
                  parseFloat(faixa.percentual) +
                  '%' +
                  dados.periodo.charAt(0).toUpperCase(),
                width: 110,
                className: 'no-ellipsis text-center',
              })
            }
          } else {
            columns[index].idsFaixa.push(faixa.id)
          }
        })
      })
    })

    columns.forEach((column) => {
      column.render = (item) => {
        let value = ''
        item.cartaopontohorasextras.forEach((extra) => {
          if (column.idsFaixa.includes(extra.horas_extras_faixa_id)) {
            value = extra.quantidade
            return false
          }
        })

        return <div>{value}</div>
      }
    })

    if (columnsList?.banco_hora) {
      columns.push({
        title: 'BCréd',
        width: 100,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.horas_banco_extras === '00:00' ? '' : item.horas_banco_extras}
          </div>
        ),
      })

      columns.push({
        title: 'BDéb',
        width: 100,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.horas_banco_falta === '00:00' ? '' : item.horas_banco_falta}
          </div>
        ),
      })

      columns.push({
        title: 'BTotal',
        width: 100,
        className: 'text-center',
        render: (item) => (
          <div>
            {(item.horas_banco_extras === '00:00'
              ? ''
              : item.horas_banco_extras) ||
              (item.horas_banco_falta === '00:00' ? '' : item.horas_banco_falta)}
          </div>
        ),
      })

      columns.push({
        title: 'BSaldo',
        width: 100,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.horas_banco_saldo === '00:00' ? '' : item.horas_banco_saldo}
          </div>
        ),
      })

      columns.push({
        title: 'BAjuste',
        width: 100,
        className: 'text-center',
        render: (item) => (
          <div>
            {item.horas_banco_ajustes === '00:00' ? '' : item.horas_banco_ajustes}
          </div>
        )
      })
    }

    this.setState({
      overtimeColumns: columns,
    })
  }

  columns = () => {
    return [
      {
        title: 'Funcionário',
        width: 230,
        render: (item) => (
          <div style={{ fontSize: '10px' }}>
            {item?.funcionario?.nome ?? '-'}
          </div>
        ),
      },
      // {
      //   title: 'Status',
      //   width: 75,
      //   className: 'status',
      //   render: (item) => (
      //     <div className="inner status-circle">
      //       <span
      //         style={{ backgroundColor: item?.status_cor ?? '#000' }}
      //       ></span>
      //     </div>
      //   ),
      // },
      {
        title: 'Previsto',
        width: 105,
        className: 'previsto',
        render: (item) => (
          <div className="inner">
            {!item?.folga &&
              !item?.feriado_id &&
              !item?.dia_especial &&
              (item?.jornadadiasemana?.jornada_batidas.length > 0
                ? item?.jornadadiasemana?.jornada_batidas
                : []
              ).map((batida, index) => {
                return (
                  <Fragment key={index}>
                    <span>
                      {batida.entrada} - {batida.saida}
                    </span>
                    <br />
                  </Fragment>
                )
              })}
          </div>
        ),
      },
      ...this.state.joinColumns,
      ...this.state.dynamicColumns,
      ...this.state.overtimeColumns,
    ]
  }

  columnsTotal = () => {
    return [
      {
        title: 'Total',
        width: 410 + this.state.maxJoinColumns * 80 * 2,
        render: () => <Fragment></Fragment>,
      },
      ...this.state.dynamicTotalColumns,
      ...this.state.overtimeTotalColumns,
    ]
  }

  getDynamicTotalColumns = () => {
    const { columnsList, dataTotal } = this.state
    let columns = []

    if (columnsList?.apuracao_normal) {
      columns.push({
        title: 'Normal',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_horas_normais === '00:00'
              ? ''
              : dataTotal.total_horas_normais}
          </div>
        ),
      })
    }

    if (columnsList?.apuracao_faltas) {
      columns.push({
        title: 'Faltas',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_horas_faltas === '00:00'
              ? ''
              : dataTotal.total_horas_faltas}
          </div>
        ),
      })
    }

    if (columnsList?.apuracao_carga) {
      columns.push({
        title: 'Carga',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_horas_carga === '00:00'
              ? ''
              : dataTotal.total_horas_carga}
          </div>
        ),
      })
    }

    // if (columnsList?.apuracao_atraso) {
    //   columns.push({
    //     title: 'Atraso',
    //     width: 80,
    //     className: 'text-center',
    //     render: () => (
    //       <div>
    //         {dataTotal.total_horas_atraso == '00:00'
    //           ? ''
    //           : dataTotal.total_horas_atraso}
    //       </div>
    //     ),
    //   })
    // }

    if (columnsList?.apuracao_falta_dia) {
      columns.push({
        title: 'Falta/Dia',
        width: 80,
        className: 'text-center',
        render: () => <div>{dataTotal.total_falta_dia ? '1' : ''}</div>,
      })
    }

    if (columnsList?.apuracao_ajuste) {
      columns.push({
        title: 'Ajuste',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal._total_ajustado === '00:00'
              ? ''
              : dataTotal._total_ajustado}
          </div>
        ),
      })
    }

    // if (columnsList?.apuracao_dsr) {
    //   columns.push({
    //     title: 'Dsr',
    //     width: 80,
    //     className: 'text-center',
    //     render: () => (
    //       <div>{dataTotal.total_dsr == '00:00' ? '' : dataTotal.total_dsr}</div>
    //     ),
    //   })
    // }

    // if (columnsList?.apuracao_dsr_debito) {
    //   columns.push({
    //     title: 'Dsr Déb.',
    //     width: 80,
    //     className: 'text-center',
    //     render: () => (
    //       <div>
    //         {dataTotal.total_dsr_debito == '00:00'
    //           ? ''
    //           : dataTotal.total_dsr_debito}
    //       </div>
    //     ),
    //   })
    // }

    if (columnsList?.apuracao_adiconal_noturno) {
      columns.push({
        title: 'Adic. Noturno',
        width: 120,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_adiconal_noturo === '00:00'
              ? ''
              : dataTotal.total_adiconal_noturo}
          </div>
        ),
      })
    }

    if (columnsList?.apuracao_abono) {
      columns.push({
        title: 'Abono',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_abono === '00:00' ? '' : dataTotal.total_abono}
          </div>
        ),
      })
    }

    if (columnsList?.apuracao_extra) {
      columns.push({
        title: 'Extra',
        width: 80,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.total_horas_extras === '00:00'
              ? ''
              : dataTotal.total_horas_extras}
          </div>
        ),
      })
    }

    this.setState({
      dynamicTotalColumns: columns,
    })
  }

  getOvertimeTotalColumns = () => {
    const { data, dataTotal, columnsList } = this.state
    let columns = []

    // hora_extra_diurna
    // hora_extra_intervalo
    // hora_extra_noturna

    data.forEach((item) => {
      ;(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {
        dados.hora_extra_faixas.forEach((faixa) => {
          const index = columns.findIndex(
            (column) =>
              column.percentual === faixa.percentual &&
              column.periodo === dados.periodo
          )

          if (index === -1) {
            if (
              (columnsList?.hora_extra_diurna && dados.periodo === 'diurno') ||
              (columnsList?.hora_extra_intervalo &&
                dados.periodo === 'intervalo') ||
              (columnsList?.hora_extra_noturna && dados.periodo === 'noturno')
            ) {
              columns.push({
                idsFaixa: [faixa.id],
                percentual: faixa.percentual,
                periodo: dados.periodo,
                title:
                  'Extra ' +
                  parseFloat(faixa.percentual) +
                  '%' +
                  dados.periodo.charAt(0).toUpperCase(),
                width: 110,
                className: 'no-ellipsis',
              })
            }
          } else {
            columns[index].idsFaixa.push(faixa.id)
          }
        })
      })
    })

    columns.forEach((column) => {
      column.render = () => {
        let value = ''
        if (dataTotal?.horas_extras?.length > 0) {
          Object.keys(dataTotal.horas_extras).forEach((key) => {
            if (column.idsFaixa.includes(parseInt(key))) {
              value = dataTotal.horas_extras[key]
              return false
            }
          })
        }

        return <div>{value}</div>
      }
    })

    if (columnsList?.banco_hora) {
      columns.push({
        title: 'BCréd',
        width: 100,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.bh_horas_extras === '00:00'
              ? ''
              : dataTotal.bh_horas_extras}
          </div>
        ),
      })

      columns.push({
        title: 'BDéb',
        width: 100,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.bh_horas_falta === '00:00'
              ? ''
              : dataTotal.bh_horas_falta}
          </div>
        ),
      })

      columns.push({
        title: 'BTotal',
        width: 100,
        className: 'text-center',
        render: () => (
          <div>
            {(dataTotal.bh_horas_extras === '00:00'
              ? ''
              : dataTotal.bh_horas_extras) ||
              (dataTotal.bh_horas_falta === '00:00'
                ? ''
                : dataTotal.bh_horas_falta)}
          </div>
        ),
      })

      columns.push({
        title: 'BSaldo',
        width: 100,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.bh_horas_saldo === '00:00'
              ? ''
              : dataTotal.bh_horas_saldo}
          </div>
        ),
      })

      columns.push({
        title: 'BAjuste',
        width: 100,
        className: 'text-center',
        render: () => (
          <div>
            {dataTotal.bh_horas_ajuste === '00:00'
              ? ''
              : dataTotal.bh_horas_ajuste}
          </div>
        ),
      })
    }

    this.setState({
      overtimeTotalColumns: columns,
    })
  }

  handleGetInputOuputMarkTime = (item) => {
    if (!item) return;

    let max = 0;

    item?.cartao.map(value => {
      let totalBatidasJornada = value.jornadadiasemana?.jornada_batidas?.length ?? 0;
      let totalBatidasCartao  = value.cartaopontobatida?.length ?? 0;

      if (totalBatidasJornada) {
        const maxJornada = value.jornadadiasemana.jornada_batidas[totalBatidasJornada - 1].sequencia;

        if (maxJornada > max) {
          max = maxJornada
        }
      }

      if (totalBatidasCartao) {
        const maxCartao = value.cartaopontobatida[totalBatidasCartao - 1].sequencia;
        
        if (maxCartao > max) {
          max = maxCartao
        }
      }
    });

    return max;
  }
  
  getMarkTimeHeaders = (max) => {
    return Array.from({ length: max }).reduce((acc, _, index) => {
      return [...acc, `Ent ${index + 1}`, `Sai ${index + 1}`];
    }, []);

    // old
    // return [...Array(max).keys()].map((index) => (
    //   <Fragment key={index}>
    //     <Col span={2} className="thead"><strong>{`Ent ${index + 1}`}</strong></Col>
    //     <Col span={2} className="thead"><strong>{`Saí ${index + 1}`}</strong></Col>
    //   </Fragment>
    // ));
  }

  getEntries = (max, item) => {
    return Array.from({ length: max }).reduce((acc, _, batidaIndex) => {
      const batida = item.cartaopontobatida[batidaIndex];
  
      let entrada, saida;
      let statusBatida = this.formatBatidaStatus(item, batida);
  
      if (batida?.entrada_batida) {
        entrada = moment.utc(batida.entrada_batida).format('HH:mm');
      } else {
        entrada = statusBatida || ' ';
      }
  
      if (batida?.saida_batida) {
        saida = moment.utc(batida.saida_batida).format('HH:mm');
      } else {
        saida = statusBatida || ' ';
      }
  
      let legendaEntrada = '';
      if (batida?.ponto_ajuste_entrada && entrada.trim() !== '') {
        legendaEntrada = '*';
      }
      if (batida?.entrada_auto_batida) legendaEntrada = '^';
  
      let legendaSaida = '';
      if (batida?.ponto_ajuste_saida && saida.trim() !== '') {
        legendaSaida = '*';
      }
      if (batida?.saida_auto_batida) legendaSaida = '^';

      entrada = entrada.trim() !== ' ' ? `${entrada}${legendaEntrada}` : entrada;
      saida = saida.trim() !== ' ' ? `${saida}${legendaSaida}` : saida;
  
      return [...acc, entrada, saida];
    }, []);
    // old
    // return [...Array(max).keys()].map((index) => {
    //   const batidaIndex = value.cartaopontobatida.findIndex(batida => batida.sequencia === (index + 1));
    //   return (
    //     <Fragment key={index}>
    //       <Col span={2} >
    //         <p>
    //           {
    //             value.cartaopontobatida[batidaIndex]?.entrada_batida ? moment.utc(value.cartaopontobatida[batidaIndex]?.entrada_batida).format('HH:mm') : <div style={{ height: '20px' }}></div>
    //           }
    //         </p>
    //       </Col>
    //       <Col span={2}>
    //         <p>
    //           {
    //             value.cartaopontobatida[batidaIndex]?.saida_batida ? moment.utc(value.cartaopontobatida[batidaIndex]?.saida_batida).format('HH:mm') : null
    //           }
    //         </p>
    //       </Col>
    //     </Fragment>
    //   )
    // })
  }

  handleGetTitleColumnsInputOuputMarkTime = (max, item) => {
    return [...Array(max).keys()].map((index) => {
      return (
        <Fragment key={index}>
          {
            <Col span={3}>
              <div className="thead"><strong>{`Ent ${index + 1}`}</strong></div>
              <div className="tbody table-list-row">
                {
                  item?.cartao.map((value) => {
                    const batidaIndex = value.cartaopontobatida.findIndex(batida => batida.sequencia === (index + 1));

                    return (
                      <Fragment>
                        <p>
                          {
                            value.cartaopontobatida[batidaIndex]?.entrada_batida ? moment.utc(value.cartaopontobatida[batidaIndex]?.entrada_batida).format('HH:mm') : <div style={{ height: '20px' }}></div>
                          }
                        </p>
                      </Fragment>
                    )
                  })
                }
              </div>
            </Col>
          }
          {
            <Col span={3}>
              <div className="thead"><strong>{`Saí ${index + 1}`}</strong></div>
              <div className="tbody">
                {
                  item?.cartao.map((value) => {
                    const batidaIndex = value.cartaopontobatida.findIndex(batida => batida.sequencia === (index + 1));

                    return (
                      <Fragment>
                        <p>
                          {
                            value.cartaopontobatida[batidaIndex]?.saida_batida ? moment.utc(value.cartaopontobatida[batidaIndex]?.saida_batida).format('HH:mm') : null
                          }
                        </p>
                      </Fragment>
                    )
                  })
                }
              </div>
            </Col> 
          }
        </Fragment>
      )
    })
  }

  handleGetWorkingDays = (max, item) => {
    const [contrato] = item?.contrato || [];
    const { jornada } = contrato || {};
    const { jornadas_dias_semana } = jornada || {};

    const order = ['SEGUNDA', 'TERCA', 'QUARTA', 'QUINTA', 'SEXTA', 'SABADO', 'DOMINGO'];

    const daysMap = jornadas_dias_semana.reduce((map, item) => {
      const dia = item.dia?.toUpperCase();
      if (order.includes(dia)) {
        map[dia] = item.jornada_batidas.map(jornada_dia_semana => [
          jornada_dia_semana?.entrada && moment(jornada_dia_semana?.entrada).isValid() ? moment(jornada_dia_semana?.entrada).format('HH:mm') : ' ',
          jornada_dia_semana?.saida && moment(jornada_dia_semana?.saida).isValid() ? moment(jornada_dia_semana?.saida).format('HH:mm') : ' '
        ]).flat();
      }
      return map;
    }, {});

    const list = order.map(day => {
      const entries = daysMap[day] || [];
      return [day, ...entries];
    });

    return list;
  }

  getExtraHours = (item) => {
    const { columnsData } = this.state;
    let columns = [];
  
    item?.cartao?.forEach(card => {
      (card.hora_extra?.hora_extra_dados ?? []).forEach(dados => {
        dados.hora_extra_faixas.forEach(faixa => {
          if (
            (columnsData?.hora_extra_diurna && dados.periodo === "diurno") ||
            (columnsData?.hora_extra_intervalo && dados.periodo === "intervalo") ||
            (columnsData?.hora_extra_noturna && dados.periodo === "noturno")
          ) {
            const index = columns.findIndex(column => column.percentual === faixa.percentual && column.periodo === dados.periodo);
            if (index === -1) {
              columns.push({
                ids: [faixa.id],
                percentual: faixa.percentual,
                periodo: dados.periodo,
                title: `Extra ${parseFloat(faixa.percentual)}%${dados.periodo.charAt(0).toUpperCase()}`
              });
            } else {
              columns[index].ids.push(faixa.id);
            }
          }
        });
      });
    });
  
    return columns;
  };  

  handleGetExtraLabel = (faixas) => {
    const { columnsData } = this.state;
    const {
      hora_extra_intervalo,
      hora_extra_diurna,
      hora_extra_noturna,
    } = columnsData || {};

    return faixas?.filter(faixa =>
      (hora_extra_diurna && faixa.periodo === "diurno") ||
      (hora_extra_intervalo && faixa.periodo === "intervalo") ||
      (hora_extra_noturna && faixa.periodo === "noturno")
      ).map(faixa => 
        <div 
          style={{ 
            textAlign: faixa.length - 1 ? 'left' : 'center', 
            lineHeight: '1.08', 
            width: '45px' 
          }}
        >
          <div>Extra </div>
          <div>{+faixa.percentual}% {faixa.periodo.charAt(0).toUpperCase()}</div>
        </div>
      );
    };
  
  handleGetExtraValues = (item, faixas) => {
    const { columnsData } = this.state;
    const {
      hora_extra_intervalo,
      hora_extra_diurna,
      hora_extra_noturna,
    } = columnsData || {};

    return faixas?.map(faixa => {

      const horasExtrasObj = item?.cartaopontohorasextras.find(horaExtra => faixa.ids.includes(horaExtra.horas_extras_faixa_id));

      const quantidade = horasExtrasObj ? horasExtrasObj.quantidade : '';

      switch (faixa?.periodo) {
        case 'diurno':
          return hora_extra_diurna ? quantidade : '';
        case 'noturno':
          return hora_extra_noturna ? quantidade : '';
        case 'intervalo':
          return hora_extra_intervalo ? quantidade : '';
        default:
          return '';
      }
    });
  };
  
    
  formatTime = (time, useDefaultForZero = false) => {
    if (!time || time === '00:00:00' || time === '00:00') return useDefaultForZero ? '00:00' : '';
    const timeToString = time.toString();
  
    const isNegative = timeToString.startsWith('-');
    let formattedTime = isNegative ? timeToString.substring(1) : timeToString;
  
    let parts = formattedTime.split(':');
    let hours = parts[0];
    let minutes = parts[1] || '00';
  
    formattedTime = `${hours}:${minutes}`;
  
    return isNegative ? `-${formattedTime}` : formattedTime;
  };

  formatFaltaDiaInteiro = (falta) => {
    if (falta === 1) return '1';
    if (falta === 0) return ' ';
    return '';
  };

  formatAjuste = (time) => {
    if (!time || time === 0) return ' ';
    const timeMoment = moment().startOf("day").add(Math.abs(time), "minutes");
    return (time < 0 ? '-' : '') + timeMoment.format("HH:mm");
  };

  formatLabel = (label) => {
    const parts = label.split(' ');
    return (
      <div style={{  marginLeft: '5px', lineHeight: '1.05' }}>
        <div>{parts[0]}</div>
        <div>{parts.slice(1).join(' ')}</div>
      </div>
    );
  }

  extractTextFromReactElement = (element) => {
    if (typeof element === 'string' || typeof element === 'number') {
      const elements = element.toString();
      return elements;
    }
  
    if (Array.isArray(element)) {
      return element.map(this.extractTextFromReactElement).join('');
    }
  
    if (React.isValidElement(element)) {
      return this.extractTextFromReactElement(element.props.children);
    }
  
    if (typeof element === 'object' && element !== null && 'props' in element) {
      return this.extractTextFromReactElement(element.props.children);
    }
  
    return '';
  };
  
  getHeaders = (item, faixas) => {
    const { columnsData } = this.state;
    const {
      apuracao_normal,
      apuracao_carga,
      apuracao_abono,
      apuracao_atraso,
      apuracao_falta_dia,
      apuracao_ajuste,
      apuracao_faltas,
      apuracao_dsr,
      apuracao_dsr_debito,
      apuracao_adiconal_noturno,
      apuracao_extra,
			banco_hora,
    } = columnsData || {};
  
    const anyUseBancoHoras = item?.contrato?.some(contrato => contrato.nao_usa_banco_horas === 0);

    const list = ['Data'];
    const entriesCols = this.getMarkTimeHeaders(this.state.maxSequence);
    list.push(...entriesCols);
  
    if (apuracao_normal) list.push('Normais');
  
    if (apuracao_carga) list.push('Carga');
    
    if (apuracao_abono) list.push('Abono');

    if (apuracao_atraso) list.push('Atraso');

    if (apuracao_falta_dia) list.push(this.formatLabel('Falta Dia'));

    if (apuracao_ajuste) list.push('Ajuste');

    if (apuracao_faltas) list.push('Faltas');
    
    if (apuracao_dsr) list.push('Dsr');

    if (apuracao_dsr_debito) list.push(this.formatLabel('Dsr Deb.'));

    if (apuracao_adiconal_noturno) list.push(this.formatLabel('Adic. Noturno'));

    if (apuracao_extra) list.push('Extra');
  
    const extraColumns = this.handleGetExtraLabel(faixas)?.map((label) => label);
      if (extraColumns && extraColumns.length >= 0) {
        list.push(...extraColumns);
      }

    if (banco_hora && anyUseBancoHoras) {
      list.push('BCréd', 'BDéb', 'BTotal', 'BSaldo', 'BAjus');
    }

  
    return list;
  };  

  getColumnsData = (item, faixas) => {
    const headers = this.getHeaders(item, faixas);

    const logicHeaders = headers.map(header => {
      if (React.isValidElement(header)) {
        return this.extractTextFromReactElement(header);
      }
      return header;
    });    

    const activeColumns = new Set(logicHeaders.map(h => h.replace(/\W/g, '').toLowerCase()));

    return item?.cartao.map((card, i) => {
      const date = `${moment(card.data).format('DD/MM/YYYY')} - ${moment(card.data).format('ddd')}`;
      const entries = this.getEntries(this.state.maxSequence, card, i);
  
      let row = [date, ...entries]; 
  
      if (activeColumns.has('normais')) row.push(this.formatTime(card?.horas_normal));
      if (activeColumns.has('carga')) row.push(this.formatTime(card?.carga_horaria_jornada));
      if (activeColumns.has('abono')) row.push(this.formatTime(card?.horas_abono));
      if (activeColumns.has('atraso')) row.push(this.formatTime(card?.horas_atraso));
      if (activeColumns.has('faltadia')) row.push(this.formatFaltaDiaInteiro(card?.falta_dia_inteiro));
      if (activeColumns.has('ajuste')) row.push(this.formatAjuste(card?.ajuste_horas_falta));
      if (activeColumns.has('faltas')) row.push(this.formatTime(card?.horas_falta));
      if (activeColumns.has('dsr')) row.push(this.formatTime(card?.dsr_valor));
      if (activeColumns.has('dsrdeb')) row.push(this.formatTime(card?.dsr_debito));
      if (activeColumns.has('adicnoturno')) row.push(this.formatTime(card?.adicional_noturno));
      if (activeColumns.has('extra')) row.push(this.formatTime(card?.horas_extra));
  
      const extraValues = this.handleGetExtraValues(card, faixas);
      logicHeaders.forEach(header => {
        if (header.startsWith('Extra ')) { 
          const value = extraValues.shift();
          row.push(this.formatTime(value));
        }
      });
  
      if (activeColumns.has('bcrd')) row.push(this.formatTime(card?.horas_banco_extras));
      if (activeColumns.has('bdb')) row.push(this.formatTime(card?.horas_banco_falta));
      if (activeColumns.has('btotal')) row.push(this.formatTime(card?.horas_banco_total));
      if (activeColumns.has('bsaldo')) row.push(this.formatTime(card?.horas_banco_saldo, true));
      if (activeColumns.has('bajus')) row.push(this.formatTime(card?.horas_banco_ajustes));
  
      return row;
    });
  }; 

  getObservations = (item) => {
    const observations = item?.cartao?.filter(obs => obs.observacao && obs.observacao.trim() !== '')
      .map(obs => {
        return {
          observacao: obs.observacao,
          createdAt: moment(obs.data).format('DD/MM/YYYY')
        };
      });
  
    return observations;
  };

  getPartialJustifications = (item) => {
    const calculateHourlyAllowance = (start, end) => {
      if (start && end && start !== '00:00:00' && end !== '00:00:00') {
        const startTime = moment(start, 'HH:mm:ss');
        const endTime = moment(end, 'HH:mm:ss');
        const duration = moment.duration(endTime.diff(startTime));
        return `${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}`;
      }
      return ' ';
    };

    const justifications = item?.cartao?.flatMap(cartao =>
      cartao.justificativa_parciais?.map(justificativa => {
        const hourlyAllowance = calculateHourlyAllowance(justificativa.hora_inicio, justificativa.hora_fim);
        return {
          justificationName: justificativa?.justificativa?.nome,
          hourlyAllowance,
          createdAt: moment(justificativa.data).format('DD/MM/YYYY')
        };
      })
    );
  
    return justifications?.filter(j => j);
  };  

  getHeaderClass = (numColumns) => {
    return numColumns > 15 ? "large-table" : null;
  }

  getClassForColumn = (list, index, listLength) => {
    const item = list[index];
    const isEntryOrExit = typeof item === 'string' && (item.includes('Ent') || item.includes('Sai'));

    if (index === 0) {
      return 'first-column-cell';
    } else if (isEntryOrExit) {
      return 'entry-exit-cell';
    } else if (index === listLength - 1) {
      return 'last-column-cell';
    } else {
      return 'default-cell';
    }
  };  
  
  getLastColumn = (item, faixas) => {
    const headers = this.getHeaders(item, faixas);

    const headersName = headers.map(header => {
      if (React.isValidElement(header)) {
        return this.extractTextFromReactElement(header);
      }
      return header;
    }); 

    return headersName[headersName.length - 1];
  };

  render() {
    const { isLoading, data, columnsData } = this.state;
    const { user } = this.props;
		const { name: userName } = user || {};

    const {
      apuracao_normal,
      apuracao_carga,
      apuracao_abono,
      apuracao_atraso,
      apuracao_falta_dia,
      apuracao_ajuste,
      apuracao_faltas,
      apuracao_dsr,
      apuracao_dsr_debito,
      apuracao_adiconal_noturno,
      apuracao_extra,
      hora_extra_intervalo,
      hora_extra_diurna,
      hora_extra_noturna,
      banco_hora
    } = columnsData || {};

    const pageBreakAfter = data?.length > 1;

    return (
      <Fragment>
        {isLoading ? (
          <div
            style={{
              position: 'fixed',
              top: '150px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Spin
              spinning={isLoading}
              indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
            />
          </div>
        ) : data.length > 0 ? (
          <Fragment>
            {data?.map((item, i) => {
              const taxVar = item?.contrato[0]?.empresa?.cnpj ? 'CNPJ' : 'CPF';
              const taxValue = item?.contrato[0]?.empresa?.cnpj || item?.contrato[0]?.empresa?.cpf;

              const faixas = this.getExtraHours(item);
              const list = this.getHeaders(item, faixas);
              const listData = this.getColumnsData(item, faixas) || [];
              const lastColumnName = this.getLastColumn(item, faixas);
              const listObservations = this.getObservations(item) || [];
              const listPartialJustifications = this.getPartialJustifications(item) || [];

              const style = `@page { size: A4 landscape; margin: 0 }`;
              const tableClass = `table-impressao cartao-ponto-${item} ${pageBreakAfter ? 'page-break-after' : ''}`;

              return (
                <>
                  <table key={i} className={tableClass}>
                    <thead>
                      <tr>
                        <td className="time-card">
                          <header>
                            <div className="logo-container">
                              <div>
                                <img
                                  src="/images/logos/logo-complete.svg"
                                  alt="ByPonto"
                                  width="135" />
                              </div>
                              <div className="logo-title">
                                <div>
                                  <span style={{ fontSize: 22, fontWeight: 600, marginBottom: 8 }}>Cartão Ponto</span>
                                </div>
                                <div>
                                  <div style={{ fontSize: '10px' }}>Período: {moment(this.state.dataInicial).format('DD/MM/YYYY')} até {moment(this.state.dataFinal).format('DD/MM/YYYY')}</div>                               
                                </div>
                                <div className="user-info">
                                  <h5 style={{ marginBottom: '0.1em' }}>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h5>
                                </div>
                              </div>
                            </div>
                            <div className={listData?.length > 15 ? "info-container" : "info-container-large"}>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Row className="row-section">
                                    <Col span={24}>
                                      <div className='row-section-flex'>
                                        <div><strong>EMPRESA:</strong></div>
                                        <div>{item?.contrato[0]?.empresa?.nome}</div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="row-section">
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>{taxVar}:</strong></div>
                                        <div>{taxValue}</div>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>INSCRIÇÃO:</strong></div>
                                        <div>{item?.contrato[0]?.empresa?.ie}</div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="row-section">
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>NOME:</strong></div>
                                        <div>{item?.contrato[0]?.funcionario?.nome}</div>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>Nº FOLHA:</strong></div>
                                        <div>{item?.contrato[0]?.funcionario?.numero_folha}</div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="row-section">
                                    <Col span={8}>
                                      <div className='row-section-flex'>
                                        <div><strong>C.T.P.S:</strong></div>
                                        <div>{item?.contrato[0]?.funcionario?.ctps}</div>
                                      </div>
                                    </Col>
                                    <Col span={8}>
                                      <div className='row-section-flex'>
                                        <div><strong>CPF:</strong></div>
                                        <div>{item?.contrato[0]?.funcionario?.cpf}</div>
                                      </div>
                                    </Col>
                                    <Col span={8}>
                                      <div className='row-section-flex'>
                                        <div><strong>ADMISSÃO:</strong></div>
                                        <div>{moment(item?.contrato[0]?.data_admissao).format('DD/MM/YYYY')}</div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="row-section">
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>FUNÇÃO:</strong></div>
                                        <div>{item?.contrato[0]?.funcoes?.nome}</div>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div className='row-section-flex'>
                                        <div><strong>DEPARTAMENTO:</strong></div>
                                        <div>{item?.contrato[0]?.departamento?.nome}</div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24}>
                                      <div className="working-hours">HORÁRIO DE TRABALHO</div>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={24}>
                                      <table className={listData?.length > 15 ? "hours-table" : "hours-table-large"}>
                                        <thead>
                                          <tr>
                                            {['Data', ...this.getMarkTimeHeaders(this.state.maxSequence)].map((col, index, arr) => (
                                              <th 
                                                key={index}
                                                style={{ 
                                                  width: index === arr.length - 1 ? 'auto' : '45px',
                                                  textAlign: index === arr.length - 1 || index === 0 ? 'left' : 'center',
                                                }}
                                              >
                                                {col}
                                              </th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.handleGetWorkingDays(this.state.maxSequence, item).map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                              {['Data', ...this.getMarkTimeHeaders(this.state.maxSequence)].map((_, cellIndex, arr) => (
                                                <td 
                                                  key={cellIndex}
                                                  style={{ 
                                                    width: cellIndex === arr.length - 1 ? 'auto' : '45px',
                                                    textAlign: cellIndex === arr.length - 1 || cellIndex === 0 ? 'left' : 'center',
                                                  }}
                                                >
                                                  {row[cellIndex]}
                                                </td>
                                              ))}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </header>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <main>
                            <div className="table-container">
                              <table className="responsive-table">
                                <thead>
                                  <tr>
                                    {list?.map((col, index) => (
                                      <th 
                                        key={index} 
                                        className={`${this.getHeaderClass(listData.length)} ${this.getClassForColumn(list, index, list.length)}`}
                                      >
                                        <div className={index === list.length - 1 ? 'last-column-content-center' : ''}>
                                          {col}
                                        </div>
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {listData?.map((row, index) => (
                                    <tr key={index}>
                                      {list?.map((_, i) => (
                                        <td 
                                          key={i} 
                                          className={`${this.getHeaderClass(listData?.length)} ${this.getClassForColumn(list, i, list.length)}`}
                                        >
                                          <div style={ i === list?.length - 1 ? { marginLeft: '20px' } : {}}>
                                            {row[i]}
                                          </div>
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td key={i} className={(list?.length > 15 || listData?.length > 15) && i ===  0 ? "negrito" : i ===  0 ? "text-left negrito-large" : "negrito-large text-center"}>TOTAIS</td>
                                    <td colSpan={this.state.maxSequence * 2}></td>
                                    {apuracao_normal === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Normais' ? { textAlign: 'left', marginLeft: '20px' } : {}}>
                                          {item?.total?.total_horas_normais || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_carga === 1 && (
                                      <td 
                                       className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Carga' ? { textAlign: 'left', marginLeft: '20px' } : {}}>                                         
                                          {item?.total?.total_horas_carga || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_abono === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Abono' ? { textAlign: 'left', marginLeft: '20px' } : {}}>  
                                          {item?.total?.total_abono || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_atraso === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Atraso' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_horas_atraso || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_falta_dia === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large"}
                                      >
                                        <div style={ lastColumnName === 'FaltaDia' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_falta_dia || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_ajuste === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Ajuste' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_ajustado || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_faltas === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Falta' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_horas_faltas || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_dsr === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Dsr' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_dsr || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_dsr_debito === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'DsrDeb.' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_dsr_debito || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_adiconal_noturno === 1 && (
                                      <td 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Adic.Noturno' ? { textAlign: 'left', marginLeft: '20px' } : {}}> 
                                          {item?.total?.total_adiconal_noturo || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {apuracao_extra === 1 && (
                                      <td 
                                        key={i} 
                                        className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"}
                                      >
                                        <div style={ lastColumnName === 'Extra' ? { textAlign: 'left', marginLeft: '20px' } : {}}>
                                          {item?.total?.total_horas_extras || '00:00'}
                                        </div>
                                      </td>
                                    )}
                                    {faixas?.map((faixa, i) => {
                                      const deveExibir = 
                                        faixa?.periodo === 'diurno' ? hora_extra_diurna :
                                        faixa?.periodo === 'noturno' ? hora_extra_noturna :
                                        faixa?.periodo === 'intervalo' ? hora_extra_intervalo : false;

                                      const horasExtras = item?.total?.horas_extras;
                                      let extraValue = '00:00';
                                      if (deveExibir && horasExtras && faixa?.ids) {
                                        for (const id of faixa.ids) {
                                          if (id in horasExtras) {
                                            extraValue = horasExtras[id];
                                            break;
                                          }
                                        }
                                      }
                                      return (
                                        <td 
                                          key={i} 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"} 
                                          style={ i === faixas?.length -1 ? { textAlign: 'left' } : { textAlign: 'center' } }
                                        >
                                          <div style={ i === faixas?.length -1 ? { textAlign: 'left', marginLeft: '20px' } : { textAlign: 'center' } }>
                                            {extraValue}
                                          </div>
                                        </td>
                                      )
                                    })}
                                    {banco_hora === 1 && item?.contrato[0]?.nao_usa_banco_horas === 0 && (
                                      <>
                                        <td 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"} 
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item?.total?.bh_horas_extras || '00:00'
                                        }</td>
                                        <td 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"} 
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item?.total?.bh_horas_falta || '00:00'}
                                        </td>
                                        <td 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"} 
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item?.total?.bh_horas_total || '00:00'}
                                        </td>
                                        <td 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large text-center"} 
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item?.total?.bh_horas_saldo || '00:00'}
                                        </td>
                                        <td 
                                          className={list?.length > 15 || listData?.length > 15 ? "negrito text-center" : "negrito-large"} 
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                                            {item?.total?.bh_horas_ajuste || '00:00'}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <Row className="mt-1rem">
                                <Col xs={24}>
                                  <div className="content-subtitles">
                                    <span>(*) - Batida lançada manualmente</span>
                                    <span>(^) - Pré-Assinalado</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            {listPartialJustifications && listPartialJustifications?.length >0 &&
                              <Row className="mt-1rem">
                                <Col xs={24}>
                                  <div>
                                    <h4 className={list?.length > 15 || listData?.length > 15 ? "negrito" : "negrito-large"}>Justificativas Parciais:</h4>
                                  </div>
                                  {listPartialJustifications && listPartialJustifications.map((justifications, index) => (
                                    <ul key={index} className='row-section-flex'>
                                      <li className='fontSize10'>{justifications.justificationName}:</li>
                                      <span className='fontSize10'>{justifications.hourlyAllowance} hora(s)</span>
                                      <span className='fontSize10'>em {justifications.createdAt}</span>
                                    </ul>
                                  ))}
                                </Col>
                              </Row>
                            }

                            {listObservations && listObservations?.length > 0 &&
                              <Row className="mt-1rem">
                                <Col xs={24}>
                                  <div>
                                    <h4 className={list?.length > 15 || listData?.length > 15 ? "negrito" : "negrito-large"}>Observações:</h4>
                                  </div>
                                  {listObservations && listObservations.map((obs, index) => (
                                    <ul key={index} className='row-section-flex'>
                                      <li className='fontSize10'>{obs.createdAt} -</li>
                                      <span className='fontSize10'>{obs.observacao}</span>
                                    </ul>
                                  ))}
                                </Col>
                              </Row>
                            }

                            <Row className={listObservations?.length > 0 ? "assinaturas mt-3rem" : "assinaturas mt-20px"}>
                                <Col xs={12}>
                                  <div style={{ fontSize: '10px' }}>{item?.contrato[0]?.empresa?.nome}</div>
                                </Col>
                                <Col xs={12}>
                                  <div  style={{ fontSize: '10px' }}>{item?.contrato[0]?.funcionario?.nome}</div>
                                </Col>
                            </Row>
                          </main>
                        </td>
                      </tr>
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td>
                          <div className="footer-space">&nbsp;</div>
                        </td>
                      </tr>
                    </tfoot> */}
                  </table>
                  <style dangerouslySetInnerHTML={{ __html: style }} />
                </>
              )
            })}
          </Fragment>
        ) : (
          <Empty
            description="Nenhum registro encontrado com esses parâmetros."
            style={{
              padding: '20px',
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Button type="primary" onClick={() => window.close()}>
              Voltar
            </Button>
          </Empty>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
