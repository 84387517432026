import React from 'react';
import { Upload, message, Button } from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { employeesService } from "../../redux/services";

const awsFileUrl = 'https://prod-byponto-arquivos.s3.amazonaws.com/modeloImportFuncionario.xlsx';

const { Dragger } = Upload;

const props = {
  name: 'file',
  multiple: true,
  customRequest: ({ file, onProgress, onError, onSuccess }) => 
    handleFileUpload({ file, onProgress, onError, onSuccess }),
  accept: '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  beforeUpload(file) {
    const isExcel = 
      file.type === 'application/vnd.ms-excel' || 
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.name.endsWith('.xls') || 
      file.name.endsWith('.xlsx');
    if (!isExcel) {
      message.error('Você só pode fazer upload de arquivos (.xls, .xlsx).');
    }
    return isExcel ? true : Upload.LIST_IGNORE;
  },
};

const handleFileUpload = async ({ file, onProgress, onError, onSuccess }) => {
  try {
    onProgress({ percent: 50 });
    const res = await employeesService.storeImport(file);
    onSuccess();
    message.success('Arquivo enviado com sucesso!.');

    const created = res.data.created;
    const updated = res.data.updated;

    if (created === 0 && updated === 0) {
        message.info('Nenhum usuário foi criado ou atualizado.');
    } else {
        if (created > 0) {
            message.info(`${created} funcionários foram criados.`);
        }

        if (updated > 0) {
            message.info(`${updated} funcionários foram atualizados.`);
        }
    }
  } catch (error) {
    onError(error);
    message.error('Erro ao enviar o arquivo:', error);
  }
};

const downloadFile = () => {
  const link = document.createElement('a');
  link.href = awsFileUrl;
  link.setAttribute('download', 'Modelo_Parametros.xlsx');

  document.body.appendChild(link);
  link.click();

  link.parentNode.removeChild(link);
};

const FileUpload = () => (
  <>
    <div className="modal-btn-fileUpload">
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        size="middle"
        className="border-radius"
        onClick={downloadFile}
      >
        Baixar Modelo .xlsx
      </Button>
    </div>
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
      <p className="ant-upload-hint">
        Suporte para arquivos com extensão (.xls, .xlsx).
      </p>
      <p className="ant-upload-hint">
        Formato de colunas padrão, de acordo com o arquivo de modelo, para ter acesso clique no botão: "Baixar Modelo .xlsx"
      </p>
    </Dragger>
  </>
);

export default FileUpload;
