import React, { Component, Fragment } from "react";

import * as PropTypes from "prop-types";

import { Dropdown, Button, Menu, Modal } from "antd";

import {
	UIPageListing,
} from "../../../components";

import { employeesAdressesService } from "../../../redux/services";

import ModalEdit from "./edit";
import ModalCreate from "./create";
import ModalView from "./view";

const config = {
	searchPlaceholder: "Buscar Cidade",
	permissionPrefix: "customers",
};

class Index extends Component {
	static propTypes = {
		showOnly: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.state = {
			listType: "list",
			dataAll: [],
			data: [],
			search: '',
			// Owner item
			ownerItem: {},
			// Actions
			editModalVisible: false,
			viewModalVisible: false,
		};
	}

	init = (ownerItem) => {
		this.setState({
			ownerItem: ownerItem,
			// dataAll: ownerItem.enderecos,
			// data: ownerItem.enderecos,

		});

		setTimeout(() => {
			this.fetchGetAll();
		}, 10)
	};

	/**
	 * Create
	 */
	createOpen = () => {
		this.setState({ createModalVisible: true });

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({ createModalVisible: false });
	};

	createOnComplete = () => {
		this.setState({ createModalVisible: false });

		// Fecth all
		this.fetchGetAll();
	};

	/**
	 * Edit
	 *
	 * @param uuid
	 */
	editOpen = ({ uuid }) => {
		this.setState({ editModalVisible: true });

		// On open screen
		this.editScreen.onOpen(uuid);
	};

	/**
	 * View
	 *
	 * @param uuid
	 */
	viewOpen = ({ uuid }) => {
		this.setState({ viewModalVisible: true });

		// On open screen
		this.viewScreen.onOpen(uuid);
	};

	viewOnClose = () => {
		this.setState({ viewModalVisible: false });
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = ({ uuid }) => {
		Modal.confirm({
			title: "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText: "Excluir",
			autoFocusButton: null,
			onOk: () => {
				return this.deleteConfirmed(uuid);
			}
		});
	};

	deleteConfirmed = (uuid) => {
		return employeesAdressesService.destroy(this.state.ownerItem.uuid, uuid)
			.then((response) => {
				// Fecth all
				this.fetchGetAll();
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	editOnComplete = () => {
		this.setState({ editModalVisible: false });

		// Fecth all
		this.fetchGetAll();
	};

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			{/* {this.props.permissions.includes(config.permissionPrefix + ".edit") &&  */}
			{!this.props.showOnly && (
				<Menu.Item key="edit">
					<a onClick={() => this.editOpen(item)}>
						<i className="fal fa-pen" />Editar
					</a>
				</Menu.Item>
			)}
			{/* } */}
			{/* {this.props.permissions.includes(config.permissionPrefix + ".delete") &&  */}
			{!this.props.showOnly && (
				<Menu.Item key="delete" className="divider btn-delete">
					<a onClick={() => this.deleteConfirm(item)}>
						<i className="fal fa-trash" />Excluir
					</a>
				</Menu.Item>
			)}
			{this.props.showOnly && (
				<Menu.Item key="view">
					<a onClick={() => this.viewOpen(item)}>
						<i className="fal fa-eye" />Visualizar
					</a>
				</Menu.Item>
			)}
			{/* } */}
		</Menu>
	);

	columns = () => [
		{
			title: "Logradouro",
			render: (item) => item.logradouro,
		},
		{
			title: "Cidade",
			render: (item) => (item.cidade?.name + " - " + item.cidade?.estado.abbr) ?? '-',
		},
		{
			title: "Endereço principal",
			render: (item) => item.endereco_principal ? "Sim" : "Não",
		},
		{
			title: "Ações",
			className: "actions no-ellipsis",
			visible: true,
			render: (item) => (
				<Dropdown overlay={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
					<Button icon={<i className="fal fa-ellipsis-v" />} />
				</Dropdown>
			),
		},
	];

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		employeesAdressesService.getAll({ client_uuid: this.state.ownerItem.uuid })
			.then((response) => {
				this.setState(state => ({
					isLoading: false,
					data: response.data.data,
					dataAll: response.data.data,
				}));
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	searchItems = () => {
		const { search } = this.state;
		const newData = [...this.state.dataAll];

		this.setState({
			data: search ? newData.filter((item) => item.cidade.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.cidade.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : newData,
		});
	};

	editOnClose = () => {
		this.setState({ editModalVisible: false });
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.searchItems();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if (!e.hasOwnProperty("type")) {
			const { search } = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if (search) {
					this.searchItems();
				}
			});
		}
	};


	render() {
		const { showOnly } = this.props;

		return (
			<Fragment>
				<UIPageListing
					// onSearch={this.onSearch}
					// onSearchChange={this.onSearchChange}
					listType={this.state.listType}
					// search={this.state.search}
					// searchPlaceholder={config.searchPlaceholder}
					showListTypeChange={false}
					enablePagination={false}
					enableSearch={false}
					data={this.state.data}
					columns={this.columns()}
					buttons={[
						{
							visible: !showOnly ? true : false,
							// visible: this.props.permissions.includes(config.permissionPrefix + ".create"),
							onClick: this.createOpen,
							title: "Incluir",
							icon: <i className="far fa-plus" />,
						},
					]}
				/>
				<ModalEdit
					client_uuid={this.state.ownerItem.uuid}
					ref={el => this.editScreen = el}
					visible={this.state.editModalVisible}
					onComplete={this.editOnComplete}
					onClose={this.editOnClose}
				/>
				<ModalView
					client_uuid={this.state.ownerItem.uuid}
					ref={el => this.viewScreen = el}
					visible={this.state.viewModalVisible}
					// onComplete={this.viewOnComplete}
					onClose={this.viewOnClose}
				/>
				<ModalCreate
					empresa_id={this.state.ownerItem.contrato && this.state.ownerItem.contrato?.empresa_id}
					adressesCount={this.state.dataAll.length}
					client_uuid={this.state.ownerItem.uuid}
					ref={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
				/>
			</Fragment>
		)
	}
}

export default Index;
