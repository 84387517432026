import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Button, Modal } from "antd";
import MaskedInput from 'react-text-mask'

import moment from "moment";

import * as floatbox from "./../../helpers/floatbox";

class FloatBox extends PureComponent {
	static propTypes = {
		onEdit      : PropTypes.func.isRequired,
		onChange    : PropTypes.func.isRequired,
		onGoTo      : PropTypes.func.isRequired,
		canEdit     : PropTypes.bool.isRequired,
		itemId      : PropTypes.number.isRequired,
		itemIndex   : PropTypes.number.isRequired,
		indexBatida : PropTypes.number.isRequired,
		sequence    : PropTypes.number.isRequired,
		type        : PropTypes.string.isRequired,
		canGoToLeft : PropTypes.bool,
		canGoToRight: PropTypes.bool,
		initialValue: PropTypes.string,
		isManualPunch: PropTypes.bool,
		isPreMarked: PropTypes.bool,
	};

	static defaultProps = {
		canGoToLeft : false,
		canGoToRight: false,
		initialValue: '',
		canEdit     : false,
		isManualPunch: false,
		isPreMarked: false,
	};

	_ignoreEdit    = false;
	_deletePressed = false;

	componentDidMount() {
		setTimeout(() => {
			// Reset value
			if( this.input && this.input.inputElement )
			{
				this.input.inputElement.value = this.props.initialValue;
			}
		}, 10);
	}

	componentDidUpdate(prevProps, prevState) {
		// if( prevProps.initialValue !== this.props.initialValue )
		// {
			// Reset value
			if( this.input && this.input.inputElement )
			{
				this.input.inputElement.value = this.props.initialValue;
			}
		// }
	}

	close = () => {
		this._ignoreEdit = true;

		// Reset value
		this.input.inputElement.value = this.props.initialValue;

		// Hide all floatbox
		floatbox.hideAll();
	}

	onChange = () => {
		if( this._ignoreEdit )
		{
			return false;
		}

		let value = this.input.inputElement.value;

		// Only filled and new value
		if( value === this.props.initialValue )
		{
			return false;
		}

		// Empty value
		if( value.length === 0 )
		{
			// TODO poder salvar horario em branco, só descomentar essa linha, hoje está comentado por que o Andre achou melhor vermos depois, pq tbm precisa ver isso no back
			this.props.onChange(value, this.props.itemId, this.props.itemIndex, this.props.indexBatida, this.props.sequence, this.props.type, this.props.initialValue, true);

			return false;
		}

		if( value.includes('_') )
		{
			// Replace _ by 0 (zero)
			value = value.replaceAll('_', '0');

			// Update on input
			this.input.inputElement.value = value.replaceAll('_', '0');
		}

		// Convert to moment
		const time = moment(value, 'H:m');

		if( !time.isValid() )
		{
			Modal.error({
				title   : 'Ocorreu um erro!',
				content : 'Horário inválido',
				centered: true,
				onOk    : () => {
					if( this.input?.inputElement )
					{
						this.input.inputElement.focus();
						this.input.inputElement.select();
					}
				},
				onCancel: () => {
					if( this.input?.inputElement )
					{
						this.input.inputElement.focus();
						this.input.inputElement.select();
					}
				},
			});

			return false;
		}

		this.props.onChange(value, this.props.itemId, this.props.itemIndex, this.props.indexBatida, this.props.sequence, this.props.type, this.props.initialValue, this._deletePressed);
	}

	onKeyDown = (e) => {
		if( !this.props.canEdit )
		{
			e.preventDefault();

			return false;
		}

		this._deletePressed = false;

		if( e.key === 'Enter' )
		{
			setTimeout(() => {
				this.input.inputElement.blur();
			}, 10);
		}
		else if( e.key === 'Tab' )
		{
			// Has new value
			if( this.input.inputElement.value !== this.props.initialValue )
			{
				e.preventDefault();

				setTimeout(() => {
					this.input.inputElement.blur();
				}, 10);

				return false;
			}
		}
		else if( e.key === 'Backspace' )
		{
			setTimeout(() => {
				this.input.inputElement.value = '';
			}, 10);
		}
		else if( e.key === 'Delete' )
		{
			this._deletePressed = true;
			setTimeout(() => {
				this.input.inputElement.value = '';
				this.input.inputElement.blur();
			}, 10);
		}
	}

	onBlur = (e) => {
		if( !this.props.canEdit )
		{
			e.preventDefault();

			return false;
		}

		setTimeout(() => {
			this.onChange();
		}, 100);
	}

	onFocus = (e) => {
		if( !this.props.canEdit )
		{
			e.preventDefault();
			return false;
		}

		this._ignoreEdit = false;

		const {itemId, itemIndex, sequence, type} = this.props;

		// Hide all floatbox
		floatbox.hideAll();

		// Active floatbox
		floatbox.active(itemId, itemIndex, sequence, type);
	}

	onEdit = () => {
		this.props.onEdit(this.props.itemId, this.props.itemIndex, this.props.indexBatida, this.props.sequence, this.props.type);
	}

	goToPrev = () => {
		this.props.onGoTo('prev', this.props.itemId, this.props.itemIndex, this.props.indexBatida, this.props.sequence, this.props.type);
	}

	goToNext = () => {
		this.props.onGoTo('next', this.props.itemId, this.props.itemIndex, this.props.indexBatida, this.props.sequence, this.props.type);
	}

	render() {
		const { itemId, itemIndex, sequence, type, canGoToLeft, canGoToRight, initialValue, isManualPunch, isPreMarked, status } = this.props;

		let inputStyle = null;

		if (status === 'folga' || status === 'falta' || status === 'justificativa_parcial' || status === 'feriado') {
			if (isManualPunch) {
				inputStyle = {
					color: '#003A4D',
					fontWeight: 'bold',
				};
			}
		} else if (status !== 'falta' && status !== 'justificativa_parcial') {
			inputStyle = status !== 'afastamento' ? {
				fontSize: 14,
				textTransform: 'capitalize',
				color: isManualPunch ? '#003A4D' : isPreMarked ? '#1FA045' : null,
				fontWeight: isManualPunch || isPreMarked ? 'bold' : null,
			} : { fontWeight: 'bold' };
		}

		return (
			<div id={`floatbox_${itemId}_${itemIndex}_${sequence}_${type}`} className="floatbox">
				<div className="header">
					<Button className="btn-edit" icon={<i className="fas fa-edit" />} onClick={this.onEdit} tabIndex="-1" />
					<Button className="btn-prev" icon={<i className="fas fa-arrow-left" />} disabled={!canGoToLeft} onClick={this.goToPrev} tabIndex="-1" />
					<Button className="btn-next" icon={<i className="fas fa-arrow-right" />} disabled={!canGoToRight} onClick={this.goToNext} tabIndex="-1" />
					<Button className="btn-close" icon={<i className="fas fa-times" />} onClick={this.close} tabIndex="-1" />
				</div>
				<div className="field-wrap">
					<MaskedInput
						ref={el => this.input = el}
						className="ant-input"
						mask={this.props.canEdit ? [/\d/, /\d/, ':', /\d/, /\d/] : false}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						onKeyDown={this.onKeyDown}
						defaultValue={initialValue}
						data-default-value={initialValue}
						readOnly={!this.props.canEdit}
						style={inputStyle}
					/>
				</div>
			</div>
		)
	}
}

export default FloatBox;
