import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Table, Form, InputNumber, Row, Select, Tabs, Button, Popconfirm } from "antd";

import {
	HourPickerWithMask,
} from "./../../../components";

class DayTable extends Component {
	static propTypes = {
		incidencia: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			diurnoTipo: "quantidade",
			noturnoTipo: "quantidade",
			intervaloTipo: "quantidade",
			diurno: [
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: "diurno",
					inicial: "00:00",
					final: "",
					percentual: this.getDefaultPercent(0, "diurno"),
				},
			],
			noturno: [
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: "noturno",
					inicial: "00:00",
					final: "",
					percentual: this.getDefaultPercent(0, "noturno"),
				},
			],
			intervalo: [
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: "intervalo",
					inicial: "00:00",
					final: "",
					percentual: this.getDefaultPercent(0, "intervalo"),
				},
			],
		};
	}

	onCreateRow = (tipo) => {
		this.setState({
			[tipo]: [
				...this.state[tipo],
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: tipo,
					inicial: "",
					final: "",
					percentual: this.getDefaultPercent(this.state[tipo].length, tipo),
				},
			],
		});
	}

	onRemoveRow = (tipo, id, index) => {
		const items = this.state[tipo].filter((item) => item.id !== id);
		
		const previusIndex = index - 1;
		if (previusIndex > -1) {
			if (items[index]) items[index].inicial = items[previusIndex]?.final;
		}
		
		const lastIndex = (items.length - 1);
		if (lastIndex > -1 && items[lastIndex]) items[lastIndex].final = '';

		this.setState({ [tipo]: items });
	}

	// Quantidade
	onBlurAte = (e, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].final = e.target.value.replaceAll('_', '0');
		newData[index + 1].inicial = e.target.value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	// Intervalo
	onBlurIntervaloDe = (e, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].inicial = e.target.value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	onBlurIntervaloAte = (e, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].final = e.target.value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	onChangePercent = (value, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].percentual = value;

		this.setState({
			[tipo]: newData,
		});
	}

	getDefaultPercent = (index, tipo) => {
		let percent = null;

		if(index === 0) {
			if(tipo === "intervalo") {
				percent = 0;
			} else if(this.props.incidencia === "uteis" || this.props.incidencia === "sabado") {
				percent = 50;
			} else {
				percent = 100;
			}
		}

		return percent;
	}

	validatePercent = () => {
		const { diurno, noturno, intervalo } = this.state;
		let error = null;

		try {
			diurno.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "diurno",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
	
			noturno.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "noturno",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
	
			intervalo.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "intervalo",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
		} catch(e) {
			return error;
		}

		return error;
	}

	focusInput = (campo, tipo) => {
		this.onTabChange(tipo);

		setTimeout(() => {
			this[campo].focus();
		}, 100);
	}

	onSelectTipo = (tipo, value) => {
		const stateName = `${tipo}Tipo`;
		this.setState({
			[stateName]: value,
			[tipo]: [
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: tipo,
					inicial: value === "quantidade" ? "00:00" : "",
					final: "",
					percentual: this.getDefaultPercent(0, tipo),
				},
			],
		});
	}

	returnData = (periodo) => {
		const { incidencia, compensacao_hora_mensal_por_faixa_hora_extra = false } = this.props;
		const tipo = this.state[`${periodo}Tipo`];

		return {
			incidencia,
			periodo,
			tipo,
			compensacao_hora_mensal_por_faixa_hora_extra,
			faixas: 
				this.state[periodo].map((item, index) => {
					return {
						sequencia: index,
						inicial: item.inicial === "" ? null : item.inicial,
						final: item.final === "" ? null : item.final,
						percentual: item.percentual,
					};
				}),
		};
	}

	render() {
		const { isLoading, diurnoTipo: diurnoTipoOriginal, noturnoTipo: noturnoTipoOriginal, intervaloTipo: intervaloTipoOriginal, diurno, noturno, intervalo } = this.state;
		const { tipo_faixa, tab, onTabChange } = this.props;

		const diurnoTipo = tipo_faixa.findIndex(item => item.value === diurnoTipoOriginal) > -1 ? diurnoTipoOriginal : tipo_faixa[0];
		const noturnoTipo = tipo_faixa.findIndex(item => item.value === noturnoTipoOriginal) > -1 ? noturnoTipoOriginal : tipo_faixa[0];
		const intervaloTipo = tipo_faixa.findIndex(item => item.value === intervaloTipoOriginal) > -1 ? intervaloTipoOriginal : tipo_faixa[0];

		const columnsQuantidade = [
			{
				title: 'De',
				dataIndex: 'inicial',
				key: 'inicial',
				render: (_, { id, tipo, inicial }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`de_${id}`] = el} 
						value={index === 0 ? "00:00" : inicial} 
						disabled 
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Até',
				dataIndex: 'final',
				key: 'final',
				render: (_, { id, tipo, final }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`ate_${id}`] = el}
						value={final} 
						onBlur={(e) => this.onBlurAte(e, tipo, index)}
						disabled={index === this.state[tipo]?.length - 1}
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Coluna/Perc.',
				dataIndex: 'coluna_perc',
				key: 'coluna_perc',
				render: (_, { id, tipo, percentual }, index) => (
					<InputNumber
						ref={el => this[`perc_${id}`] = el}
						defaultValue={percentual}
						onChange={(value) => this.onChangePercent(value, tipo, index)}
						min={0}
						controls={false}
						precision={2}
						decimalSeparator=","
						style={{ maxWidth: 120 }}
						prefix="%"
					/>
				),
			},
			{
				title: 'Ações',
				dataIndex: 'acoes',
				key: 'acoes',
				render: (_, { id, tipo }, index) => (
					<Row gutter={5}>
						<Col span={12}>
							<Popconfirm 
								placement="topRight" 
								title="Deseja excluir essa faixa?" 
								onConfirm={() => this.onRemoveRow(tipo, id, index)} 
								okText="Sim" 
								cancelText="Não"
							>
								<Button danger shape="circle" icon={<i className="fal fa-trash"></i>} />
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		const columnsIntervalo = [
			{
				title: 'De',
				dataIndex: 'inicial',
				key: 'inicial',
				render: (_, { id, tipo }, index) => (
					<HourPickerWithMask form_={false} ref={el => this[`de_${id}`] = el} onBlur={(e) => this.onBlurIntervaloDe(e, tipo, index)} style={{ maxWidth: 140 }} />
				),
			},
			{
				title: 'Até',
				dataIndex: 'final',
				key: 'final',
				render: (_, { id, tipo }, index) => (
					<HourPickerWithMask form_={false} ref={el => this[`ate_${id}`] = el} onBlur={(e) => this.onBlurIntervaloAte(e, tipo, index)} style={{ maxWidth: 140 }} />
				),
			},
			{
				title: 'Coluna/Perc.',
				dataIndex: 'coluna_perc',
				key: 'coluna_perc',
				render: (_, { id, tipo, percentual }, index) => (
					<InputNumber
						ref={el => this[`perc_${id}`] = el}
						defaultValue={percentual}
						onChange={(value) => this.onChangePercent(value, tipo, index)}
						min={0}
						controls={false}
						precision={2}
						decimalSeparator=","
						style={{ maxWidth: 120 }}
						prefix="%"
					/>
				),
			},
			{
				title: 'Ações',
				dataIndex: 'acoes',
				key: 'acoes',
				render: (_, { id, tipo }, index) => (
					<Row gutter={5}>
						<Col span={12}>
							<Popconfirm placement="topRight" title="Deseja excluir essa faixa?" onConfirm={() => this.onRemoveRow(tipo, id, index)} okText="Sim" cancelText="Não">
								<Button danger shape="circle" icon={<i className="fal fa-trash"></i>} />
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Tabs activeKey={tab} onChange={onTabChange} style={{ paddingLeft: "35px" }}>
				<Tabs.TabPane forceRender tab="Diurno" key="diurno">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={diurnoTipo} onSelect={(value) => this.onSelectTipo("diurno", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table columns={diurnoTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={diurno} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('diurno')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane forceRender tab="Noturno" key="noturno">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={noturnoTipo} onSelect={(value) => this.onSelectTipo("noturno", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table columns={noturnoTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={noturno} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('noturno')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane forceRender tab="Intervalo" key="intervalo">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={intervaloTipo} onSelect={(value) => this.onSelectTipo("intervalo", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table columns={intervaloTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={intervalo} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('intervalo')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
			</Tabs>
		);
	}
}

export default DayTable;