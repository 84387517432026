import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import axios from "axios";
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Tabs,
  Row,
  Switch,
  InputNumber,
  Select,
  Spin,
} from 'antd'

import { perimetersService, webserviceService } from './../../redux/services'

import { UIDrawerForm } from './../../components'
import { API_ERRO_TYPE_CANCEL } from '../../config/general';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isSending: false,
      uuid: 0,
      address: [],
      addressIsLoading: false,
    }

    this._axiosCancelPerimeterToken = null
  }

  onOpen = (uuid) => {
    this.setState({
      isLoading: true,
      uuid: uuid,
    })

    perimetersService
      .show({ uuid })
      .then((response) => {
        let item = response.data.data

        this.setState(
          {
            isLoading: false,
          },
          () => {
            // Fill form
            this.fillForm(item)
          }
        )
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
          onOk: () => {
            // Force close
            return this.onClose()
          },
        })
      })
  }

  fillForm = (data) => {
    this.form.setFieldsValue({
      nome: data.nome,
      endereco: data.endereco,
      raio: data.raio,
      status: data.status,
    })
  }

  resetFields = () => {
    this.form.resetFields()
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  fetchAddress = (address) => {
    if (this._axiosCancelPerimeterToken) {
      this._axiosCancelPerimeterToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelPerimeterToken = axios.CancelToken.source()

    if (!address.trim().length) {
      this.setState({
        addressIsLoading: false,
        address: [],
      })

      return false
    }

    this.setState({
      addressIsLoading: true,
    })

    webserviceService
      .getAutocompleteAddress(
        address,
        this._axiosCancelPerimeterToken.token,
      )
      .then((response) => {
        this.setState({
          addressIsLoading: false,
          address: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          addressIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const { uuid } = this.state

    const data = {
      ...values,
    }

    // uuid
    data.uuid = uuid

    const { address } = this.state
    data.latitude = parseFloat(address[0].geometry.lat).toFixed(8)
    data.longitude = parseFloat(address[0].geometry.lng).toFixed(8)

    perimetersService
      .edit(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        // Success message
        message.success('Registro atualizado com sucesso.')

        // Callback
        this.props.onComplete()
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  render() {
    const { visible } = this.props

    const { isLoading, isSending, address, addressIsLoading } = this.state

    return (
      <UIDrawerForm
        visible={visible}
        width={700}
        onClose={this.onClose}
        isLoading={isLoading}
        isSending={isSending}
        formId={formId}
        title={`Editar Perímetro`}
      >
        <Form
          ref={(el) => (this.form = el)}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
        >
          <Tabs defaultActiveKey="general">
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name="nome"
                    label="Descrição"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Input ref={(el) => (this.nome = el)} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="endereco"
                    label="Endereço"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      filterOption={false}
                      allowClear
                      placeholder="Busque o endereço"
                      notFoundContent={
                        addressIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onSearch={this.fetchAddress}
                      showSearch
                    >
                      {address.map((item, index) => (
                        <Select.Option key={index} value={item.formatted_address}>
                          {item.formatted_address}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="raio"
                    label="Raio (em metros)"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <InputNumber
                      ref={(el) => (this.raio = el)}
                      min={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item
                    name="status"
                    label="Status"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked={this.status == 1 ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

export default Edit
