import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Spin } from "antd";

import moment from "moment";

class ModalMap extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			latitude: "",
			longitude: "",
		};
	}

	resetFields = () => {};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = (latitude, longitude, dataHora, fora_perimetro) => {
		this.setState({ latitude, longitude, dataHora, fora_perimetro }, () => {
			this.setState({ isLoading: false });
		});
	};

	render() {
		const { visible } = this.props;
		const { isLoading, latitude, longitude, dataHora, fora_perimetro } = this.state;
		let title = `Localização da batida [${moment(dataHora).format("DD/MM/YYYY - HH:mm:ss")}]`;
		if (fora_perimetro) title += ` Fora de Perimetro`

		return (
			<Modal
				visible={visible}
				title={title}
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={680}
				onCancel={this.onClose}
				centered
				footer={null}
			>
				<Spin spinning={isLoading} indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}>
					<div style={{ width: "100%" }}>
						<iframe
							width="100%"
							height="600"
							frameBorder="0"
							scrolling="no"
							marginHeight="0"
							marginWidth="0"
							src={`https://maps.google.com/maps?width=100%25&height=600&hl=pt_br&q=${latitude},%20${longitude}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
						>
						</iframe>
					</div>
				</Spin>
			</Modal>
		);
	}
}

export default ModalMap;
