import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { message, Button, Dropdown, Menu, Modal, Spin, Tag } from "antd";
import QueueAnim from "rc-queue-anim";
import enquire from "enquire.js";

import moment from "moment";

import { DESKTOP_DOWN } from "../../config/mediaQueries";

import { generalActions } from "./../../redux/actions";

import { holidaysService } from "./../../redux/services";

import {
	UIPageListing,
} from "./../../components";

import ModalRecalculate from './ModalRecalculate';

import ModalShow from "./show";
import ModalFilters from "./filters";
import ModalEdit from "./edit";
import ModalCreate from "./create";

const config = {
	title            : "Feriados",
	permissionPrefix : "feriados",
	list             : "feriados",
	searchPlaceholder: "Buscar por descrição",
	orders           : [
		{
			label  : "Mais recentes",
			field  : "id",
			sort   : "desc",
			default: true,
		},
		{
			label: "Mais antigos",
			field: "id",
			sort : "asc",
		},
		{
			label: "Nome A|Z",
			field: "descricao",
			sort : "asc",
		},
		{
			label: "Nome Z|A",
			field: "descricao",
			sort : "desc",
		},
	],
};

class Index extends Component {
	constructor(props) {
		super(props);

		const defaultOrder = config.orders.find(o => o.default);

		this.state = {
			isLoading   : false,
			isSending   : false,
			recalcularModalVisible: false,
			listType    : "list",
			data        : [],
			pagination  : {
				current : 1,
				pageSize: 20,
				total   : 0,
			},
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
			search      : "",
			// Actions
			editModalVisible    : false,
			showModalVisible    : false,
			exportModalVisible  : false,
			filtersModalVisible : false,
			activeLoadings      : [],
			isExporting         : false,
			passwordModalVisible: false,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			// Media queries
			desktopDown: false,
			// Filters
			filters     : {
				recorrente: null,
				ativo     : 1,
				created_at: null,
			},
			deleted: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if( props.listType && state.listType !== props.listType )
		{
			return {
				listType: props.listType
			};
		}

		return null;
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll(true);

		// Listen Media Querie sideBar
		enquire.register(DESKTOP_DOWN, {
			match  : () => {
				this.setState({
					desktopDown: true,
				})
			},
			unmatch: () => {
				this.setState({
					desktopDown: false,
				})
			}
		});
	};

	componentWillUnmount() {
		// Unlisten Media Querie sideBar
		enquire.unregister(DESKTOP_DOWN);
	};

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<i className="fal fa-file" />Visualizar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".edit") && <Menu.Item key="edit">
				<a onClick={() => this.editOpen(item)}>
					<i className="fal fa-pen" />Editar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".delete") && <Menu.Item key="delete" className="divider btn-delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<i className="fal fa-trash" />Excluir
				</a>
			</Menu.Item>}
		</Menu>
	);

	columns = () => {
		const listTypeCard = this.state.listType === "card";
		return [
			{
				title : "Descrição",
				// width: 150,
				render: (item) => listTypeCard ? <h3>{item.descricao}</h3> : item.descricao,
			},
			{
				title : "Data do Feriado",
				width: 150,
				render: (item) => listTypeCard ? <h3>{moment(item.data_feriado).format("DD/MM/YYYY")}</h3> : moment(item.data_feriado).format("DD/MM/YYYY"),
			},
			{
				title: "Recorrente",
				className: "card-block-width-2",
				width: 100,
				render: (item) => {
					if (listTypeCard) {
						return (
							<Fragment>
								<i className="fal fa-sync-alt" style={{ marginRight: 5 }} />{item.recorrente ? "Sim" : "Não"}
							</Fragment>
						);
					}

					return item.recorrente ? "Sim" : "Não";
				}
			},
			{
				title: "Data do Cadastro",
				className: "datetime card-block-width-2",
				render: (item) => {
					if (listTypeCard) {
						return (
							<Fragment>
								<i className="fal fa-plus-circle" style={{ marginRight: 5 }} />{moment(item.created_at).format("DD/MM/YYYY")}
							</Fragment>
						);
					}

					return moment(item.created_at).format("DD/MM/YYYY");
				}
			},
			{
				title    : "Status",
				className: "active no-ellipsis",
				width: 90,
				render   : (item) => this.state.activeLoadings.indexOf(item.uuid) !== -1 ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : <Tag color={item.ativo ? "#0acf97" :  "#fa5c7c"}>{item.ativo ? "Ativo" : "Inativo"}</Tag>
			},
			{
				title    : "Ações",
				className: "actions no-ellipsis",
				visible  : this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete"),
				render   : (item) => (
					<Dropdown overlay={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon={<i className="fal fa-ellipsis-v" />} />
					</Dropdown>
				),
			},
		];
	};

	fetchGetAll = (init = false, exportItems = false) => {
		const {pagination, orderByField, orderBySort, search, filters} = this.state;
		if( exportItems )
		{
			this.setState({
				isExporting: true,
			});
		}
		else
		{
			this.setState({
				isLoading: true,
			});
		}

		const data = {
			orderBy: `${orderByField}:${orderBySort}`,
			search : search,
		};

		if( exportItems )
		{
			data.exportItems = true;
		}
		else
		{
			data.page  = init ? 1 : pagination.current;
			data.limit = pagination.pageSize;
		}

		if( filters.recorrente !== null ) {
			data.recorrente = filters.recorrente;
		}

		if( filters.ativo !== null )
		{
			data.ativo = filters.ativo;
		}

		if( filters.created_at )
		{
			data.created_at = [
				filters.created_at[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.created_at[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		holidaysService.getAll(data)
		.then((response) => {
			if( exportItems )
			{	
				this.setState({
					isExporting: false,
				});

				window.open(response.data.file_url, "_blank");
			}
			else
			{
				this.setState(state => ({
					isLoading : false,
					data      : response.data.data,
					pagination: {
						...state.pagination,
						current: response.data.meta.current_page,
						total  : response.data.meta.total,
					},
				}));
			}
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onListTypeChange = (type) => {
		this.props.onChangeListType(type);
	};

	onPaginationChange = (page) => {
		this.setState(state => ({
			pagination: {
				...state.pagination,
				current: page,
			},
		}), () => {
			this.fetchGetAll();
		});
	};

	onOrderChange = (value) => {
		const defaultOrder = config.orders.find(o => `${o.field}:${o.sort}` === value);

		if( !defaultOrder ) return null;

		this.setState(state => ({
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
		}), () => {
			this.fetchGetAll(true);
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.fetchGetAll(true);
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e.hasOwnProperty("type") )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.fetchGetAll(true);
				}
			});
		}
	};

	/**
	 * Show
	 *
	 * @param uuid
	 */
	showOpen = ({uuid}, general_key) => {
		this.setState({showModalVisible: true});

		// On open screen
		this.showScreen.onOpen(uuid, general_key);
	};

	showOnClose = () => {
		this.setState({showModalVisible: false});
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = (data) => {
		Modal.confirm({
			title          : "Confirmar exclusão!",
			content        : "Tem certeza de que deseja excluir este registro?",
			okText         : "Excluir",
			autoFocusButton: null,
			onOk           : () => {
				this.deleteConfirmed(data);
			}
		});
	};

	// TODO Verificar
	deleteConfirmed = (data) => {
		const { uuid } = data;
		return holidaysService.destroy({uuid})
			.then((response) => {
				console.log('teste', response);
				this.setState(({
					deleted: true,
				}))
				this.recalculateOpen(data);
			})
			.catch((data) => {
				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	/**
	 * Create
	 */
	createOpen = () => {
		this.setState({createModalVisible: true});

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({createModalVisible: false});
	};

	createOnComplete = () => {
		this.setState({createModalVisible: false});
		// Fecth all
		this.fetchGetAll(true);
		
		// setTimeout(() => {
		// 	this.showOpen(this.state.data[0]);
		// }, 1000);
	};

	/**
	 * Edit
	 *
	 * @param uuid
	 */
	editOpen = ({uuid}) => {
		this.setState({editModalVisible: true});

		// On open screen
		this.editScreen.onOpen(uuid);
	};

	editOnClose = () => {
		this.setState({editModalVisible: false});
	};

	editOnComplete = () => {
		this.setState({editModalVisible: false});

		// Fecth all
		this.fetchGetAll();
	};

	/**
	 * Password
	 */
	passwordOpen = (item) => {
		this.setState({passwordModalVisible: true});

		// On open screen
		this.passwordScreen.onOpen(item);
	};

	passwordOnClose = () => {
		this.setState({passwordModalVisible: false});
	};

	passwordOnComplete = () => {
		this.setState({passwordModalVisible: false});

		Modal.success({
			title  : "E-mail enviado!",
			content: "Foi enviado para o usuário sua nova senha.",
		});
	};

	/**
	 * Filter
	 */
	filtersOpen = () => {
		this.setState({filtersModalVisible: true});

		// On open screen
		this.filtersScreen.onOpen({...this.state.filters});
	};

	filtersOnClose = () => {
		this.setState({filtersModalVisible: false});
	};

	filtersOnComplete = (filters) => {
		this.setState({filtersModalVisible: false});
		this.setState({
			filters: filters,
		}, () => {
			// Fecth all
			this.fetchGetAll(true);
		});
	};

	/**
	 * Active/Desactive
	 *
	 * @param {number} uuid
	 * @param {boolean} activate
	 */
	activateDeactivate = ({uuid}, activate) => {
		const {activeLoadings} = this.state;

		if( activeLoadings.indexOf(uuid) === -1 )
		{
			activeLoadings.push(uuid);
		}

		this.setState({
			activeLoadings: activeLoadings,
		});

		holidaysService.edit({uuid, ativo: activate})
		.then((response) => {
			const newData = [...this.state.data];
			const index   = newData.findIndex(item => uuid === item.uuid);

			if( index !== -1 )
			{
				const item = newData[index];

				newData.splice(index, 1, {
					...item,
					status: response.data.data.status,
				});

				this.setState({
					data: newData,
				});
			}
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		}).finally(() => {
			const {activeLoadings} = this.state;
			const index            = activeLoadings.indexOf(uuid);

			if( index !== -1 )
			{
				activeLoadings.splice(index, 1);

				this.setState({
					activeLoadings: activeLoadings,
				});
			}
		});
	};

	/**
	 * Image preview
	 */
	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (url) => {
		this.setState({
			imagePreviewImage  : url,
			imagePreviewVisible: true,
		});
	};

	recalculateOpen = ({ uuid }) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		console.log('recal', uuid);

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = (uuid) => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");

		// Callback
		this.fetchGetAll();
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.fetchGetAll();
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.fetchGetAll();
	}

	render() {
		const { recalcularModalVisible, deleted } = this.state;

		return (
			<QueueAnim className="site-content-inner">
				<div className="page-content fixed-header" key="1">
					<h1 className="page-title">{config.title}</h1>
					<UIPageListing
						onSearch={this.onSearch}
						onSearchChange={this.onSearchChange}
						onPaginationChange={this.onPaginationChange}
						onOrderChange={this.onOrderChange}
						onListTypeChange={this.onListTypeChange}
						onFiltersClick={this.filtersOpen}
						isLoading={this.state.isLoading}
						listType={this.state.listType}
						orderByField={this.state.orderByField}
						orderBySort={this.state.orderBySort}
						orders={config.orders}
						search={this.state.search}
						searchPlaceholder={config.searchPlaceholder}
						data={this.state.data}
						pagination={this.state.pagination}
						columns={this.columns()}
						showFilters={true}
						buttons={[
							{
								visible: this.props.permissions.includes(config.permissionPrefix + ".create"),
								onClick: this.createOpen,
								title  : "Incluir",
								icon   : <i className="far fa-plus" />,
							},
							{
								visible: false , // TODO this.props.permissions.includes(config.permissionPrefix + ".export"),
								onClick: () => this.fetchGetAll(true, true),
								title  : this.state.isExporting ? "Exportando" : "Exportar",
								icon   : <i className="fal fa-file-export" />,
								loading: this.state.isExporting,
							},
						]}
					/>
				</div>
				<ModalCreate
					ref={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
				/>
				<ModalEdit
					ref={el => this.editScreen = el}
					visible={this.state.editModalVisible}
					onComplete={this.editOnComplete}
					onClose={this.editOnClose}
				/>
				<ModalShow
					ref={el => this.showScreen = el}
					visible={this.state.showModalVisible}
					onClose={this.showOnClose}
				/>
				<ModalFilters
					ref={el => this.filtersScreen = el}
					visible={this.state.filtersModalVisible}
					onComplete={this.filtersOnComplete}
					onClose={this.filtersOnClose}
				/>
				<Modal wrapClassName="modal-image" visible={this.state.imagePreviewVisible} centered footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={this.state.imagePreviewImage} />
				</Modal>

				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					deleted={deleted}
				/>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
		listType   : state.general.listType[config.list],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChangeListType: (type) => {
			dispatch(generalActions.changeListType(config.list, type));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
