/**
 * Hide all boxes
 */
export function hideAll() {
	const els = document.querySelectorAll('.floatbox.active');

	els.forEach((el) => {
		el.classList.remove('active');
	});
}

/**
 * Reset input value
 *
 * @param itemId
 * @param itemIndex
 * @param sequence
 * @param type
 */
export function resetInput(itemId, itemIndex, sequence, type) {
	const input = document.querySelector(`#floatbox_${itemId}_${itemIndex}_${sequence}_${type} input`);

	if( input )
	{
		input.value = input.getAttribute('data-default-value');
	}
}

/**
 * Active
 *
 * @param itemId
 * @param itemIndex
 * @param sequence
 * @param type
 */
export function active(itemId, itemIndex, sequence, type) {
	const el = document.getElementById(`floatbox_${itemId}_${itemIndex}_${sequence}_${type}`);

	if( el )
	{
		el.classList.add('active');
	}
}
