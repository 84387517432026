import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, InputNumber, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"
import { IMask } from 'react-imask';
import moment from "moment";
import { API_ERRO_TYPE_CANCEL } from "./../../config/general";
import { employeesService, perimetersService } from "../../redux/services";
import ModalDepartmentCreate from "./departmentCreate";
import ModalFunctionsCreate from "./functionCreate";
import ModalDepartmentsHistory from "./departmentsHistoryShow";
import Adresses from "./Adresses"
import Contracts from "./Contracts"
import Contacts from "./Contacts"
import { checkIfAvatarIsValid } from "../../utils/checkAvatar";
import defaultAvatar from '../../images/default-profile.png';
import {
	DatePickerWithMask,
	UIDrawerForm,
	UIUpload,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;


class ModalEdit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			uuid: null,
			app: false,
			companiesIsLoading: false,
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			habilitar_app_empresa: false,
			selectedAnnualLeave: { descricao: null, id: null, uuid: null },
			selectedJourney: { descricao: null, id: null, uuid: null },
			selectedFunction: null,
			selectedDepartment: null,
			type: null,
			contractsHistory: [],

			companies: [],
			departmentsHistory: [],
			annualLeaveIsLoading: false,
			annualLeave: [],
			departmentsIsLoading: false,
			departments: [],
			functionsIsLoading: false,
			functions: [],
			journeysIsLoading: false,
			journeys: [],
			perimetersIsLoading: false,
			perimeters: [],
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};

		this._axiosCancelToken = null;
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	/**
	 * Show
	 *
	 * @param uuid
	 */
	journeyShowOpen = () => {
		this.setState({ journeyShowModalVisible: true });
		// On open screen
		if (this.state.selectedJourney.descricao != null) {
			this.journeyShowScreen.onOpen(this.state.selectedJourney.uuid);
		} else {
			this.journeyShowScreen.onOpen(this.state.journeys.filter((journey) => journey.id == this.state.selectedJourney.id)[0].uuid);
		}

	};

	journeyShowOnClose = () => {
		this.setState({ journeyShowModalVisible: false });
	};

	/**
	 * Show
	 *
	 * @param uuid
	 */
	annualLeaveShowOpen = ({ uuid }) => {
		this.setState({ annualLeaveShowModalVisible: true });

		// On open screen
		if (this.state.selectedAnnualLeave.descricao != null) {
			this.annualLeaveShowScreen.onOpen(this.state.selectedAnnualLeave.uuid);
		} else {
			this.annualLeaveShowScreen.onOpen(this.state.annualLeave.filter((annual) => annual.id == this.state.selectedAnnualLeave.id)[0].uuid);
		}
	};

	annualLeaveShowOnClose = () => {
		this.setState({ annualLeaveShowModalVisible: false });
	};

	/**
	  * Department Create
	  */
	departmentCreateOpen = () => {
		this.setState({ departmentCreateModalVisible: true });

		// On open screen
		this.departmentCreateScreen.onOpen();
	};

	departmentCreateOnClose = () => {
		this.setState({ departmentCreateModalVisible: false });
	};

	departmentCreateOnComplete = (item) => {
		this.setState({ departmentCreateModalVisible: false });

		this.form.setFieldsValue({ departamento_id: item.id })
		this.setState({ selectedDepartment: item.nome })
	};

	/**
	  * Function Create
	  */
	functionCreateOpen = () => {
		this.setState({ functionCreateModalVisible: true });

		// On open screen
		this.functionCreateScreen.onOpen();
	};

	functionCreateOnClose = () => {
		this.setState({ functionCreateModalVisible: false });
	};

	functionCreateOnComplete = (item) => {
		this.setState({ functionCreateModalVisible: false });

		this.form.setFieldsValue({ funcao_id: item.id })
		this.setState({ selectedFunction: item.nome })
	};

	fetchPerimeters = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				perimetersIsLoading: false,
				perimeters: [],
			});

			return false;
		}

		this.setState({
			perimetersIsLoading: true,
		});

		perimetersService.getAutocomplete({
			search: value,
			status: 1,
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					perimetersIsLoading: false,
					perimeters: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					perimetersIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		employeesService.show({ uuid })
			.then((response) => {

				let item = response.data.data;

				let companies = [];
				let departments = [];
				let functions = [];
				let perimeters = [];

				// if (item.contrato == null) {
				// 	const current_contract = response.data.historico_contratos[response.data.historico_contratos.length - 1];
				// 	item.contrato = current_contract;
				// }

				// if (item.contrato) {

				// 	if (item.contrato.empresa) {
				// 		companies.push({
				// 			id: item.contrato.empresa.id,
				// 			nome: item.contrato.empresa.nome,
				// 		});
				// 	}

				// 	if (item.contrato.funcoes) {
				// 		functions.push({
				// 			id: item.contrato.funcoes.id,
				// 			nome: item.contrato.funcoes.nome,
				// 		});
				// 	}

				// 	if (item.contrato.departamento) {
				// 		departments.push({
				// 			id: item.contrato.departamento.id,
				// 			nome: item.contrato.departamento.nome,
				// 		});
				// 	}
				// }


				if (item.perimetro) {
					perimeters.push({
						id: item.perimetro.id,
						nome: item.perimetro.nome,
					});
				}

				item.departmentsHistory = response.data.historico_departamentos;

				this.setState({
					departmentsHistory: response.data.historico_departamentos,
					contractsHistory: response.data.historico_contratos,
					isLoading: false,
					companies: companies,
					departments: departments,
					functions: functions,
					perimeters: perimeters,
				}, () => {
					// Fill form
					this.fillForm(item);

					if (item.avatar) {
						const file = {
							uuid: item.uuid,
							url: item.avatar,
							type: 'image/jpeg',
						}

						this.upload.setFiles([file]);

						// removido por enquanto
						// checkIfAvatarIsValid(item.avatar).then(res => {
						// 		if (res.status !== 200) {
						// 			file.url = defaultAvatar;
						// 			this.upload.setFiles([file]);
						// 			return;
						// 		}

						// 		this.upload.setFiles([file]);
						// 	}).catch(_ => {
						// 		file.url = defaultAvatar;
						// 		this.upload.setFiles([file]);
						// 	});
					}

					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
					this.tabContracts && this.tabContracts.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			nome: data.nome,
			ativo: data.ativo,
			cpf: data.cpf,
			ctps: data.ctps,
			perimetro_id: data.perimetro ? data.perimetro.id : null,
			data_nascimento: data.data_nascimento ? moment(data.data_nascimento) : null,
			email: data.email,
			habilitar_app_empresa: data.habilitar_app_empresa ?? null,
			app: data.app ?? null,
			equipamento_admin: data.equipamento_admin,
			envio_self: data.envio_self ?? null,
			equipamento_biometria: data.equipamento_biometria,
			equipamento_codigo_barras: data.equipamento_codigo_barras,
			equipamento_enviar_rfid: data.equipamento_enviar_rfid,
			equipamento_rfid: data.equipamento_rfid,
			equipamento_senha: data.equipamento_senha,
			nacionalidade: data.nacionalidade,
			naturalidade: data.naturalidade,
			pin: data.pin,
			sexo: data.sexo,
			nome_mae: data.nome_mae,
			arquivar: data.contrato ? data.contrato.arquivar : null,
			nome_pai: data.nome_pai,
			numero_folha: data.numero_folha,
			numero_identificador: data.numero_identificador,
			pis_pasep: IMask.pipe(data.pis_pasep, {mask: '000.00000.00-0'}),
			rg: data.rg,
			rg_data_expedicao: data.rg_data_expedicao ? moment(data.rg_data_expedicao) : null,
			rg_ssp: data.rg_ssp,
		});

		this.setState({
			habilitar_app_empresa: data.habilitar_app_empresa,
			app: data.app,
		})


	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		const file = this.upload.getFiles();

		if (file.hasError) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: file.error,
			});

			return false;
		}

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		// uuid
		data.uuid = uuid;

		if (data.data_demissao) {
			data.data_demissao = data.data_demissao.format("YYYY-MM-DD");
		}

		if (data.data_admissao) {
			data.data_admissao = data.data_admissao.format("YYYY-MM-DD");
		}

		if (data.data_inicio_cartao_ponto) {
			data.data_inicio_cartao_ponto = data.data_inicio_cartao_ponto.format("YYYY-MM-DD");
		}

		if (data.data_nascimento) {
			data.data_nascimento = data.data_nascimento.format("YYYY-MM-DD");
		}

		if (data.rg_data_expedicao) {
			data.rg_data_expedicao = data.rg_data_expedicao.format("YYYY-MM-DD");
		}

		if (file.files.length) {
			if (!file.files[0].uuid) {
				data.avatar = file.files[0];
			}
		} else {
			data.avatar = null;
		}

		data.pis_pasep = data.pis_pasep.replaceAll('.', '').replace('-', '');

		if (!data.password || data.password.length < 6) {
			delete data.password;
		}

		employeesService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}

	onFinishFailed = (errorInfo) => {
		let showModal = false;
	
		errorInfo.errorFields.forEach((field) => {
			if (field.name[0] === 'email' || field.name[0] === 'pin') {
				showModal = true; 
			}
		});
	
		if (showModal) {
			Modal.error({
				title: 'Erro ao enviar o formulário',
				content: (
					<div>
						Os campos obrigatórios na aba:<br />"Parâmetros REP-P" não foram preenchidos.
					</div>
				),
			});
		}
	};		

	render() {
		const { visible } = this.props;

		const { 
			isLoading, 
			isSending,
			app, 
			habilitar_app_empresa, 
			perimeters, 
			perimetersIsLoading,
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee 
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Funcionário`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinishFailed={this.onFinishFailed}
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={5}>
									<UIUpload
										name="avatar"
										ref={el => this.upload = el}
										label="Avatar"
										labelError="Avatar"
										acceptedFiles={['jpg', 'jpeg', 'png']}
									/>
								</Col>
								<Col xs={13}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item name="cpf" label="CPF" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]} >
										<MaskedInput
											mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
											className="ant-input"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={8}>
									<Form.Item name="numero_folha" label="Número da Folha" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={8}>
									<Form.Item name="numero_identificador" label="Número do Identificador" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={8}>
									<Form.Item 
										name="pis_pasep" 
										label="PIS/PASEP" 
										hasFeedback
										// rules={[{ required: true, message: "Campo obrigatório." }]}
									>
										<MaskedInput
											className="ant-input"
											mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/,]}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={8}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.sexo.focus()
										}
									}} name="data_nascimento" label="Data de Nascimento" disableDateAfter={true} />
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item name="sexo" label="Sexo" >
										<Select
											ref={el => this.sexo = el}
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											showSearch>
											<Select.Option value="0">Masculino</Select.Option>
											<Select.Option value="1">Feminino</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={10}>
									<Form.Item name="ctps" label="CTPS">
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item name="rg" label="RG" hasFeedback >
										<MaskedInput
											mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
											className="ant-input"
										/>
									</Form.Item>
								</Col>
								<Col xs={8}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.rg_ssp.focus()
										}
									}} name="rg_data_expedicao" label="Data de Expedição" disableDateAfter={true} />
								</Col>
								<Col xs={8}>
									<Form.Item name="rg_ssp" label="SSP" hasFeedback >
										<Input ref={el => this.rg_ssp = el} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="nacionalidade" label="Nacionalidade" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="naturalidade" label="Naturalidade" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="nome_pai" label="Nome do Pai" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="nome_mae" label="Nome da Mãe" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="ativo" label="Ativo" valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contratos" key="contracts">
							<Contracts
								onEditContract={() => {
									this.props.onComplete();
								}
								}
								ref={el => this.tabContracts = el}
							/>
						</Tabs.TabPane>
						{
							displayParamsREPP && (
								<Tabs.TabPane forceRender tab="Parâmetros REP-P" key="app">
									<Row gutter={16}>
										{
											displayAppEmployee && (
												<Col xs={8}>
													<Form.Item name="app" label="Habilitar app funcionário" hasFeedback valuePropName="checked" >
														<Switch onChange={(value) => this.setState({ app: value })} />
													</Form.Item>
												</Col>
											)
										}
										{app && (
											<>
												<Col xs={8}>
													<Form.Item name="email" label="E-mail" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
														<Input />
													</Form.Item>
												</Col>
												<Col xs={8}>
													<Form.Item name="password" label="Senha" hasFeedback rules={[{ min: 6, message: "Deve conter no mínimo 6 caracteres." }]} >
														<Input minLength={6} />
													</Form.Item>
												</Col>
											</>
										)}
									</Row>
									<Row gutter={16}>
										{
											displayAppCompany && (
												<Col xs={8}>
													<Form.Item name="habilitar_app_empresa" label="Habilitar app empresa" hasFeedback valuePropName="checked" >
														<Switch onChange={(value) => this.setState({ habilitar_app_empresa: value })} />
													</Form.Item>
												</Col>
											)
										}
										{habilitar_app_empresa && (
											<Col xs={8}>
												<Form.Item name="pin" label="PIN" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }, { min: 4, max: 4, message: "Deve conter 4 caracteres." }]}>
													<Input type="number" style={{ width: "100%" }} />
												</Form.Item>
											</Col>
										)}
									</Row>
									<Row gutter={16}>
										<Col xs={12}>
											<Form.Item name="envio_self" label="Necessário envio de selfie" valuePropName="checked" hasFeedback >
												<Switch />
											</Form.Item>
										</Col>
									</Row>
									{(habilitar_app_empresa || app) && (
										<Row gutter={16}>
											<Col xs={12}>
												<Form.Item name="perimetro_id" label={"Perímetro"} hasFeedback>
													<Select
														filterOption={false}
														allowClear
														placeholder="Escolha o perímetro"
														notFoundContent={perimetersIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
														onSearch={this.fetchPerimeters}
														showSearch
													>
														{perimeters.map((item, index) => (
															<Select.Option key={index} value={item.id}>
																{item.nome}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
										</Row>
									)}
								</Tabs.TabPane>
							)
						}
						<Tabs.TabPane forceRender tab="Credenciais REP-C" key="equipments-data">
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_senha" label="Senha do equipamento" hasFeedback >
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_codigo_barras" label="Código de barras" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_rfid" label="RFID" hasFeedback >
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_enviar_rfid" label="Enviar RFID" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_admin" label="Admin" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_biometria" label="Biometria" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
				<ModalDepartmentCreate
					ref={el => this.departmentCreateScreen = el}
					visible={this.state.departmentCreateModalVisible}
					onComplete={this.departmentCreateOnComplete}
					onClose={this.departmentCreateOnClose}
				/>
				<ModalFunctionsCreate
					ref={el => this.functionCreateScreen = el}
					visible={this.state.functionCreateModalVisible}
					onComplete={this.functionCreateOnComplete}
					onClose={this.functionCreateOnClose}
				/>
				<ModalDepartmentsHistory
					ref={el => this.departmentsHistoryShowScreen = el}
					visible={this.state.departmentsHistoryShowModalVisible}
					onClose={this.departmentsHistoryShowOnClose}
				/>
			</UIDrawerForm>
		)
	}
}

export default ModalEdit;
