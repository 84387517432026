import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Spin, Form, Select, message } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../../../config/general";

import moment from "moment";

import {
	timeCardService,
} from "./../../../../redux/services";

import axios from "axios";

import {
	DatePickerWithMask,
} from "./../../../../components";

class ModalExcecao extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			journeys: [],
			journeysIsLoading: false,
			overtime: [],
			overtimeIsLoading: false,
			selectedType: "",
		}

		this._axiosCancelJourneysToken = null;
		this._axiosCancelOvertimeToken = null;
	}

	fetchJourneys = (value) => {
		if (this._axiosCancelJourneysToken) {
			this._axiosCancelJourneysToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelJourneysToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				journeysIsLoading: false,
				journeys: [],
			});

			return false;
		}

		this.setState({
			journeysIsLoading: true,
		});

		let params = {
			search: value,
			ativo: 1,
			orderBy: "descricao:asc",
			cancelToken: this._axiosCancelJourneysToken.token,
		};

		timeCardService
			.autocompleteJornada({
				...params,
			})
			.then((response) => {
				this.setState({
					journeysIsLoading: false,
					journeys: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchOvertime = (value) => {
		if (this._axiosCancelOvertimeToken) {
			this._axiosCancelOvertimeToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelOvertimeToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				overtimeIsLoading: false,
				overtime: [],
			});

			return false;
		}

		this.setState({
			overtimeIsLoading: true,
		});

		let params = {
			search: value,
			ativo: 1,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelOvertimeToken.token,
		};

		timeCardService
			.autocompleteHorasExtras({
				...params,
			})
			.then((response) => {
				this.setState({
					overtimeIsLoading: false,
					overtime: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					overtimeIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {
	}

	onTypeSelect = (value) => {
		this.setState({
			selectedType: value
		});
	}

	onSubmit = (values) => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			let data = { ...values };

			data.empresas = [this.props.empresa.id],
			data.departamentos = [this.props.departamento.id],
			data.funcionarios = [this.props.funcionario.id],
			data.data_inicio = moment(values.data_inicio).format("YYYY-MM-DD"),
			data.data_fim = moment(values.data_fim).format("YYYY-MM-DD"),

			timeCardService
				.excecaoHorario(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Exceção de horário iniciada com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	}

	render() {
		const { visible } = this.props;

		const { isSending, journeys, journeysIsLoading, overtime, overtimeIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title="Exceção de Horário/HE"
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={580}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Iniciando" : "Iniciar"}
				cancelButtonProps={{disabled: isSending}}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				closable={!isSending}
				centered
			>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						data_inicio: moment(this.props.dataDia),
					}}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label="Empresa" style={{ marginBottom: 15 }}>
								{this.props.empresa?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Departamento" style={{ marginBottom: 15 }}>
								{this.props.departamento?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Funcionário" style={{ marginBottom: 15 }}>
								{this.props.funcionario?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24} md={12}>
							<Form.Item label="Tipo" name="tipo" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Select placeholder="Selecione o tipo" onSelect={this.onTypeSelect}>
									<Select.Option value="execao_jornada">Exceção de horário</Select.Option>
									<Select.Option value="execao_hora_extra">Exceção de hora extra</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={24} md={12}>
							{this.state.selectedType === "execao_jornada" && (
								<Form.Item label="Horário" name="jornada_id" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Select
										filterOption={false}
										allowClear
										placeholder="Selecione o horário"
										notFoundContent={
											journeysIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchJourneys}
										showSearch
									>
										{journeys.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.descricao}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}

							{this.state.selectedType === "execao_hora_extra" && (
								<Form.Item label="Hora extra" name="hora_extra_id" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Select
										filterOption={false}
										allowClear
										placeholder="Selecione o horário"
										notFoundContent={
											overtimeIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchOvertime}
										showSearch
									>
										{overtime.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}
						</Col>
						<Col xs={24} md={12}>
							<DatePickerWithMask
								onKeyUp={(e) => {
									if (e.keyCode === 13 || e.keyCode === 9) {
										this.form.data_fim.focus();
									}
								}}
								label="Data inicial"
								name="data_inicio"
								required={true}
								rules={[
									{
										required: true,
										message: "Campo obrigatório.",
									},
									{
										validator: async (_, data_inicio) => {
											var data_fim = this.form.getFieldValue("data_fim");
											if (data_fim !== null) {
												if (data_inicio > data_fim) {
													return Promise.reject(
														"A data inicial deve ser menor ou igual a data final."
													);
												}
											}
										},
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12}>
							<DatePickerWithMask
								label="Data final"
								name="data_fim"
								onKeyUp={(e) => {
									if (e.keyCode === 13 || e.keyCode === 9) {
										this.form.data_inicio.focus();
									}
								}}
								required={true}
								rules={[
									{
										required: true,
										message: "Campo obrigatório.",
									},
									{
										validator: async (_, data_fim) => {
											var data_inicio = this.form.getFieldValue("data_inicio");
											if (data_inicio !== null) {
												if (data_fim < data_inicio) {
													return Promise.reject(
														"A data final deve ser maior ou igual a data inicial."
													);
												}
											}
										},
									},
								]}
							/>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default ModalExcecao;