import { api } from "./../../config/api-byponto";

const basePath = "auth";

/**
 * Check login type
 *
 * @param {Object} data
 *
 * @returns {Promise<T>}
 */
export const checkLogin = (data) => {
	return api.post(`${basePath}/login-type`, data);
};

/**
 * Check clientes
 *
 * @param {Object} data
 *
 * @returns {Promise<T>}
 */
export const checkClientes = (data) => {
	return api.post(`${basePath}/login-direcionamento`, data);
};