import React, { Component, Fragment } from 'react'

import { Menu, Modal } from 'antd'

import { UIPageListing } from '../../../components'

import { equipmentsService } from '../../../redux/services'

const config = {
  searchPlaceholder: 'Buscar funcionário',
  permissionPrefix: 'customers',
}

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      listType: 'list',
      dataAll: [],
      data: [],
      search: '',
      // Owner item
      ownerItem: {},
    }
  }

  init = (ownerItem) => {
    this.setState({
      ownerItem: ownerItem,
    }, () => {
      this.fetchGetAll()
    })
  }

  menuItem = (item) => (
    <Menu className="actions-dropdown-menu"></Menu>
  )

  columns = () => [
    {
      title: 'Nome',
      render: (item) => item?.nome ?? '-',
    },
    {
      title: 'Departamento',
      render: (item) => item?.contrato[0]?.departamento?.nome ?? '-',
    },
    {
      title: 'Função',
      render: (item) => item?.contrato[0]?.funcoes?.nome ?? '-',
    },
  ]

  fetchGetAll = () => {
    this.setState({
      isLoading: true,
    })

    const data = {
      limit: 30,
      nome: this.state.search,
      equipamento_id: this.state.ownerItem.uuid,
    }

    equipmentsService
      .getEmployeesEquipment(data)
      .then((response) => {
        this.setState((state) => ({
          isLoading: false,
          data: response.data.data,
          dataAll: response.data.data,
          pagination: {
            ...state.pagination,
            current: response.data.meta.current_page,
            total: response.data.meta.total,
          },
        }))
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  searchItems = () => {
    const { search } = this.state
    const newData = [...this.state.dataAll]

    this.setState({
      data: search
        ? newData.filter(
            (item) =>
              item?.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              item?.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
        : newData,
    })
  }

  onSearch = (value) => {
    this.setState(
      {
        search: value,
      },
      () => {
        this.searchItems()
      }
    )
  }

  onSearchChange = (e) => {
    // If it does not have type then it's cleaning
    if (!e.hasOwnProperty('type')) {
      const { search } = this.state

      this.setState(
        {
          search: e.target.value,
        },
        () => {
          if (search) {
            this.searchItems()
          }
        }
      )
    }
  }

  render() {
    return (
      <Fragment>
        <UIPageListing
          onSearch={this.onSearch}
          onSearchChange={this.onSearchChange}
          listType={this.state.listType}
          search={this.state.search}
          searchPlaceholder={config.searchPlaceholder}
          showListTypeChange={false}
          data={this.state.data}
          columns={this.columns()}
        />
      </Fragment>
    )
  }
}

export default Index
