import React, { Component, Fragment } from "react";
import axios from "axios";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import QueueAnim from "rc-queue-anim";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { privacyPolicyService } from "./../../redux/services";

import {
	UIRichTextEditor,
} from "./../../components";

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			type     : this.props.match.params.type,
		};

		this._cancelToken = null;
	}

	componentDidMount() {
		// Fetch item
		this.fetchItem();
	}

	componentWillUnmount() {
		this._cancelToken && this._cancelToken.cancel("Only one request allowed at a time.");
	}

	componentDidUpdate(prevProps) {
		if( prevProps.match.params.type !== this.props.match.params.type )
		{
			this.setState({
				type: this.props.match.params.type,
			}, () => {
				// Fetch item
				this.fetchItem();
			});
		}
	}

	fetchItem = () => {
		if( this._cancelToken )
		{
			this._cancelToken.cancel("Only one request allowed at a time.");
		}

		this._cancelToken = axios.CancelToken.source();

		this.setState({
			isLoading: true,
		});

		const {type} = this.state;

		privacyPolicyService.show(type, this._cancelToken.token)
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	fillForm = (data) => {
		this.form.setFieldsValue({
			name: data.name,
		});

		// Editor
		this.editor.setValue(data.text);
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const {type} = this.state;

		const data = {
			...values,
			type,
		};

		privacyPolicyService.edit(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Registro atualizado com sucesso.");
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const {isLoading, isSending, type} = this.state;

		let pageTitle = type === 'funcionario' ? 'Funcionário' : 'Empresa';

		return (
			<QueueAnim className="site-content-inner" style={{maxWidth: 700}}>
				<div className="page-content" key="1">
					<h1 className="page-title">{`${pageTitle} - Política de Privacidade`}</h1>
					<Form
						ref={el => this.form = el}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}>
						{isLoading ? (
							<div className="text-center" style={{padding: 20}}>
								<Spin indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />} />
							</div>
						) : (
							<Fragment>
								<Form.Item name="name" label="Nome" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
									<Input maxLength={191} />
								</Form.Item>
								<UIRichTextEditor
									ref={el => this.editor = el}
									name="text"
									label="Texto"
								/>
								<Button type="primary" htmlType="submit" icon={<i className="far fa-check" />} loading={isSending} disabled={isLoading}>{isSending ? "Salvando" : "Salvar"}</Button>
							</Fragment>
						)}
					</Form>
				</div>
			</QueueAnim>
		)
	}
}

export default PrivacyPolicy;
