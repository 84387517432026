import moment from 'moment';

export const formatTzDate = (date, timezone) => {
  if (!date) return '--';

  if (!moment(date).isValid()) return '--';

  const formattedDate = moment.utc(date).utcOffset(timezone).format('DD/MM/YYYY HH:mm');

  return formattedDate;
};
