import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Table, Form, InputNumber, Row, Select, Tabs, Button, Popconfirm } from "antd";

class DayTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			diurnoTipo: null,
			noturnoTipo: null,
			intervaloTipo: null,
			diurno: [],
			noturno: [],
			intervalo: [],
		};
	}

	initData = (dados) => {
		if(dados.diurno && dados.noturno && dados.intervalo) {
			this.setState({
				diurnoTipo: dados?.diurno?.tipo === "" ? null : dados?.diurno?.tipo,
				noturnoTipo: dados?.noturno?.tipo === "" ? null : dados?.noturno?.tipo,
				intervaloTipo: dados?.intervalo?.tipo === "" ? null : dados?.intervalo?.tipo,
				diurno: dados?.diurno?.faixas,
				noturno: dados?.noturno?.faixas,
				intervalo: dados?.intervalo?.faixas,
				isLoading: false,
			});
		}
	}

	render() {
		const { isLoading, diurno, noturno, intervalo } = this.state;

		const columns = [
			{
				title: 'De',
				dataIndex: 'de',
				key: 'de',
				render: (_, { inicial }, index) => (
					<span>{inicial}</span>
				),
			},
			{
				title: 'Até',
				dataIndex: 'ate',
				key: 'ate',
				render: (_, { final }, index) => (
					<span>{final}</span>
				),
			},
			{
				title: 'Coluna/Perc.',
				dataIndex: 'coluna_perc',
				key: 'coluna_perc',
				render: (_, { percentual }, index) => (
					<span>{percentual}%</span>
				),
			},
		];

		return (
			<>
				<Tabs style={{ paddingLeft: "35px" }}>
					<Tabs.TabPane forceRender tab="Diurno" key="diurno">
						<Col xs={24} md={12}>
							<Form.Item label="Tipo das faixas">
								<span style={{ textTransform: "capitalize" }}>{this.state.diurnoTipo ?? "-"}</span>
							</Form.Item>
						</Col>
						<Table loading={isLoading} columns={columns} dataSource={diurno} pagination={false} />
					</Tabs.TabPane>
					<Tabs.TabPane forceRender tab="Noturno" key="noturno">
						<Col xs={24} md={12}>
							<Form.Item label="Tipo das faixas">
								<span style={{ textTransform: "capitalize" }}>{this.state.noturnoTipo ?? "-"}</span>
							</Form.Item>
						</Col>
						<Table loading={isLoading} columns={columns} dataSource={noturno} pagination={false} />
					</Tabs.TabPane>
					<Tabs.TabPane forceRender tab="Intervalo" key="intervalo">
						<Col xs={24} md={12}>
							<Form.Item label="Tipo das faixas">
								<span style={{ textTransform: "capitalize" }}>{this.state.intervaloTipo ?? "-"}</span>
							</Form.Item>
						</Col>
						<Table loading={isLoading} columns={columns} dataSource={intervalo} pagination={false} />
					</Tabs.TabPane>
				</Tabs>
			</>
		);
	}
}

export default DayTable;