import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs } from "antd";
import { IMask } from 'react-imask';
import moment from "moment";
import { employeesService } from "./../../redux/services";
import Adresses from "./Adresses"
import Contacts from "./Contacts"
import Contracts from "./Contracts"
import { checkIfAvatarIsValid } from "../../utils/checkAvatar";
import defaultAvatar from '../../images/default-profile.png';
import {
	UIDrawerForm,
	UIUpload,
} from "./../../components";

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null,
			avatar: null,
			contract: null,
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		employeesService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				console.log({ item })

				if (item.contrato == null) {
					const current_contract = response.data.historico_contratos[response.data.historico_contratos.length - 1];
					item.contrato = current_contract;
				}

				this.setState({
					isLoading: false,
					item: item,
				}, () => {
					// Upload
					if (item.avatar) {
						const file = {
							uuid: item.uuid,
							url: item.avatar,
							type: 'image/jpeg',
						}
				
						this.upload.setFiles([file]);

						// removido por enquanto
						// checkIfAvatarIsValid(item.avatar).then(_ => {
						// 	console.log(res.status)
						// 		if (res.status !== 200) {
						// 			file.url = defaultAvatar;
						// 			this.upload.setFiles([file]);
						// 			return;
						// 		}
								
						// 	}).catch(error => {
						// 		console.log({ error })
						// 		console.log('ai papai')
						// 		file.url = defaultAvatar;
						// 		this.upload.setFiles([file]);
						// 	});
					}

					// Load options
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
					this.tabContracts && this.tabContracts.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}
	
	render() {
		const { visible } = this.props;
		const { 
			isLoading, 
			item,
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Funcionário`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey={this.state.general_key != null ? this.state.general_key : "general"} >
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={8}>
									<UIUpload
										ref={el => this.upload = el}
										label="Avatar"
										disabled
										picture={item.avatar}
									/>
								</Col>
								<Col xs={24} md={8}>
									<Form.Item label="Nome">
										{item.nome ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} md={8}>
									<Form.Item label="CPF">
										{item.cpf}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Número da Folha">
										{item.numero_folha ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Número do Identificador">
										{item.numero_identificador ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="PIS/PASEP">
										{IMask.pipe(`${item.pis_pasep}`, {mask: '000.00000.00-0'}) ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Data de Nascimento">
										{(item.data_nascimento && moment(item.data_nascimento).format("DD/MM/YYYY")) ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Sexo">
										{item.sexo != null ? (item.sexo == 0) ? "Masculino" : "Feminino" : "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="CTPS">
										{item.ctps ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="RG">
										{item.rg ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Data de Expedição">
										{(item.rg_data_expedicao && moment(item.rg_data_expedicao).format("DD/MM/YYYY")) ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="SSP">
										{item.rg_ssp ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Nacionalidade">
										{item.nacionalidade ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Naturalidade">
										{item.naturalidade ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Nome do Pai">
										{item.nome_pai ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Nome da Mãe">
										{item.nome_mae ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar() ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Ativo">
										<Switch disabled checked={item.ativo == 1 ? true : false} />
									</Form.Item>
								</Col>
							</Row>

						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contratos" key="contracts">
							<Contracts
								showOnly={true}
								ref={el => this.tabContracts = el}
							/>
						</Tabs.TabPane>
						{
							displayParamsREPP && (
								<Tabs.TabPane forceRender tab="Parâmetros REP-P" key="app">
									<Row gutter={16}>
										{
											displayAppEmployee && (
												<Col xs={8}>
													<Form.Item label="Habilitar app funcionário">
														<Switch disabled checked={item.app} />
													</Form.Item>
												</Col>
											)
										}
										{item.app && (
											<Col xs={8}>
												<Form.Item label="Email">
													{item.email ?? "-"}
												</Form.Item>
											</Col>
										)}
									</Row>
									<Row gutter={16}>
										{
											displayAppCompany && (
												<Col xs={8}>
													<Form.Item label="Habilitar app empresa">
														<Switch disabled checked={item.habilitar_app_empresa} />
													</Form.Item>
												</Col>
											)
										}
										{item.habilitar_app_empresa && (
											<Col xs={8}>
												<Form.Item label="PIN">
													{item.pin ?? "-"}
												</Form.Item>
											</Col>
										)}
									</Row>
									<Row gutter={16}>
										<Col xs={24}>
											<Form.Item label="Necessário envio de selfie">
												<Switch disabled checked={item.envio_self} />
											</Form.Item>
										</Col>
									</Row>
									{
										(item.habilitar_app_empresa || item.app) &&
										(
											<Row gutter={16}>
												<Col xs={24} sm={8}>
													<Form.Item label="Perímetro">
														{item.perimetro ? item.perimetro.nome : "-"}
													</Form.Item>
												</Col>
											</Row>
										)
									}
								</Tabs.TabPane>
							)
						}
						<Tabs.TabPane forceRender tab="Credenciais REP-C" key="equipments-data">
							<Row gutter={16}>
								<Col xs={12} >
									<Form.Item label="Senha do equipamento">
										{item.equipamento_senha ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={12} >
									<Form.Item label="Código de barras">
										{item.equipamento_codigo_barras ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12} >
									<Form.Item label="RFID">
										{item.equipamento_rfid ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={12} >
									<Form.Item label="Enviar RFID">
										<Switch disabled checked={item.equipamento_enviar_rfid == 1 ? true : false} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12} >
									<Form.Item label="Admin">
										<Switch disabled checked={item.equipamento_admin == 1 ? true : false} />
									</Form.Item>
								</Col>
								<Col xs={12} >
									<Form.Item label="Biometria">
										<Switch disabled checked={item.equipamento_biometria == 1 ? true : false} />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								showOnly={true}
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Contacts
								showOnly={true}
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
