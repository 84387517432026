import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Input, Modal, Row, Col, Form, Select, message } from "antd";
import MaskedInput from 'react-text-mask'

import { timeCardService } from "./../../../redux/services";

import * as floatbox from "./../../../helpers/floatbox";

import moment from "moment";

import {
	DatePickerWithMask,
} from "./../../../components";

class ModalCreate extends Component {
	static propTypes = {
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			visible    : false,
			isSending  : false,
			item       : null,
			value      : '',
			itemIndex  : 0,
			batidaIndex: -1,
			sequence   : 0,
			type       : '',
		}
	}

	onOpen = (item, value, itemIndex, batidaIndex, sequence, type, motivo) => {
		this.setState({
			visible: true,
			item,
			value,
			itemIndex,
			batidaIndex,
			sequence,
			type,
			motivo,
		});

		setTimeout(() => {
			this.form.setFieldsValue({
				data   : moment(item.data),
				horario: value,
				motivo : motivo,
			});
		}, 100);
	}

	onClose = () => {
		this.setState({
			visible: false,
		});

		// Hide all float box
		floatbox.hideAll();

		// Reset input
		floatbox.resetInput(this.state.item.id, this.state.itemIndex, this.state.sequence, this.state.type);

		// Reset fields
		this.resetFields();

		// Callback
		if( typeof this.props.onClose !== 'undefined' )
		{
			this.props.onClose();
		}
	};

	resetFields = () => {
		this.setState({
			item       : null,
			value      : '',
			itemIndex  : 0,
			batidaIndex: -1,
			sequence   : 0,
			type       : '',
		});
	};

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			const {item, itemIndex, batidaIndex, type, sequence} = this.state;

			const isEditing = batidaIndex !== -1;

			const data = {
				sequencia: sequence,
			}

			if( isEditing )
			{
				data.cartao_ponto_batida_id = item.cartaopontobatida[batidaIndex].id;

				if( type === 'entrada' )
				{
					data.hora_entrada   = `${moment(values.data).format("YYYY-MM-DD")} ${values.horario}:00`;
					data.motivo_entrada = values.motivo;

					// if( item.cartaopontobatida[batidaIndex].saida_batida )
					// {
					// 	data.hora_saida = `${item.data} ${item.cartaopontobatida[batidaIndex].saida_batida}:00`;
					// }
				}
				else
				{
					data.hora_saida   = `${moment(values.data).format("YYYY-MM-DD")} ${values.horario}:00`;
					data.motivo_saida = values.motivo;

					// if( item.cartaopontobatida[batidaIndex].entrada_batida )
					// {
					// 	data.hora_entrada = `${item.data} ${item.cartaopontobatida[batidaIndex].entrada_batida}:00`;
					// }
				}
			}
			else
			{
				data.cartao_ponto_id = item.id;

				if( type === 'entrada' )
				{
					data.hora_entrada   = `${moment(values.data).format("YYYY-MM-DD")} ${values.horario}:00`;
					data.motivo_entrada = values.motivo;
				}
				else
				{
					data.hora_saida   = `${moment(values.data).format("YYYY-MM-DD")} ${values.horario}:00`;
					data.motivo_saida = values.motivo;
				}
			}

			timeCardService[isEditing ? 'batidaHorarioEdit' : 'batidaHorarioCreate'](data)
			.then((response) => {
				this.setState({
					visible  : false,
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success('Batida manual do cartão ponto cadastrada com sucesso!');

				// Callback
				this.props.onComplete(item.id, itemIndex, sequence, type, response.data.data[0], values.motivo);
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
		});
	}

	render() {
		const {visible, isSending, item, type, sequence} = this.state;

		return (
			<Modal
				visible={visible}
				title={`Justificar ${type === 'entrada' ? 'entrada' : 'saída'} ${sequence} [${item?.data ? moment(item.data).format('DD/MM/YYYY') : ''}]`}
				destroyOnClose
				width={450}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{disabled: isSending}}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={false}
				closable={!isSending}
				centered
				focusTriggerAfterClose={false}
			>	
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<DatePickerWithMask onKeyUp={(e) => {
								if (e.keyCode === 13 || e.keyCode === 9) {
									this.motivo.focus()
								}
							}} label="Data" name="data" required={true} disableDateBefore={true} customDisableDate={item?.data} />
						</Col>
						<Col xs={24} sm={12}>
							<Form.Item label="Horário" name="horario" rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									className="ant-input"
									mask={[/\d/, /\d/, ':', /\d/, /\d/]}
									disabled={isSending}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item label="Motivo" name="motivo" rules={[{ required: true, message: "Campo obrigatório." }]}>
						<Input
							ref={el => { if (el) el.focus() }}
							disabled={isSending}
							onKeyUp={(e) => {
								if (e.key === 'Enter') {
									this.onSubmit();
								}
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default ModalCreate;
