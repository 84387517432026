import React, { Component, Fragment } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { holidaysService, companiesService, departmentsService, employeesService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
} from "./../../components";

import ModalRecalculate from './ModalRecalculate';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			recorrente: false,
			formToCompare: {},
			data: [],

			recalcularModalVisible: false,
		};

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;
		this._axiosCancelEmployeesToken = null;
	}

	fetchCompanies = () => {
		if (this._axiosCancelCompaniesToken) {
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelDepartmentsToken) {
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos") ? [] : this.form.getFieldValue("empresas"),
		}

		departmentsService.getAutocomplete({
			...params
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchEmployees = (value) => {
		if (this._axiosCancelEmployeesToken) {
			this._axiosCancelEmployeesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				employeesIsLoading: false,
				employees: [],
			});

			return false;
		}

		this.setState({
			employeesIsLoading: true,
		});

		let params = {
			search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelEmployeesToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos") ? [] : this.form.getFieldValue("empresas"),
			departamentos_ids: this.form.getFieldValue("departamentos").includes("todos") ? [] : this.form.getFieldValue("departamentos"),
		};

		employeesService.getAutocomplete({
			...params,
		})
			.then((response) => {
				this.setState({
					employeesIsLoading: false,
					employees: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					employeesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onCompanyChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("empresas", value.filter((item) => item !== "todos"));
		}

		this.setState({
			employees: [],
			employeesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
		});

		this.form.setFieldValue("departamentos", ["todos"]);
		this.form.setFieldValue("funcionarios", ["todos"]);
	};

	onCompanySelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("empresas", ["todos"]);
		}
	};

	onDepartmentChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("departamentos", value.filter((item) => item !== "todos"));
		}
	};

	onDepartmentSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("departamentos", ["todos"]);
		}
	};

	onEmployeeChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("funcionarios", value.filter((item) => item !== "todos"));
		}
	};

	onEmployeeSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("funcionarios", ["todos"]);
		}
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		if (!this.state.companies.length) {
			this.fetchCompanies();
		}

		holidaysService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				this.setState({
					isLoading: false,
					departments: item.departamentos ?? [],
					employees: item.funcionarios ?? [],
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		const formValues = {
			descricao: data.descricao,
			empresas: data.empresas.length > 0 ? data.empresas.map((item) => item.id) : ["todos"],
			departamentos: data.departamentos.length > 0 ? data.departamentos.map((item) => item.id) : ["todos"],
			funcionarios: data.funcionarios.length > 0 ? data.funcionarios.map((item) => item.id) : ["todos"],
			ativo: data.ativo,
			recorrente: data.recorrente,
			data_feriado: moment(data.data_feriado),
		}

		this.setState({
			recorrente: data.recorrente,
			formToCompare: formValues,
		});

		this.form.setFieldsValue(formValues);
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			recorrente: false,
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		if (!this.checkAnyFieldHasBeenChanged()) return;
		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = { ...values };

		// uuid
		data.uuid = uuid;

		data.data_feriado = moment(data.data_feriado).format("YYYY-MM-DD");

		if (!(data.empresas instanceof Array)) {
			data.empresas = [data.empresas];
		}

		if (!(data.departamentos instanceof Array)) {
			data.departamentos = [data.departamentos];
		}

		if (!(data.funcionarios instanceof Array)) {
			data.funcionarios = [data.funcionarios];
		}

		if (data.empresas.includes("todos")) {
			data.empresas = null;
		}

		if (data.departamentos.includes("todos")) {
			data.departamentos = null;
		}

		if (data.funcionarios.includes("todos")) {
			data.funcionarios = null;
		}

		this.setState(({
			data: data,
		}));

		const needToRecalculate = this.checkIfNeedToRecalculate();

		if (needToRecalculate) {
			this.recalculateOpen(data);

			return;
		}

		holidaysService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	checkAnyFieldHasBeenChanged = () => {
		const { formToCompare } = this.state;


		const fieldsModified = Object.keys(formToCompare).map(field => ({
			[field]: JSON.stringify(formToCompare[field]) !== JSON.stringify(this.form.getFieldValue(field))
		}));

		const someFieldHasBeenChanged = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return value[fieldName];
		});

		return someFieldHasBeenChanged;
	}

	checkIfNeedToRecalculate = () => {
		const fieldHasNoNeedToCallRecalculate = [
			'descricao'
		];

		const { formToCompare } = this.state;

		const fieldsModified = Object.keys(formToCompare).map(field => {
			return {
				[field]: JSON.stringify(formToCompare[field]) !== JSON.stringify(this.form.getFieldValue(field))
			};
		});




		const needToRecalculate = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return !fieldHasNoNeedToCallRecalculate.includes(fieldName) && value[fieldName];
		});

		return needToRecalculate;
	}

	recalculateOpen = ({ uuid }) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");

		// Callback
		this.props.onComplete();
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.props.onComplete();
	}

	render() {
		const { visible } = this.props;

		const {
			isLoading,
			isSending,
			companies,
			companiesIsLoading,
			departments,
			departmentsIsLoading,
			employees,
			employeesIsLoading,
			recalcularModalVisible
		} = this.state;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar Feriado`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="descricao" label="Descrição" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.descricao = el} />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.empresas.focus()
											}
										}} label="Data do Feriado" name="data_feriado" required={true} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={24}>
										<Form.Item name="empresas" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												ref={el => this.empresas = el}
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione a(s) empresa(s)"
												notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onChange={this.onCompanyChange}
												onSelect={this.onCompanySelect}
												showSearch>
												<Select.Option value="todos">Todas</Select.Option>
												{companies.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24}>
										<Form.Item name="departamentos" label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione o(s) departamento(s)"
												notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchDepartments}
												onChange={this.onDepartmentChange}
												onSelect={this.onDepartmentSelect}
												showSearch>
												<Select.Option value="todos">Todos</Select.Option>
												{departments.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24}>
										<Form.Item name="funcionarios" label="Funcionário" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												mode="multiple"
												filterOption={false}
												allowClear
												placeholder="Selecione o(s) funcionário(s)"
												notFoundContent={employeesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchEmployees}
												onChange={this.onEmployeeChange}
												onSelect={this.onEmployeeSelect}
												showSearch>
												<Select.Option value="todos">Todos</Select.Option>
												{employees.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col sm={24} md={12}>
										<Form.Item name="recorrente" label="Recorrente" valuePropName="checked">
											<Switch onChange={(e) => this.setState({ recorrente: e })} />
										</Form.Item>
									</Col>
									<Col sm={24} md={12}>
										<Form.Item name="ativo" label="Ativo" valuePropName="checked">
											<Switch />
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
				/>
			</Fragment>
		)
	}
}

export default Edit;
