import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Switch, Tabs } from 'antd'

import moment from 'moment'

import { equipmentsService } from './../../redux/services'
import { UIDrawerForm } from './../../components'

import ADV from './components/show/ADV'
import IDClass from './components/show/IDClass'
import IDX from './components/show/IDX'
import Primme from './components/show/Primme'

import Employees from './Employees'

const EQUIPAMENTS = {
  Primme: Primme,
  IDClass: IDClass,
  'Rep IDClass': IDClass,
  ADV: ADV,
  IDX: IDX,
  'Rep IDX': IDX,
};

class Show extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    external: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.stateClean = {
      isLoading: true,
      uuid: 0,
      item: {},
    }

    this.state = {
      ...this.stateClean,
    }
  }

  onOpen = (uuid) => {
    this.setState({
      ...this.stateClean,
    })

    equipmentsService
      .show({ uuid })
      .then((response) => {
        const item = response.data.data

        this.setState(
          {
            isLoading: false,
            item,
          },
          () => {
            this.tabEmployees && this.tabEmployees.init(item)
          }
        )
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
          onOk: () => {
            // Force close
            return this.onClose()
          },
        })
      })
  }

  onClose = () => {
    // Callback
    this.props.onClose()
  }

  render() {
    const { visible } = this.props

    const { isLoading, item } = this.state

    const EquipamentComponent = EQUIPAMENTS[item.modelo];

    return (
      <UIDrawerForm
        visible={visible}
        width={500}
        onClose={this.onClose}
        isLoading={isLoading}
        showBtnSave={false}
        title={`Visualizar Equipamento`}
      >
        <Form layout="vertical">
          <Tabs defaultActiveKey="general">
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Marca">{item.marca}</Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Modelo">{item.modelo}</Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Empresa(s)">
                    {item.empresas?.length > 0
                      ? item.empresas.map((empresa, index) => {
                          return (
                            <span key={empresa.id}>
                              {empresa.nome}{' '}
                              {index < item.empresas?.length - 1 ? <br /> : ''}
                            </span>
                          )
                        })
                      : 'Todas'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Departamento(s)">
                    {item.departamentos?.length > 0
                      ? item.departamentos.map((departamento, index) => {
                          return (
                            <span key={departamento.id}>
                              {departamento.nome}{' '}
                              {index < item.departamentos?.length - 1 ? (
                                <br />
                              ) : (
                                ''
                              )}
                            </span>
                          )
                        })
                      : 'Todos'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Funcionários(s)">
                    {item.funcionarios?.length > 0
                      ? item.funcionarios.map((funcionario, index) => {
                          return (
                            <span key={funcionario.id}>
                              {funcionario.nome}{' '}
                              {index < item.funcionarios?.length - 1 ? (
                                <br />
                              ) : (
                                ''
                              )}
                            </span>
                          )
                        })
                      : 'Todos'}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Tipo comunicação">
                    {item.tipo_comunicacao_nome}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Criação">
                    {moment(item.created_at).calendar()}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Última atualização">
                    {moment(item.updated_at).calendar()}
                  </Form.Item>
                </Col>
                <Form.Item label="Ativo">
                  <Switch disabled checked={item.ativo} />
                </Form.Item>
              </Row>
            </Tabs.TabPane>
            
            <Tabs.TabPane forceRender tab="Configurações" key="config">
              {EquipamentComponent && (<EquipamentComponent item={item} />)}
            </Tabs.TabPane>

            <Tabs.TabPane forceRender tab="Funcionários" key="employees">
              <Employees ref={(el) => (this.tabEmployees = el)} />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Show)
