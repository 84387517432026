import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Avatar, Badge, Card, Col, List, Modal, Row, Spin, Typography } from "antd";
const { Title } = Typography;
import QueueAnim from "rc-queue-anim";
// import { Pie } from "@ant-design/plots";

import moment from "moment";

import { dashboardService } from "./../../redux/services";

const customColors = ['#FFD700', '#FF7F7F ', '#00CED1 ', '#8A2BE2 ', '#32CD32 '];

let chartConfig = {
	appendPadding: 10,
	data: [],
	angleField: 'value',
	colorField: 'type',
	radius: 1,
	innerRadius: 0.6,
	height: 300,
	label: {
		type: 'inner',
		offset: '-50%',
		content: '{value}',
		style: {
			textAlign: 'center',
			fontSize: 0,
		},
	},
	theme: {
		colors10: customColors,
	},
	interactions: [
		{
			type: 'element-selected',
		},
		{
			type: 'element-active',
		},
	],
	// 	legend: {
	// 		position: MOBILE && "top"
	// 	},
};

const equipamento_labels = {
	'REP-P': 'Aplicativo',
	'REP-C': 'Equipamento',
}

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			funcionarios_ativos: 0,
			funcionarios_inativos: 0,
			funcionarios_ausentes: 0,
			total_funcionarios: 0,
			ultimo_registros: [],
			status_conexao: {},
			hoursChat: []
		};
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll();
	};

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		dashboardService.getAll()
			.then((response) => {
				const { data } = response.data || {};

				const chartData = [
					{
						type: 'Horas Extras',
						value: parseInt(data.horas_extra),
					},
					{
						type: 'Horas Faltas',
						value: parseInt(data.horas_falta),
					},
					{
						type: 'Banco de horas',
						value: parseInt(data.total_saldo),
					},
					{
						type: 'Débitos Totais',
						value: parseInt(data.total_bdebito),
					},
					{
						type: 'Créditos Totais',
						value: parseInt(data.total_bcredito),
					}
				];

				this.setState({
					isLoading: false,
					funcionarios_ativos: data.funcionarios_ativos ?? 0,
					funcionarios_inativos: data.funcionarios_inativos ?? 0,
					funcionarios_ausentes: data.funcionarios_ausentes ?? 0,
					total_funcionarios: data.total_funcionarios ?? 0,
					ultimo_registros: data.ultimo_registros ?? [],
					status_conexao: data.status_conexao ?? {},
					hoursChat: chartData ?? []
				});
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	greeting = () => {
		const hour = moment().hour();
		let day = "Bom dia";

		if (hour >= 19) {
			day = "Boa noite";
		}
		else if (hour >= 12) {
			day = "Boa tarde";
		}

		return `Olá ${this.props.user.name}, ${day}!`;
	};

	handleGetAvatarUrl = (basePathAvatar) => {
		return `${process.env.MIX_API_EMPRESA_URL}/${basePathAvatar}`
	}

	render() {
		const {
			isLoading,
			funcionarios_ativos,
			funcionarios_inativos,
			funcionarios_ausentes,
			total_funcionarios,
			ultimo_registros,
			status_conexao,
			hoursChat
		} = this.state;

		chartConfig = {
			...chartConfig,
			data: hoursChat,
		}

		// TODO definir corretamente as urls dos cards

		return (
			<QueueAnim className="site-content-inner page-home alternative-wrapper">
				<div className="page-content" key="1">
					<h1 className="page-title">{this.greeting()}</h1>
					{isLoading ? (
						<div className="text-center">
							<Spin indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />} />
						</div>
					) : (
						<Fragment>
							<div className="cards">
								<Row gutter={16} align="stretch">
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Total de funcionários</h3>
											<div className="value">{total_funcionarios}</div>
											<i className="fad fa-user" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Funcionários ativos</h3>
											<div className="value">{funcionarios_ativos}</div>
											<i className="fad fa-poll-people" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Funcionários <br /> inativos</h3>
											<div className="value">{funcionarios_inativos}</div>
											<i className="fad fa-poll-people" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Funcionários ausentes</h3>
											<div className="value">{funcionarios_ausentes}</div>
											<i className="fad fa-building" />
										</Card>
									</Col>
									{/* <Col xs={24} sm={8} lg={6} xxl={4}>
										<Card style={{ minHeight: "106px" }}>
											<h3>{status_conexao?.marca} - {status_conexao?.modelo}</h3>
											<Badge count={status_conexao?.status_conexao === "offline" ? "Offline" : "Online"} style={status_conexao.status_conexao === "offline" ? { backgroundColor: "#fa5c7c" } : { backgroundColor: "#0acf97" }} />
											<i className="fas fa-business-time" />
										</Card>
									</Col> */}
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="top" style={{ marginTop: "30px" }}>
									<Col xs={24} sm={12} lg={12} xl={12} xxl={6}>
										<Typography>
											<Title level={5}>Registros das últimas 24 horas</Title>
										</Typography>
										<List
											className="list-ultimos-registros"
											itemLayout="horizontal"
											dataSource={ultimo_registros}
											style={{ height: '300px', overflowY: 'scroll', paddingRight: '10px' }}
											renderItem={(item) => (
												<List.Item>
													<List.Item.Meta
														avatar={
															(!item?.funcionario?.avatar) ? (
																<i className="fad fa-user-circle avatar-placeholder" style={{ fontSize: 60, color: "#b3b3b3" }} />
															) : (
																<Avatar src={item?.funcionario?.avatar} size={60} />
															)}
														title={<Badge count={moment(item.data).format("DD/MM/YYYY - HH:mm:ss")} />}
														description={<strong>{item.funcionario?.nome}</strong>}
													/>
													<div style={{ paddingLeft: '80px' }}>
														<p><strong>{item.tipo}</strong></p>
														<p><strong>{item.equipamento?.tipo_equipamento || 'N/A'}</strong></p>
													</div>
												</List.Item>
											)}
										/>
									</Col>
									{/* <Col xs={24} sm={12} lg={12} xxl={6}>
										<Typography>
											<Title level={5}>Estatísticas dos últimos 30 dias</Title>
										</Typography>
										<Pie {...chartConfig} />
									</Col> */}
								</Row>
							</div>
						</Fragment>
					)}
				</div>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.auth.userData,
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Home);
