import React from 'react'
import { Card, Form, message, Space, Typography } from 'antd'
const { Text } = Typography
import moment from 'moment'
import { copyTextToClipboard } from '../../../../helpers/text'

const IDX = ({ item }) => {
  const { configuracao, numero_serial_rep, ultimo_nsr, token } = item

  const handleTokenCopyClick = (target) => {
    copyTextToClipboard(target.innerText).then(() => {
      message.info('Token copiado para área de transferência.')
    })
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Form.Item label="Número de série">{numero_serial_rep}</Form.Item>
        <Form.Item label="Último NSR">{ultimo_nsr}</Form.Item>
        <Form.Item label="IP">{configuracao.ip}</Form.Item>
        <Form.Item label="Porta TCP/IP">{configuracao.porta_tcp_ip}</Form.Item>
        <Form.Item label="Chave de comunicação">
          {configuracao.chaver_comunicacao}
        </Form.Item>
        <Form.Item label="Dígito para barras">
          {configuracao.digito_barras}
        </Form.Item>
      </Card>
      <Card title="Outros">
        <Form.Item label="Token">
          <Text
            onClick={({ target }) => handleTokenCopyClick(target)}
            style={{ cursor: 'pointer' }}
          >
            {token}
          </Text>
        </Form.Item>
        <Form.Item label="Fuso horário">{configuracao.fuso_horario}</Form.Item>
        <Form.Item label="Data e horário">
          {moment(configuracao?.atualizar_data_hora).format(
            'DD/MM/YYYY HH:mm:ss'
          )}
        </Form.Item>
      </Card>
      <Card title="Horário de verão">
        <Form.Item label="Início do horário de verão">
          {moment(configuracao.inicio_horario_verao).format('DD/MM/YYYY')}
        </Form.Item>
        <Form.Item label="Fim do horário de verão">
          {moment(configuracao.fim_horario_verao).format('DD/MM/YYYY')}
        </Form.Item>
      </Card>
    </Space>
  )
}

export default IDX
