import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import moment from 'moment';
import { Col, DatePicker, Card, Button, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "../../config/general";

import { employeesService, perimetersService, departmentsService, companiesService, journeysService, functionsService, annualLeaveService } from "../../redux/services";
import MaskedInput from "react-text-mask"

import ModalDepartmentCreate from "./departmentCreate";
import ModalFunctionsCreate from "./functionCreate";
import ModalJourneyShow from "./../Journeys/show";
import ModalAnnualLeaveShow from "./../AnnualLeave/show";

import { debounce } from 'lodash'; 

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
	UIUpload,
} from "../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;
class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};


	constructor(props) {
		super(props);

		this.state = {
			arquivar: false,
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			habilitar_app_empresa: false,
			selectedAnnualLeave: null,
			selectedJourney: null,
			selectedFunction: null,
			selectedDepartment: null,
			selectedNewContractAnnualLeave: null,
			selectedNewContractJourney: null,
			selectedNewContractFunction: null,
			selectedNewContractDepartment: null,
			app: false,
			type: null,
			companiesIsLoading: false,
			companies: [],
			departmentsIsLoading: false,
			departments: [],
			functionsIsLoading: false,
			functions: [],
			annualLeaveIsLoading: false,
			annualLeave: [],
			journeysIsLoading: false,
			journeys: [],
			perimetersIsLoading: false,
			perimeters: [],
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	onOpen = () => {
		this.setState({
			app: false,
			habilitar_app_empresa: false,
			selectedAnnualLeave: null,
			selectedJourney: null,
			selectedFunction: null,
			selectedDepartment: null,
			selectedNewContractAnnualLeave: null,
			selectedNewContractJourney: null,
			selectedNewContractFunction: null,
			selectedNewContractDepartment: null,
		})

		setTimeout(() => {
			this.nome && this.nome.focus()

		}, 100)
	};

	/**
	 * Journey Show
	 *
	 * @param uuid
	 */
	journeyShowOpen = ({ uuid }) => {
		this.setState({ journeyShowModalVisible: true });

		// On open screen
		this.journeyShowScreen.onOpen(this.state.selectedJourney);
	};

	journeyShowOnClose = () => {
		this.setState({ journeyShowModalVisible: false });
	};

	/**
	 * AnnualLeave Show
	 *
	 * @param uuid
	 */
	annualLeaveShowOpen = ({ uuid }) => {
		this.setState({ annualLeaveShowModalVisible: true });

		// On open screen
		this.annualLeaveShowScreen.onOpen(this.state.selectedAnnualLeave);
	};

	annualLeaveShowOnClose = () => {
		this.setState({ annualLeaveShowModalVisible: false });
	};

	/**
	  * Department Create
	  */
	departmentCreateOpen = (contract) => {
		this.setState({ departmentCreateModalVisible: true });

		// On open screen
		this.departmentCreateScreen.onOpen(contract ?? null);
	};

	departmentCreateOnClose = () => {
		this.setState({ departmentCreateModalVisible: false });
		this.departamento_id && this.departamento_id.focus();
	};

	departmentCreateOnComplete = (item, contract) => {
		this.setState({ departmentCreateModalVisible: false });

		this.departamento_id && this.departamento_id.focus();
		this.form.setFieldsValue({ departamento_id: item.id })
		this.setState({ selectedDepartment: item.nome })

	};

	/**
	  * Function Create
	  */
	functionCreateOpen = () => {
		this.setState({ functionCreateModalVisible: true });

		// On open screen
		this.functionCreateScreen.onOpen();
	};

	functionCreateOnClose = () => {
		this.setState({ functionCreateModalVisible: false });
		this.funcao_id && this.funcao_id.focus();
	};

	functionCreateOnComplete = (item) => {
		this.setState({ functionCreateModalVisible: false });

		this.funcao_id && this.funcao_id.focus();
		this.form.setFieldsValue({ funcao_id: item.id })
		this.setState({ selectedFunction: item.nome })
	};

	fetchCompaniesSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				companiesIsLoading: false,
				companies: [],
			});

			return false;
		}

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			ativo: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 1000);

	fetchCompanies = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAll({cancelToken: this._axiosCancelTenantsToken.token})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchPerimeters = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				perimetersIsLoading: false,
				perimeters: [],
			});

			return false;
		}

		this.setState({
			perimetersIsLoading: true,
		});

		perimetersService.getAutocomplete({
			search: value,
			status: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					perimetersIsLoading: false,
					perimeters: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					perimetersIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartmentsSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 1000);

	fetchDepartments = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({cancelToken: this._axiosCancelTenantsToken.token})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchFunctionsSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				functionsIsLoading: false,
				functions: [],
			});

			return false;
		}

		this.setState({
			functionsIsLoading: true,
		});

		functionsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					functionsIsLoading: false,
					functions: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					functionsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 1000);

	fetchFunctions = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			functionsIsLoading: true,
		});

		functionsService.getAll({cancelToken: this._axiosCancelTenantsToken.token})
			.then((response) => {
				this.setState({
					functionsIsLoading: false,
					functions: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					functionsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchAnnualLeaveSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				annualLeaveIsLoading: false,
				annualLeave: [],
			});

			return false;
		}

		this.setState({
			annualLeaveIsLoading: true,
		});

		annualLeaveService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					annualLeaveIsLoading: false,
					annualLeave: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					annualLeaveIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 1000);

	fetchAnnualLeave = () => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			annualLeaveIsLoading: true,
		});

		annualLeaveService.getAll({cancelToken: this._axiosCancelTenantsToken.token})
			.then((response) => {
				this.setState({
					annualLeaveIsLoading: false,
					annualLeave: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					annualLeaveIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchJourneysSearch = debounce((value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				journeysIsLoading: false,
				journeys: [],
			});

			return false;
		}

		this.setState({
			journeysIsLoading: true,
		});

		journeysService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					journeystenantsIsLoading: false,
					journeys: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 1000);

	fetchJourneys = () => {		
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		this.setState({
			journeysIsLoading: true,
		});

		journeysService.getAll({cancelToken: this._axiosCancelTenantsToken.token})
			.then((response) => {
				this.setState({
					journeystenantsIsLoading: false,
					journeys: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		const file = this.upload.getFiles();

		if (file.hasError) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: file.error,
			});

			return false;
		}
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		if (data.data_demissao) {
			data.data_demissao = data.data_demissao.format("YYYY-MM-DD");
		}

		if (data.novo_contrato_data_admissao) {
			data.novo_contrato_data_admissao = data.novo_contrato_data_admissao.format("YYYY-MM-DD");
		}

		if (data.data_admissao) {
			data.data_admissao = data.data_admissao.format("YYYY-MM-DD");
		}

		if (data.data_inicio_cartao_ponto) {
			data.data_inicio_cartao_ponto = data.data_inicio_cartao_ponto.format("YYYY-MM-DD");
		}

		if (data.data_nascimento) {
			data.data_nascimento = data.data_nascimento.format("YYYY-MM-DD");
		}

		if (data.rg_data_expedicao) {
			data.rg_data_expedicao = data.rg_data_expedicao.format("YYYY-MM-DD");
		}

		if (file.files.length) {
			if (!file.files[0].uuid) {
				data.avatar = file.files[0];
			}
		}
		else {
			data.avatar = null;
		}

		if (data.pin) {
			const numbers = data.pin.split("_")
			data.pin = numbers[0]
		}

		if (data.banco_horas_id) {
			data["vinculo[0][banco_horas_id]"] = data.banco_horas_id
		}

		data.pis_pasep = data.pis_pasep.replaceAll('.', '').replace('-', '')

		employeesService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Cliente cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onDateLayOffChange = (value) => {
		this.handleSwitchArchive(value)
	}
	
	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}

	handleSwitchArchive = (value) => {
		const archive = !!value;
		this.form.setFieldValue('arquivar', archive);
		this.setState({
			arquivar
		});
	}

	handleDropdownVisibleChange = (entity, fetchFunction) => {
		if (entity.length === 0) {
			fetchFunction();
		} 
	};

	render() {
		const { visible } = this.props;

		const {
			isLoading,
			isSending,
			nextOrder,
			companies,
			companiesIsLoading,
			annualLeave,
			annualLeaveIsLoading,
			departments,
			departmentsIsLoading,
			functions,
			functionsIsLoading,
			journeys,
			journeysIsLoading,
			app,
			habilitar_app_empresa,
			perimeters,
			perimetersIsLoading,
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Novo Funcionário">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						order: nextOrder,
						required: true,
						ativo: true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={5}>
									<UIUpload
										name="avatar"
										ref={el => this.upload = el}
										label="Avatar"
										labelError="Avatar"
										acceptedFiles={['jpg', 'jpeg', 'png']}
									/>
								</Col>
								<Col xs={13}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input 
											ref={el => this.nome = el} 
											onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item name="cpf" label="CPF" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]} >
										<MaskedInput
											mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
											className="ant-input"
											onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={8}>
									<Form.Item name="numero_folha" label="Número da Folha" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber type="number" style={{ width: "100%" }} onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
								<Col xs={8}>
									<Form.Item name="numero_identificador" label="Número do Identificador" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber type="number" style={{ width: "100%" }} onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
								<Col xs={8}>
									<Form.Item name="pis_pasep" label="PIS/PASEP" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<MaskedInput
											className="ant-input"
											mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/,]}
											onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={8}>
									<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.sexo.focus()
											}
										}} name="data_nascimento" label="Data de Nascimento" disableDateAfter={true} 
									/>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item name="sexo" label="Sexo" >
										<Select
											ref={el => this.sexo = el}
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											showSearch>
											<Select.Option value="0">Masculino</Select.Option>
											<Select.Option value="1">Feminino</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={10}>
									<Form.Item name="ctps" label="CTPS">
										<InputNumber type="number" style={{ width: "100%" }} onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item name="rg" label="RG" hasFeedback >
										<MaskedInput
											mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
											className="ant-input"
											onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}
										/>
									</Form.Item>
								</Col>
								<Col xs={8}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.rg_ssp.focus()
										}
									}} name="rg_data_expedicao" label="Data de Expedição" disableDateAfter={true} />
								</Col>
								<Col xs={8}>
									<Form.Item name="rg_ssp" label="SSP" hasFeedback >
										<Input ref={el => this.rg_ssp = el} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="nacionalidade" label="Nacionalidade" hasFeedback >
										<Input onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="naturalidade" label="Naturalidade" hasFeedback >
										<Input onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="nome_pai" label="Nome do Pai" hasFeedback >
										<Input onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="nome_mae" label="Nome da Mãe" hasFeedback >
										<Input onDragStart={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="ativo" label="Ativo" valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contratos" key="contracts">
							{/* <Card title="Novo contrato" style={{ marginBottom: "20px" }}>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="novo_contrato_empresa_id" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												filterOption={false}
												allowClear
												disabled
												placeholder="Escolha a empresa"
												notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchCompanies}
												showSearch
											>
												{companies.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item name="novo_contrato_departamento_id" hasFeedback label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													style={{ width: '100%' }}
													placeholder="Escolha o departamento"
													notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onSearch={this.fetchDepartments}
													showSearch
													onChange={(value) => {
														this.setState({ selectedNewContractDepartment: value })
														this.form.setFieldsValue({ novo_contrato_departamento_id: value })
													}}
												>
													{departments.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
											</Input.Group>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="novo_contrato_funcao_id" label="Função" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													style={{ width: '100%' }}
													placeholder="Escolha a função"
													notFoundContent={functionsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onSearch={this.fetchFunctions}
													showSearch
													onChange={(value) => {
														this.setState({ selectedNewContractFunction: value })
														this.form.setFieldsValue({ novo_contrato_funcao_id: value })
													}}
												>
													{functions.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
											</Input.Group>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item name="novo_contrato_jornada_id" label="Horário" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													style={{ width: '100%' }}
													placeholder="Escolha o horário"
													notFoundContent={journeysIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onSearch={this.fetchJourneys}
													onChange={(value) => {
														this.setState({ selectedNewContractJourney: journeys.filter((journey) => journey.id == value)[0].uuid })
														this.form.setFieldsValue({ novo_contrato_jornada_id: value })
													}}
													showSearch
												>
													{journeys.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.descricao}
														</Select.Option>
													))}
												</Select>
											</Input.Group>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="novo_contrato_banco_horas_id" label="Banco de horas" hasFeedback >
											<Input.Group compact>
												<Select
													ref={el => this.banco_horas_id = el}
													filterOption={false}
													allowClear
													style={{ width: '100%' }}
													placeholder="Escolha o banco de horas"
													notFoundContent={annualLeaveIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onSearch={this.fetchAnnualLeave}
													onChange={(value) => {
														this.setState({ selectedNewContractAnnualLeave: annualLeave.filter((annual) => annual.id == value)[0].uuid })
														this.form.setFieldsValue({ novo_contrato_banco_horas_id: value })
													}
													}
													showSearch
												>
													{annualLeave.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.descricao}
														</Select.Option>
													))}
												</Select>
											</Input.Group>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<DatePickerWithMask name="novo_contrato_data_admissao" label="Data de admissão" required={true} />
									</Col>
								</Row>
							</Card> */}
							<Card title="Contrato 1" style={{ marginBottom: "20px" }}>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="empresa_id" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												filterOption={false}
												allowClear
												placeholder="Escolha a empresa"
												notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onDropdownVisibleChange={() => this.handleDropdownVisibleChange(companies, this.fetchCompanies)}
												onSearch={this.fetchCompaniesSearch}
												showSearch
												onChange={(value) => this.form.setFieldsValue({ novo_contrato_empresa_id: value })}
											>
												{companies.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item name="departamento_id" hasFeedback label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													ref={el => this.departamento_id = el}
													style={{ width: 'calc(100% - 50px)' }}
													placeholder="Escolha o departamento"
													notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(departments, this.fetchDepartments)}
													showSearch
													onSearch={this.fetchDepartmentsSearch}
													onChange={(value) => {
														this.setState({ selectedDepartment: value })
														this.form.setFieldsValue({ departamento_id: value })
													}}
													value={this.state.selectedDepartment}
												>
													{departments.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
												<Button onClick={this.departmentCreateOpen} type="primary">+</Button>
											</Input.Group>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="funcao_id" label="Função" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													ref={el => this.funcao_id = el}
													style={{ width: 'calc(100% - 50px)' }}
													placeholder="Escolha a função"
													notFoundContent={functionsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(functions, this.fetchFunctions)}
													showSearch
													onSearch={this.fetchFunctionsSearch}
													onChange={(value) => {
														this.setState({ selectedFunction: value })
														this.form.setFieldsValue({ funcao_id: value })
													}}
													value={this.state.selectedFunction}
												>
													{functions.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.nome}
														</Select.Option>
													))}
												</Select>
												<Button onClick={this.functionCreateOpen} type="primary">+</Button>
											</Input.Group>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item name="jornada_id" label="Horário" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input.Group compact>
												<Select
													filterOption={false}
													allowClear
													style={{ width: 'calc(100% - 50px)' }}
													placeholder="Escolha o horário"
													notFoundContent={journeysIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(journeys, this.fetchJourneys)}
													onChange={(value) => {
														if (!value) {
															this.setState({ selectedJourney: null })
															this.form.setFieldsValue({ jornada_id: null })
														} else {
															this.setState({ selectedJourney: journeys.filter((journey) => journey.id == value)[0].uuid })
															this.form.setFieldsValue({ jornada_id: value })
														}
													}}
													showSearch
													onSearch={this.fetchJourneysSearch}
												>
													{journeys.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.descricao}
														</Select.Option>
													))}
												</Select>
												<Button disabled={!this.state.selectedJourney ? true : false} onClick={this.journeyShowOpen} type="primary">|||</Button>
											</Input.Group>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="banco_horas_id" label="Banco de horas" hasFeedback >
											<Input.Group compact>
												<Select
													ref={el => this.banco_horas_id = el}
													filterOption={false}
													allowClear
													style={{ width: 'calc(100% - 50px)' }}
													placeholder="Escolha o banco de horas"
													notFoundContent={annualLeaveIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
													onDropdownVisibleChange={() => this.handleDropdownVisibleChange(annualLeave, this.fetchAnnualLeave)}
													onChange={(value) => {
														if (!value) {
															this.setState({ selectedAnnualLeave: null })
															this.form.setFieldsValue({ banco_horas_id: null })
														} else {
															if (value != -1) {
																this.setState({ selectedAnnualLeave: annualLeave.filter((annual) => annual.id == value)[0].uuid })
															}
															
															this.form.setFieldsValue({ banco_horas_id: value })
														}
													}
													}
													showSearch
													onSearch={this.fetchAnnualLeaveSearch}
												>
													<Select.Option key={-1} value={-1}>
														Não vincular banco de horas
													</Select.Option>
													{annualLeave.map((item, index) => (
														<Select.Option key={index} value={item.id}>
															{item.descricao}
														</Select.Option>
													))}
												</Select>
												<Button disabled={!this.state.selectedAnnualLeave ? true : false} onClick={this.annualLeaveShowOpen} type="primary">|||</Button>
											</Input.Group>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<DatePickerWithMask name="data_admissao" label="Data de admissão" required={true} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<DatePickerWithMask 
											name="data_demissao" 
											label="Data de Demissão" 
											onChange={this.onDateLayOffChange}
										/>
									</Col>
									<Col xs={12}>
										<DatePickerWithMask name="data_inicio_cartao_ponto" label="Data Início Cartão Ponto" required={true} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={12}>
										<Form.Item name="arquivar" label={<div className="inputInfo"><p>Arquivar</p><span>(Não mostrar o contrato no cartão ponto e relatórios)</span></div>} valuePropName="checked">
											<Switch ref={el => this.arquivar = el} onChange={(value) => this.setState({ arquivar: value })} />
										</Form.Item>
									</Col>
								</Row>
							</Card>
						</Tabs.TabPane>
						{
							displayParamsREPP && (
								<Tabs.TabPane forceRender tab="Parâmetros REP-P" key="app">
									<Row gutter={16}>
										{
											displayAppEmployee && (
												<Col xs={8}>
													<Form.Item name="app" label="Habilitar app funcionário" hasFeedback >
														<Switch onChange={(value) => this.setState({ app: value })} />
													</Form.Item>
												</Col>
											)
										}
										{app && (
											<>
												<Col xs={8}>
													<Form.Item name="email" label="E-mail" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
														<Input />
													</Form.Item>
												</Col>
												<Col xs={8}>
													<Form.Item name="password" label="Senha" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }, { min: 6, message: "Deve conter no mínimo 6 caracteres." }]} >
														<Input minLength={6} />
													</Form.Item>
												</Col>
											</>
										)}
									</Row>
									<Row gutter={16}>
										{
											displayAppCompany && (
												<Col xs={8}>
													<Form.Item name="habilitar_app_empresa" label="Habilitar app empresa" hasFeedback >
														<Switch onChange={(value) => this.setState({ habilitar_app_empresa: value })} />
													</Form.Item>
												</Col>
											)
										}
										{habilitar_app_empresa && (
											<Col xs={8}>
												<Form.Item name="pin" label="PIN" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }, { min: 4, max: 4, message: "Deve conter 4 caracteres." }]}>
													<Input type="number" style={{ width: "100%" }} />
												</Form.Item>
											</Col>
										)}
									</Row>
									<Row gutter={16}>
										<Col xs={12}>
											<Form.Item name="envio_self" label="Necessário envio de selfie" hasFeedback >
												<Switch />
											</Form.Item>
										</Col>
									</Row>
									{(habilitar_app_empresa || app) && (
										<Row gutter={16}>
											<Col xs={12}>
												<Form.Item name="perimetro_id" label={"Perímetro"} hasFeedback>
													<Select
														filterOption={false}
														allowClear
														placeholder="Escolha o perímetro"
														notFoundContent={perimetersIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
														onSearch={this.fetchPerimeters}
														showSearch
													>
														{perimeters.map((item, index) => (
															<Select.Option key={index} value={item.id}>
																{item.nome}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
										</Row>
									)}
								</Tabs.TabPane>
							)
						}
						<Tabs.TabPane forceRender tab="Credenciais REP-C" key="equipments-data">
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_senha" label="Senha do equipamento" hasFeedback >
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_codigo_barras" label="Código de barras" hasFeedback >
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_rfid" label="RFID" hasFeedback >
										<InputNumber type="number" style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_enviar_rfid" label="Enviar RFID" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<Form.Item name="equipamento_admin" label="Admin" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item name="equipamento_biometria" label="Biometria" hasFeedback valuePropName="checked" >
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
				<ModalDepartmentCreate
					ref={el => this.departmentCreateScreen = el}
					visible={this.state.departmentCreateModalVisible}
					onComplete={this.departmentCreateOnComplete}
					onClose={this.departmentCreateOnClose}
				/>
				<ModalFunctionsCreate
					ref={el => this.functionCreateScreen = el}
					visible={this.state.functionCreateModalVisible}
					onComplete={this.functionCreateOnComplete}
					onClose={this.functionCreateOnClose}
				/>
				<ModalJourneyShow
					ref={el => this.journeyShowScreen = el}
					visible={this.state.journeyShowModalVisible}
					onClose={this.journeyShowOnClose}
				/>
				<ModalAnnualLeaveShow
					ref={el => this.annualLeaveShowScreen = el}
					visible={this.state.annualLeaveShowModalVisible}
					onClose={this.annualLeaveShowOnClose}
				/>
			</UIDrawerForm>
		)
	}
}

export default Create;
