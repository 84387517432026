import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown, Menu, Modal, Spin, Tag } from 'antd'
import QueueAnim from 'rc-queue-anim'
import enquire from 'enquire.js'

import moment from 'moment'

import { DESKTOP_DOWN } from '../../config/mediaQueries'

import { generalActions } from './../../redux/actions'

import { perimetersService } from './../../redux/services'

import { UIPageListing } from './../../components'

import ModalShow from './show'
import ModalFilters from './filters'
import ModalEdit from './edit'
import ModalCreate from './create'

const config = {
  title: 'Perímetros',
  permissionPrefix: 'perimetro',
  list: 'perimetro',
  searchPlaceholder: 'Buscar por descrição',
  orders: [
    {
      label: 'Mais recentes',
      field: 'id',
      sort: 'desc',
      default: true,
    },
    {
      label: 'Mais antigos',
      field: 'id',
      sort: 'asc',
    },
    {
      label: 'Nome A|Z',
      field: 'nome',
      sort: 'asc',
    },
    {
      label: 'Nome Z|A',
      field: 'nome',
      sort: 'desc',
    },
  ],
}

class Index extends Component {
  constructor(props) {
    super(props)

    const defaultOrder = config.orders.find((o) => o.default)

    this.state = {
      isLoading: false,
      listType: 'list',
      data: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
      orderByLabel: defaultOrder.label,
      orderByField: defaultOrder.field,
      orderBySort: defaultOrder.sort,
      search: '',
      // Actions
      createModalVisible: false,
      editModalVisible: false,
      filtersModalVisible: false,
      showModalVisible: false,
      activeLoadings: [],
      // Media queries
      desktopDown: false,
      // Filters
      filters: {
        status: 1,
      },
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.listType && state.listType !== props.listType) {
      return {
        listType: props.listType,
      }
    }

    return null
  }

  componentDidMount() {
    // Fetch all
    this.fetchGetAll(true)

    // Listen Media Query sideBar
    enquire.register(DESKTOP_DOWN, {
      match: () => {
        this.setState({
          desktopDown: true,
        })
      },
      unmatch: () => {
        this.setState({
          desktopDown: false,
        })
      },
    })
  }

  componentWillUnmount() {
    // Unlisten Media Query sideBar
    enquire.unregister(DESKTOP_DOWN)
  }

  menuItem = (item) => (
    <Menu className="actions-dropdown-menu">
      <Menu.Item key="show">
        <a onClick={() => this.showOpen(item)}>
          <i className="fal fa-file" />
          Visualizar
        </a>
      </Menu.Item>
      <Menu.Item key="edit">
        <a onClick={() => this.editOpen(item)}>
          <i className="fal fa-pen" />
          Editar
        </a>
      </Menu.Item>
      <Menu.Item key="delete" className="divider btn-delete">
        <a onClick={() => this.deleteConfirm(item)}>
          <i className="fal fa-trash" />
          Excluir
        </a>
      </Menu.Item>
    </Menu>
  )

  columns = () => {
    const listTypeCard = this.state.listType === 'card'
    return [
      {
        title: 'Descrição',
        render: (item) => (listTypeCard ? <h3>{item.nome}</h3> : item.nome),
      },
      {
        title: 'Latitude',
        render: (item) =>
          listTypeCard ? <h3>{item.latitude}</h3> : item.latitude,
      },
      {
        title: 'Longitude',
        render: (item) =>
          listTypeCard ? <h3>{item.longitude}</h3> : item.longitude,
      },
      {
        title: 'Raio (em metros)',
        render: (item) => (listTypeCard ? <h3>{item.raio}</h3> : item.raio),
      },
      {
        title: 'Data do Cadastro',
        className: 'datetime',
        render: (item) => {
          if (listTypeCard) {
            return (
              <Fragment>
                <i className="fal fa-plus-circle" style={{ marginRight: 5 }} />
                {moment(item.created_at).format('DD/MM/YYYY')}
              </Fragment>
            )
          }

          return moment(item.created_at).format('DD/MM/YYYY')
        },
      },
      {
        title: 'Status',
        className: 'active no-ellipsis',
        width: 90,
        render: (item) =>
          this.state.activeLoadings.indexOf(item.uuid) !== -1 ? (
            <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} />
          ) : (
            <Tag color={item.status ? '#0acf97' : '#fa5c7c'}>
              {item.status ? 'Ativo' : 'Inativo'}
            </Tag>
          ),
      },
      {
        title: 'Ações',
        className: 'actions no-ellipsis',
        visible: true,
        render: (item) => (
          <Dropdown
            overlay={this.menuItem(item)}
            className="actions-dropdown"
            placement="bottomRight"
            trigger={['click']}
          >
            <Button icon={<i className="fal fa-ellipsis-v" />} />
          </Dropdown>
        ),
      },
    ]
  }

  fetchGetAll = (init = false) => {
    const { pagination, orderByField, orderBySort, search, filters } =
      this.state

    this.setState({
      isLoading: true,
    })

    const data = {
      orderBy: `${orderByField}:${orderBySort}`,
      search: search,
    }

    data.page = init ? 1 : pagination.current
    data.limit = pagination.pageSize

    if (filters.status !== null) {
      data.status = filters.status
    }

    perimetersService
      .getAll(data)
      .then((response) => {
        this.setState((state) => ({
          isLoading: false,
          data: response.data.data,
          pagination: {
            ...state.pagination,
            current: response.data.meta.current_page,
            total: response.data.meta.total,
          },
        }))
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onListTypeChange = (type) => {
    this.props.onChangeListType(type)
  }

  onPaginationChange = (page) => {
    this.setState(
      (state) => ({
        pagination: {
          ...state.pagination,
          current: page,
        },
      }),
      () => {
        this.fetchGetAll()
      }
    )
  }

  onOrderChange = (value) => {
    const defaultOrder = config.orders.find(
      (o) => `${o.field}:${o.sort}` === value
    )

    if (!defaultOrder) return null

    this.setState(
      (state) => ({
        orderByLabel: defaultOrder.label,
        orderByField: defaultOrder.field,
        orderBySort: defaultOrder.sort,
      }),
      () => {
        this.fetchGetAll(true)
      }
    )
  }

  onSearch = (value) => {
    this.setState(
      {
        search: value,
      },
      () => {
        this.fetchGetAll(true)
      }
    )
  }

  onSearchChange = (e) => {
    // If it does not have type then it's cleaning
    if (!e.hasOwnProperty('type')) {
      const { search } = this.state

      this.setState(
        {
          search: e.target.value,
        },
        () => {
          if (search) {
            this.fetchGetAll(true)
          }
        }
      )
    }
  }

  /**
   * Show
   *
   * @param uuid
   */
  showOpen = ({ uuid }, general_key) => {
    this.setState({ showModalVisible: true })

    // On open screen
    this.showScreen.onOpen(uuid, general_key)
  }

  showOnClose = () => {
    this.setState({ showModalVisible: false })
  }

  /**
   * Delete
   *
   * @param uuid
   */
  deleteConfirm = ({ uuid }) => {
    Modal.confirm({
      title: 'Confirmar exclusão!',
      content: 'Tem certeza de que deseja excluir este registro?',
      okText: 'Excluir',
      autoFocusButton: null,
      onOk: () => {
        return this.deleteConfirmed(uuid)
      },
    })
  }

  deleteConfirmed = (uuid) => {
    return perimetersService
      .destroy({ uuid })
      .then((response) => {
        // Fetch all
        this.fetchGetAll()
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

 /**
	 * Create
	 */
	createOpen = () => {
		this.setState({ createModalVisible: true });

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({ createModalVisible: false });
	};

	createOnComplete = () => {
		this.setState({ createModalVisible: false });
		// Fetch all
		this.fetchGetAll(true)
	};

  /**
   * Edit
   *
   * @param uuid
   */
  editOpen = ({ uuid }) => {
    this.setState({ editModalVisible: true })

    // On open screen
    this.editScreen.onOpen(uuid)
  }

  editOnClose = () => {
    this.setState({ editModalVisible: false })
  }

  editOnComplete = () => {
    this.setState({ editModalVisible: false })

    // Fetch all
    this.fetchGetAll()
  }

  /**
   * Filter
   */
  filtersOpen = () => {
    this.setState({ filtersModalVisible: true })

    // On open screen
    this.filtersScreen.onOpen({ ...this.state.filters })
  }

  filtersOnClose = () => {
    this.setState({ filtersModalVisible: false })
  }

  filtersOnComplete = (filters) => {
    this.setState({ filtersModalVisible: false })
    this.setState(
      {
        filters: filters,
      },
      () => {
        // Fetch all
        this.fetchGetAll(true)
      }
    )
  }

  render() {
    return (
      <QueueAnim className="site-content-inner">
        <div className="page-content fixed-header" key="1">
          <h1 className="page-title">{config.title}</h1>
          <UIPageListing
            onSearch={this.onSearch}
            onSearchChange={this.onSearchChange}
            onPaginationChange={this.onPaginationChange}
            onOrderChange={this.onOrderChange}
            onListTypeChange={this.onListTypeChange}
            onFiltersClick={this.filtersOpen}
            isLoading={this.state.isLoading}
            listType={this.state.listType}
            orderByField={this.state.orderByField}
            orderBySort={this.state.orderBySort}
            orders={config.orders}
            search={this.state.search}
            searchPlaceholder={config.searchPlaceholder}
            data={this.state.data}
            pagination={this.state.pagination}
            columns={this.columns()}
            showFilters={true}
            buttons={[
              {
                visible: true,
                onClick: this.createOpen,
                title: 'Incluir',
                icon: <i className="far fa-plus" />,
              },
            ]}
          />
        </div>
        <ModalCreate
					ref={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
				/>
        <ModalEdit
          ref={(el) => (this.editScreen = el)}
          visible={this.state.editModalVisible}
          onComplete={this.editOnComplete}
          onClose={this.editOnClose}
        />
        <ModalShow
          ref={(el) => (this.showScreen = el)}
          visible={this.state.showModalVisible}
          onClose={this.showOnClose}
        />
        <ModalFilters
          ref={(el) => (this.filtersScreen = el)}
          visible={this.state.filtersModalVisible}
          onComplete={this.filtersOnComplete}
          onClose={this.filtersOnClose}
        />
      </QueueAnim>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
    listType: state.general.listType[config.list],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeListType: (type) => {
      dispatch(generalActions.changeListType(config.list, type))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
