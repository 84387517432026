import React, { Component } from 'react'
import * as PropTypes from "prop-types";

import { Form, Modal, Radio, Row, Col, message } from "antd";
import { DatePickerWithMask } from '../../../components';

import moment from 'moment';

import { journeysService, recalculateSchedulesService } from '../../../redux/services';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Recalculate extends Component {
  static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
	};

  constructor(props) {
		super(props);

		this.state = {
      isSending: false,
      recalcular: true,
      horario: []
    };
	}

  onOpen = (uuid) => {
    journeysService.show({ uuid })
      .then((response) => {
        const item = response.data.data[0];

        this.setState({
          horario: [item.id],
        })
      })
      .catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
  };

  onClose = () => {
		// Callback
		this.props.onComplete();
	};

  onFinish = async () => {
    try {
      const values = await this.form.validateFields();
      this.setState({ isSending: true });
  
      const { recalcular, horario } = this.state;
      const { data: dados } = this.props;
      
      const data = {
        ...values,
        data_inicial: moment(values.data_inicial).format('YYYY-MM-DD'),
        data_final: moment(values.data_final).format('YYYY-MM-DD'),
        horarios: horario,
      };
  
      try {
        await journeysService.edit(dados);
        this.setState({ isSending: false });
        if (!recalcular) this.props.onComplete();
      } catch (error) {
        this.setState({ isSending: false });
        Modal.error({
          title: "Ocorreu um erro!",
          content: String(error),
        });
        return;
      }
  
      if (recalcular) {
        this.setState({ isSending: true });
        try {
          await recalculateSchedulesService.recalculate(data);
          this.setState({ isSending: false });
          this.props.onComplete();
        } catch (error) {
          this.setState({ isSending: false });
          Modal.error({
            title: "Ocorreu um erro!",
            content: String(error),
          });
        }
      } else {
        this.setState({ isSending: false });
        this.props.onClose();
      }
    } catch (error) {
      this.setState({ isSending: false });
      message.error("Erro na validação dos campos:", error);
    }
  };

  validateEndDate = async (_, value) => {
    const dataInicial = this.form.getFieldValue('data_inicial');
    if (!value || !dataInicial || value.endOf('day').isSameOrAfter(dataInicial)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Data final deve ser maior ou igual à data inicial.'));
  };

  validateStartDate = async (_, value) => {
    const dataFinal = this.form.getFieldValue('data_final');
    if (!value || !dataFinal || value.startOf('day').isSameOrBefore(dataFinal)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Data inicial deve ser maior ou igual à data final.'));
  };
  
  render() {
    const { visible } = this.props;

    const { isSending } = this.state;

    return (
      <Modal
        visible={visible}
        title="Recalcular Jornadas"
        keyboard={false}
        centered
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        width={500}
        okText={this.state.recalcular ? "Recalcular" : "Finalizar"}
        onOk={this.onFinish}
        confirmLoading={isSending}
        onCancel={() => this.props.onCancel?.()}
        className="modal-recalcular"
      >
        <Form
          ref={el => this.form = el}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <p>
            Atenção: A modificação que você fez aqui pode alterar cálculos de
            horas de dias anteriores. O que deseja fazer?
          </p>
          <Radio
            checked={this.state.recalcular}
            onChange={() => this.setState({ recalcular: true })}
          >
            Recalcular marcações de dias anteriores.
          </Radio>
          <Row gutter={16}>
            <Col xs={12}>
              <DatePickerWithMask
                name="data_inicial"
                label="Data Início"
                rules={[
                  { required: this.state.recalcular, message: 'Campo obrigatório.' },
                  { validator: this.validateStartDate }
                ]}
              />
            </Col>
            <Col xs={12}>
              <DatePickerWithMask
                name="data_final"
                label="Data Fim"
                rules={[
                  { required: this.state.recalcular, message: 'Campo obrigatório.' },
                  { validator: this.validateEndDate }
                ]}
              />
            </Col>
          </Row>
          <Radio
            checked={!this.state.recalcular}
            onChange={() => this.setState({ recalcular: false })}
          >
            Aplicar esta modificação somente para as novas marcações.
          </Radio>
        </Form>
      </Modal>
    )
  }
}

export default Recalculate;
