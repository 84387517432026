import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Input, Checkbox, message, Modal, Tabs, Row, Select, Switch, InputNumber } from "antd";

import { overtimeService, overtimeDadosService, overtimeFaixasService } from "./../../redux/services";

import { CheckIdOvertime } from "../../utils/checkOvertimeAndDSRHasEmployee";

import {
	HourPickerWithMask,
	UIDrawerForm,
} from "./../../components";

import DayTable from "./Table/edit";
import ModalRecalculate from './ModalRecalculate';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const TIPO_FAIXAS = [
	{ label: 'Faixas por quantidade', value: 'quantidade', type: ['diario', 'semanal', 'mensal'] },
	{ label: 'Faixas por intervalo', value: 'intervalo', type: ['diario'] },
];

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			controle_horas: 'diario',
			controle_horas_inicio: null,
			isLoading: true,
			isSending: false,
			uuid: 0,
			tab: "uteis",
			tabIntern: "diurno",
			recalcularModalVisible: false,
			formToCompare: {},
			hora_extra_dados: [],
			data: [],
			dados: [],
			id: 0,
		};
	}

	onTabChange = (key) => {
		this.setState({ tab: key });
	}

	onTabInternChange = (key) => {
		this.setState({ tabIntern: key });
	}

	getFaixas = async (uuid) => {
		const overtimeDados = (await overtimeDadosService.show({ uuid })).data.data;

		let dados = {
			uteis: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			sabado: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			domingo: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			feriado: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			folga: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			dia_especial: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
		};

		Promise.all(overtimeDados.map(async (item) => {
			await overtimeFaixasService.show({ uuid: item.uuid }).then((response) => {
				if (item.incidencia) {
					dados[item.incidencia][item.periodo].id = item.id;
					dados[item.incidencia][item.periodo].tipo = item.tipo;
					response.data.data.map(async (faixa) => {
						await dados[item.incidencia][item.periodo].faixas.push({
							id: faixa.id,
							acao: "editar",
							key: faixa.uuid,
							tipo: item.periodo,
							inicial: faixa.inicial,
							final: faixa.final,
							percentual: faixa.percentual,
						});
					});
				}
			});
		})
		).then(() => {
			this.uteis.initData(dados.uteis);
			this.sabado.initData(dados.sabado);
			this.domingo.initData(dados.domingo);
			this.feriado.initData(dados.feriado);
			this.folga.initData(dados.folga);
			this.dia_especial.initData(dados.dia_especial);
		});
	}

	onOpen = async (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
		});

		try {
			const overtime = (await overtimeService.show({ uuid })).data.data;

			this.getFaixas(uuid);

			this.setState({
				controle_horas: overtime?.controle_horas,
				controle_horas_inicio: overtime?.controle_horas_inicio,
				id: overtime?.id,
			}, () => {
				// Fill form
				this.setState({ isLoading: false }, () => {
					this.fillForm(overtime);
				});
			});
		} catch (error) {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title: "Ocorreu um erro!",
				content: String(error),
				onOk: () => {
					// Force close
					return this.onClose();
				}
			});

			return false;
		}
	};

	fillForm = (data) => {
		const formValues = {
			nome: data.nome,
			controle_horas: data.controle_horas,
			controle_horas_inicio: data.controle_horas_inicio,
			is_active: data.is_active,
			hora_extra_dados: data.hora_extra_dados,
			agrupar_mesma_porcentagem: data.agrupar_mesma_porcentagem,
			marcar_feriado_como_hora_extra: data.marcar_feriado_como_hora_extra,
			separar_extras_noturnas_de_extras_normais: data.separar_extras_noturnas_de_extras_normais,
			separar_extras_intervalos_de_extras_normais: data.separar_extras_intervalos_de_extras_normais,
			calcular_faltas_somente_para_dia_inteiro_horas_e_dias: data.calcular_faltas_somente_para_dia_inteiro_horas_e_dias,
			compensacao_hora_mensal_por_faixa_hora_extra: data.compensacao_hora_mensal_por_faixa_hora_extra,
			marcar_falta_dias_em_branco: data.marcar_falta_dias_em_branco,

			// Período noturno
			periodo_noturno_hora_inicio: data.periodo_noturno_hora_inicio?.substring(0, 5) ?? "",
			periodo_noturno_hora_fim: data.periodo_noturno_hora_fim?.substring(0, 5) ?? "",
			periodo_noturno_reduzido: data?.periodo_noturno_reduzido ?? "",
			periodo_noturno_ate_ultima_batida: data.periodo_noturno_ate_ultima_batida,
			dividir_jornada_feriado_antes_ou_apos_meia_noite: data.dividir_jornada_feriado_antes_ou_apos_meia_noite,
		}
		this.setState({
			formToCompare: JSON.parse(JSON.stringify(formValues)),
		});
		this.form.setFieldsValue(formValues)
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			tab: "uteis",
			tabIntern: "diurno",
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = async (values) => {
		if (!this.checkAnyFieldHasBeenChanged()) {
			this.props.onComplete();
			return;
		}
		this.setState({
			isSending: true,
		});

		if (this.uteis.validatePercent() !== null) {
			let error = this.uteis.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.uteis.focusInput(error.campo, error.tipo);
			return;
		}

		if (this.sabado.validatePercent() !== null) {
			let error = this.sabado.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.sabado.focusInput(error.campo, error.tipo);
			return;
		}

		if (this.domingo.validatePercent() !== null) {
			let error = this.domingo.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.domingo.focusInput(error.campo, error.tipo);
			return;
		}

		if (this.feriado.validatePercent() !== null) {
			let error = this.feriado.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.feriado.focusInput(error.campo, error.tipo);
			return;
		}

		if (this.folga.validatePercent() !== null) {
			let error = this.folga.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.folga.focusInput(error.campo, error.tipo);
			return;
		}

		if (this.dia_especial.validatePercent() !== null) {
			let error = this.dia_especial.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.dia_especial.focusInput(error.campo, error.tipo);
			return;
		}

		const { uuid, id } = this.state;

		const data = { ...values };

		// uuid
		data.uuid = uuid;

		const dias = ["uteis", "sabado", "domingo", "feriado", "folga", "dia_especial"];
		const periodos = ["diurno", "noturno", "intervalo"];
		const dados = [];

		dias.forEach(dia => {
			periodos.forEach(periodo => {
				const item = this[dia].returnData(periodo);

				if (item) {
					dados.push(item);
				}
			});
		});

		this.setState(({
			data: data,
			dados: dados,
		}));

		const hasEmployee = await CheckIdOvertime(id);
		const needToRecalculate = this.checkIfNeedToRecalculate(dados, data);

		if (hasEmployee && needToRecalculate) {
			this.recalculateOpen(data, dados);
			return;
		}

		overtimeService.edit({
			...data,
			dados: dados,
		})
			.then(async () => {
				this.setState({
					isLoading: false,
					isSending: false,
				});

				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	checkAnyFieldHasBeenChanged = () => {
		const { formToCompare } = this.state;

		const fieldsModified = Object.keys(formToCompare).map(field => ({
			[field]: formToCompare[field] !== this.form.getFieldValue(field)
		}));

		const someFieldHasBeenChanged = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return value[fieldName];
		});

		return someFieldHasBeenChanged;
	}

	checkIfNeedToRecalculate = (currentValues, data) => {
		const { formToCompare } = this.state;
		
		const deepCompare = (obj1, obj2) => {
			if (obj1 === obj2) return true;
			if ((obj1 == null && obj2 === "") || (obj1 === "" && obj2 == null)) return true; 
			if (typeof obj1 !== typeof obj2) return false;
	
			if (typeof obj1 === 'string' && typeof obj2 === 'string') {
				return obj1.trim() === obj2.trim();
			}
	
			if (typeof obj1 === 'number' && typeof obj2 === 'number') {
				return obj1 === obj2;
			}
	
			return false;
		};
	
		if (!formToCompare.hora_extra_dados || !currentValues) {
			return false;
		}
	
		const fieldsToCompare = ['final', 'inicial', 'percentual'];
	
		const fieldsModifiedHoraExtra = formToCompare.hora_extra_dados.map((formValue, index) => {
			const formFaixas = formValue.hora_extra_faixas;
			const currentFaixas = currentValues[index]?.faixas || [];
	
			const isModified = formFaixas.some((formFaixa, faixaIndex) => {
				const currentFaixa = currentFaixas[faixaIndex];
				if (!currentFaixa) return true;
	
				return fieldsToCompare.some(field => {
					return !deepCompare(formFaixa[field], currentFaixa[field]);
				});
			});
	
			return {
				index,
				isModified
			};
		});
	
		const fieldsToIgnore = ['hora_extra_dados', 'nome', 'controle_horas_inicio'];

		const fieldsModifiedData = Object.keys(formToCompare).reduce((acc, field) => {
			if (fieldsToIgnore.includes(field)) {
				return acc;
			}
			if (!deepCompare(formToCompare[field], data[field])) {
				acc.push({ [field]: true });
			}
			return acc;
		}, []);

		const needToRecalculateHoraExtra = fieldsModifiedHoraExtra.some(({ isModified }) => isModified);
		const needToRecalculateData = fieldsModifiedData.length > 0;

		return needToRecalculateHoraExtra || needToRecalculateData;
	};
	
	recalculateOpen = ({ uuid }, dados) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		// On open screen
		this.recalculateScreen.onOpen(uuid, dados);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.props.onComplete();
	}

	render() {
		const { visible } = this.props;
		const { isLoading, isSending, tab, recalcularModalVisible, controle_horas, controle_horas_inicio } = this.state;
		const tipo_faixa_options = controle_horas ? TIPO_FAIXAS.filter(item => item.type.includes(controle_horas)) : TIPO_FAIXAS;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar Hora Extra`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
					>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={24}>
										<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.nome = el} />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={16}>
									<Col sm={24} md={12}>
										<Form.Item name="controle_horas" label="Controle de horas" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select onChange={controle_horas => this.setState({ controle_horas })}>
												<Select.Option value="diario">Diário</Select.Option>
												<Select.Option value="semanal">Semanal</Select.Option>
												<Select.Option value="mensal">Mensal</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									{controle_horas !== 'diario' && (
										<Col sm={24} md={8}>
											<Form.Item
												name="controle_horas_inicio"
												label="Início do controle de horas"
												shouldUpdate
												rules={[{ type: 'integer', required: true, message: "Campo obrigatório." }]}
											>
												{controle_horas === 'mensal' && (
													<InputNumber
														ref={el => this.controle_horas_inicio = el}
														onChange={controle_horas_inicio => this.form.setFieldsValue({ controle_horas_inicio })}
														min={1}
														max={25}
														defaultValue={controle_horas_inicio}
													/>
												)}
												{controle_horas === 'semanal' && (
													<Select
														ref={el => this.controle_horas_inicio = el}
														onChange={controle_horas_inicio => this.form.setFieldsValue({ controle_horas_inicio })}
														defaultValue={controle_horas_inicio}
													>
														<Select.Option value={0}>Domingo</Select.Option>
														<Select.Option value={1}>Segunda</Select.Option>
														<Select.Option value={2}>Terça</Select.Option>
														<Select.Option value={3}>Quarta</Select.Option>
														<Select.Option value={4}>Quinta</Select.Option>
														<Select.Option value={5}>Sexta</Select.Option>
														<Select.Option value={6}>Sabádo</Select.Option>
													</Select>
												)}
											</Form.Item>
										</Col>
									)}
									<Col sm={24} md={4}>
										<Form.Item name="is_active" label="Ativo" valuePropName="checked">
											<Switch />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="agrupar_mesma_porcentagem" valuePropName="checked">
											<Checkbox>Agrupar extras de mesma porcentagem</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="marcar_feriado_como_hora_extra" valuePropName="checked">
											<Checkbox>Marcar feriados como hora extra</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="separar_extras_noturnas_de_extras_normais" valuePropName="checked">
											<Checkbox>Separar extras noturnas de extras normais</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="separar_extras_intervalos_de_extras_normais" valuePropName="checked">
											<Checkbox>Separar extras intervalos de extras normais</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="calcular_faltas_somente_para_dia_inteiro_horas_e_dias" valuePropName="checked">
											<Checkbox>Calcular faltas somente para o dia inteiro horas e dias</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="marcar_falta_dias_em_branco" valuePropName="checked">
											<Checkbox>Marcar como falta dias em branco</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="compensacao_hora_mensal_por_faixa_hora_extra" valuePropName="checked">
											<Checkbox>Marcar compensação hora mensal por faixa de hora extra</Checkbox>
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={16}>
									<Col xs={24}>
										<Tabs activeKey={tab} onChange={this.onTabChange}>
											<Tabs.TabPane forceRender tab="Úteis" key="uteis">
												<DayTable
													ref={el => this.uteis = el}
													incidencia="uteis"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Sábado" key="sabado">
												<DayTable
													ref={el => this.sabado = el}
													incidencia="sabado"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Domingo" key="domingo">
												<DayTable
													ref={el => this.domingo = el}
													incidencia="domingo"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Feriado" key="feriado">
												<DayTable
													ref={el => this.feriado = el}
													incidencia="feriado"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Folga" key="folga">
												<DayTable
													ref={el => this.folga = el}
													incidencia="folga"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Dia especial" key="dia_especial">
												<DayTable
													ref={el => this.dia_especial = el}
													incidencia="dia_especial"
													compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
													tipo_faixa={tipo_faixa_options}
													onTabChange={this.onTabInternChange}
													tab={this.state.tabIntern}
												/>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender tab="Periodo noturno" key="periodo_noturno">
												<Row gutter={16}>
													<Col xs={24} md={8}>
														<HourPickerWithMask name="periodo_noturno_hora_inicio" label="Hora início" required={true} />
													</Col>
													<Col xs={24} md={8}>
														<HourPickerWithMask name="periodo_noturno_hora_fim" label="Hora fim" required={true} />
													</Col>
													<Col xs={24} md={8}>
														<HourPickerWithMask name="periodo_noturno_reduzido" label="Adicional noturno reduzido" seconds={true} required={true} />
													</Col>
												</Row>
												<Row gutter={16}>
													<Col xs={24}>
														<Form.Item name="periodo_noturno_ate_ultima_batida" valuePropName="checked">
															<Checkbox>Período noturno até a ultima batida</Checkbox>
														</Form.Item>
													</Col>
													<Col xs={24}>
														<Form.Item name="dividir_jornada_feriado_antes_ou_apos_meia_noite" valuePropName="checked">
															<Checkbox>Dividir jornadas quando houver feriado antes ou após a meia noite</Checkbox>
														</Form.Item>
													</Col>
												</Row>
											</Tabs.TabPane>
										</Tabs>
									</Col>
								</Row>
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
					dados={this.state.dados}
				/>
			</Fragment>
		)
	}
}

export default Edit;
