import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Button, Modal, Radio } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { webserviceService, resalesService } from "./../../redux/services";

class Filters extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.filtersClean = {
			status: null,
			tipo_contrato: null,
			city_id: null,
			created_at: null,
			revenda_id: null,
			state_id: null,
		};

		this.state = {
			filters: {
				...this.filtersClean,
			},
			citiesIsLoading: false,
			cities: [],
			resalesIsLoading: false,
			resales: [],
			statesIsLoading: false,
			states: [],
		};
	}

	fetchTenants = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				citiesIsLoading: false,
				cities: [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					citiesIsLoading: false,
					cities: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					citiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchStates = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				statesIsLoading: false,
				states: [],
			});

			return false;
		}

		this.setState({
			statesIsLoading: true,
		});

		webserviceService.getStates({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					statesIsLoading: false,
					states: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					statesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchResales = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				resalesIsLoading: false,
				resales: [],
			});

			return false;
		}

		this.setState({
			resalesIsLoading: true,
		});

		resalesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					resalesIsLoading: false,
					resales: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					resalesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (filters) => {
		this.setState({
			filters: filters,
		});
	};

	cleanFilters = () => {
		this.setState({
			filters: this.filtersClean,
		}, () => {
			// Callback
			this.props.onComplete({ ...this.state.filters });
		});
	};

	onClose = () => {
		// Callback
		this.props.onClose();
	};

	filtersOnConfirm = () => {
		// Callback
		this.props.onComplete({ ...this.state.filters });
	};

	setFilter = (name, value) => {
		this.setState(state => ({
			filters: {
				...state.filters,
				[name]: value,
			}
		}));
	};

	render() {
		const { visible } = this.props;

		const { filters, citiesIsLoading, cities, resalesIsLoading, resales, states, statesIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title="Filtrar"
				centered={true}
				destroyOnClose={true}
				maskClosable={true}
				width={900}
				okText="Aplicar"
				onCancel={this.onClose}
				onOk={this.filtersOnConfirm}
				className="modal-filters"
				footer={[
					<Button key="back" type="link" onClick={this.cleanFilters}>Excluir filtros</Button>,
					<Button key="submit" type="primary" onClick={this.filtersOnConfirm}>Aplicar</Button>,
				]}>
				{/* <div className="filter-group">
					<div className="filter-group-title" style={{ paddingTop: 0 }}>
						<h3>Tipo</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 5 }}>
						<div className="filter-group-radios">
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("tipo_contrato", null)} checked={filters.tipo_contrato === null}>Todos</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("tipo_contrato", "Mensal")} checked={filters.tipo_contrato === "Mensal"}>Mensal</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("tipo_contrato", "Anual")} checked={filters.tipo_contrato === "Anual"}>Anual</Radio>
							</div>
						</div>
					</div>
				</div> */}
				<div className="filter-group">
					<div className="filter-group-title" style={{ paddingTop: 0 }}>
						<h3>Status</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 5 }}>
						<div className="filter-group-radios">
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("status", null)} checked={filters.status === null}>Todos</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("status", 1)} checked={filters.status === 1}>Ativo</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("status", 0)} checked={filters.status === 0}>Inativo</Radio>
							</div>
							{/* <div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("status", "Bloqueado")} checked={filters.status === "Bloqueado"}>Bloqueado</Radio>
							</div> */}
						</div>
					</div>
				</div>
				{/* <div className="filter-group">
					<div className="filter-group-title">
						<h3>Criação</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<DateWithEndPickerMask filters={filters} setFilter={this.setFilter} />
					</div>
				</div> */}
			</Modal>
		)
	}
}

export default Filters;
