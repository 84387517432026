import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import axios from 'axios'
import { Button, Form, Modal, Select, Spin } from 'antd'
import { companiesService, departmentsService, employeesService, justificationsService } from '../../redux/services'

class Filters extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.filtersClean = {
      empresa: null,
      departamento: null,
      funcionario: null,
      justificativa: null,
    }

    this.state = {
      filters: {
        ...this.filtersClean,
        companiesIsLoading: false,
        companies: [],
        departmentsIsLoading: false,
        departments: [],
        employeesIsLoading: false,
        employees: [],
        justificationsIsLoading: false,
        justifications: [],
      },
    }
  }

  fetchCompanies = (value) => {
    if (this._axiosCancelTenantsToken) {
      this._axiosCancelTenantsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelTenantsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        companiesIsLoading: false,
        companies: [],
      })

      return false
    }

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAll({
        search: value,
        cancelToken: this._axiosCancelTenantsToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelTenantsToken) {
      this._axiosCancelTenantsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelTenantsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    departmentsService
      .getAll({
        search: value,
        cancelToken: this._axiosCancelTenantsToken.token,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelTenantsToken) {
      this._axiosCancelTenantsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelTenantsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      })

      return false
    }

    this.setState({
      employeesIsLoading: true,
    })

    employeesService
      .getAll({
        search: value,
        cancelToken: this._axiosCancelTenantsToken.token,
      })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          employeesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchJustifications = (value) => {
    if (this._axiosCancelTenantsToken) {
      this._axiosCancelTenantsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelTenantsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        justificationsIsLoading: false,
        justifications: [],
      })

      return false
    }

    this.setState({
      justificationsIsLoading: true,
    })

    justificationsService
      .getAll({
        search: value,
        cancelToken: this._axiosCancelTenantsToken.token,
      })
      .then((response) => {
        this.setState({
          justificationsIsLoading: false,
          justifications: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          justificationsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onOpen = (filters) => {
    this.setState({
      filters: filters,
    })
  }

  cleanFilters = () => {
    this.setState(
      {
        filters: this.filtersClean,
      },
      () => {
        // Callback
        this.props.onComplete({ ...this.state.filters })
      }
    )
  }

  onClose = () => {
    // Callback
    this.props.onClose()
  }

  filtersOnConfirm = () => {
    // Callback
    this.props.onComplete({ ...this.state.filters })
  }

  setFilter = (name, value) => {
    this.setState((state) => ({
      filters: {
        ...state.filters,
        [name]: value,
      },
    }))
  }

  render() {
    const { visible } = this.props

    const { filters, companies, companiesIsLoading, departments, departmentsIsLoading, employeesIsLoading, employees, justificationsIsLoading, justifications } = this.state

    return (
      <Modal
        visible={visible}
        title="Filtrar"
        centered={true}
        destroyOnClose={true}
        maskClosable={true}
        width={900}
        okText="Aplicar"
        onCancel={this.onClose}
        onOk={this.filtersOnConfirm}
        className="modal-filters"
        footer={[
          <Button key="back" type="link" onClick={this.cleanFilters}>
            Excluir filtros
          </Button>,
          <Button key="submit" type="primary" onClick={this.filtersOnConfirm}>
            Aplicar
          </Button>,
        ]}
      >
        <div className="filter-group">
          <div className="filter-group-title">
            <h3>Empresa</h3>
          </div>
          <div className="filter-group-filters" style={{ paddingBottom: 0 }}>
            <Form.Item>
              <Select
                filterOption={false}
                allowClear
                placeholder="Pesquise a empresa"
                notFoundContent={
                  companiesIsLoading ? (
                    <Spin
                      indicator={<i className="fad fa-spinner-third fa-spin" />}
                    />
                  ) : null
                }
                onSearch={this.fetchCompanies}
                onChange={(value) =>
                  this.setFilter('empresa', value ?? null)
                }
                showSearch
                value={filters.empresa}
              >
                {companies?.map((item, index) => (
                  <Select.Option key={index} value={item.uuid}>
                    {item.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="filter-group">
          <div className="filter-group-title">
            <h3>Departamento</h3>
          </div>
          <div className="filter-group-filters" style={{ paddingBottom: 0 }}>
            <Form.Item>
              <Select
                filterOption={false}
                allowClear
                placeholder="Pesquise o departamento"
                notFoundContent={
                  departmentsIsLoading ? (
                    <Spin
                      indicator={<i className="fad fa-spinner-third fa-spin" />}
                    />
                  ) : null
                }
                onSearch={this.fetchDepartments}
                onChange={(value) =>
                  this.setFilter('departamento', value ?? null)
                }
                showSearch
                value={filters.departamento}
              >
                {departments?.map((item, index) => (
                  <Select.Option key={index} value={item.uuid}>
                    {item.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="filter-group">
          <div className="filter-group-title">
            <h3>Funcionário</h3>
          </div>
          <div className="filter-group-filters" style={{ paddingBottom: 0 }}>
            <Form.Item>
              <Select
                filterOption={false}
                allowClear
                placeholder="Pesquise o funcionário"
                notFoundContent={
                  employeesIsLoading ? (
                    <Spin
                      indicator={<i className="fad fa-spinner-third fa-spin" />}
                    />
                  ) : null
                }
                onSearch={this.fetchEmployees}
                onChange={(value) =>
                  this.setFilter('funcionario', value ?? null)
                }
                showSearch
                value={filters.funcionario}
              >
                {employees?.map((item, index) => (
                  <Select.Option key={index} value={item.uuid}>
                    {item.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="filter-group">
          <div className="filter-group-title">
            <h3>Justificativa</h3>
          </div>
          <div className="filter-group-filters" style={{ paddingBottom: 0 }}>
            <Form.Item>
              <Select
                filterOption={false}
                allowClear
                placeholder="Pesquise a justificativa"
                notFoundContent={
                  justificationsIsLoading ? (
                    <Spin
                      indicator={<i className="fad fa-spinner-third fa-spin" />}
                    />
                  ) : null
                }
                onSearch={this.fetchJustifications}
                onChange={(value) =>
                  this.setFilter('justificativa', value ?? null)
                }
                showSearch
                value={filters.justificativa}
              >
                {justifications?.map((item, index) => (
                  <Select.Option key={index} value={item.uuid}>
                    {item.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Modal>
    )
  }
}

export default Filters
