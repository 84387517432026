import React, { forwardRef, Fragment } from 'react';
import { Form } from 'antd';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const HourPickerWithMask = forwardRef((props, ref) => {
  const {
    label,
    name,
    required,
    onKeyUp,
    form_,
    disabled,
    placeholder,
    seconds,
    customRoles,
    threeDigitMask,
    ...rest
  } = props;

  const mask = threeDigitMask
    ? [/\d/, /\d/, /\d/, ':', /\d/, /\d/]
    : seconds
    ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]
    : [/\d/, /\d/, ':', /\d/, /\d/];

  const inputElement = (
    <MaskedInput
      ref={ref}
      className="ant-input"
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      mask={mask}
      disabled={disabled}
      {...rest}
    />
  );

  return (
    <Fragment>
      {form_ ? (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'Campo obrigatório.' }, ...customRoles]}
        >
          {inputElement}
        </Form.Item>
      ) : (
        inputElement
      )}
    </Fragment>
  );
});

HourPickerWithMask.defaultProps = {
  label: '',
  name: '',
  required: false,
  onKeyUp: null,
  form_: true,
  customRoles: [],
  disabled: false,
  placeholder: '',
  seconds: false,
  threeDigitMask: false,
};

HourPickerWithMask.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onKeyUp: PropTypes.func,
  form_: PropTypes.bool,
  customRoles: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  seconds: PropTypes.bool,
  threeDigitMask: PropTypes.bool,
};

export default HourPickerWithMask;
