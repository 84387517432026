import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, Table, Row, Button, Popconfirm, Form } from "antd";

import moment from "moment";

import {
	DatePickerWithMask,
} from "../../../components";

class PeriodsTable extends Component {
	static propTypes = {
		showOnly: PropTypes.bool,
		values: PropTypes.arrayOf(PropTypes.any),
	}

	constructor(props) {
		super(props);

		if (props.values && props.values.length > 0) {
			this.state = {
				showOnly: this.props.showOnly ?? false,
				deletedsOriginalPeriods: [],
				periods: props.values.map((item, index) => ({
					name: `Período - ${index + 1}`,
					action: 'editar',
					id: item.id,
					data_inicial: moment(item.vigencia_inicio),
					data_final: moment(item.vigencia_final),
				})),
			}

			// props.values.forEach((item, index) => {
			// 	this.form.setFieldValue(`${id}_data_inicial`, item.data_inicial)
			// 	this.form.setFieldValue(`${id}_data_final`, item.data_final)
			// })
		} else {
			this.state = {
				showOnly: this.props.showOnly ?? false,
				periods: [
					{
						name: 'Período - 1',
						id: Math.floor(Math.random() * 10001),
						data_inicial: null,
						data_final: null,
					},
				],
			};

		}
	}

	onCreateRow = () => {
		this.setState({
			periods: [
				...this.state.periods,
				{
					name: `Período - ${this.state.periods.length + 1}`,
					id: Math.floor(Math.random() * 10001),
					data_inicial: null,
					data_final: null,
				},
			],
		});
	}

	onRemoveRow = (id, action, index) => {
		if (action) {
			this.setState({
				deletedsOriginalPeriods: [...this.state.deletedsOriginalPeriods, {
					...this.state.periods[index], action: 'deletar'
				}
				],

			})
		}

		this.setState({
			periods: this.state.periods.filter((item) => item.id != id),
		});
	}

	render() {
		const { periods, showOnly } = this.state;

		const columns = [
			{
				title: 'Data inicial',
				dataIndex: 'data_inicial',
				width: 900,
				render: (_, { id, data_final, data_inicial }, index) => (
					<DatePickerWithMask initialFormValue={data_inicial} key={id + '_data_inicial'} disabled={showOnly} name={id + '_data_incial'} isForm={true} rules={[{ required: true, message: "" }]} value={data_inicial} onChange={(value) => {
						const newPeriods = [...periods];

						newPeriods[index].data_inicial = value;

						this.setState({ periods: newPeriods })
					}} />
				),
			},
			{
				title: 'Data final',
				dataIndex: 'data_final',
				width: 900,
				render: (_, { id, data_inicial, data_final }, index) => (
					<DatePickerWithMask initialFormValue={data_final} disableDateBefore={true} customDisableDate={data_inicial} key={id + '_data_final'} disabled={showOnly} name={id + '_data_final'} isForm={true} rules={[{ required: true, message: "" }]} value={data_final} onChange={(value) => {
						const newPeriods = [...periods];

						newPeriods[index].data_final = value;

						this.setState({ periods: newPeriods })
					}} />
				),
			},
		];

		if (showOnly == false && periods.length != 1) {
			columns.push(
				{
					title: 'Ações',
					dataIndex: 'acoes',
					width: 120,
					render: (_, { id, action }, index) => (
						<Popconfirm placement="topRight" title="Deseja excluir esse período?" onConfirm={() => this.onRemoveRow(id, action, index)} okText="Sim" cancelText="Não">
							<Button danger shape="circle" icon={<i className="fal fa-times"></i>} />
						</Popconfirm>
					),
				},
			)

		}

		return (
			<div>
				<Table size="small" columns={columns} owKey='id' dataSource={periods} pagination={false} />
				{(showOnly == false) && (<div className="text-right">
					<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow()}>Adicionar novo período</Button>
				</div>)}
			</div>
		);
	}
}

export default PeriodsTable;