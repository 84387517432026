import { api } from "../../config/api";
import { toFormData } from "../../helpers/form";
import { appendToFormData } from '../../helpers/form'


const basePath = "integracao";

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
  const options_default = {}

  // Merge config
  options = Object.assign({}, options_default, options)

  let params = []
  let params_qs = ''

  if (options.hasOwnProperty('page')) {
    params.push(`page=${options.page}`)
  }

  if (options.hasOwnProperty('limit')) {
    params.push(`limit=${options.limit}`)
  }

  if (options.hasOwnProperty('search')) {
    params.push(`search=${options.search}`)
  }

  if (options.hasOwnProperty('orderBy')) {
    params.push(`orderBy=${options.orderBy}`)
  }

  if (options.hasOwnProperty('empresa_uuid')) {
    params.push(`empresa_uuid=${options.empresa_uuid}`)
  }

  if (options.hasOwnProperty('created_at')) {
    options.created_at.forEach((item, index) => {
      params.push(`created_at[${index}]=${item}`)
    })
  }

  if (options.hasOwnProperty('empresa')) {
    params.push(`empresa=${options.empresa}`)
  }

  if (options.hasOwnProperty('departamento')) {
    params.push(`departamento=${options.departamento}`)
  }

  if (options.hasOwnProperty('funcionario')) {
    params.push(`funcionario=${options.funcionario}`)
  }

  if (params.length) {
    params_qs = `?${params.join('&')}`
  }

  return api.get(`${basePath}${params_qs}`)
};

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
	return api.post(basePath, toFormData(options));
};

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
	const formData = new FormData()
  
	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key])
		}
	}
  
	return api.post(`${basePath}/${options.uuid}`, formData)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
  return api.get(`${basePath}/${options.uuid}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
  return api.delete(`${basePath}/${options.uuid}`)
}

/**
 * Create integration Report .txt
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const integrationFile = (options) => {
  return api.post(`${basePath}/integrationFile`, toFormData(options));
};