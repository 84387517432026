import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Checkbox, Form, Input, message, Modal, Row, Select, Switch, Tabs, InputNumber } from "antd";

import { overtimeService } from "../../redux/services";

import {
	HourPickerWithMask,
	UIDrawerForm,
} from "./../../components";

import DayTable from "./Table/create";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const TIPO_FAIXAS = [
	{ label: 'Faixas por quantidade', value: 'quantidade', type: ['diario', 'semanal', 'mensal'] },
	{ label: 'Faixas por intervalo', value: 'intervalo', type: ['diario'] },
];

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			controle_horas: 'diario',
			isLoading: false,
			isSending: false,
			tab: "uteis",
			tabIntern: "diurno",
		};
	}

	onTabChange = (key) => {
		this.setState({ tab: key });
	}

	onTabInternChange = (key) => {
		this.setState({ tabIntern: key });
	}

	onOpen = () => {
		setTimeout(() => {
			this.nome && this.nome.focus();
		}, 100);
	};

	resetFields = () => {
		this.setState({
			tab: "uteis",
			tabIntern: "diurno",
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = async (values) => {
		this.setState({
			isSending: true,
		});
		
		if(this.uteis.validatePercent() !== null) {
			let error = this.uteis.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.uteis.focusInput(error.campo, error.tipo);
			return;
		}

		if(this.sabado.validatePercent() !== null) {
			let error = this.sabado.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.sabado.focusInput(error.campo, error.tipo);
			return;
		}

		if(this.domingo.validatePercent() !== null) {
			let error = this.domingo.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.domingo.focusInput(error.campo, error.tipo);
			return;
		}

		if(this.feriado.validatePercent() !== null) {
			let error = this.feriado.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.feriado.focusInput(error.campo, error.tipo);
			return;
		}

		if(this.folga.validatePercent() !== null) {
			let error = this.folga.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.folga.focusInput(error.campo, error.tipo);
			return;
		}

		if(this.dia_especial.validatePercent() !== null) {
			let error = this.dia_especial.validatePercent();
			message.error("Preencha todos os campos de percentual!");
			this.setState({
				isSending: false,
			});
			this.onTabChange(error.incidencia);
			this.dia_especial.focusInput(error.campo, error.tipo);
			return;
		}

		const data = { ...values };

		const dias = ["uteis", "sabado", "domingo", "feriado", "folga", "dia_especial"];
		const periodos = ["diurno", "noturno", "intervalo"];
		const dados = [];

		dias.forEach(dia => {
			periodos.forEach(periodo => {
				const item = this[dia].returnData(periodo);
				if(item) {
					dados.push(item);
				}
			});
		});

		overtimeService.create({
			...data,
			dados: dados,
		})
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success("Hora extra cadastrada com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title: "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const { visible } = this.props;
		const { isLoading, isSending, tab, controle_horas } = this.state;
		const tipo_faixa_options  = controle_horas ? TIPO_FAIXAS.filter(item => item.type.includes(controle_horas)) : TIPO_FAIXAS;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Nova Hora Extra">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						controle_horas: "diario",
						controle_horas_inicio: null,
						is_active: true,
						agrupar_mesma_porcentagem: 1,
						marcar_feriado_como_hora_extra: 1,
						separar_extras_noturnas_de_extras_normais: 0,
						separar_extras_intervalos_de_extras_normais: 0,
						calcular_faltas_somente_para_dia_inteiro_horas_e_dias: 0,
						compensacao_hora_mensal_por_faixa_hora_extra: 0,
						marcar_falta_dias_em_branco: 0,
						periodo_noturno_hora_inicio: "22:00",
						periodo_noturno_hora_fim: "05:00",
						periodo_noturno_reduzido: "00:52:30",
						periodo_noturno_ate_ultima_batida: 0,
						dividir_jornada_feriado_antes_ou_apos_meia_noite: 0,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col sm={24} md={12}>
									<Form.Item name="controle_horas" label="Controle de horas" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select onChange={controle_horas => this.setState({ controle_horas })}>
											<Select.Option value="diario">Diário</Select.Option>
											<Select.Option value="semanal">Semanal</Select.Option>
											<Select.Option value="mensal">Mensal</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								{controle_horas !== 'diario' &&  (
									<Col sm={24} md={8}>
										<Form.Item 
											name="controle_horas_inicio" 
											label="Início do controle de horas" 
											rules={[{ required: true, message: "Campo obrigatório." }]}
										>
											{controle_horas === 'mensal' && (
												<InputNumber 
													ref={el => this.controle_horas_inicio = el} 
													onChange={controle_horas_inicio => this.form.setFieldsValue({ controle_horas_inicio })}
													min={1} 
													max={25} 
												/>
											)}
											{controle_horas === 'semanal' && (
												<Select 
													ref={el => this.controle_horas_inicio = el} 
													onChange={controle_horas_inicio => this.form.setFieldsValue({ controle_horas_inicio })}
												>
													<Select.Option value={0}>Domingo</Select.Option>
													<Select.Option value={1}>Segunda</Select.Option>
													<Select.Option value={2}>Terça</Select.Option>
													<Select.Option value={3}>Quarta</Select.Option>
													<Select.Option value={4}>Quinta</Select.Option>
													<Select.Option value={5}>Sexta</Select.Option>
													<Select.Option value={6}>Sabádo</Select.Option>
												</Select>
											)}
										</Form.Item>
									</Col>
								)}
								<Col sm={24} md={4}>
									<Form.Item name="is_active" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Switch />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item name="agrupar_mesma_porcentagem" valuePropName="checked">
										<Checkbox>Agrupar extras de mesma porcentagem</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="marcar_feriado_como_hora_extra" valuePropName="checked">
										<Checkbox>Marcar feriados como hora extra</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="separar_extras_noturnas_de_extras_normais" valuePropName="checked">
										<Checkbox>Separar extras noturnas de extras normais</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="separar_extras_intervalos_de_extras_normais" valuePropName="checked">
										<Checkbox>Separar extras intervalos de extras normais</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="calcular_faltas_somente_para_dia_inteiro_horas_e_dias" valuePropName="checked">
										<Checkbox>Calcular faltas somente para o dia inteiro horas e dias</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="marcar_falta_dias_em_branco" valuePropName="checked">
										<Checkbox>Marcar como falta dias em branco</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="compensacao_hora_mensal_por_faixa_hora_extra" valuePropName="checked">
										<Checkbox>Marcar compensação hora mensal por faixa de hora extra</Checkbox>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col xs={24}>
									<Tabs activeKey={tab} onChange={this.onTabChange}>
										<Tabs.TabPane forceRender tab="Úteis" key="uteis">
											<DayTable 
												ref={el => this.uteis = el}
												incidencia="uteis" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern}
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Sábado" key="sabado">
											<DayTable 
												ref={el => this.sabado = el}
												incidencia="sabado" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern} 
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Domingo" key="domingo">
											<DayTable 
												ref={el => this.domingo = el}
												incidencia="domingo" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern} 
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Feriado" key="feriado">
											<DayTable 
												ref={el => this.feriado = el}
												incidencia="feriado" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern} 
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Folga" key="folga">
											<DayTable 
												ref={el => this.folga = el}
												incidencia="folga" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern} 
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Dia especial" key="dia_especial">
											<DayTable 
												ref={el => this.dia_especial = el}
												incidencia="dia_especial" 
												compensacao_hora_mensal_por_faixa_hora_extra={this.form?.getFieldValue('compensacao_hora_mensal_por_faixa_hora_extra')}
												tipo_faixa={tipo_faixa_options} 
												onTabChange={this.onTabInternChange}
												tab={this.state.tabIntern} 
											/>
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Periodo noturno" key="periodo_noturno">
											<Row gutter={16}>
												<Col xs={24} md={8}>
													<HourPickerWithMask name="periodo_noturno_hora_inicio" label="Hora início" required={true} />
												</Col>
												<Col xs={24} md={8}>
													<HourPickerWithMask name="periodo_noturno_hora_fim" label="Hora fim" required={true} />
												</Col>
												<Col xs={24} md={8}>
													<HourPickerWithMask name="periodo_noturno_reduzido" label="Adicional noturno reduzido" seconds={true} required={true} />
												</Col>
											</Row>
											<Row gutter={16}>
												<Col xs={24}>
													<Form.Item name="periodo_noturno_ate_ultima_batida" valuePropName="checked">
														<Checkbox>Período noturno até a ultima batida</Checkbox>
													</Form.Item>
												</Col>
												<Col xs={24}>
													<Form.Item name="dividir_jornada_feriado_antes_ou_apos_meia_noite" valuePropName="checked">
														<Checkbox>Dividir jornadas quando houver feriado antes ou após a meia noite</Checkbox>
													</Form.Item>
												</Col>
											</Row>
										</Tabs.TabPane>
									</Tabs>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
