import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Button, Radio, Space, Card, Checkbox, Spin, Select, message } from "antd";

import moment from "moment";

import axios from "axios";
import { API_ERRO_TYPE_CANCEL } from "./../../../config/general";

import ModalAfastamentos from "./ModalAfastamentos";
import ModalExcecao from "./ModalExcecao";
import ModalJustificativa from "./ModalJustificativa";
import ModalObservacao from "../../TimeCard/ModalActions/ModalObservacao";
import { timeCardService } from "../../../redux/services";

class ModalActions extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			tipoCalculo: this.props.tipoCalculo || "flexivel",
			outros: "",
			item: this.props.item || {},
			selecionarFeriado: false,
			feriado_id: null,
			afastamentosModalVisible: false,
			ajustesModalVisible: false,
			excecaoModalVisible: false,
			justificativaModalVisible: false,
			observacaoModalVisible: false,

			holidays: [],
			holidaysIsLoading: false,
		}

		this._axiosCancelHolidaysToken = null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.tipoCalculo !== this.props.tipoCalculo || JSON.stringify(prevProps.item) !== JSON.stringify(this.state.item)) {
			this.setState({
				tipoCalculo: this.props.tipoCalculo,
				item: this.state.item,
			});
		}
	}

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {
		const { item } = this.state;

		if(item.folga) {
			this.setState({ outros: "folga", selecionarFeriado: false, holidays: [], feriado_id: null });
		} else if(item.feriado_id) {
			this.setState({
				outros: "feriado",
				selecionarFeriado: true,
				holidays: [{
					id: item.feriado_id,
					descricao: item.feriado.descricao,
				}],
				feriado_id: item.feriado_id
			});
		} else if(item.dia_especial) {
			this.setState({ outros: "dia_especial", selecionarFeriado: false, holidays: [], feriado_id: null });
		}
	}

	fetchHolidays = (value) => {
		if (this._axiosCancelHolidaysToken) {
			this._axiosCancelHolidaysToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelHolidaysToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				holidaysIsLoading: false,
				holidays: [],
			});

			return false;
		}

		this.setState({
			holidaysIsLoading: true,
		});

		let params = {
			search: value,
			ativo: 1,
			orderBy: "descricao:asc",
			cancelToken: this._axiosCancelHolidaysToken.token,
		};

		timeCardService
			.autocompleteFeriado({
				...params,
			})
			.then((response) => {
				this.setState({
					holidaysIsLoading: false,
					holidays: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					holidaysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onHolidaySelect = (value) => {
		this.setState({
			isLoading: true,
			feriado_id: value
		});

		timeCardService.alterarFeriado({
			cartao_ponto_id: this.props.cartaoPontoId,
			feriado_id: value,
		})
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			message.success("Feriado aplicado com sucesso!");

			// Callback
			this.props.onComplete(response.data.data[0]);
		})
		.catch((data) => {
			this.setState({ isLoading: false });
			Modal.error({
				title: "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	// Afastamentos
	afastamentosOpen = () => {
		this.setState({ afastamentosModalVisible: true });

		// On open screen
		this.afastamentosScreen.onOpen();
	};

	afastamentosOnClose = () => {
		this.setState({ afastamentosModalVisible: false });
	}

	afastamentosOnComplete = () => {
		this.setState({ afastamentosModalVisible: false });

		// Reload row
		this.props.reloadRow(this.props.cartaoPontoId);
		this.onClose();
	}

	// Observação
	observacaoOpen = () => {
		this.setState({ observacaoModalVisible: true });

		// On open screen
		this.observacaoScreen.onOpen();
	};

	observacaoOnClose = () => {
		this.setState({ observacaoModalVisible: false });
	}

	observacaoOnComplete = () => {
		this.setState({ observacaoModalVisible: false });

		// Reload row
		this.props.reloadRow(this.props.cartaoPontoId);
		this.onClose();
	};

	// Exceção de Horário/HE
	excecaoOpen = () => {
		this.setState({ excecaoModalVisible: true });

		// On open screen
		this.excecaoScreen.onOpen();
	};

	excecaoOnClose = () => {
		this.setState({ excecaoModalVisible: false });
	}

	excecaoOnComplete = () => {
		this.setState({ excecaoModalVisible: false });

		// Reload row
		this.props.reloadRow(this.props.cartaoPontoId);
		this.onClose();
	}

	// Justificativa parcial
	justificativaOpen = () => {
		this.setState({ justificativaModalVisible: true });

		// On open screen
		this.justificativaScreen.onOpen();
	};

	justificativaOnClose = () => {
		this.setState({ justificativaModalVisible: false });
	}

	justificativaOnComplete = () => {
		this.setState({ justificativaModalVisible: false });

		// Reload row
		this.props.reloadRow(this.props.cartaoPontoId);
		this.onClose();
	}

	onCalcTypeChange = (e) => {
		this.setState({
			isLoading: true,
		});

		timeCardService.alterarCalculo({
			cartao_ponto_id: this.props.cartaoPontoId,
			tipo_calculo: e.target.value,
		})
		.then((response) => {
			this.setState({
				isLoading  : false,
				data       : response.data.data,
				tipoCalculo: e.target.value,
			});

			message.success("Tipo de cálculo alterado com sucesso.");

			// Update row
			this.props.onComplete(response.data.data[0]);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	onHourBankChange = (e) => {
		this.setState({
			isLoading: true,
		});

		timeCardService.alterarBancoHoras({
			cartao_ponto_id: this.props.cartaoPontoId,
			banco_horas: false,
		})
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			message.success("Banco de horas removido com sucesso.");

			// Update row
			this.props.onComplete(response.data.data[0]);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	onOtherChange = (value, item) => {
		const { cartaoPontoId } = this.props;
		const { outros, selecionarFeriado } = this.state;

    if (item.status === 'afastamento') {
			if (value === 'dia_especial') value = 'dia especial';
			message.error(`Não é possível aplicar ${value} em um dia de afastamento.`);
			return;
    }

    this.setState({ isLoading: true });

    let alterarStatus;
    let sucessoMsg;
    let falhaMsg;

    switch (value) {
			case "folga":
				alterarStatus = timeCardService.alterarFolga({
					cartao_ponto_id: cartaoPontoId,
					folga: outros !== "folga",
				});
				sucessoMsg = outros === "folga" ? "Folga removida com sucesso." : "Folga aplicada com sucesso.";
				falhaMsg = "Erro ao alterar folga.";
				break;
			case "feriado":
				if(outros === "feriado") {
					timeCardService.alterarFeriado({
						cartao_ponto_id: cartaoPontoId,
						feriado: null,
					})
					.then((response) => {
						this.setState({
							isLoading: false,
							outros: "",
							holidays: [],
							feriado_id: null,
							selecionarFeriado: false,
						});

						message.success("Feriado removido com sucesso.");

						// Update row
						this.props.onComplete(response.data.data[0]);
					})
					.catch((data) => {
						this.setState({
							isLoading: false,
						});
						Modal.error({
							title  : "Ocorreu um erro!",
							content: String(data),
						});
					});

					break;
				}

				this.setState({
					isLoading: false,
					outros: outros === "feriado" ? "" : "feriado",
					selecionarFeriado: !selecionarFeriado,
				});

				break;
			case "dia_especial":
				alterarStatus = timeCardService.alterarDiaEspecial({
					cartao_ponto_id: cartaoPontoId,
					dia_especial: outros !== "dia_especial",
				});
				sucessoMsg = outros === "dia_especial" ? "Dia especial removido com sucesso." : "Dia especial aplicado com sucesso.";
				falhaMsg = "Erro ao alterar dia especial.";
				break;
			default:
				this.setState({ isLoading: false });
				return;
    }

		if (value !== 'feriado') {
			alterarStatus
				.then((response) => {
					message.success(sucessoMsg);
					this.setState({
						isLoading: false,
						outros: outros === value ? "" : value,
						holidays: [],
						feriado_id: null,
						selecionarFeriado: false,
					});
	
					// Update row
					this.props.onComplete(response.data.data[0]);
				})
				.catch((error) => {
					this.setState({ isLoading: false });
					Modal.error({
						title: "Ocorreu um erro!",
						content: falhaMsg,
					});
				});
		}
	}

	render() {
		const { visible, item } = this.props;

		const { isLoading, holidays, holidayIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title={`Ações do cartão ponto [${moment(this.props.dataDia).format("DD/MM/YYYY")}]`}
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={680}
				onCancel={this.onClose}
				footer={null}
				centered
				maskClosable={false}
				className="modal-row-actions"
			>
				<Spin spinning={isLoading} indicator={<i className="fad fa-spinner-third fa-spin fa-3x"/>}>
					<Row gutter={16}>
						<Col xs={24} md={12}>
							<Card title="Ações" style={{marginBottom: 16}}>
								<Space direction="vertical" size="small" className="actions-column">
									<Button type="link" onClick={this.afastamentosOpen}>Afastamentos</Button>
									<Button type="link" onClick={this.justificativaOpen}>Justificativa parcial</Button>
									<Button type="link" onClick={this.excecaoOpen}>Exceção de Horário/HE</Button>
									<Button type="link" onClick={this.props.restartOpen}>Retornar dados originais</Button>
									<Button type="link" onClick={this.props.detailsOpen}>Detalhes do cartão ponto</Button>
									<Button type="link" onClick={this.props.newJoinColumn}>Adicionar colunas</Button>
									<Button type="link" onClick={this.props.removeBatida}>Remover colunas</Button>
									<Button type="link" onClick={() => this.props.journeyOpen(this.props?.item?.contrato?.jornada?.uuid)}>Detalhes do horário</Button>
									<Button type="link" block onClick={this.observacaoOpen}>Observação</Button>
								</Space>
							</Card>
						</Col>
						<Col xs={24} md={12}>
							<Card title="Tipo de cálculo" style={{marginBottom: 16}}>
								<Radio.Group onChange={this.onCalcTypeChange} value={this.state.tipoCalculo}>
									<Space direction="vertical" size="small" style={{ display: 'flex' }}>
										<Radio value="flexivel">Flexível</Radio>
										<Radio value="horista">Horista</Radio>
										<Radio value="intervalo_flexivel">Intervalo flexível</Radio>
										<Radio value="normal">Normal</Radio>
									</Space>
								</Radio.Group>
							</Card>
							<Card title="Outros" style={{marginBottom: 16}}>
								<Radio.Group value={this.state.outros}>
									<Space direction="vertical" size="small" style={{ display: 'flex' }}>
										<Radio onClick={() => this.onOtherChange("folga", item)} value="folga">Folga</Radio>
										<Radio onClick={() => this.onOtherChange("feriado", item)} value="feriado">Feriado</Radio>
										<Radio onClick={() => this.onOtherChange("dia_especial", item)} value="dia_especial">Dia especial</Radio>
									</Space>
								</Radio.Group>
								<div style={{marginTop: 10}}>
									<Checkbox 
										disabled={item?.contrato?.nao_usa_banco_horas}
										checked={this.state.item.usa_banco_horas} 
										onChange={this.onHourBankChange}
									>
										Banco de horas
									</Checkbox>
								</div>
								{this.state.selecionarFeriado && (
									<div style={{marginTop: 20}}>
										<Select
											style={{ width: '100%' }}
											filterOption={false}
											placeholder="Selecione o feriado"
											notFoundContent={
												holidayIsLoading ? (
													<Spin
														indicator={
															<i className="fad fa-spinner-third fa-spin" />
														}
													/>
												) : null
											}
											value={this.state.feriado_id}
											onSelect={this.onHolidaySelect}
											onSearch={this.fetchHolidays}
											showSearch
										>
											{holidays.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.descricao}
												</Select.Option>
											))}
										</Select>
									</div>
								)}
							</Card>
						</Col>
					</Row>
				</Spin>
				<ModalAfastamentos
					ref={el => this.afastamentosScreen = el}
					visible={this.state.afastamentosModalVisible}
					onComplete={this.afastamentosOnComplete}
					onClose={this.afastamentosOnClose}
					dataDia={this.props.dataDia}
					empresa={this.props.empresa}
					departamento={this.props.departamento}
					funcionario={this.state?.item?.funcionario}
					cartaoPonto={this.state.item}
				/>
				<ModalExcecao
					ref={el => this.excecaoScreen = el}
					visible={this.state.excecaoModalVisible}
					onComplete={this.excecaoOnComplete}
					onClose={this.excecaoOnClose}
					dataDia={this.props.dataDia}
					empresa={this.props.empresa}
					departamento={this.props.departamento}
					funcionario={this.state?.item?.funcionario}
				/>
				<ModalJustificativa
					ref={el => this.justificativaScreen = el}
					visible={this.state.justificativaModalVisible}
					onComplete={this.justificativaOnComplete}
					onClose={this.justificativaOnClose}
					dataDia={this.props.dataDia}
					empresa={this.props.empresa}
					departamento={this.props.departamento}
					funcionario={this.state?.item?.funcionario}
				/>
				<ModalObservacao
					ref={el => this.observacaoScreen = el}
					visible={this.state.observacaoModalVisible}
					onComplete={this.observacaoOnComplete}
					onClose={this.observacaoOnClose}
					dataDia={this.props.dataDia}
					cartaoPontoId={this.state.item?.uuid}
				/>
			</Modal>
		)
	}
}

export default ModalActions;
