/**
 * Capitalize First Letter
 *
 * @returns {string}
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Copy to clipboard
 *
 * @param {string} text
 */
export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

/**
 * Timezones Object Labels
 *
 * @param {string} gmt
 */
export const getTimeZoneLabel = {
  "+00:00": "GMT",
  "-01:00": "GMT-1",
  "-02:00": "GMT-2 Horário de Fernando de Noronha",
  "-03:00": "GMT-3 Horário de Brasília",
  "-04:00": "GMT-4 Horário da Amazônia",
  "-05:00": "GMT-5 Horário do Acre",
  "-06:00": "GMT-6",
  "-07:00": "GMT-7",
  "-08:00": "GMT-8",
  "-09:00": "GMT-9",
  "-10:00": "GMT-10",
  "-11:00": "GMT-1",
  "-12:00": "GMT-1",
  "+01:00": "GMT-1",
  "+02:00": "GMT-1",
  "+03:00": "GMT-1",
  "+04:00": "GMT-1",
  "+05:00": "GMT-1",
  "+06:00": "GMT-1",
  "+07:00": "GMT-1",
  "+08:00": "GMT-1",
  "+09:00": "GMT-1",
  "+10:00": "GMT-1",
  "+11:00": "GMT-1",
  "+12:00": "GMT-1",
}