import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Input, Modal, Checkbox, Row, Tabs } from "antd";
import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { overtimeService, dsrService, journeysService, timeService } from "../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
} from "./../../components";

import DayTable from "./table";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			nextOrder: 1,
			type: null,
			item: null,

			values: [],
			dsrIsLoading: false,
			dsr: [],
			overtimeIsLoading: false,
			overtime: [],

			intervals: [],

			tipo_pessoa: null,
			fixar_tolerancia: true,
			tolerancia_extra: 10,
			tolerancia_falta: 10,
			tipo_calculo: null,
			tipo_horario: '',
			typeCalculations: [
				{ 
					label: 'Flexível',
					value: 'flexivel'
				},
				{ 
					label: 'Intervalo Flexível',
					value: 'intervalo_flexivel'
				},
				{ 
					label: 'Horário',
					value: 'horario'
				},
				{ 
					label: 'Normal',
					value: 'normal'
				},
				{
					label: 'Horista',
					value: 'horista'
				}
			]
		};
	}

	fetchOvertime = (value) => {
		if (this._axiosCancelOvertimeToken) {
			this._axiosCancelOvertimeToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelOvertimeToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				overtimeIsLoading: false,
				overtime: [],
			});

			return false;
		}

		this.setState({
			overtimeIsLoading: true,
		});

		overtimeService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelOvertimeToken.token,
		})
			.then((response) => {
				this.setState({
					overtimeIsLoading: false,
					overtime: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					overtimeIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDsr = (value) => {
		if (this._axiosCancelDsrToken) {
			this._axiosCancelDsrToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDsrToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				dsrIsLoading: false,
				dsr: [],
			});

			return false;
		}

		this.setState({
			dsrIsLoading: true,
		});

		dsrService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelDsrToken.token,
		})
			.then((response) => {
				this.setState({
					dsrIsLoading: false,
					dsr: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					dsrIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	getIntervals = (tracks) => {
		let intervals = [];

		tracks[0]?.batida.forEach((batida, index) => {
			if(tracks[0].batida.length <= 1 || index === tracks[0].batida.length - 1) {
				return false;
			}

			intervals.push({
				id: Math.floor(Math.random() * 10001),
				title: "Saída " + (index + 1) + " / Entrada " + (index + 2),
				value: (index + 1) + "-" + (index + 2),
			});
		});

		this.setState({
			intervals: intervals,
		});
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		timeService.show({ uuid })
			.then((response) => {
				let item = response.data.data[0];

				let dsr = [];
				let overtime = [];

				if (item.dsr) {
					dsr.push({
						id: item.dsr.id,
						nome: item.dsr.nome,
					});
				}

				if (item.hora_extra) {
					overtime.push({
						id: item.hora_extra.id,
						nome: item.hora_extra.nome,
					});
				}

				this.setState({
					dsr: dsr,
					overtime: overtime,
					values: item.horarios_dias_semana,
					item: item,
					isLoading: false,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			descricao: data.descricao,
			tolerencia_clt_art_58: data.tolerencia_clt_art_58,
			tipo_calculo: data.tipo_calculo,
			tipo_horario: data.tipo_horario,
			dsr_id: data.dsr?.id,
			hora_extra_id: data.hora_extra?.id,
			fechamento: data?.fechamento,
			data_inicio_escala: moment.utc(data.data_inicio_escala, 'YYYY-MM-DD'),
			tipo_hora_escala: data?.tipo_hora_escala
		});

		this.setState({
			tipo_horario: data.tipo_horario,
			fixar_tolerancia: data.tolerancia_clt_art_58 == 1 ? true : false,
			tipo_calculo: data.tipo_calculo,
			fechamento: data?.horarios_dias_semana[0]?.fechamento,
			tipo_hora_escala: data?.tipo_hora_escala
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const {
			isLoading,
			item,
			isSending,
			nextOrder,
			intervals,
			values,
			fixar_tolerancia,
			tipo_calculo,
			tipo_horario,
			tolerancia_extra,
			tolerancia_falta,
			dsr,
			dsrIsLoading,
			overtime,
			overtimeIsLoading,
			tipo_hora_escala
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={1200}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Horário`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Descrição" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										{item?.descricao ?? "-"}
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
										{item?.is_active ? "Ativo" : "Inativo"}
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item label="Tipo de cálculo" rules={[{ required: true, message: "Campo obrigatório." }]}>
										{
											this.state.typeCalculations.find(type => type.value === item?.tipo_calculo)?.label ?? '-'
										}
									</Form.Item>
								</Col>
							</Row>
							{tipo_calculo != "horista" ? (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item label="Hora extra" rules={[{ required: true, message: "Campo obrigatório." }]}>
												{overtime[0]?.nome}
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item label="DSR">
												{dsr[0]?.nome}
											</Form.Item>
										</Col>
										<Col sm={8}>
											<Form.Item label="Tipo de horário" rules={[{ required: true, message: "Campo obrigatório." }]}>
												{item?.tipo_horario == "semanal" ? "Semanal" : "Escala"}
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item label={<div className="inputInfo"><p>Fixar tolerância de extras/faltas em 5 minutos e 10 minutos diários</p><span>(CLT - Art. 58)</span></div>} rules={[{ required: true, message: "Campo obrigatório." }]}>
												<Checkbox disabled checked={item?.tolerancia_clt_art_58 == 1 ? true : false} onChange={(evt) => this.setState({ fixar_tolerancia: evt.target.checked })} />
											</Form.Item>
										</Col>
										{/* <Col xs={24} sm={12}>
											<Form.Item valuePropName="checked" label={"Pré-assinalar intervalo"}>
												<Checkbox checked={(item?.horarios_dias_semana && item?.horarios_dias _semana[0]?.horario_batidas[0]?.saida_auto) == 1 ? true : false} disabled />
											</Form.Item>
										</Col> */}
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item label="Tolerância extra" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
												{item?.tolerancia_extra ?? "-"}
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item label="Tolerância falta" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
												{item?.tolerancia_falta ?? "-"}
											</Form.Item>
										</Col>
										{fixar_tolerancia == false && (
											<Fragment>
												<Col xs={24} sm={8}>
													<Form.Item label="Tol. Antes" hasFeedback>
														{item?.tolerancia_batida_extra ?? "-"}
													</Form.Item>
												</Col>
												<Col xs={24} sm={8}>
													<Form.Item label="Tol. Depois" hasFeedback>
														{item?.tolerancia_batida_falta ?? "-"}
													</Form.Item>
												</Col>
											</Fragment>
										)}
										{tipo_horario === "escala" && (
											<>
												<Col xs={24} sm={8}>
													<DatePickerWithMask 
													disabled 
													label="Data Base" 
													name="data_inicio_escala" 
													required={true} 
												/>
												</Col>
												<Col xs={24} sm={8}>
													<Form.Item label="Tipo de hora escala" hasFeedback>
														{ tipo_hora_escala ?? '-' }
													</Form.Item>
												</Col>
											</>
										)}
										{intervals.length > 0 && (
											<Col xs={24} sm={8}>
												<Form.Item label="Pré assinalar intervalo">
													{item?.intervalo_refeicao_sequencias ? `Saída ${item?.intervalo_refeicao_sequencias.split("-")[0]} / Entrada ${item?.intervalo_refeicao_sequencias.split("-")[1]}` : "-"}
												</Form.Item>
											</Col>
										)}
									</Row>
									<Row gutter={16}>
										<Col xs={24}>
											<DayTable 
												ref={el => this.table = el}
												showOnly={true} 
												getIntervals={this.getIntervals} 
												tipo_horario={tipo_horario} 
												values={values} 
												fixar_tolerancia={fixar_tolerancia} 
												tipo_calculo={tipo_calculo} 
												tolerancia_extra={tolerancia_extra} 
												tolerancia_falta={tolerancia_falta} 
											/>
										</Col>
									</Row>
								</>
							) : (
								<Row gutter={16}>
									<Col xs={24} sm={8}>
										<Form.Item name="fechamento" label="Fechamento" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input disabled />
										</Form.Item>
									</Col>
								</Row>
							)}
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
