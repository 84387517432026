import React, { Component, Fragment } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, Spin, Modal, Checkbox, Row, Select, Tabs, message } from "antd";

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { journeysService, overtimeService, dsrService } from "../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
} from "./../../components";

import DayTable from "./table";
import ModalRecalculate from './ModalRecalculate';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			item: {},
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,

			values: [],

			data: [],

			dsrIsLoading: false,
			dsr: [],
			overtimeIsLoading: false,
			overtime: [],

			intervals: [],

			tipo_pessoa: null,
			fixar_tolerancia: true,
			tolerancia_extra: 10,
			tolerancia_falta: 10,
			tipo_calculo: null,
			tipo_horario: '',

			pre_assinalar: false,

			recalcularModalVisible: false,
			formToCompare: {},
			hasChangeInTable: false
		};

		this._axiosCancelOvertimeToken = null;
		this._axiosCancelDsrToken = null;
	}

	componentDidUpdate(_, prevState) {
    const { tipo_horario } = this.state;

    if (prevState.tipo_horario === 'semanal' && tipo_horario === 'escala') {
			this.form.setFieldsValue({ data_inicio_escala: null });
    }
	}

	fetchOvertime = (value) => {
		if (this._axiosCancelOvertimeToken) {
			this._axiosCancelOvertimeToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelOvertimeToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				overtimeIsLoading: false,
				overtime: [],
			});

			return false;
		}

		this.setState({
			overtimeIsLoading: true,
		});

		overtimeService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelOvertimeToken.token,
		})
			.then((response) => {
				this.setState({
					overtimeIsLoading: false,
					overtime: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					overtimeIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDsr = (value) => {
		if (this._axiosCancelDsrToken) {
			this._axiosCancelDsrToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDsrToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				dsrIsLoading: false,
				dsr: [],
			});

			return false;
		}

		this.setState({
			dsrIsLoading: true,
		});

		dsrService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelDsrToken.token,
		})
			.then((response) => {
				this.setState({
					dsrIsLoading: false,
					dsr: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					dsrIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onChangePreAssinalar = (e) => {
		if(!e.target.checked) {
			this.form.setFieldValue("intervalo_refeicao_sequencias", null);
		}

		this.setState({
			pre_assinalar: e.target.checked,
		});
	}

	onChangeTolerancia = (e) => {
		if(e.target.checked) {
			this.onResetToleranciaExtraAndFalta();
			this.onResetToleranciaBatidaExtraFalta();
		}
		
		this.setState({
			fixar_tolerancia: e.target.checked,
		});
	}

	onResetToleranciaExtraAndFalta() {
		const DEFAULT_VALUE = 10;
		
		this.setState({
			tolerancia_extra: DEFAULT_VALUE,
			tolerancia_falta: DEFAULT_VALUE,
		})

		this.form.setFieldValue("tolerancia_extra", DEFAULT_VALUE);
		this.form.setFieldValue("tolerancia_falta", DEFAULT_VALUE);
	}

	onResetToleranciaBatidaExtraFalta = () => {
		this.form.setFieldValue("tolerancia_batida_extra", null);
		this.form.setFieldValue("tolerancia_batida_falta", null);
	}

	onChangeTipoHorario = (value) => {
		this.onChangeTolerancia({
			target: {
				checked: this.state.fixar_tolerancia,
			}
		});

		this.setState({
			tipo_horario: value,
		});
	}

	getIntervals = (tracks) => {
		let intervals = [];

		tracks[0]?.batida.forEach((batida, index) => {
			if(tracks[0].batida.length <= 1 || index === tracks[0].batida.length - 1) {
				return false;
			}

			intervals.push({
				id: Math.floor(Math.random() * 10001),
				title: "Saída " + (index + 1) + " / Entrada " + (index + 2),
				value: (index + 1) + "-" + (index + 2),
			});
		});

		this.setState({
			intervals: intervals,
		});
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		journeysService.show({ uuid })
			.then((response) => {
				let item = response.data.data[0];

				let dsr = [];
				let overtime = [];

				if (item.dsr) {
					dsr.push({
						id: item.dsr.id,
						nome: item.dsr.nome,
					});
				}

				if (item.hora_extra) {
					overtime.push({
						id: item.hora_extra.id,
						nome: item.hora_extra.nome,
					});
				}

				this.setState({
					isLoading: false,
					dsr: dsr,
					item: item,
					values: item.jornadas_dias_semana,
					overtime: overtime,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		const fixar_tolerancia = data.tolerancia_clt_art_58 === 1;
	
		const formValues = {
			descricao: data.descricao,
			fixar_tolerancia,
			tipo_calculo: data.tipo_calculo,
			tipo_horario: data.tipo_horario,
			fechamento: data.jornadas_dias_semana[0]?.fechamento,
			dsr_id: data.dsr?.id,
			is_active: data.is_active,
			hora_extra_id: data.hora_extra?.id,
			tolerancia_extra: data.tolerancia_extra,
			tolerancia_falta: data.tolerancia_falta,
			tolerancia_batida_extra: data.tolerancia_batida_extra,
			tolerancia_batida_falta: data.tolerancia_batida_falta,
			tipo_hora_escala: data.tipo_hora_escala,
			data_inicio_escala: moment.utc(data.data_inicio_escala, 'YYYY-MM-DD'),
			intervalo_refeicao_sequencias: data?.intervalo_refeicao_sequencias?.length > 0 ? data?.intervalo_refeicao_sequencias : null,
		};

		this.setState({
			tipo_horario: data?.tipo_horario,
			fixar_tolerancia,
			tipo_calculo: data.tipo_calculo,
			pre_assinalar: data?.intervalo_refeicao_sequencias?.length > 0,
			formToCompare: formValues
		});

		this.form.setFieldsValue(formValues);
	};

	resetFields = () => {
		this.setState({
			tipo_horario: null,
			fixar_tolerancia: null,
			tipo_calculo: null,
		})
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		if (!this.checkAnyFieldHasBeenChanged() && !this.state.hasChangeInTable) {
			this.props.onComplete();
			return;
		}

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		let tracks = []

		if (data.tipo_calculo !== 'horista') {
			this.table?.state?.data.forEach((track) => {
				if (track.fechamento === null) {
					track.fechamento = 0;
				}

				track.deletedsOriginalBatidas?.length > 0 && track.deletedsOriginalBatidas?.forEach((batida) => {
					track.batida.push(batida);
				});

				delete track.deletedsOriginalBatidas;

				tracks.push({ ...track, tipo_calculo: data.tipo_calculo });
			});

			data.diasemana = tracks;

			this.table.state.deletedsOriginalTracks?.length > 0 && this.table.state.deletedsOriginalTracks?.forEach((track) =>
				data.diasemana.push(
					({ ...track, batida: [] })
				));

			data.tolerencia_extra = data.tolerancia_extra;
			data.tolerencia_falta = data.tolerancia_falta;
			
			const hasValidTimePairing = data.diasemana.every(dia => 
        dia.batida.every(batida => {
					const entradaPreenchida = batida.entrada && batida.entrada.trim() !== '';
					const saidaPreenchida = batida.saida && batida.saida.trim() !== '';
					return entradaPreenchida === saidaPreenchida;
        })
   	 	);

			const allFieldsEmpty = data.diasemana.every(dia => 
				dia.batida.every(batida => {
					const entradaVazia = !batida.entrada || batida.entrada.trim() === '';
					const saidaVazia = !batida.saida || batida.saida.trim() === '';
					return entradaVazia && saidaVazia;
				})
			);

			if (!hasValidTimePairing || allFieldsEmpty) {
				this.setState({ isSending: false });
				message.error('Cadastre um par de horário, entrada e saída.');
				return;
			}
		} else {
			data.diasemana = []

			data.diasemana.push(
				{
					dia: null,
					folga: null,
					tipo_calculo: 'horista',
					duracao_escala: null,
					somar_total: null,
					tempo_mais_menos: null,
					tolerancia_hora_falta: null,
					tolerancia_hora_extra: null,
					tempo_mais_menos: null,
					carga_horaria: null,
					fechamento: data.fechamento,
					batida: null,
				},
			)
		}

		data.tolerancia_clt_art_58 = this.state.fixar_tolerancia;

		data.uuid = uuid;

		if (data.data_inicio_escala) {
			data.data_inicio_escala = moment(data.data_inicio_escala).format('YYYY-MM-DD');
		}
		
		this.setState(({
			data: data,
		}));

		if (this.checkIfNeedToRecalculate() || this.state.hasChangeInTable) {
			this.recalculateOpen(data);
			return;
		}
   
		journeysService.edit(data)
		.then(() => {
			this.setState({
				isSending: false,
			});
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title: "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	checkAnyFieldHasBeenChanged = () => {
		const { formToCompare } = this.state;
		
		const fieldsModified = Object.keys(formToCompare).map(field => ({
			[field]: formToCompare[field] !== this.form.getFieldValue(field)
		}));

		const someFieldHasBeenChanged = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return value[fieldName];
		});

		return someFieldHasBeenChanged;
	}

	checkIfNeedToRecalculate = () => {
		const fieldHasNoNeedToCallRecalculate = ['descricao'];
	
		const { formToCompare } = this.state;
	
		if (!this.form || typeof this.form.getFieldValue !== 'function') {
			return false;
		}
	
		const fieldsModified = Object.keys(formToCompare).reduce((changes, field) => {
			const fieldValue = this.form.getFieldValue(field);
			const hasChanged = fieldValue !== formToCompare[field];
			if (hasChanged) {
				changes[field] = hasChanged;
			}
			return changes;
		}, {});
	
		const needToRecalculate = Object.keys(fieldsModified).some(fieldName => {
			return !fieldHasNoNeedToCallRecalculate.includes(fieldName);
		});
	
		return needToRecalculate;
	};	

	recalculateOpen = ({ uuid }) => {
		this.setState({ recalcularModalVisible: true });

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");

		// Callback
		this.props.onComplete();
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		this.setState({
			isSending: false,
		});
		// Callback
		this.props.onComplete();
	}

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	onTableChange = () => {
		this.setState({
			hasChangeInTable: true
		});
	}

	render() {
		const { visible } = this.props;

		const {
			isLoading,
			isSending,
			intervals,
			values,
			tipo_horario,
			fixar_tolerancia,
			tipo_calculo,
			tolerancia_extra,
			tolerancia_falta,
			dsr,
			dsrIsLoading,
			overtime,
			overtimeIsLoading,
			recalcularModalVisible
		} = this.state;
	
		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={1200}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar Jornada`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={24} sm={8}>
										<Form.Item name="descricao" label="Descrição" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.descricao = el} />
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item name="is_active" label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												showSearch
											>
												<Select.Option value={true}>Ativo</Select.Option>
												<Select.Option value={false}>Inativo</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col sm={10}>
										<Form.Item name="tipo_calculo" label="Tipo de cálculo" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												onChange={(value) => this.setState({ tipo_calculo: value })}
												allowClear
												showSearch
												placeholder="- Selecione -"
											>
												<Select.Option value="flexivel">Flexível</Select.Option>
												<Select.Option value="horista">Horista</Select.Option>
												<Select.Option value="intervalo_flexivel">Intervalo Flexível</Select.Option>
												<Select.Option value="normal">Normal</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								{tipo_calculo != "horista" ? (
									<>
										<Row gutter={16}>
											<Col xs={24} sm={8}>
												<Form.Item name="hora_extra_id" label="Hora extra" rules={[{ required: true, message: "Campo obrigatório." }]}>
													<Select
														filterOption={false}
														allowClear
														placeholder="Escolha a hora extra"
														notFoundContent={overtimeIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
														onSearch={this.fetchOvertime}
														showSearch
													>
														{overtime.map((item, index) => (
															<Select.Option key={index} value={item.id}>
																{item.nome}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={8}>
												<Form.Item name="dsr_id" label="DSR" >
													<Select
														filterOption={false}
														allowClear
														placeholder="Escolha o dsr"
														notFoundContent={dsrIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
														onSearch={this.fetchDsr}
														showSearch
													>
														{dsr.map((item, index) => (
															<Select.Option key={index} value={item.id}>
																{item.nome}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col sm={8}>
												<Form.Item name="tipo_horario" label="Tipo de horário" rules={[{ required: true, message: "Campo obrigatório." }]}>
													<Select
														optionFilterProp="children"
														filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
														onChange={this.onChangeTipoHorario}
														showSearch
														placeholder="- Selecione -"
													>
														<Select.Option value="semanal">Semanal</Select.Option>
														<Select.Option value="escala">Escala</Select.Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col xs={24} sm={12}>
												<Form.Item name="fixar_tolerancia" valuePropName="checked" label={
													<div className="inputInfo">
														<p>Fixar tolerância de extras/faltas em 5 minutos e 10 minutos diários</p>
														<span>(CLT - Art. 58)</span>
													</div>} rules={[{ required: true, message: "Campo obrigatório." }]}>
													<Checkbox onChange={this.onChangeTolerancia} />
												</Form.Item>
											</Col>
											{/* <Col xs={24} sm={12}>
												<Form.Item name="pre_assinalar" valuePropName="checked" label={"Pré-assinalar intervalo"}>
													<Checkbox />
												</Form.Item>
											</Col> */}
										</Row>
										<Row gutter={16}>
											<Col xs={24} sm={8}>
												<Form.Item name="tolerancia_extra" label="Tolerância extra" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
													<Input disabled={fixar_tolerancia} onBlur={(evt) => this.setState({ tolerancia_extra: evt.target.value })} />
												</Form.Item>
											</Col>
											<Col xs={24} sm={8}>
												<Form.Item name="tolerancia_falta" label="Tolerância falta" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
													<Input disabled={fixar_tolerancia} onBlur={(evt) => this.setState({ tolerancia_falta: evt.target.value })} />
												</Form.Item>
											</Col>
											{fixar_tolerancia == false && (
												<Fragment>
													<Col xs={24} sm={8}>
														<Form.Item name="tolerancia_batida_extra" label="Tol. Antes" hasFeedback>
															<Input onChange={(evt) => this.setState({ tolerancia_antes: evt.target.value })} />
														</Form.Item>
													</Col>
													<Col xs={24} sm={8}>
														<Form.Item name="tolerancia_batida_falta" label="Tol. Depois" hasFeedback>
															<Input onChange={(evt) => this.setState({ tolerancia_depois: evt.target.value })} />
														</Form.Item>
													</Col>
												</Fragment>
											)}
											{tipo_horario == "escala" && (
												<Fragment>
													<Col xs={24} sm={8}>
														<Form.Item name="tipo_hora_escala" label="Tipo de hora escala" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
															<Select
																optionFilterProp="children"
																filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
																allowClear
																showSearch
																placeholder="- Selecione -"
															>
																<Select.Option value="12">12</Select.Option>
																<Select.Option value="24">24</Select.Option>
																<Select.Option value="36">36</Select.Option>
																<Select.Option value="48">48</Select.Option>
															</Select>
														</Form.Item>
													</Col>
													<Col xs={24} sm={8}>
														<DatePickerWithMask label="Data Base"  name="data_inicio_escala" required={true} />
													</Col>
												</Fragment>
											)}
											{intervals.length > 0 && (
												<Col xs={24} sm={8}>
													<Form.Item name="intervalo_refeicao_sequencias" label={
															<Fragment>
																<Checkbox onChange={this.onChangePreAssinalar} checked={this.state.pre_assinalar} /> <span style={{ marginLeft: 5 }}>Pré assinalar intervalo</span>
															</Fragment>
														}
													>
														<Select
															optionFilterProp="children"
															filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
															allowClear
															showSearch
															placeholder="Selecione o intervalo"
															disabled={!this.state.pre_assinalar}
														>
															{
																intervals.map((interval) => {
																	return (
																		<Select.Option key={interval.id} value={interval.value}>{interval.title}</Select.Option>
																	)
																})
															}
														</Select>
													</Form.Item>
												</Col>
											)}
										</Row>
										<Row gutter={16}>
											<Col xs={24}>
												<DayTable 
													showOnly={false} 
													ref={el => this.table = el} 
													getIntervals={this.getIntervals} 
													values={values} 
													tipo_horario={tipo_horario} 
													fixar_tolerancia={fixar_tolerancia} 
													tipo_calculo={tipo_calculo} 
													tolerancia_extra={tolerancia_extra} 
													tolerancia_falta={tolerancia_falta} 
													onChange={this.onTableChange}
												/>
											</Col>
										</Row>
									</>
								) : (
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item name="fechamento" label="Fechamento" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								)}
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
				/>
			</Fragment>
		)
	}
}

export default Edit;
