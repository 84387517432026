import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Form, Select, message } from "antd";

import { timeCardService } from "./../../../redux/services";

import {
	HourPickerWithMask,
} from "./../../../components";

import moment from "moment";

class ModalAjustes extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			item: null,
			isSending: false,
		}
		this.hourPickerRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      setTimeout(() => {
				if (this.hourPickerRef.current) {
					this.hourPickerRef.current.inputElement.focus();
				}
			}, 100)
    }
  }

	resetFields = () => {
		this.setState({
			item: null,
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = (item) => {
		this.setState({ item });
		setTimeout(() => {
			this.form.setFieldsValue({
				horario: item._ajuste_horas_falta.replace("-", ""),
				tipo: String(item.ajuste_horas_falta).includes("-") ? "negativo" : "positivo",
			});
		}, 100);
	}

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			let hours = values.horario.split(":")[0];
			let minutes = values.horario.split(":")[1];
			let ajustesHoras = (values.tipo === "negativo" ? "-" : "") + String(moment.duration({ hour: hours, minute: minutes }).asMinutes());

			timeCardService
				.gerenciarAjuste({
					cartao_ponto_id: this.state.item.id,
					ajuste_horas: ajustesHoras,
				})
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success('Registro atualizado com sucesso.');

					// Callback
					this.props.onComplete(response.data.data[0]);
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	}

	render() {
		const { visible } = this.props;

		const { isSending } = this.state;
	
		return (
			<Modal
				visible={visible}
				title="Editar ajuste"
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={380}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{disabled: isSending}}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={false}
				closable={!isSending}
				centered
			>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
				>
					<Row gutter={16}>
						<Col span={24}>
						<Col span={24}>
							<HourPickerWithMask 
								name="horario" 
								label="Horário" 
								required={true} 
								ref={this.hourPickerRef}
								onKeyUp={(e) => {
									if (e.key === 'Enter') {
										this.onSubmit();
									}
								}}
							/>
						</Col>
						</Col>
						<Col span={24}>
							<Form.Item label="Tipo" name="tipo" rules={[{ required: true, message: 'Campo obrigatório.' }]}>
								<Select placeholder="Selecione o tipo">
									<Select.Option value="negativo">Negativo</Select.Option>
									<Select.Option value="positivo">Positivo</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default ModalAjustes;