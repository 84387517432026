import React from 'react'
import { Col, Card, Form, message, Row, Space, Switch, Typography } from 'antd'
const { Text } = Typography
import moment from 'moment'
import { copyTextToClipboard } from '../../../../helpers/text'

const Primme = ({ item }) => {
  const { configuracao, numero_serial_rep, ultimo_nsr, token } = item

  const handleTokenCopyClick = (target) => {
    copyTextToClipboard(target.innerText).then(() => {
      message.info('Token copiado para área de transferência.')
    })
  }

  const renderTcpIpMode = (type) => {
    if (type === 'servidor') return 'Servidor'
    else return 'Cliente'
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Número de série">{numero_serial_rep}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Último NSR">{ultimo_nsr}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="IP">{configuracao.ip}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Porta TCP/IP">
              {configuracao.porta_tcp_ip}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Gateway">{configuracao.gateway}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Máscara de sub rede">
              {configuracao.mascara_sub_rede}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Modo TCP/IP">
              {renderTcpIpMode(configuracao.modo_tcp_ip)}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="IP do servidor">
              {configuracao.ip_servidor}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="DNS">{configuracao.dns}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="MAC">{configuracao.mac}</Form.Item>
          </Col>
        </Row>
        <Form.Item label="Desativar verificação ICMP neste equipamento">
          <Switch disabled checked={configuracao.desativar_icmp} />
        </Form.Item>
        <Form.Item label="Gravar fluxo de comunicação em arquivo log">
          <Switch disabled checked={configuracao.gravar_comunicacao_log} />
        </Form.Item>
      </Card>
      <Card title="Configurações de reconexão">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item label="Quantidade de tentativas">
              {configuracao.quantidade_tentativa}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Reconectar após tentativa">
              {configuracao.reconectar_apos_tentativa}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Tempo entre tentativas (seg)">
              {configuracao.tempo_entre_tentativa}
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Segurança">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Habilitar conexão segura">
              <Switch
                disabled
                checked={configuracao.habilitar_conexao_segura}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="IP da conexão segura">
              {configuracao.ip_conexao_segura}
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Leitoras">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item label="Tipo de leitora">
              {configuracao.tipo_leitora}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Módulo biométrico">
              {configuracao.modulo_biometrico}
            </Form.Item>
          </Col>
          {/* //TODO: Espaço para o novo campo que ainda não foi add na API */}
          <Col xs={24} md={8}></Col>
        </Row>
      </Card>
      <Card title="Outros">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Atualizar data e hora do relógio">
              <Switch disabled checked={configuracao?.atualizar_data_hora} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Data e hora">
              {moment(configuracao.data_hora).format('DD/MM/YYYY HH:mm')}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Fuso horário">
              {configuracao.fuso_horario}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Token">
              <Text
                onClick={({ target }) => handleTokenCopyClick(target)}
                style={{ cursor: 'pointer' }}
              >
                {token}
              </Text>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Horário de verão">
        <Form.Item label="Habilitar horário de verão">
          <Switch disabled checked={configuracao.habilitar_horario_verao} />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Início do horário de verão">
              {moment(configuracao.inicio_horario_verao).format('DD/MM/YYYY')}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Fim do horário de verão">
              {moment(configuracao.fim_horario_verao).format('DD/MM/YYYY')}
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Space>
  )
}

export default Primme
