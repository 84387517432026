import React, { Component } from "react";
import { Button, Col, Form, Modal, Row, Select, Spin } from "antd";
import { DatePickerWithMask } from "../../components";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "../../config/general";

import {
	companiesService,
	departmentsService,
	employeesService,
	reportService,
} from "../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			nextOrder: 1,
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: [],
			employees: [],
			employeesIsLoading: false,
		};

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;
		this._axiosCancelEmployeesToken = null;
	}

	fetchCompanies = () => {
		if (this._axiosCancelCompaniesToken) {
			this._axiosCancelCompaniesToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService
			.getAutocomplete({
				orderBy: "nome:asc",
				ativo: 1,
				cancelToken: this._axiosCancelCompaniesToken.token,
			})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelDepartmentsToken) {
			this._axiosCancelDepartmentsToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			search: value,
			is_active: 1,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos")
				? []
				: this.form.getFieldValue("empresas"),
		};

		departmentsService
			.getAutocomplete({
				...params,
			})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchEmployees = (value) => {
		if (this._axiosCancelEmployeesToken) {
			this._axiosCancelEmployeesToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				employeesIsLoading: false,
				employees: [],
			});

			return false;
		}

		this.setState({
			employeesIsLoading: true,
		});

		let params = {
			search: value,
			ativo: 1,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelEmployeesToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos")
				? []
				: this.form.getFieldValue("empresas"),
			departamentos_ids: this.form
				.getFieldValue("departamentos")
				.includes("todos")
				? []
				: this.form.getFieldValue("departamentos"),
		};

		employeesService
			.getAutocomplete({
				...params,
			})
			.then((response) => {
				this.setState({
					employeesIsLoading: false,
					employees: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					employeesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onCompanyChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue(
				"empresas",
				value.filter((item) => item !== "todos")
			);
		}

		this.setState({
			employees: [],
			employeesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
		});

		this.form.setFieldValue("departamentos", ["todos"]);
		this.form.setFieldValue("funcionarios", ["todos"]);
	};

	onCompanySelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("empresas", ["todos"]);
		}
	};

	onDepartmentChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue(
				"departamentos",
				value.filter((item) => item !== "todos")
			);
		}
	};

	onDepartmentSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("departamentos", ["todos"]);
		}
	};

	onEmployeeChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue(
				"funcionarios",
				value.filter((item) => item !== "todos")
			);
		}
	};

	onEmployeeSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("funcionarios", ["todos"]);
		}
	};

	componentDidMount() {
		if (!this.state.companies.length) {
			this.fetchCompanies();
		}
	}

	resetFields = () => {
		this.setState({
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
		});
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		let params = new URLSearchParams();

		if (values.empresas) {
			params.set("empresas", values.empresas.join(","));
		}

		if (values.departamentos) {
			params.set("departamentos", values.departamentos.join(","));
		}

		if (values.funcionarios) {
			params.set("funcionarios", values.funcionarios.join(","));
		}

		if (values.data_inicio) {
			params.set(
				"data_inicio",
				moment(values.data_inicio).format("YYYY-MM-DD")
			);
		}

		if (values.data_final) {
			params.set("data_final", moment(values.data_final).format("YYYY-MM-DD"));
		}

		window.open(`/impressao/mirror-point?${params.toString()}`, '_blank');

		this.setState({
			isSending: false,
		});
	};

	render() {
		const {
			isSending,
			nextOrder,
			companies,
			companiesIsLoading,
			departments,
			departmentsIsLoading,
			employees,
			employeesIsLoading,
		} = this.state;

		return (
			<QueueAnim className="site-content-inner alternative-wrapper">
				<div className="page-content fixed-header" key="1">
					<h1 className="page-title">Espelho Ponto</h1>
					<Form
						ref={(el) => (this.form = el)}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						initialValues={{
							order: nextOrder,
							required: true,
							empresas: ["todos"],
							departamentos: ["todos"],
							funcionarios: ["todos"],
						}}
					>
						<Row gutter={16} align="middle">
							<Col xs={24} md={8}>
								<Form.Item
									name="empresas"
									label="Empresa"
									hasFeedback
									rules={[{ required: true, message: "Campo obrigatório." }]}
								>
									<Select
										ref={(el) => (this.empresas = el)}
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === "string"
												? option.children
												: option.children.props.children
											)
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione a(s) empresa(s)"
										notFoundContent={
											companiesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onChange={this.onCompanyChange}
										onSelect={this.onCompanySelect}
										showSearch
									>
										<Select.Option value="todos">Todas</Select.Option>
										{companies.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={8}>
								<Form.Item
									name="departamentos"
									label="Departamento"
									hasFeedback
									rules={[{ required: true, message: "Campo obrigatório." }]}
								>
									<Select
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === "string"
												? option.children
												: option.children.props.children
											)
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o(s) departamento(s)"
										notFoundContent={
											departmentsIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchDepartments}
										onChange={this.onDepartmentChange}
										onSelect={this.onDepartmentSelect}
										showSearch
									>
										<Select.Option value="todos">Todos</Select.Option>
										{departments.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={8}>
								<Form.Item
									name="funcionarios"
									label="Funcionário"
									hasFeedback
									rules={[{ required: true, message: "Campo obrigatório." }]}
								>
									<Select
										mode="multiple"
										filterOption={false}
										allowClear
										placeholder="Selecione o(s) funcionário(s)"
										notFoundContent={
											employeesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchEmployees}
										onChange={this.onEmployeeChange}
										onSelect={this.onEmployeeSelect}
										showSearch
									>
										<Select.Option value="todos">Todos</Select.Option>
										{employees.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={12} md={6} lg={6} xl={5} xxl={4}>
								<DatePickerWithMask
									onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.form.data_final.focus();
										}
									}}
									label="Data Início"
									name="data_inicio"
									required={true}
									rules={[
										{
											required: true,
											message: "Campo obrigatório.",
										},
										{
											validator: async (_, data_inicio) => {
												var data_final = this.form.getFieldValue("data_final");
												if (data_final !== null) {
													if (data_inicio > data_final) {
														return Promise.reject(
															"A data inicial deve ser menor ou igual a data final."
														);
													}
												}
											},
										},
									]}
								/>
							</Col>
							<Col xs={12} md={6} lg={6} xl={5} xxl={4}>
								<DatePickerWithMask
									label="Data Fim"
									name="data_final"
									onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.form.data_inicio.focus();
										}
									}}
									required={true}
									rules={[
										{
											required: true,
											message: "Campo obrigatório.",
										},
										{
											validator: async (_, data_final) => {
												var data_inicio =
													this.form.getFieldValue("data_inicio");
												if (data_inicio !== null) {
													if (data_final < data_inicio) {
														return Promise.reject(
															"A data final deve ser maior ou igual a data inicial."
														);
													}
												}
											},
										},
									]}
								/>
							</Col>
							<div style={{ marginLeft: '10px' }}>
								<Button
									type="primary"
									htmlType="submit"
									shape="round"
									className="btn-save"
									icon={<i className="far fa-check" />}
									loading={isSending}
									disabled={isSending}
									block
								>
									{isSending ? "Gerando" : "Gerar"}
								</Button>
							</div>
						</Row>
					</Form>
				</div>
			</QueueAnim>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Index);
