import React, { PureComponent, Fragment } from 'react';
import { Dropdown, Input, Typography, Modal, Skeleton } from 'antd';
import { connect } from "react-redux";
import { routeLogin, api } from "../../config/api";
import { authCheckService, authService } from '../../redux/services';
import { authActions } from "../../redux/actions";

import styles from './index.css';

const { Search } = Input;

class CompanyDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
      companies: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.searchCompany('');
  }

  handleSubmit = ({ key: cliente_id }) => {
    if (!cliente_id) return;

    this.setState({
      isSending: true,
    });

    authService.changeLogin(cliente_id)
      .then((response) => {
        if (response.data === 0) return;
        const { user } = this.props;

        // TODO Thiago - Verificar
        const values = {
          action: 'updateAuth',
          cliente_id,
          email: user.email,
          token: api.defaults.headers.common["Authorization"]
        }

        routeLogin(
          values, 
          (success) => {
            this.setState({
              isSending: false,
            });
            // Do Login
            this.props.doLogin(success);
            window.location.reload(true);
          }, 
          (error) => {
            this.setState({
              isSending: false,
            });
    
            Modal.error({
              title  : "Ocorreu um erro!",
              content: String(error),
            });
          }
        );
      }).catch((data) => {
        console.log('error', data);
      })
  };

  searchCompany = search => {
    const { user } = this.props;
    this.setState({ loading: true }, () => {
      authCheckService
        .checkClientes({ email: user.email, search })
        .then((response) => {
          const { data: companies } = response;
          this.setState({ companies, loading: false });
        });
    });
  };

  render() {
    const { visible = true } = this.props;
    const companyCurrent = JSON.parse(localStorage.getItem('@mainCompany'));
    const { companies, isSending, loading } = this.state;

    if (!visible || !companyCurrent) return <Fragment></Fragment>;

    return (
      <Dropdown
        loading={loading} 
        menu={{ items: companies, onClick: this.handleSubmit }} 
        trigger={['click']} 
        className={styles.menu}
        dropdownRender={(menu) => (
          <div>
            <div className="ant-dropdown-menu-item">
              <Search allowClear onSearch={this.searchCompany} enterButton />
            </div>
            {React.cloneElement(menu)}
          </div>
        )}
      >
        {isSending ? (
          <Skeleton active paragraph={false} />
        ) : (
          <a className="ant-dropdown-link" href="#" style={{ marginRight: 10 }}>
            <Typography.Text strong>{companyCurrent?.nome}</Typography.Text>
            <i className="fa fa-arrow-down"></i>
          </a>
        )}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.auth.userData,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		doLogin: (data) => {
			dispatch(authActions.login(data));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDropdown);
