import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, Alert, InputNumber, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { companiesService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UIUpload,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

import Employees from "./Employees";
import Adresses from "./Adresses";
import Contacts from "./Contacts";

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			ativo: null,
			settings: null,
			tipo_pessoa: null,
			cnpj_principal: null,
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};

		this._axiosCancelToken = null;
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	fetchTenants = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				resalesIsLoading: false,
				resales: [],
			});

			return false;
		}

		this.setState({
			resalesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					resalesIsLoading: false,
					resales: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					resalesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		companiesService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				let companies = [];

				if (item.empresa) {
					resales.push({
						id: item.empresa.id,
						nome: item.empresa.nome,
					});
				}

				this.setState({
					settings: {
						app_funcionario: response.data.configuracao.app_funcionario,
						app_empresa: response.data.configuracao.app_empresa,
					},
					cnpj_principal: item.cnpj_principal,
					isLoading: false,
					companies: companies,
					tipo_pessoa: item.tipo_pessoa,
				}, () => {
					// Fill form
					this.fillForm(item);

					this.tabEmployees && this.tabEmployees.init(item);
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			nome: data.nome,
			ativo: data.ativo,
			cnpj: data.cnpj,
			matriz: data.matriz,
			nome_responsavel: data.nome_responsavel,
			numero_folha: data.numero_folha,
			cargo_responsavel: data.cargo_responsavel,
			cnpj_principal: data.cnpj_principal,
			cei: data.cei,
			cpf: data.cpf,
			im: data.im,
			tipo_pessoa: data.tipo_pessoa,
			rg: data.rg,
			ie: data.ie,
			caepf: data.caepf,
			cno: data.cno,
			data_nascimento: data.data_nascimento ? moment(data.data_nascimento) : null,
			app_empresa: data.app_empresa,
			app_funcionario: data.app_funcionario,
		});

		// Upload
		if (data.avatar) {
			this.upload.setFiles([
				{
					uuid: data.uuid,
					url: data.avatar,
					type: 'image/jpeg',
				}
			]);
		}
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		// const file = this.upload.getFiles();

		// if (file.hasError) {
		// 	Modal.error({
		// 		title: "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		// uuid
		data.uuid = uuid;

		if (data.data_nascimento) {
			data.data_nascimento = data.data_nascimento.format("YYYY-MM-DD");
		} else {
			delete data.data_nascimento;
		}

		companiesService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}

	render() {
		const { visible } = this.props;

		const { 
			isLoading, 
			isSending, 
			settings, 
			tipo_pessoa, 
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Empresa`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general">
					<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col sm={6}>
									<Form.Item name="cnpj_principal" label="Empresa Principal" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
								<Col xs={18}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de Pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="cei" label="CEI" hasFeedback >
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="rg" label="RG" hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="cpf" rules={[{ required: true, message: "Campo obrigatório." }]} label="CPF" hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<DatePickerWithMask onKeyUp={(e) => {
												if (e.keyCode === 13 || e.keyCode === 9) {
													this.tipo_contrato.focus()
												}
											}} label="Data de nascimento" name="data_nascimento" disableDateAfter={true} />
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="caepf" label="CAEPF" hasFeedback >
												<Input />
											</Form.Item>
										</Col>
									</Row>
								</>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="cnpj" label="CNPJ" rules={[{ required: true, message: "Campo obrigatório." }]} hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="cno" label="CNO" hasFeedback >
												<Input />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
								</>
							))}
							
							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="numero_folha" label="Número da Folha" >
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="nome_responsavel" label="Nome do Responsável" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="cargo_responsavel" label="Cargo do Responsável" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="ativo" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Switch />
									</Form.Item>
								</Col>
								{
									displayParamsREPP && (
										<>
											{settings && settings.app_empresa && displayAppCompany && (<Col sm={8}>
												<Form.Item name="app_empresa" valuePropName="checked" label="App empresa">
													<Switch />
												</Form.Item>
											</Col>)}
											{settings && settings.app_funcionario && displayAppEmployee && (<Col sm={8}>
												<Form.Item name="app_funcionario" valuePropName="checked" label="App funcionario">
													<Switch />
												</Form.Item>
											</Col>)}
										</>
									)
								}
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Funcionários" key="employees">
							<Employees
								ref={el => this.tabEmployees = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
