import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Form, Modal, Select, Spin } from "antd";

import axios from "axios";
import { companiesService, departmentsService, timeCardService } from "./../../redux/services";

class Filters extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

  constructor(props) {
		super(props);

		this.filtersClean = {
			empresa_id     : null,
			departamento_id: null,
			funcao_id      : null,
			jornada_id     : null,
		};

		this.state = {
			filters: {
				...this.filtersClean,
			},
			companies           : [],
      companiesIsLoading  : false,
			departments         : [],
			departmentsIsLoading: false,
			roles               : [],
			rolesIsLoading      : false,
			journeys            : [],
			journeysIsLoading   : false,
		};

		this._axiosCancelCompaniesToken   = null;
		this._axiosCancelDepartmentsToken = null;
		this._axiosCancelRolesToken       = null;
		this._axiosCancelJourneysToken    = null;
	}

  onOpen = (filters) => {
		this.setState({
			filters: filters,
		});
	};

  onClose = () => {
		// Callback
		this.props.onClose();
	};

	cleanFilters = () => {
		this.setState({
			filters: this.filtersClean,
		}, () => {
			// Callback
			this.props.onComplete({ ...this.state.filters });
		});
	};

  filtersOnConfirm = () => {
		// Callback
		this.props.onComplete({ ...this.state.filters });
	};

  setFilter = (name, value) => {
		this.setState(state => ({
			filters: {
				...state.filters,
				[name]: value,
			}
		}));
	};

	fetchDepartments = (value) => {
		if( this._axiosCancelDepartmentsToken )
		{
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		this.setState({
			departmentsIsLoading: true,
		});

		if( !value.trim().length )
		{
			this.setState({
				departmentsIsLoading: false,
				departments         : [],
			});

			return false;
		}

		const { filters } = this.state;

		const empresa_id = filters.empresa_id;

		let params = {
			orderBy    : "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
		};

		if( empresa_id ) {
			params.empresas_ids = [empresa_id];
		}

		departmentsService.getAutocomplete(params)
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments         : response.data.data,
				});
			})
			.catch((data) => {
				if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchRoles = (value) => {
		if( this._axiosCancelRolesToken )
		{
			this._axiosCancelRolesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelRolesToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				rolesIsLoading: false,
				roles         : [],
			});

			return false;
		}

		this.setState({
			rolesIsLoading: true,
		});

		let params = {
			search     : value,
			ativo      : 1,
			orderBy    : "nome:asc",
			cancelToken: this._axiosCancelRolesToken.token,
		};

		timeCardService
			.autocompleteFuncao({
				...params,
			})
			.then((response) => {
				this.setState({
					rolesIsLoading: false,
					roles         : response.data.data,
				});
			})
			.catch((data) => {
				if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

				this.setState({
					rolesIsLoading: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchJourneys = (value) => {
		if( this._axiosCancelJourneysToken )
		{
			this._axiosCancelJourneysToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelJourneysToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				journeysIsLoading: false,
				journeys         : [],
			});

			return false;
		}

		this.setState({
			journeysIsLoading: true,
		});

		let params = {
			search     : value,
			ativo      : 1,
			orderBy    : "descricao:asc",
			cancelToken: this._axiosCancelJourneysToken.token,
		};

		timeCardService
			.autocompleteJornada({
				...params,
			})
			.then((response) => {
				this.setState({
					journeysIsLoading: false,
					journeys         : response.data.data,
				});
			})
			.catch((data) => {
				if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

  render() {
    const { visible } = this.props;

    const {
			filters,
			companies,
			companiesIsLoading,
			departments,
			departmentsIsLoading,
			roles,
			rolesIsLoading,
			journeys,
			journeysIsLoading
		} = this.state;

    return (
      <Modal
        visible={visible}
        title="Filtrar"
        centered={true}
        destroyOnClose={true}
        maskClosable={true}
        width={900}
        okText="Aplicar"
        onCancel={this.onClose}
        onOk={this.filtersOnConfirm}
        className="modal-filters"
				footer={[
					<Button key="back" type="link" onClick={this.cleanFilters}>Excluir filtros</Button>,
					<Button key="submit" type="primary" onClick={this.filtersOnConfirm}>Aplicar</Button>,
				]}>
					<Form
						ref={el => this.form = el}
						layout="vertical"
						scrollToFirstError
					>
						<div className="filter-group">
							<div className="filter-group-title">
								<h3>Departamento</h3>
							</div>
							<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
								<Form.Item>
									<Select
										allowClear
										filterOption={false}
										notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onChange={(value) => this.setFilter("departamento_id", value ?? null)}
										onSearch={this.fetchDepartments}
										placeholder="Pesquise o departamento"
										showSearch
										value={this.state.filters.departamento_id}>
										{departments.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
						<div className="filter-group">
							<div className="filter-group-title">
								<h3>Função</h3>
							</div>
							<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
								<Form.Item>
									<Select
										allowClear
										filterOption={false}
										notFoundContent={rolesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onChange={(value) => this.setFilter("funcao_id", value ?? null)}
										onSearch={this.fetchRoles}
										placeholder="Pesquise a função"
										showSearch
										value={this.state.filters.funcao_id}>
										{roles.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
						<div className="filter-group">
							<div className="filter-group-title">
								<h3>Horário</h3>
							</div>
							<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
								<Form.Item>
									<Select
										allowClear
										filterOption={false}
										notFoundContent={journeysIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onChange={(value) => this.setFilter("jornada_id", value ?? null)}
										onSearch={this.fetchJourneys}
										placeholder="Pesquise o horário"
										showSearch
										value={this.state.filters.jornada_id}>
										{journeys.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
					</Form>
      </Modal>
    )
  }
}

export default Filters;