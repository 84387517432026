import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Select } from "antd";

import moment from "moment";

import { resalesService } from "./../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "./../../components";

import Adresses from "./adresses/index";
import Contacts from "./contacts/index";
import Clients from "./clients/index";
import Users from "./users/index";

const config = {
	externalName: "paciente",
};

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null
		};
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		resalesService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				this.setState({
					isLoading: false,
					item: item,
				}, () => {
					// Upload
					if (item.avatar) {
						this.upload.setFiles([
							{
								uuid: item.uuid,
								url: item.avatar,
								type: 'image/jpeg',
							}
						]);
					}

					// Load options
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
					this.tabClients && this.tabClients.init(item);
					this.tabUsers && this.tabUsers.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { uuid, isLoading, item } = this.state;
		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar revenda`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey={this.state.general_key != null ? this.state.general_key : "general"}>
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							{/* <UIUpload
								ref={el => this.upload = el}
								label="Avatar"
								disabled
							/> */}
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Nome">
										{item.nome}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Tipo de pessoa">
										{item.tipo_pessoa ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							{item.tipo_pessoa == "Jurídica" ? (
								<Row gutter={16}>
									<Col sm={8}>
										<Form.Item label="CNPJ">
											{item.cnpj ?? "-"}
										</Form.Item>
									</Col>

									<Col sm={8}>
										<Form.Item label="IE">
											{item.ie ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={8}>
										<Form.Item label="IM">
											{item.im ?? "-"}
										</Form.Item>
									</Col>
								</Row>

							) : (
								<Row gutter={16}>
									<Col sm={8}>
										<Form.Item label="RG">
											{item.rg ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={8}>
										<Form.Item label="CPF">
											{item.cpf ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={8}>
										<Form.Item label="Data de nascimento">
											{item.nascimento ? moment(item.nascimento).calendar() : "-"}
										</Form.Item>
									</Col>
								</Row>

							)}
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item label="Observações">
										{item.observacoes ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Status">
										{item.status ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="vencimento" label="Data de Vencimento">
										{item.vencimento ? moment(item.vencimento).calendar() : "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar()}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Última atualização">
										{moment(item.updated_at).calendar()}
									</Form.Item>
								</Col>
							</Row>
							{/* <Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="App funcionário">
										<Switch disabled checked={item.app_funcionario == 1 ? true : false} />
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="App empresa">
										<Switch disabled checked={item.app_empresa == 1 ? true : false} />
									</Form.Item>
								</Col>
							</Row> */}
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="anamnese">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Clientes" key="clients">
							<Clients
								ref={el => this.tabClients = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Usuários" key="users">
							<Users
								ref={el => this.tabUsers = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
