import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, Form, Input, Spin, Card, message, Modal, Row, Select, Switch, Tabs, Checkbox } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { annualLeaveService, companiesService, departmentsService } from "../../redux/services";

import moment from "moment";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
	HourPickerWithMask,
} from "./../../components";

import PeriodsTable from "./tables/periods"
import TracksTable from "./tables/tracks"
import { map } from "lodash";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

import ModalRecalculate from './ModalRecalculate';

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			uuid: 0,
			type: null,
			controle_faltas: null,
			nao_multiplicar_pelo_percentual: null,
			tipo_controle_extra: null,
			initialDate: '',
			create: true,

			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			recalcularModalVisible: false,
		};

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;
	}

	onOpen = () => {
		setTimeout(() => {
			this.descricao && this.descricao.focus()
			this.form.setFieldsValue({
				is_active: 1,
			});
		}, 100);
	};


	fetchCompanies = () => {
		if (this._axiosCancelCompaniesToken) {
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelDepartmentsToken) {
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue("empresas").includes("todos") ? [] : this.form.getFieldValue("empresas"),
		}

		departmentsService.getAutocomplete({
			...params
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onCompanyChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("empresas", value.filter((item) => item !== "todos"));
		}

		this.setState({
			employees: [],
			employeesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
		});

		this.form.setFieldValue("departamentos", ["todos"]);
	};

	onCompanySelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("empresas", ["todos"]);
		}
	};

	onDepartmentChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("departamentos", value.filter((item) => item !== "todos"));
		}
	};

	onDepartmentSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("departamentos", ["todos"]);
		}
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onChangeChecked = (checkboxName, checked) => {
		if (checkboxName == "Selecionar Todos") {
			if (checked) {
				this.form.setFieldsValue({
					extra_domingo: true,
					extra_feriado: true,
					extra_folga: true,
					extra_sabado: true,
					extra_uteis: true,
				})
			} else {
				this.form.setFieldsValue({
					extra_domingo: false,
					extra_feriado: false,
					extra_folga: false,
					extra_sabado: false,
					extra_uteis: false,
				})

			}
		} else {
			if (
				this.form.getFieldValue("extra_domingo") == true &&
				this.form.getFieldValue("extra_feriado") == true &&
				this.form.getFieldValue("extra_folga") == true &&
				this.form.getFieldValue("extra_sabado") == true &&
				this.form.getFieldValue("extra_uteis") == true
			) {
				this.selecionar_todos.setState({ checked: true });
			} else {
				this.selecionar_todos.setState({ checked: false });
			}
		}
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		if (!(data.empresas instanceof Array)) {
			data.empresas = [data.empresas];
		}

		if (!(data.departamentos instanceof Array)) {
			data.departamentos = [data.departamentos];
		}

		if (data.empresas.includes("todos")) {
			data.empresas = null;
			data.todas_empresas = true;
		}

		if (data.departamentos.includes("todos")) {
			data.todas_departamentos = true;
			data.departamentos = null;
		}

		if (data.faixas_de_horario) {

			data.faixas_de_horario.forEach((faixa) => {
				data[`multiplicar_percentual_${faixa}`] = true;
			})

		}

		data.vigencia = this.periodsTable?.state?.periods?.map((period) => ({ vigencia_inicio: moment(period.data_inicial).format('YYYY-MM-DD'), vigencia_final: moment(period.data_final).format('YYYY-MM-DD') }));

		Object.keys(data).forEach(key => {
			if (key.includes('_data_incial')) {
				this.setState({
					initialDate: moment(data[key]).format('YYYY-MM-DD'),
				})
			}
		});

		annualLeaveService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
					uuid: response.data.data.uuid,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Banco de horas cadastrado com sucesso.");

				const currentDate = moment().format();

				const isInitialDateBeforeCurrentDate = moment(this.state.initialDate).isBefore(currentDate);

				if (isInitialDateBeforeCurrentDate) {
					this.recalculateOpen(this.state.uuid);
				}

				this.props.onComplete();

			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	recalculateOpen = (uuid) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});


		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");

		// Callback
		this.props.onComplete();
	};

	recalculateError = () => {
		this.setState({ recalcularModalVisible: false });

		message.error("Ocorreu um erro inesperado! Tenta novamente.");
		// Callback
		this.props.onComplete();
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, nextOrder, companies, companiesIsLoading, departments, departmentsIsLoading, recalcularModalVisible } = this.state;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title="Incluir Novo Banco de Horas">
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						initialValues={{
							order: nextOrder,
							is_active: true,
							nao_multiplicar_pelo_percentual: false,
							tipo_controle_extra: 'primeira_faixa_entrada',
							controle_faltas: 'diario',
							controle_atraso: 'nenhuma_hora_atraso',
							empresas: ["todos"],
							departamentos: ["todos"],
						}}>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={24} md={24}>
										<Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.descricao = el} />
										</Form.Item>
									</Col>
									<Col xs={24} md={24}>
										<Form.Item name="is_active" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Switch />
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Períodos" key="periods">
								<Row gutter={16}>
									<Col xs={24}>
										<PeriodsTable ref={el => this.periodsTable = el} />
									</Col>
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Extras" key="extras">
								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="tipo_controle_extra" label="Controle de extras:" >
											<Select
												ref={el => this.sexo = el}
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												onChange={(value) => this.setState({ tipo_controle_extra: value })}
												style={{ width: '100%' }}
												showSearch>
												<Select.Option key={"diario"} value="diario">Diário</Select.Option>
												<Select.Option key={"semanal"} value="semanal">Semanal</Select.Option>
												<Select.Option key={"mensal"} value="mensal">Mensal</Select.Option>
												<Select.Option key={"primeira_faixa_entrada"} value="primeira_faixa_entrada">Somente a primeira faixa de extra</Select.Option>
												<Select.Option key={"ultima_faixa_entrada"} value="ultima_faixa_entrada">Somente a última faixa de extra</Select.Option>
												<Select.Option key={"todas_horas_extras"} value="todas_horas_extras">Todas as faixas de horas extras</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="nao_multiplicar_pelo_percentual" label="Multiplicar pelo percentual" valuePropName="checked" >
											<Switch onChange={(value) => this.setState({ nao_multiplicar_pelo_percentual: value })} />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									{(this.state.tipo_controle_extra == "diario" ||
										this.state.tipo_controle_extra == "semanal" ||
										this.state.tipo_controle_extra == "mensal"
									) && (
											<Col md={12}>
												<HourPickerWithMask name="limite_maximo_de_extras" label="Limite máximo de extras:" required={true} />
											</Col>
										)}
									{this.state.nao_multiplicar_pelo_percentual == true
										&& (
											<Col md={12}>
												<Form.Item name="faixas_de_horario" label="Faixas de horário:">
													<Select
														mode="multiple"
														optionFilterProp="children"
														filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
														allowClear
														placeholder="Selecione o(s) campo(s)"
														showSearch>
														<Select.Option value="domingo">Domingo</Select.Option>
														<Select.Option value="feriado">Feriado</Select.Option>
														<Select.Option value="folga">Folga</Select.Option>
														<Select.Option value="sabado">Sábado</Select.Option>
														<Select.Option value="uteis">Úteis</Select.Option>
													</Select>
												</Form.Item>
											</Col>
										)}
								</Row>
								<Row gutter={16} style={{ marginBottom: '20px' }}>
									<Col xs={24} md={12}>
										Ignorar faixa de extras:
									</Col>
								</Row>
								<Card>
									<Checkbox ref={el => this.selecionar_todos = el} onChange={(e) => this.onChangeChecked("Selecionar Todos", e.target.checked)} style={{ marginBottom: '20px' }}>Selecionar todos</Checkbox>
									<Row gutter={16}>
										<Col xs={24} md={8}>
											<Form.Item name="extra_domingo" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Domingo</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item name="extra_feriado" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Feriado</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item name="extra_folga" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Folga</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item style={{ marginBottom: '10px' }} name="extra_sabado" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Sábado</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item style={{ marginBottom: '10px' }} name="extra_uteis" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Úteis</Checkbox>
											</Form.Item>
										</Col>
									</Row>
								</Card>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Faltas" key="faults">
								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="controle_faltas" label="Controle de faltas:" >
											<Select
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												onChange={(value) => this.setState({ controle_faltas: value })}
												showSearch>
												<Select.Option key={"diario"} value="diario">Diário</Select.Option>
												<Select.Option key={"mensal"} value="mensal">Mensal</Select.Option>
												<Select.Option key={"nenhuma_hora_falta"} value="nenhuma_hora_falta">Nenhuma hora falta</Select.Option>
												<Select.Option key={"semanal"} value="semanal">Semanal</Select.Option>
												<Select.Option key={"todas_horas_faltas"} value="todas_horas_faltas">Todas as horas falta</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									{(this.state.controle_faltas != "nenhuma_hora_falta" && this.state.controle_faltas != "todas_horas_faltas") && (
										<Col md={12}>
											<HourPickerWithMask name="limite_maximo_de_faltas" label="Limite máximo de faltas:" required={true} />
										</Col>
									)}
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Atrasos" key="dalays">
								<Col xs={24} md={12}>
									<Form.Item name="controle_atraso" label="Controle de atrasos:">
										<Select
											ref={el => this.sexo = el}
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											style={{ width: '100%' }}
											showSearch>
											<Select.Option key={"nenhuma_hora_atraso"} value="nenhuma_hora_atraso">Nenhuma hora atraso</Select.Option>
											<Select.Option key={"todas_horas_atraso"} value="todas_horas_atraso">Todas as horas atraso</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Vínculos" key="ties">
								<Row>
									<Col xs={24}>
										<Form.Item name="empresas" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												ref={el => this.empresas = el}
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione a(s) empresa(s)"
												notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchCompanies}
												onChange={this.onCompanyChange}
												onSelect={this.onCompanySelect}
												showSearch>
												<Select.Option value="todos">Todas</Select.Option>
												{companies.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={24}>
										<Form.Item name="departamentos" label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione o(s) departamento(s)"
												notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchDepartments}
												onChange={this.onDepartmentChange}
												onSelect={this.onDepartmentSelect}
												showSearch>
												<Select.Option value="todos">Todos</Select.Option>
												{departments.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								{/* <Row gutter={16}>
									<Col xs={24}>
										<Tabs defaultActiveKey="uteis">
											<TracksTable ref={el => this.tracksTable = el} />
										</Tabs>
									</Col>
								</Row> */}
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					create={this.state.create}
					recalculateError={this.recalculateError}
				/>
			</Fragment>
		)
	}
}

export default Create;
