import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
	Modal,
	Row,
	Col,
	Input,
	Spin,
	Form,
	Select,
	message,
} from "antd";

import {
	timeCardService,
	justificationsService,
} from "./../../../../redux/services";

import axios from "axios";

import moment from "moment";

const { TextArea } = Input;

import { DatePickerWithMask } from "./../../../../components";

class ModalCreate extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			justifications: [],
			justificationsIsLoading: false,
		};

		this._axiosCancelJustificationsToken = null;
	}

	fetchJustifications = (value) => {
		if (this._axiosCancelJustificationsToken) {
			this._axiosCancelJustificationsToken.cancel(
				"Only one request allowed at a time."
			);
		}

		this._axiosCancelJustificationsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				justificationsIsLoading: false,
				justifications: [],
			});

			return false;
		}

		this.setState({
			justificationsIsLoading: true,
		});

		let params = {
			search: value,
			ativo: 1,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelJustificationsToken.token,
		};

		justificationsService
			.getAll({
				...params,
			})
			.then((response) => {
				this.setState({
					justificationsIsLoading: false,
					justifications: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					justificationsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
		this.setState({
			justifications: [],
			justificationsIsLoading: false,
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {};

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true,
			});

			timeCardService
				.afastamentoCreate({
					empresas: [this.props.empresa.id],
					departamentos: [this.props.departamento.id],
					funcionarios: [this.props.funcionario.id],
					justificativa_id: values.justificativa,
					motivo: values.motivo,
					data_inicio: moment(values.data_inicio).format("YYYY-MM-DD"),
					data_fim: moment(values.data_fim).format("YYYY-MM-DD"),
				})
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Afastamento cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	};

	render() {
		const { visible } = this.props;

		const { isSending, justifications, justificationsIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title="Incluir Novo Afastamento"
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={580}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{ disabled: isSending }}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={!isSending}
				closable={!isSending}
				centered
			>
				<Form
					ref={(el) => (this.form = el)}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					disabled={isSending}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label="Empresa" style={{ marginBottom: 15 }}>
								{this.props.empresa?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Departamento" style={{ marginBottom: 15 }}>
								{this.props.departamento?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Funcionário" style={{ marginBottom: 15 }}>
								{this.props.funcionario?.nome ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="justificativa"
								label="Justificativa"
								rules={[{ required: true, message: "Campo obrigatório." }]}
							>
								<Select
									filterOption={false}
									allowClear
									placeholder="Selecione a justificativa"
									notFoundContent={
										justificationsIsLoading ? (
											<Spin
												indicator={
													<i className="fad fa-spinner-third fa-spin" />
												}
											/>
										) : null
									}
									onSearch={this.fetchJustifications}
									showSearch
								>
									{justifications.map((item, index) => (
										<Select.Option key={index} value={item.id}>
											{item.nome}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="motivo" label="Motivo" hasFeedback>
								<TextArea
									ref={(el) => (this.motivo = el)}
									placeholder="Motivo"
									autoSize={{ minRows: 2, maxRows: 5 }}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<DatePickerWithMask
								onKeyUp={(e) => {
									if (e.keyCode === 13 || e.keyCode === 9) {
										this.form.data_fim.focus();
									}
								}}
								label="Data inicial"
								name="data_inicio"
								required={true}
								rules={[
									{
										required: true,
										message: "Campo obrigatório.",
									},
									{
										validator: async (_, data_inicio) => {
											var data_fim = this.form.getFieldValue("data_fim");
											if (data_fim !== null) {
												if (data_inicio > data_fim) {
													return Promise.reject(
														"A data inicial deve ser menor ou igual a data final."
													);
												}
											}
										},
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12}>
							<DatePickerWithMask
								label="Data final"
								name="data_fim"
								onKeyUp={(e) => {
									if (e.keyCode === 13 || e.keyCode === 9) {
										this.form.data_inicio.focus();
									}
								}}
								required={true}
								rules={[
									{
										required: true,
										message: "Campo obrigatório.",
									},
									{
										validator: async (_, data_fim) => {
											var data_inicio = this.form.getFieldValue("data_inicio");
											if (data_inicio !== null) {
												if (data_fim < data_inicio) {
													return Promise.reject(
														"A data final deve ser maior ou igual a data inicial."
													);
												}
											}
										},
									},
								]}
							/>
						</Col>
					</Row>
				</Form>
			</Modal>
		);
	}
}

export default ModalCreate;
