import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Modal } from "antd";
import { LoadingOutlined, ImportOutlined } from '@ant-design/icons';

import FileUpload from "./fileupload";

class Imports extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		loading: PropTypes.bool,
	};

	constructor(props) {
		super(props);
	
		this.state = { };
	}

	onClose = () => {
		// Callback
		this.props.onClose();
	};

	importsOnConfirm = () => {
		// Callback
		this.props.onComplete();
	};

	render() {
		const { visible, loading } = this.props;

		return (
			<Modal
				visible={visible}
				title="Importar Arquivo(s)"
				centered={true}
				destroyOnClose={true}
				maskClosable={true}
				width={900}
				onCancel={this.onClose}
				className="modal-filters"
				footer={[
					<Button
						key="submit"
						type="primary"
						onClick={this.importsOnConfirm}
						className="imports-btn"
						icon={loading ? <LoadingOutlined /> : <ImportOutlined />}
					>
						{loading ? 'Importando' : 'Importar'}
					</Button>,
				]}
			>
				<FileUpload />
			</Modal>
		)
	}
}

export default Imports;
