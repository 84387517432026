import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Checkbox, Card, Input, Select } from "antd";

import moment from "moment";

import { annualLeaveService } from "./../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
	HourPickerWithMask,
	DatePickerWithMask,
} from "./../../components";

import PeriodsTable from "./tables/periods"
import TracksTable from "./tables/tracks"

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null,
			periods: [],
			tracks: [],
			controle_faltas: null,
			nao_multiplicar_pelo_percentual: null,
			tipo_controle_extra: null,

			companies: [], 
			departments: [],
		};
	}

	onChangeChecked = (checkboxName, checked) => {
		// if (checkboxName == "Selecionar Todos") {
		// 	this.setState({ selecionar_todos: checked });
		// 	if (checked) {
		// 		this.form.setFieldsValue({
		// 			extra_domingo: true,
		// 			extra_feriado: true,
		// 			extra_folga: true,
		// 			extra_sabado: true,
		// 			extra_uteis: true,
		// 		})
		// 	} else {
		// 		this.form.setFieldsValue({
		// 			extra_domingo: false,
		// 			extra_feriado: false,
		// 			extra_folga: false,
		// 			extra_sabado: false,
		// 			extra_uteis: false,
		// 		})

		// 	}
		// } else {
		// 	if (
		// 		this.form.getFieldValue("extra_domingo") == true &&
		// 		this.form.getFieldValue("extra_feriado") == true &&
		// 		this.form.getFieldValue("extra_folga") == true &&
		// 		this.form.getFieldValue("extra_sabado") == true &&
		// 		this.form.getFieldValue("extra_uteis") == true
		// 	) {
		// 		this.selecionar_todos.setState({ checked: true });
		// 	} else {
		// 		this.selecionar_todos.setState({ checked: false });
		// 	}
		// }
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		annualLeaveService.show({ uuid })
			.then((response) => {
				let item = response.data.data;
				this.setState({
					isLoading: false,
					periods: item.banco_horas_vigencia ?? [],
					companies: item?.companies ?? [],
					departments: item?.departments ?? [],
				});

				this.fillForm(item);
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		
		let faixas = [];

		if (data.multiplicar_percentual_domingo == 1) {
			faixas.push("domingo")
		}
		if (data.multiplicar_percentual_folga == 1) {
			faixas.push("folga")
		}
		if (data.multiplicar_percentual_uteis == 1) {
			faixas.push("uteis")
		}
		if (data.multiplicar_percentual_feriado == 1) {
			faixas.push("feriado")
		}
		if (data.multiplicar_percentual_sabado == 1) {
			faixas.push("sabado")
		}


		this.form.setFieldsValue({
			descricao: data.descricao,
			is_active: data.is_active,
			tipo_controle_extra: data.tipo_controle_extra,
			controle_faltas: data.controle_faltas,
			controle_atraso: data.controle_atraso,
			nao_multiplicar_pelo_percentual: data.nao_multiplicar_pelo_percentual,
			extra_domingo: data.extra_domingo,
			extra_feriado: data.extra_feriado,
			extra_folga: data.extra_folga,
			extra_sabado: data.extra_sabado,
			extra_uteis: data.extra_uteis,
			companies: (data.companies && data.companies?.length > 0) ? data.companies.map((item) => item.id) : ["todos"],
			departments: (data.companies && data.departments?.length > 0) ? data.departments.map((item) => item.id) : ["todos"],
			limite_maximo_de_faltas: data.limite_maximo_de_faltas.substr(0, 5),
			limite_maximo_de_extras: data.limite_maximo_de_extras.substr(0, 5),
			faixas_de_horario: faixas,
		});

		this.setState({
			controle_faltas: data.controle_faltas,
			tipo_controle_extra: data.tipo_controle_extra,
			nao_multiplicar_pelo_percentual: data.nao_multiplicar_pelo_percentual,
		})

		this.onChangeChecked("", true);
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { uuid, isLoading, tracks, periods, companies, departments } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Banco de Horas`}>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} md={24}>
									<Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input disabled />
									</Form.Item>
								</Col>
								<Col xs={24} md={24}>
									<Form.Item name="is_active" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Switch disabled />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Períodos" key="periods">
							<Row gutter={16}>
								<Col xs={24}>
									<PeriodsTable showOnly={true} values={periods} ref={el => this.periodsTable = el} />
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Extras" key="extras">
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item name="tipo_controle_extra" label="Controle de extras:" >
										<Select
											optionFilterProp="children"
											allowClear
											disabled
											style={{ width: '100%' }}
											showSearch>
											<Select.Option key={"diario"} value="diario">Diário</Select.Option>
											<Select.Option key={"semanal"} value="semanal">Semanal</Select.Option>
											<Select.Option key={"mensal"} value="mensal">Mensal</Select.Option>
											<Select.Option key={"primeira_faixa_entrada"} value="primeira_faixa_entrada">Somente a primeira faixa de extra</Select.Option>
											<Select.Option key={"ultima_faixa_entrada"} value="ultima_faixa_entrada">Somente a última faixa de extra</Select.Option>
											<Select.Option key={"todas_horas_extras"} value="todas_horas_extras">Todas as faixas de horas extras</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item name="nao_multiplicar_pelo_percentual" label="Multiplicar pelo percentual" valuePropName="checked" >
										<Switch disabled />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								{(this.state.tipo_controle_extra == "diario" ||
									this.state.tipo_controle_extra == "semanal" ||
									this.state.tipo_controle_extra == "mensal"
								) && (
										<Col md={12}>
											<HourPickerWithMask disabled name="limite_maximo_de_extras" label="Limite máximo de extras:" required={true} />
										</Col>
									)}
								{this.state.nao_multiplicar_pelo_percentual == true
									&& (
										<Col md={12}>
											<Form.Item name="faixas_de_horario" label="Campos para multiplicar:">
												<Select
													mode="multiple"
													allowClear
													disabled
													placeholder="Selecione o(s) campo(s)"
													showSearch>
													<Select.Option value="domingo">Domingo</Select.Option>
													<Select.Option value="feriado">Feriado</Select.Option>
													<Select.Option value="folga">Folga</Select.Option>
													<Select.Option value="sabado">Sábado</Select.Option>
													<Select.Option value="uteis">Úteis</Select.Option>
												</Select>
											</Form.Item>
										</Col>
									)}
							</Row>
							<Row gutter={16} style={{ marginBottom: '20px' }}>
								<Col xs={24} md={12}>
									Ignorar faixa de extras:
								</Col>
							</Row>
							<Card>
								<Checkbox disabled style={{ marginBottom: '20px' }}>Selecionar todos</Checkbox>
								<Row gutter={16}>
									<Col xs={24} md={8}>
										<Form.Item name="extra_domingo" valuePropName="checked" >
											<Checkbox disabled  >Domingo</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<Form.Item name="extra_feriado" valuePropName="checked" >
											<Checkbox disabled >Feriado</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<Form.Item name="extra_folga" valuePropName="checked" >
											<Checkbox disabled >Folga</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<Form.Item style={{ marginBottom: '10px' }} name="extra_sabado" valuePropName="checked" >
											<Checkbox disabled >Sábado</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<Form.Item style={{ marginBottom: '10px' }} name="extra_uteis" valuePropName="checked" >
											<Checkbox disabled >Úteis</Checkbox>
										</Form.Item>
									</Col>
								</Row>
							</Card>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Faltas" key="faults">
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item name="controle_faltas" label="Controle de faltas:" >
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											disabled
											onChange={(value) => this.setState({ controle_faltas: value })}
											showSearch>
											<Select.Option key={"diario"} value="diario">Diário</Select.Option>
											<Select.Option key={"mensal"} value="mensal">Mensal</Select.Option>
											<Select.Option key={"nenhuma_hora_falta"} value="nenhuma_hora_falta">Nenhuma hora falta</Select.Option>
											<Select.Option key={"semanal"} value="semanal">Semanal</Select.Option>
											<Select.Option key={"todas_horas_faltas"} value="todas_horas_faltas">Todas as horas falta</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								{(this.state.controle_faltas != "nenhuma_hora_falta" && this.state.controle_faltas != "todas_horas_faltas") && (
									<Col md={12}>
										<HourPickerWithMask disabled={true} name="limite_maximo_de_faltas" label="Limite máximo de faltas:" required={true} />
									</Col>
								)}
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Atrasos" key="dalays">
							<Col xs={24} md={12}>
								<Form.Item name="controle_atraso" label="Controle de atrasos:" rules={[{ required: true, message: "Campo obrigatório." }]}>
									<Select
										ref={el => this.sexo = el}
										disabled
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										style={{ width: '100%' }}
										showSearch>
										<Select.Option key={"nenhuma_hora_atraso"} value="nenhuma_hora_atraso">Nenhuma hora atraso</Select.Option>
										<Select.Option key={"todas_horas_atraso"} value="todas_horas_atraso">Todas as horas atraso</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Empresas" key="companies">
							<Row>
								<Col xs={24}>
									<Form.Item name="companies" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											ref={el => this.empresas = el}
											mode="multiple"
											optionFilterProp="children"
											filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											disabled
											placeholder="Selecione a(s) empresa(s)"
											// notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											// onChange={this.onCompanyChange}
											// onSelect={this.onCompanySelect}
											showSearch>
											<Select.Option value="todos">Todas</Select.Option>
											{companies.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.nome}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24}>
									<Form.Item name="departments" label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											mode="multiple"
											optionFilterProp="children"
											filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											disabled
											placeholder="Selecione o(s) departamento(s)"
											// notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											// onSearch={this.fetchDepartments}
											// onChange={this.onDepartmentChange}
											// onSelect={this.onDepartmentSelect}
											showSearch>
											<Select.Option value="todos">Todos</Select.Option>
											{departments.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
