import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import { Col, Card, Checkbox, Form, Modal, Row, Spin, message } from 'antd'

import moment from 'moment'

import { API_ERRO_TYPE_CANCEL } from './../../../config/general'

import { columnsService } from './../../../redux/services'

const config = {
  columns: [
    {
      name: 'Colunas apuração',
      key: 'colunas-apuracao',
      columns: [
        {
          uuid: 'apuracao_normal',
          key: 'apuracao_normal',
          name: 'Normal',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_faltas',
          key: 'apuracao_faltas',
          name: 'Faltas',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_carga',
          key: 'apuracao_carga',
          name: 'Carga',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_atraso',
          key: 'apuracao_atraso',
          name: 'Atraso',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_falta_dia',
          key: 'apuracao_falta_dia',
          name: 'Falta/Dia',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_ajuste',
          key: 'apuracao_ajuste',
          name: 'Ajuste',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_dsr',
          key: 'apuracao_dsr',
          name: 'Dsr',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_dsr_debito',
          key: 'apuracao_dsr_debito',
          name: 'Dsr Déb.',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_adiconal_noturno',
          key: 'apuracao_adiconal_noturno',
          name: 'Adic. Noturno',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_abono',
          key: 'apuracao_abono',
          name: 'Abono',
          group: 'Colunas apuração',
        },
        {
          uuid: 'apuracao_extra',
          key: 'apuracao_extra',
          name: 'Extra',
          group: 'Colunas apuração',
        },
      ],
    },
    {
      name: 'Colunas extra',
      key: 'colunas-extra',
      columns: [
        {
          uuid: 'hora_extra_diurna',
          key: 'hora_extra_diurna',
          name: 'Hora extra diurna',
          group: 'Colunas extra',
        },
        {
          uuid: 'hora_extra_intervalo',
          key: 'hora_extra_intervalo',
          name: 'Hora extra intervalo',
          group: 'Colunas extra',
        },
        {
          uuid: 'hora_extra_noturna',
          key: 'hora_extra_noturna',
          name: 'Hora extra noturno',
          group: 'Colunas extra',
        },
      ],
    },
    {
      name: 'Colunas banco de horas',
      key: 'colunas-banco-de-horas',
      columns: [
        {
          uuid: 'banco_hora',
          key: 'banco_hora',
          name: 'Banco de horas',
          group: 'Colunas banco de horas',
        },
      ],
    },
  ],
}

class ModalColumns extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isSending: false,
      empresa_id: 0,
      columnsSelected: [],
      columnsCheckAll: [],
      columnsIndeterminate: [],
    }
  }

  onOpen = (empresa_id) => {
    this.setState({
      isLoading: true,
      empresa_id,
    })

    columnsService
      .show({ empresa_id })
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            data: response.data.data,
          },
          () => {
            this.formatColumns()
          }
        )
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })

        // Force close
        return this.onClose()
      })
  }

  formatColumns = () => {
    const { data } = this.state

    let activeColumns = Object.fromEntries(
      Object.entries(data[0]).filter(([key, value]) => value == 1)
    )
    let columnsSelected = Object.keys(activeColumns).filter((key) => {
      return (
        !key.startsWith('seq') && !key.endsWith('_at') && !key.endsWith('id')
      )
    })
    let columnsCheckAll = []
    let columnsIndeterminate = []

    config.columns.forEach((group) => {
      let all = true
      let indeterminate = false

      group.columns.forEach((column) => {
        if (!columnsSelected.includes(column.key)) {
          all = false
        } else {
          indeterminate = true
        }
      })

      if (all) {
        columnsCheckAll.push(group.key)
      } else if (indeterminate) {
        columnsIndeterminate.push(group.key)
      }
    })

    this.setState({
      columnsCheckAll,
      columnsIndeterminate,
      columnsSelected: columnsSelected,
    })
  }

  resetFields = () => {
    this.form.resetFields()

    this.setState({
      columnsSelected: [],
      columnsCheckAll: [],
      columnsIndeterminate: [],
    })
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const data = { ...values }

    holidaysService
      .edit(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        // Success message
        message.success('Registro atualizado com sucesso.')

        // Callback
        this.props.onComplete()
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onSubmit = () => {
    this.form
      .validateFields()
      .then((values) => {
        this.setState({
          isSending: true,
        })

        const { columnsSelected } = this.state

        let columnsSelectedFormatted = {}
        let i = 0

        config.columns.forEach((columnGroup) => {
          columnGroup.columns.forEach((column) => {
            columnsSelectedFormatted[column.key] = columnsSelected.includes(
              column.key
            )
              ? 1
              : 0
            columnsSelectedFormatted[`seq_${column.key}`] = i
            i++
          })
        })

        columnsService
          .edit({
            empresa_id: this.state.empresa_id,
            ...columnsSelectedFormatted,
          })
          .then((response) => {
            this.setState({
              isSending: false,
            })

            // Reset fields
            this.resetFields()

            // Success message
            message.success('Colunas atualizadas com sucesso.')

            // Callback
            this.props.onComplete()
          })
          .catch((data) => {
            this.setState({
              isSending: false,
            })

            Modal.error({
              title: 'Ocorreu um erro!',
              content: String(data),
            })
          })
      })
      .catch((data) => {
        //
      })
  }

  onChangeColumn = (uuid, groupKey, checked) => {
    const { columnsSelected, columnsCheckAll, columnsIndeterminate } =
      this.state
    const { columns } = config

    let newColumnsCheckAll = [...columnsCheckAll]
    let newColumnsIndeterminate = [...columnsIndeterminate]
    let newColumnsSelected = [...columnsSelected]
    const indexSelected = newColumnsSelected.indexOf(uuid)
    const groupIndex = columns.findIndex((item) => item.key === groupKey)
    const groupIndexCheckAll = newColumnsCheckAll.indexOf(groupKey)
    const groupIndexIndeterminate = newColumnsIndeterminate.indexOf(groupKey)

    if (checked) {
      if (indexSelected === -1) {
        newColumnsSelected.push(uuid)
      }
    } else {
      if (indexSelected !== -1) {
        newColumnsSelected.splice(indexSelected, 1)
      }
    }

    let groupTotal = columns[groupIndex].columns.length
    let groupTotalSelected = 0

    columns[groupIndex].columns.forEach((column) => {
      let columnIndex = newColumnsSelected.indexOf(column.uuid)

      if (columnIndex !== -1) {
        groupTotalSelected++
      }
    })

    if (groupTotal === groupTotalSelected) {
      if (groupIndexCheckAll === -1) {
        newColumnsCheckAll.push(groupKey)
      }

      if (groupIndexIndeterminate !== -1) {
        newColumnsIndeterminate.splice(groupIndexIndeterminate, 1)
      }
    } else {
      if (groupIndexCheckAll !== -1) {
        newColumnsCheckAll.splice(groupIndexCheckAll, 1)
      }

      if (groupTotalSelected > 0) {
        if (groupIndexIndeterminate === -1) {
          newColumnsIndeterminate.push(groupKey)
        }
      } else {
        if (groupIndexIndeterminate !== -1) {
          newColumnsIndeterminate.splice(groupIndexIndeterminate, 1)
        }
      }
    }

    this.setState({
      columnsSelected: newColumnsSelected,
      columnsCheckAll: newColumnsCheckAll,
      columnsIndeterminate: newColumnsIndeterminate,
    })
  }

  onChangeAllColumns = (key, checked) => {
    const { columnsSelected, columnsCheckAll, columnsIndeterminate } =
      this.state
    const { columns } = config

    let newColumnsCheckAll = [...columnsCheckAll]
    let newColumnsSelected = [...columnsSelected]
    let newColumnsIndeterminate = [...columnsIndeterminate]
    const indexCheckAll = newColumnsCheckAll.indexOf(key)
    const indexIndeterminate = newColumnsIndeterminate.indexOf(key)
    const groupIndex = columns.findIndex((item) => item.key === key)

    if (checked) {
      if (indexCheckAll === -1) {
        newColumnsCheckAll.push(key)
      }

      if (indexIndeterminate !== -1) {
        newColumnsIndeterminate.splice(indexIndeterminate, 1)
      }

      columns[groupIndex].columns.forEach((column) => {
        let columnIndex = newColumnsSelected.indexOf(column.uuid)

        if (columnIndex === -1) {
          newColumnsSelected.push(column.uuid)
        }
      })
    } else {
      if (indexCheckAll !== -1) {
        newColumnsCheckAll.splice(indexCheckAll, 1)
      }

      columns[groupIndex].columns.forEach((column) => {
        let columnIndex = newColumnsSelected.indexOf(column.uuid)

        if (columnIndex !== -1) {
          newColumnsSelected.splice(columnIndex, 1)
        }
      })
    }

    this.setState({
      columnsSelected: newColumnsSelected,
      columnsCheckAll: newColumnsCheckAll,
      columnsIndeterminate: newColumnsIndeterminate,
    })
  }

  render() {
    const { visible } = this.props

    const {
      isLoading,
      isSending,
      columnsSelected,
      columnsCheckAll,
      columnsIndeterminate,
    } = this.state

    return (
      <Modal
        visible={visible}
        title="Gerenciar colunas"
        wrapClassName="modal-scroll"
        destroyOnClose={true}
        maskClosable={false}
        width={580}
        okText={isSending ? 'Salvando' : 'Salvar'}
        cancelButtonProps={{ disabled: isSending }}
        onCancel={this.onClose}
        onOk={this.onSubmit}
        confirmLoading={isSending}
        keyboard={false}
        closable={!isSending}
        centered
      >
        <Spin
          spinning={isLoading || isSending}
          indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
        >
          <Form
            ref={(el) => (this.form = el)}
            layout="vertical"
            scrollToFirstError
            onFinish={this.onFinish}
          >
            <Row gutter={16}>
              <Col xs={24}>
                {config.columns.map((columnGroup, key) => (
                  <Card
                    key={key}
                    title={columnGroup.name}
                    style={{ marginBottom: 16 }}
                  >
                    <Checkbox
                      onChange={(e) =>
                        this.onChangeAllColumns(
                          columnGroup.key,
                          e.target.checked
                        )
                      }
                      indeterminate={
                        columnsIndeterminate.indexOf(columnGroup.key) !== -1
                      }
                      checked={columnsCheckAll.indexOf(columnGroup.key) !== -1}
                      style={{ marginBottom: 20 }}
                    >
                      Selecionar todos
                    </Checkbox>
                    <Row gutter={16}>
                      {columnGroup.columns.map((column, key_p) => (
                        <Col key={key_p} xs={24} sm={12}>
                          <div style={{ marginBottom: 5 }}>
                            <Checkbox
                              onChange={(e) =>
                                this.onChangeColumn(
                                  column.uuid,
                                  columnGroup.key,
                                  e.target.checked
                                )
                              }
                              checked={
                                columnsSelected.indexOf(column.uuid) !== -1
                              }
                            >
                              {column.name}
                            </Checkbox>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                ))}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

export default ModalColumns
