import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, Button, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { employeesContractsService, journeysService, companiesService, functionsService, departmentsService, annualLeaveService } from "../../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
} from "../../../components";

import ModalDepartmentCreate from "../departmentCreate";
import ModalFunctionsCreate from "../functionCreate";
import ModalJourneyShow from "../../Journeys/show";
import ModalAnnualLeaveShow from "../../AnnualLeave/show";
import ModalDepartmentsHistory from "../departmentsHistoryShow";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
		revenda_id: PropTypes.any,
		departmentsHistory: PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,

			selectedAnnualLeave: null,
			selectedJourney: null,
			selectedFunction: null,
			selectedDepartment: null,

			departmentsHistory: [],
			annualLeave: [],
			annualLeaveIsLoading: false,
			companies: [],
			companiesIsLoading: [],
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			functions: [],
			functionsIsLoading: false,
			journeys: [],
			journeysIsLoading: false,
		};

	}

	onOpen = () => {
		this.setState({
			isLoading: false,
			departmentsHistory: this.props.departmentsHistory ?? [],

			selectedAnnualLeave: null,
			selectedJourney: null,
			selectedFunction: null,
			selectedDepartment: null,
			selectedNewContractAnnualLeave: null,
			selectedNewContractJourney: null,
			selectedNewContractFunction: null,
			selectedNewContractDepartment: null,
		});

		setTimeout(() => {
			this.nome && this.nome.focus()

		}, 100)

	};

	/**
	 * Journey Show
	 *
	 * @param uuid
	 */
	journeyShowOpen = ({ uuid }) => {
		this.setState({ journeyShowModalVisible: true });

		// On open screen
		this.journeyShowScreen.onOpen(this.state.selectedJourney);
	};

	journeyShowOnClose = () => {
		this.setState({ journeyShowModalVisible: false });
	};

	/**
  * Departments History
  */

	departmentsHistoryShowOpen = () => {
		this.setState({ departmentsHistoryShowModalVisible: true });

		// On open screen
		this.departmentsHistoryShowScreen.onOpen(this.state.departmentsHistory);
	};

	departmentsHistoryShowOnClose = () => {
		this.setState({ departmentsHistoryShowModalVisible: false });
	};

	/**
	 * AnnualLeave Show
	 *
	 * @param uuid
	 */
	annualLeaveShowOpen = ({ uuid }) => {
		this.setState({ annualLeaveShowModalVisible: true });

		// On open screen
		this.annualLeaveShowScreen.onOpen(this.state.selectedAnnualLeave);
	};

	annualLeaveShowOnClose = () => {
		this.setState({ annualLeaveShowModalVisible: false });
	};

	/**
	  * Department Create
	  */
	departmentCreateOpen = (contract) => {
		this.setState({ departmentCreateModalVisible: true });

		// On open screen
		this.departmentCreateScreen.onOpen(contract ?? null);
	};

	departmentCreateOnClose = () => {
		this.setState({ departmentCreateModalVisible: false });
		this.departamento_id && this.departamento_id.focus();
	};

	departmentCreateOnComplete = (item, contract) => {
		this.setState({ departmentCreateModalVisible: false });

		this.departamento_id && this.departamento_id.focus();
		this.form.setFieldsValue({ departamento_id: item.id })
		this.setState({ selectedDepartment: item.nome })

	};

	/**
	  * Function Create
	  */
	functionCreateOpen = () => {
		this.setState({ functionCreateModalVisible: true });

		// On open screen
		this.functionCreateScreen.onOpen();
	};

	functionCreateOnClose = () => {
		this.setState({ functionCreateModalVisible: false });
		this.funcao_id && this.funcao_id.focus();
	};

	functionCreateOnComplete = (item) => {
		this.setState({ functionCreateModalVisible: false });

		this.funcao_id && this.funcao_id.focus();
		this.form.setFieldsValue({ funcao_id: item.id })
		this.setState({ selectedFunction: item.nome })
	};

	fetchCompanies = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				companiesIsLoading: false,
				companies: [],
			});

			return false;
		}

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			ativo: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchFunctions = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				functionsIsLoading: false,
				functions: [],
			});

			return false;
		}

		this.setState({
			functionsIsLoading: true,
		});

		functionsService.getAll({
			search: value,
			is_active: 1,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					functionsIsLoading: false,
					functions: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					functionsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchAnnualLeave = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				annualLeaveIsLoading: false,
				annualLeave: [],
			});

			return false;
		}

		this.setState({
			annualLeaveIsLoading: true,
		});

		annualLeaveService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					annualLeaveIsLoading: false,
					annualLeave: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					annualLeaveIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchJourneys = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value?.trim().length) {
			this.setState({
				journeysIsLoading: false,
				journeys: [],
			});

			return false;
		}

		this.setState({
			journeysIsLoading: true,
		});

		journeysService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					journeystenantsIsLoading: false,
					journeys: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					journeysIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = {
			...values
		}

		if (data.data_demissao) {
			data.data_demissao = data.data_demissao.format("YYYY-MM-DD");
		}

		if (data.data_admissao) {
			data.data_admissao = data.data_admissao.format("YYYY-MM-DD");
		}

		if (data.data_inicio_cartao) {
			data.data_inicio_cartao = data.data_inicio_cartao.format("YYYY-MM-DD");
		}

		if (!data.funcionario_id) {
			data.funcionario_id = this.props.client_uuid;
		}

		if (data.banco_horas_id) {
			data["vinculo[0][banco_horas_id]"] = data.banco_horas_id;
		}

		employeesContractsService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onDateLayOffChange = (value) => {
		this.handleSwitchArchive(value)
	}

	handleSwitchArchive = (value) => {
		const archive = !!value;
		this.form.setFieldValue('arquivar', archive);
		this.setState({
			arquivar: archive
		});
	}

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, annualLeave, annualLeaveIsLoading, companies, companiesIsLoading,
			departments, departmentsIsLoading, functions, functionsIsLoading, journeys, journeysIsLoading } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Novo Contrato">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						arquivar: false,
					}}>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item name="empresa_id" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Select
									filterOption={false}
									allowClear
									placeholder="Escolha a empresa"
									notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
									onSearch={this.fetchCompanies}
									showSearch
									onChange={(value) => this.form.setFieldsValue({ novo_contrato_empresa_id: value })}
								>
									{companies.map((item, index) => (
										<Select.Option key={index} value={item.id}>
											{item.nome}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item name="departamento_id" hasFeedback label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input.Group compact>
									{this.state.departmentsHistory.length <= 1 ? (
										<>
											<Select
												filterOption={false}
												allowClear
												ref={el => this.departamento_id = el}
												style={{ width: 'calc(100% - 50px)' }}
												placeholder="Escolha o departamento"
												notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchDepartments}
												showSearch
												onChange={(value) => {
													this.setState({ selectedDepartment: value })
													this.form.setFieldsValue({ departamento_id: value })
												}}
												value={this.state.selectedDepartment}
											>
												{departments.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
											<Button onClick={this.departmentCreateOpen} type="primary">+</Button>
										</>
									) : (
										<>
											<Select
												filterOption={false}
												allowClear
												ref={el => this.departamento_id = el}
												style={{ width: 'calc(100% - 98px)' }}
												placeholder="Escolha o departamento"
												notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchDepartments}
												showSearch
												onChange={(value) => {
													this.setState({ selectedDepartment: value })
													this.form.setFieldsValue({ departamento_id: value })
												}}
												value={this.state.selectedDepartment}
											>
												{departments.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
											<Button onClick={this.departmentCreateOpen} type="primary">+</Button>
											<Button onClick={this.departmentsHistoryShowOpen} type="primary">|||</Button>
										</>

									)}
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item name="funcao_id" label="Função" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input.Group compact>
									<Select
										filterOption={false}
										allowClear
										ref={el => this.funcao_id = el}
										style={{ width: 'calc(100% - 50px)' }}
										placeholder="Escolha a função"
										notFoundContent={functionsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onSearch={this.fetchFunctions}
										showSearch
										onChange={(value) => {
											this.setState({ selectedFunction: value })
											this.form.setFieldsValue({ funcao_id: value })
										}}
										value={this.state.selectedFunction}
									>
										{functions.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
									<Button onClick={this.functionCreateOpen} type="primary">+</Button>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item name="jornada_id" label="Horário" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input.Group compact>
									<Select
										filterOption={false}
										allowClear
										style={{ width: 'calc(100% - 50px)' }}
										placeholder="Escolha o horário"
										notFoundContent={journeysIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onSearch={this.fetchJourneys}
										onChange={(value) => {
											if (!value) {
												this.setState({ selectedJourney: null })
												this.form.setFieldsValue({ jornada_id: null })
											} else {
												this.setState({ selectedJourney: journeys.filter((journey) => journey.id == value)[0].uuid })
												this.form.setFieldsValue({ jornada_id: value })
											}
										}}
										showSearch
									>
										{journeys.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.descricao}
											</Select.Option>
										))}
									</Select>
									<Button disabled={!this.state.selectedJourney ? true : false} onClick={this.journeyShowOpen} type="primary">|||</Button>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item name="banco_horas_id" label="Banco de horas" hasFeedback >
								<Input.Group compact>
									<Select
										ref={el => this.banco_horas_id = el}
										filterOption={false}
										allowClear
										style={{ width: 'calc(100% - 50px)' }}
										placeholder="Escolha o banco de horas"
										notFoundContent={annualLeaveIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
										onSearch={this.fetchAnnualLeave}
										onChange={(value) => {
											if (!value) {
												this.setState({ selectedAnnualLeave: null })
												this.form.setFieldsValue({ banco_horas_id: null })
											} else {
												if (value != -1) {
													this.setState({ selectedAnnualLeave: annualLeave.filter((annual) => annual.id == value)[0].uuid })
												}

												this.form.setFieldsValue({ banco_horas_id: value })
											}
										}
										}
										showSearch
									>
										<Select.Option key={-1} value={-1}>
											Não vincular banco de horas
										</Select.Option>
										{annualLeave.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.descricao}
											</Select.Option>
										))}
									</Select>
									<Button disabled={!this.state.selectedAnnualLeave ? true : false} onClick={this.annualLeaveShowOpen} type="primary">|||</Button>
								</Input.Group>
							</Form.Item>
						</Col>
						<Col xs={12}>
							<DatePickerWithMask name="data_admissao" label="Data de admissão" required={true} />
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={12}>
							<DatePickerWithMask 
								name="data_demissao" 
								label="Data de Demissão"
								onChange={this.onDateLayOffChange}
							/>
						</Col>
						<Col xs={12}>
							<DatePickerWithMask name="data_inicio_cartao" label="Data Início Cartão Ponto" required={true} />
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24}>
							<Form.Item name="arquivar" label={<div className="inputInfo"><p>Arquivar</p><span>(Não mostrar o contrato no cartão ponto e relatórios)</span></div>} valuePropName="checked">
								<Switch ref={el => this.arquivar = el} onChange={(value) => this.setState({ arquivar: value })} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<ModalDepartmentCreate
					ref={el => this.departmentCreateScreen = el}
					visible={this.state.departmentCreateModalVisible}
					onComplete={this.departmentCreateOnComplete}
					onClose={this.departmentCreateOnClose}
				/>
				<ModalFunctionsCreate
					ref={el => this.functionCreateScreen = el}
					visible={this.state.functionCreateModalVisible}
					onComplete={this.functionCreateOnComplete}
					onClose={this.functionCreateOnClose}
				/>
				<ModalJourneyShow
					ref={el => this.journeyShowScreen = el}
					visible={this.state.journeyShowModalVisible}
					onClose={this.journeyShowOnClose}
				/>
				<ModalAnnualLeaveShow
					ref={el => this.annualLeaveShowScreen = el}
					visible={this.state.annualLeaveShowModalVisible}
					onClose={this.annualLeaveShowOnClose}
				/>
				<ModalDepartmentsHistory
					ref={el => this.departmentsHistoryShowScreen = el}
					visible={this.state.departmentsHistoryShowModalVisible}
					onClose={this.departmentsHistoryShowOnClose}
				/>
			</UIDrawerForm>
		)
	}
}

export default Create;
