import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs, TimePicker } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { dsrService, companiesService } from "../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	DatePickerWithMask,
	HourPickerWithMask,
	UIDrawerForm,
	UILabelHelp,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,
			tenantsIsLoading: false,
			tenants: [],
			tipo_pessoa: null,
		};
	}

	onOpen = () => {
		this.setState({
			tipo_pessoa: null,
		});

		setTimeout(() => {
			this.nome && this.nome.focus()

		}, 100)
	};

	fetchTenants = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				tenantsIsLoading: false,
				tenants: [],
			});

			return false;
		}

		this.setState({
			tenantsIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					tenantsIsLoading: false,
					tenants: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					tenantsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		dsrService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Dsr cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, nextOrder, tenantsIsLoading, tenants, tipo_pessoa } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Novo DSR">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						order: nextOrder,
						required: true,
						ativo: true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={8}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
								<Col md={8}>
									<HourPickerWithMask name="horas_falta_limite" label="Limite de horas falta" required={true} />
								</Col>

								<Col md={8}>
									<HourPickerWithMask name="horas_dsr" label="Valor DSR" required={true} />
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={24}>
									<Form.Item name="ativo" label="Ativo" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
