import React, { Component, Fragment } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Form, Input, message, Modal, Tabs, Row, Switch } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { dsrService, companiesService } from "./../../redux/services";

import { CheckIdDSR } from "../../utils/checkOvertimeAndDSRHasEmployee";

import {
	HourPickerWithMask,
	UIDrawerForm,
} from "./../../components";
import ModalRecalculate from './ModalRecalculate';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			resales: [],
			resalesIsLoading: false,
			ativo: null,
			formToCompare: {},
			data: [],
			id: 0,

			recalcularModalVisible: false,
		};

		this._axiosCancelToken = null;
	}

	fetchTenants = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				resalesIsLoading: false,
				resales: [],
			});

			return false;
		}

		this.setState({
			resalesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					resalesIsLoading: false,
					resales: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					resalesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		dsrService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				this.setState(({
					id: item?.id,
				}));

				let resales = [];


				this.setState({
					isLoading: false,
					resales: resales,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		const formValues = {
			nome: data.nome,
			horas_falta_limite: data.horas_falta_limite,
			horas_dsr: data.horas_dsr,
			ativo: data.ativo,
		};

		this.form.setFieldsValue(formValues);

		this.setState({
			ativo: data.ativo == 1 ? true : false,
			formToCompare: formValues,
		})

		// Upload
		if (data.avatar) {
			this.upload.setFiles([
				{
					uuid: data.uuid,
					url: data.avatar,
					type: 'image/jpeg',
				}
			]);
		}
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		if (!this.checkAnyFieldHasBeenChanged()) return;
		// const file = this.upload.getFiles();

		// if (file.hasError) {
		// 	Modal.error({
		// 		title: "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		// uuid
		data.uuid = uuid;

		// File
		// if (file.files.length) {
		// 	if (!file.files[0].uuid) {
		// 		data.avatar = file.files[0];
		// 	}
		// }
		// else {
		// 	data.avatar = null;
		// }

		// birth

		this.setState(({
			data: data,
		}));

		// Call recalculate modal
		const { id } = this.state;

		const hasEmployee = CheckIdDSR(id);
		const needToRecalculate = this.checkIfNeedToRecalculate();

		if (hasEmployee && needToRecalculate) {
			this.recalculateOpen(data);
			return;
		}
		
		dsrService.edit(data)
			.then(async (response) => {
				this.setState({
					isSending: false,
				});
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	checkAnyFieldHasBeenChanged = () => {
		const { formToCompare } = this.state;

		const fieldsModified = Object.keys(formToCompare).map(field => ({
			[field]: formToCompare[field] !== this.form.getFieldValue(field)
		}));

		const someFieldHasBeenChanged = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return value[fieldName];
		});

		return someFieldHasBeenChanged;
	}

	checkIfNeedToRecalculate = () => {
		const fieldHasNoNeedToCallRecalculate = [
			'nome'
		];

		const { formToCompare } = this.state;

		const fieldsModified = Object.keys(formToCompare).map(field => ({
			[field]: formToCompare[field] !== this.form.getFieldValue(field)
		}));

		const needToRecalculate = fieldsModified.some(value => {
			if (!value) {
				return;
			}

			const [fieldName] = Object.keys(value);
			return !fieldHasNoNeedToCallRecalculate.includes(fieldName) && value[fieldName];
		});

		return needToRecalculate;
	}


	recalculateOpen = ({ uuid }) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");

		// Callback
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {

			// Success message
			message.success("Cálculo realizado com sucesso.");

			// Callback
			this.props.onComplete();
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.props.onComplete();
	}

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, recalcularModalVisible } = this.state;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar DSR`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={8}>
										<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.nome = el} />
										</Form.Item>
									</Col>
									<Col md={8}>
										<HourPickerWithMask name="horas_falta_limite" label="Limite de horas falta" required={true} />
									</Col>
									<Col md={8}>
										<HourPickerWithMask name="horas_dsr" label="Valor DSR" required={true} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col sm={24}>
										<Form.Item name="ativo" label="Ativo" valuePropName="checked">
											<Switch />
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
				/>
			</Fragment>
		)
	}
}

export default Edit;
