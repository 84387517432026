import { employeesService } from "../redux/services";

export const CheckIdOvertime = async (id) => {
  try {
    const response = await employeesService.getAll();
    const employees = response.data.data;

    for (const employee of employees) {
      const contracts = employee.contrato;

      for (const contract of contracts) {
        const overtimeId = contract.jornada.hora_extra_id;

        if (overtimeId === id) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const CheckIdDSR = async (id) => {
  try {
    const response = await employeesService.getAll();
    const employees = response.data.data;

    for (const employee of employees) {
      const contracts = employee.contrato;

      for (const contract of contracts) {
        const dsrId = contract.jornada.dsr_id;

        if (dsrId === id) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
