import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Input, Modal, Row, Col, Form, Select, message } from "antd";
import MaskedInput from 'react-text-mask'

import { timeCardService } from "./../../../redux/services";

import * as floatbox from "./../../../helpers/floatbox";

import moment from "moment";

import {
	DatePickerWithMask,
} from "./../../../components";

class ModalDiscard extends Component {
	static propTypes = {
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			visible      : false,
			isSending    : false,
			item         : null,
			value        : '',
			itemIndex    : 0,
			batidaIndex  : -1,
			sequence     : 0,
			type         : '',
			initialValue : '',
			deletePressed: false,
		}
	}

	onOpen = (item, value, itemIndex, batidaIndex, sequence, type, motivo, initialValue, deletePressed, softDelete = false) => {
		this.setState({
			isSending: softDelete,
			visible: !softDelete,
			item,
			value,
			itemIndex,
			batidaIndex,
			sequence,
			type,
			motivo,
			initialValue,
			deletePressed,
		});

		if(softDelete) {
			this.softDelete();

			return false;
		}

		setTimeout(() => {
			this.form.setFieldsValue({
				data   : moment(item.data),
				horario: initialValue,
				motivo : motivo,
			});
		}, 100);
	}

	onClose = () => {
		this.setState({
			visible: false,
		});

		// Hide all float box
		floatbox.hideAll();

		// Reset input
		floatbox.resetInput(this.state.item.id, this.state.itemIndex, this.state.sequence, this.state.type);

		// Reset fields
		this.resetFields();

		// Callback
		if( typeof this.props.onClose !== 'undefined' )
		{
			this.props.onClose();
		}
	};

	resetFields = () => {
		this.setState({
			item         : null,
			value        : '',
			itemIndex    : 0,
			batidaIndex  : -1,
			sequence     : 0,
			type         : '',
			deletePressed: false,
		});
	};

	softDelete = () => {
		const {item, itemIndex, batidaIndex, type, sequence, motivo} = this.state;

		const data = {
			data: moment(item.data).format('YYYY-MM-DD'),
			cartao_ponto_id: item.id,
			cartao_ponto_batida_id: item.cartaopontobatida[batidaIndex]?.id,
			sequencia: sequence,
			motivo: motivo,
		}

		if(type === 'entrada') {
			data.ponto_registro_entrada_id = item.cartaopontobatida[batidaIndex]?.ponto_registro_entrada_id;
			data.ponto_ajuste_entrada_id = item.cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada_id;
		} else {
			data.ponto_registro_saida_id = item.cartaopontobatida[batidaIndex]?.ponto_registro_saida_id;
			data.ponto_ajuste_saida_id = item.cartaopontobatida[batidaIndex]?.ponto_ajuste_saida_id;
		}

		let value = this.state.value;
		let deletePressed = this.state.deletePressed;

		timeCardService.batidaHorarioDiscard(data)
		.then((response) => {
			this.setState({
				visible  : false,
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success('Batida manual do cartão ponto excluída com sucesso!');

			// Callback
			this.props.onComplete(item.id, itemIndex, sequence, type, motivo, value, this.state.batidaIndex, deletePressed);
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});
			
			this.resetFields();
			this.props.onComplete(item.id, itemIndex, sequence, type, motivo, value, this.state.batidaIndex, deletePressed);
			
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			const {item, itemIndex, batidaIndex, type, sequence} = this.state;

			const data = {
				data: moment(values.data).format('YYYY-MM-DD'),
				cartao_ponto_id: item.id,
				cartao_ponto_batida_id: item.cartaopontobatida[batidaIndex]?.id,
				sequencia: sequence,
				motivo: values.motivo,
			}

			if(type === 'entrada') {
				data.ponto_registro_entrada_id = item.cartaopontobatida[batidaIndex]?.ponto_registro_entrada_id;
				data.ponto_ajuste_entrada_id = item.cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada_id;
			} else {
				data.ponto_registro_saida_id = item.cartaopontobatida[batidaIndex]?.ponto_registro_saida_id;
				data.ponto_ajuste_saida_id = item.cartaopontobatida[batidaIndex]?.ponto_ajuste_saida_id;
			}

			timeCardService.batidaHorarioDiscard(data)
			.then((response) => {
				this.setState({
					visible  : false,
					isSending: false,
				});

				let value = this.state.value;
				let deletePressed = this.state.deletePressed;

				// Reset fields
				this.resetFields();

				// Success message
				message.success('Batida manual do cartão ponto excluída com sucesso!');

				// Callback
				this.props.onComplete(item.id, itemIndex, sequence, type, values.motivo, value, this.state.batidaIndex, deletePressed);
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
		});
	}

	render() {
		const {visible, isSending, item, type, sequence} = this.state;

		return (
			<Modal
				visible={visible}
				title={`Justificar o descarte da ${type === 'entrada' ? 'entrada' : 'saída'} ${sequence} [${item?.data ? moment(item.data).format('DD/MM/YYYY') : ''}]`}
				destroyOnClose
				width={450}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{disabled: isSending}}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={false}
				closable={!isSending}
				centered
				focusTriggerAfterClose={false}>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<DatePickerWithMask onKeyUp={(e) => {
								if (e.keyCode === 13 || e.keyCode === 9) {
									this.motivo.focus()
								}
							}} label="Data" name="data" required={true} disableDateBefore={true} customDisableDate={item?.data} />
						</Col>
						<Col xs={24} sm={12}>
							<Form.Item label="Horário" name="horario" rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									className="ant-input"
									mask={[/\d/, /\d/, ':', /\d/, /\d/]}
									disabled
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item label="Motivo" name="motivo" rules={[{required: true, message: "Campo obrigatório."}]}>
						<Input
							ref={el => el && el.focus()}
							disabled={isSending}
							onKeyUp={(e) => {
								if (e.key === 'Enter') {
									this.onSubmit();
								}
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default ModalDiscard;
