import React, { Component, Fragment } from "react";
import { Col, Modal, Spin, Row, Empty, Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { reportService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			dataInicial: "",
			dataFinal: "",
			data: [],
			tableData: [],

			columnsList: [],

			dataDia: "",
			empresa_nome: "",
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print", "page-print-banco-de-horas");
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print", "page-print-banco-de-horas");
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = new URLSearchParams(this.props.location.search);

		const data = {};

		if (params.has("empresas")) {
			if (params.get("empresas") !== "todos") {
				data.empresas = params.get("empresas").split(",");
			}
		} else {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Empresa não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					// window.close();
				},
			});
			return false;
		}

		if (params.has("departamentos")) {
			if (params.get("departamentos") !== "todos") {
				data.departamentos = params.get("departamentos").split(",");
			}
		}

		if (params.has("funcionarios")) {
			if (params.get("funcionarios") !== "todos") {
				data.funcionarios = params.get("funcionarios").split(",");
			}
		}

		if (params.has("data_admissao_inicial")) {
			data.data_admissao_inicial = params.get("data_admissao_inicial");

			this.setState({
				dataInicial: moment(params.get("data_admissao_inicial")).format("DD/MM/YYYY"),
			})
		} else {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Data inicial não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					// window.close();
				},
			});
			return false;
		}

		if (params.has("data_admissao_final")) {
			data.data_admissao_final = params.get("data_admissao_final");
			this.setState({
				dataFinal: moment(params.get("data_admissao_final")).format("DD/MM/YYYY"),
			})
		} else {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Data final não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					// window.close();
				},
			});
			return false;
		}

		reportService
			.bankStatementHours(data)
			.then((response) => {
				this.setState({
					isLoading: false,
					data: response.data[0],
					tableData: response.data
				}, () => {
          setTimeout(() => {
            // Print
            document.title = 'Extrato de Banco de Horas';

						window.print();
						// window.close();
          }, 1000);
        })
      })
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {

		const {
			isLoading,
			data,
			dataInicial,
			dataFinal,
			tableData,
		} = this.state;

		const { user } = this.props;
		const { name: userName } = user || {};

		const style = `
			@page {
				margin:0;
				size: A4;
			}
		`;
		
		const taxVar = data?.empresa?.cnpj ? 'CNPJ' : 'CPF';
		const taxValue = data?.empresa?.cnpj || data?.empresa?.cpf;
		const { data_admissao } = data?.funcionario?.contrato || {};
		const formattedDate = moment(data_admissao).format('DD/MM/YYYY');

		return (
			<Fragment>
				{isLoading ? (
					<div
						style={{
							position: "fixed",
							top: "150px",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spin
							spinning={isLoading}
							indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
						/>
					</div>
				) : (
					data ? (
						<div>
							{tableData.map((value, index) => (
								<>
									<div className="hour-bank">
										<div style={{ width: '100%' }}>
											<header className="header page-break" key={index}>
												<div className="logo-container">
													<img
														src="/images/logos/logo-complete.svg"
														alt="ByPonto"
														width="200"
													/>
													<div className="logo-title" style={{ float: 'right' }}>
														<div>
															<h1>Extrato do Banco de Horas</h1>
														</div>
														<div>
															<div>Período:{" "}De {dataInicial} até {dataFinal}</div>															
														</div>
														<div className="user-info">
															<h4>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h4>
														</div>
													</div>
												</div>
											</header>
										</div>
									</div>

									<div style={{ width: '50%' }}>
										<table className="table-impressao">
											<tbody className="table-impressao-interna text-center">
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>Empresa:</strong>{" "}{value.empresa?.nome ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>{taxVar}:</strong>{" "}{taxValue ?? "-"}</p>
													</td>
													<td className="text-left w50">
														<p className="subtitle"><strong>IE:</strong>{" "}{value.empresa?.ie ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>Endereço:</strong>{" "}{value.empresa?.logradouro ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>Nome:</strong>{" "}{value.funcionario?.nome ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>N° Folha:</strong>{" "}{value.funcionario?.numero_folha ?? "-"}</p>
													</td>
													<td className="text-left w50">
														<p className="subtitle"><strong>Pis/Pasep:</strong>{" "}{value.funcionario?.pis_pasep ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>Ctps:</strong>{" "}{value.funcionario?.ctps ?? "-"}</p>
													</td>
													<td className="text-left w50">
														<p className="subtitle"><strong>Data Admissão:</strong>{" "}{formattedDate ?? "-"}</p>
													</td>
												</tr>
												<tr className="row-section">
													<td className="text-left w50">
														<p className="subtitle"><strong>Função:</strong>{" "}{value.funcionario?.funcao?.nome ?? "-"}</p>
													</td>
													<td className="text-left w50">
														<p className="subtitle"><strong>Departamento:</strong>{" "}{value.departamento?.nome ?? "-"}</p>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>
														<div className="footer-space">&nbsp;</div>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>

									<div>
										<table className="table-impressao-interna table-impressao-interna-color text-left">
											<tbody>
												<tr className="table-list-header">
													<td width="170">Período</td>
													<td width="80">Crédito</td>
													<td width="80">Débito</td>
													<td width="80">Saldo</td>
													<td width="auto">Ajuste</td>
												</tr>
												<tr>
													<td className="bold">Saldo Anterior</td>
													<td></td>
													<td></td>
													<td className="bold">{value.saldo_anterior}</td>
													<td></td>
												</tr>
												<tr>
													<td>{value.periodo}</td>
													<td>{value.horas_extras}</td>
													<td>{value.horas_falta}</td>
													<td>{value.saldo}</td>
													<td>{value.ajuste}</td>
												</tr>
												<tr>
													<td className="bold">Total:</td>
													<td className="bold">{value.horas_extras}</td>
													<td className="bold">{value.horas_falta}</td>
													<td className="bold">{value.saldo_total}</td>
													<td className="bold">{value.ajuste}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<Row className="assinaturas" style={{ marginTop: '100px' }}>
										<Col xs={12}>
											<div>{value?.funcionario?.nome}</div>
										</Col>
										<Col xs={12}>
											<div>{value?.empresa?.nome}</div>
										</Col>
									</Row>
								</>
							))}
						</div>
					) : (
						<Empty description="Nenhum registro encontrado com esses parâmetros." style={{ padding: "20px", position: "absolute", top: "20px", left: "50%", transform: "translateX(-50%)" }}>
							<Button type="primary" onClick={() => window.close()}>Voltar</Button>
						</Empty>
					)
				)}
				<style dangerouslySetInnerHTML={{ __html: style }} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
