import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Button, Form, InputNumber,  Modal, Spin, Radio, Select } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { webserviceService, departmentsService, companiesService } from "./../../redux/services";

class Filters extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.filtersClean = {
			is_active: null,
			cidade_id: null,
			pis_pasep: null,
			numero_folha: null,
			numero_identificador: null,
			departamento_id: null,
			empresa_id: null,
		};

		this.state = {
			filters: {
				...this.filtersClean,
			},
			citiesIsLoading: false,
			cities: [],
			companiesIsLoading: false,
			companies: [],
			departmentsIsLoading: false,
			departments: [],
		};
	}

	fetchCities = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				citiesIsLoading: false,
				cities: [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					citiesIsLoading: false,
					cities: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					citiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchCompanies = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				companiesIsLoading: false,
				companies: [],
			});

			return false;
		}

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		departmentsService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (filters) => {
		this.setState({
			filters: filters,
		});
	};

	cleanFilters = () => {
		this.setState({
			filters: this.filtersClean,
		}, () => {
			// Callback
			this.props.onComplete({ ...this.state.filters });
		});
	};

	onClose = () => {
		// Callback
		this.props.onClose();
	};

	filtersOnConfirm = () => {
		// Callback
		this.props.onComplete({ ...this.state.filters });
	};

	setFilter = (name, value) => {
		this.setState(state => ({
			filters: {
				...state.filters,
				[name]: value,
			}
		}));
	};

	render() {
		const { visible } = this.props;

		const { filters, citiesIsLoading, cities, companies, companiesIsLoading, departments, departmentsIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title="Filtrar"
				centered={true}
				destroyOnClose={true}
				maskClosable={true}
				width={900}
				okText="Aplicar"
				onCancel={this.onClose}
				onOk={this.filtersOnConfirm}
				className="modal-filters"
				footer={[
					<Button key="back" type="link" onClick={this.cleanFilters}>Excluir filtros</Button>,
					<Button key="submit" type="primary" onClick={this.filtersOnConfirm}>Aplicar</Button>,
				]}>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>PIS/PASEP</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<InputNumber type="number" value={this.state.filters.pis_pasep} onChange={(value) => this.setFilter("pis_pasep", value)} />
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>N° de Folha</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<InputNumber type="number" value={this.state.filters.numero_folha} onChange={(value) => this.setFilter("numero_folha", value)} />
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>Identificador</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<InputNumber type="number" value={this.state.filters.numero_identificador} onChange={(value) => this.setFilter("numero_identificador", value)} />
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>Cidade</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<Select
								allowClear
								filterOption={false}
								notFoundContent={citiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
								onChange={(value) => this.setFilter("cidade_id", value ?? null)}
								onSearch={this.fetchCities}
								placeholder="Pesquise a cidade"
								showSearch
								value={this.state.filters.cidade_id}>
								{cities.map((item, index) => (
									<Select.Option key={index} value={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>Empresa</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<Select
								filterOption={false}
								allowClear
								placeholder="Pesquise a empresa"
								notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
								onSearch={this.fetchCompanies}
								onChange={(value) => this.setFilter("empresa_id", value ?? null)}
								showSearch
								value={this.state.filters.empresa_id}>
								{companies.map((item, index) => (
									<Select.Option key={index} value={item.id}>
										{item.nome}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title">
						<h3>Departamento</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 0 }}>
						<Form.Item>
							<Select
								filterOption={false}
								allowClear
								placeholder="Pesquise o departamento"
								notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
								onSearch={this.fetchDepartments}
								onChange={(value) => this.setFilter("departamento_id", value ?? null)}
								showSearch
								value={this.state.filters.departamento_id}>
								{departments.map((item, index) => (
									<Select.Option key={index} value={item.id}>
										{item.nome}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className="filter-group">
					<div className="filter-group-title" style={{ paddingTop: 0 }}>
						<h3>Status</h3>
					</div>
					<div className="filter-group-filters" style={{ paddingBottom: 5 }}>
						<div className="filter-group-radios">
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("is_active", null)} checked={filters.is_active === null}>Todos</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("is_active", 1)} checked={filters.is_active === 1}>Ativo</Radio>
							</div>
							<div className="filter-group-radio">
								<Radio onChange={(e) => this.setFilter("is_active", 0)} checked={filters.is_active === 0}>Inativo</Radio>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default Filters;
