import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BackTop, Drawer, Layout, Alert, Space } from "antd";
import moment from "moment";

import enquire from "enquire.js";

const {Content, Header, Sider} = Layout;

import {
	generalActions,
} from "./../redux/actions";

import MainNavigation from "./../navigations/mainNavigation";
import UserDropdownNavigation from "./../navigations/userDropdownNavigation";

import { withRouter } from "react-router-dom";
import CompanyDropdown from "../components/CompanyDropdown";

class DefaultTemplate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			siderBarDrawer: false,
			isEqualOrLassThanThiryDays: false,
			remainingDays: 0
		};

		this.mdSideBarOn  = "screen and (max-width: 1199px)";
		this.mdSideBarOff = "screen and (min-width: 1200px)";
	}

	componentDidMount() {
		enquire.register(this.mdSideBarOn, () => {
			this.setState({
				siderBarDrawer: true,
			})
		});

		enquire.register(this.mdSideBarOff, () => {
			this.setState({
				siderBarDrawer: false,
			})
		});

		this.checkHasThirtyDaysOrLess();
	};

	checkHasThirtyDaysOrLess() {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));
		
		if (!companyData) return;

		const dueDate = companyData.vencimento;
		const today = new Date();

		const diffDays = moment(dueDate).diff(today, 'days');
	
		if (diffDays <= 30) {
			this.setState({
				isEqualOrLassThanThiryDays: true,
				remainingDays: diffDays
			})
		}
	}

	componentWillUnmount() {
		enquire.unregister(this.mdSideBarOn);
		enquire.unregister(this.mdSideBarOff);
	};

	toggle = () => {
		this.props.siderToggle(!this.props.siderCollapsed);
	};

	

	render() {
		const { siderBarDrawer, isEqualOrLassThanThiryDays, remainingDays } = this.state;
		const {siderCollapsed} = this.props;

		const siderWidth = siderBarDrawer ? 0 : (siderCollapsed ? 80 : 256);

		const menuDisabled = this.props.location.pathname.startsWith("/impressao");

		return (
			<Fragment>
				{!menuDisabled ? (

					<Layout className="template-default">
						{!menuDisabled && (
							siderBarDrawer ? (
								<Drawer
									placement="left"
									closable={false}
									onClose={this.toggle}
									open={!siderCollapsed}
									className="template-default-ant-drawer site-menu">
									<div className="logo">
										<img src="/images/logos/byPonto-logocomplete.png" alt="byponto-logo" width={200} />
									</div>
									<MainNavigation
										onClick={this.toggle}
									/>
								</Drawer>
							) : (
								<Sider
									theme="dark"
									trigger={null}
									collapsible={true}
									collapsed={siderCollapsed}
									breakpoint="lg"
									width={265}
									collapsedWidth={80}
									className="site-menu">
									<div className="logo" >
										<img style={{ width: '75%' }} src="/images/logos/byPonto-logocomplete.png" alt="byponto-logo" width={150} />
										<img src="/images/logos/logo-byponto.svg" className="logo-icon" alt="" />
									</div>
									<MainNavigation />
								</Sider>
							)
						)}
						<Layout style={{paddingLeft: !menuDisabled ? siderWidth : 0}}>
							{!menuDisabled && (
								<Header key="1" className="site-header" style={{left: siderWidth}}>
									<i className={`trigger ${siderCollapsed ? "fad fa-indent" : "fad fa-outdent"}`} onClick={this.toggle} />
									<div className="inner">
										<CompanyDropdown />
										<UserDropdownNavigation />
									</div>
									{
										isEqualOrLassThanThiryDays && (
											<Alert message={`Faltam ${ remainingDays } dias para o vencimento da sua assinatura. Entre em contato com seu revendedor para realizar a renovação.`} type="warning" />
										)
									}
								</Header>
							)}
							<Content className="site-content">
								{this.props.children}
							</Content>
							<BackTop />
						</Layout>
					</Layout>
				) : (
					// Impressão
					<Fragment>
						{this.props.children}
					</Fragment>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		siderCollapsed: state.general.siderCollapsed,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		siderToggle: (collapsed) => {
			dispatch(generalActions.siderToggle(collapsed));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultTemplate));
