import React from 'react'
import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import { DatePickerWithMask } from '../../../../components'

const ADV = ({ communicationType, handleClearDateTime, handleClearTimeZones }) => {
  const [habilitarConexaoSegura, setHabilitarConexaoSegura] =
    React.useState(false)
  const [atualizar, setAtualizar] = React.useState(false)
  const [habilitarHorarioVerao, setHabilitarHorarioVerao] =
    React.useState(false)

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="numero_serial_rep"
              label="Número de série"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Número de série" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="ultimo_nsr"
              label="Último NSR"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Último NSR"
                min="1"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[ip]"
              label="IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="IP" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[porta_tcp_ip]"
              label="Porta TCP/IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Porta TCP/IP"
                min="1"
                style={{ width: '100%' }}
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[gateway]"
              label="Gateway"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Gateway" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[tipo_documento]"
              label="Tipo de comunicação"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                placeholder="Selecione o tipo de comunicação"
              >
                <Select.Option value="cpf">CPF</Select.Option>
                <Select.Option value="pis">PIS/PASEP</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[formato_cpf]"
              label="Formato CPF"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                placeholder="Selecione o tipo de formato do CPF"
              >
                <Select.Option value="11_digitos">11 Digitos</Select.Option>
                <Select.Option value="12_digitos">12 Digitos</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[tipo_corte]"
              label="Tipo de corte do papel"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Select
                placeholder="Selecione o tipo de corte do papel"
              >
                <Select.Option value="total">Total</Select.Option>
                <Select.Option value="parcial">Parcial</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[mascara_sub_rede]"
              label="Máscara de sub rede"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Máscara de sub rede" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[modo_tcp_ip]"
              label="Modo TCP/IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Selecione o tipo de comunicação"
                showSearch
                disabled={communicationType !== 'pendrive' ? false : true}
              >
                <Select.Option value="servidor">Servidor</Select.Option>
                <Select.Option value="cliente">Cliente</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[ip_servidor]"
              label="IP do servidor"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="IP do servidor" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[dns]"
              label="DNS"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="DNS" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[mac]"
              label="MAC"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="MAC" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="configuracao_adv[desativar_icmp]"
          label="Desativar verificação ICMP neste equipamento"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="configuracao_adv[gravar_comunicacao_log]"
          label="Gravar fluxo de comunicação em arquivo log"
        >
          <Switch />
        </Form.Item>
      </Card>
      <Card title="Configurações de reconexão">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_adv[quantidade_tentativa]"
              label="Quantidade de tentativas"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Quantidade de tentativas" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_adv[reconectar_apos_tentativa]"
              label="Reconectar após tentativa"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Reconectar após tentativa" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_adv[tempo_entre_tentativa]"
              label="Tempo entre tentativas"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input type='number' placeholder="Tempo entre tentativas" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Segurança">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[habilitar_conexao_segura]"
              label="Habilitar conexão segura"
            >
              <Switch
                value={habilitarConexaoSegura}
                onChange={() => setHabilitarConexaoSegura(!habilitarConexaoSegura)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[ip_conexao_segura]"
              label="IP da conexão segura"
            >
              <Input
                placeholder="IP da conexão segura"
                disabled={!habilitarConexaoSegura}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Autenticação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[usuario]"
              label="Usuário"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Usuário" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[senha]"
              label="Senha"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input type="password" placeholder="Senha" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Leitora">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_adv[tipo_leitora]"
              label="Tipo de leitora"
            >
              <Input placeholder="Tipo de leitora" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_adv[modulo_biometrico]"
              label="Módulo biométrico"
            >
              <Input placeholder="Módulo biométrico" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Outros">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[atualizar_data_hora]"
              label="Atualizar data e hora do relógio"
              valuePropName="checked"
            >
              <Switch
                value={atualizar}
                onChange={() => {
                  setAtualizar(!atualizar)
                  handleClearDateTime()
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Data e hora"
              name="configuracao_adv[data_hora]"
              showTime={true}
              disabled={!atualizar}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_adv[fuso_horario]"
              label="Fuso horário"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Selecione o fuso horário"
                showSearch
              >
                <Select.Option value="+00:00">GMT</Select.Option>
                <Select.Option value="-01:00">GMT-1</Select.Option>
                <Select.Option value="-02:00">
                  GMT-2 Horário de Fernando de Noronha
                </Select.Option>
                <Select.Option value="-03:00">
                  GMT-3 Horário de Brasília
                </Select.Option>
                <Select.Option value="-04:00">
                  GMT-4 Horário da Amazônia
                </Select.Option>
                <Select.Option value="-05:00">GMT-5 Horário do Acre</Select.Option>
                <Select.Option value="-06:00">GMT-6</Select.Option>
                <Select.Option value="-07:00">GMT-7</Select.Option>
                <Select.Option value="-08:00">GMT-8</Select.Option>
                <Select.Option value="-09:00">GMT-9</Select.Option>
                <Select.Option value="-10:00">GMT-10</Select.Option>
                <Select.Option value="-11:00">GMT-11</Select.Option>
                <Select.Option value="-12:00">GMT-12</Select.Option>
                <Select.Option value="+01:00">GMT+1</Select.Option>
                <Select.Option value="+02:00">GMT+2</Select.Option>
                <Select.Option value="+03:00">GMT+3</Select.Option>
                <Select.Option value="+04:00">GMT+4</Select.Option>
                <Select.Option value="+05:00">GMT+5</Select.Option>
                <Select.Option value="+06:00">GMT+6</Select.Option>
                <Select.Option value="+07:00">GMT+7</Select.Option>
                <Select.Option value="+08:00">GMT+8</Select.Option>
                <Select.Option value="+09:00">GMT+9</Select.Option>
                <Select.Option value="+10:00">GMT+10</Select.Option>
                <Select.Option value="+11:00">GMT+11</Select.Option>
                <Select.Option value="+12:00">GMT+12</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Horário de verão">
        <Form.Item
          name="configuracao_adv[habilitar_horario_verao]"
          label="Habilitar horário de verão"
          valuePropName="checked"
        >
          <Switch
            value={habilitarHorarioVerao}
            onChange={() => {
              setHabilitarHorarioVerao(!habilitarHorarioVerao)
              handleClearTimeZones()
            }}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Início do horário de verão"
              name="configuracao_adv[inicio_horario_verao]"
              disabled={!habilitarHorarioVerao}
              disableDateBefore={true}
              customDisable="day+1"
            />
          </Col>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Fim do horário de verão"
              name="configuracao_adv[fim_horario_verao]"
              disabled={!habilitarHorarioVerao}
              disableDateBefore={true}
              customDisable="day+1"
            />
          </Col>
        </Row>
      </Card>
    </Space>
  )
}

export default ADV
