import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin, Switch, message } from 'antd'
import { DatePickerWithMask } from '../../components'
import QueueAnim from 'rc-queue-anim'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import { API_ERRO_TYPE_CANCEL } from '../../config/general'

import {
	companiesService,
	departmentsService,
	employeesService,
	journeysService,
	reportService,
	justificationsService,
} from '../../redux/services'

class Index extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isSending: false,
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: [],
			employees: [],
			employeesIsLoading: false,
			journeys: [],
			journeysIsLoading: false,
			justifications: [],
			justificationsIsLoading: false,
			dateError: false,
			includeMissedHoursAbsence: false,
			includeInactiveEmployees: false,
			includePartialJustifications: false,
			selectedGroupBy: "1",
		}

		this._axiosCancelCompaniesToken = null
		this._axiosCancelDepartmentsToken = null
		this._axiosCancelEmployeesToken = null
		this._axiosCancelJourneysToken = null
		this._axiosCancelJustificationsToken = null
	}

	fetchCompanies = () => {
		if( this._axiosCancelCompaniesToken )
		{
			this._axiosCancelCompaniesToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source()

		this.setState({
			companiesIsLoading: true,
		})

		companiesService.getAutocomplete({
			orderBy    : 'nome:asc',
			ativo      : 1,
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
		.then((response) => {
			this.setState({
				companiesIsLoading: false,
				companies         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				companiesIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchDepartments = (value) => {
		if( this._axiosCancelDepartmentsToken )
		{
			this._axiosCancelDepartmentsToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				departmentsIsLoading: false,
				departments         : [],
			})

			return false
		}

		this.setState({
			departmentsIsLoading: true,
		})

		let params = {
			search      : value,
			is_active   : 1,
			orderBy     : 'nome:asc',
			cancelToken : this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue('empresas').includes('todos') ? [] : this.form.getFieldValue('empresas'),
		}

		departmentsService.getAutocomplete(params)
		.then((response) => {
			this.setState({
				departmentsIsLoading: false,
				departments         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				departmentsIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchEmployees = (value) => {
		if( this._axiosCancelEmployeesToken )
		{
			this._axiosCancelEmployeesToken.cancel(
				'Only one request allowed at a time.'
			)
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				employeesIsLoading: false,
				employees         : [],
			})

			return false
		}

		this.setState({
			employeesIsLoading: true,
		})

		let params = {
			search           : value,
			ativo            : 1,
			orderBy          : 'nome:asc',
			cancelToken      : this._axiosCancelEmployeesToken.token,
			empresas_ids     : this.form.getFieldValue('empresas').includes('todos') ? [] : this.form.getFieldValue('empresas'),
			departamentos_ids: this.form.getFieldValue('departamentos').includes('todos') ? [] : this.form.getFieldValue('departamentos'),
		}

		employeesService.getAutocomplete(params)
		.then((response) => {
			this.setState({
				employeesIsLoading: false,
				employees         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				employeesIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchJourneys = (value) => {
		if( this._axiosCancelJourneysToken )
		{
			this._axiosCancelJourneysToken.cancel(
				'Only one request allowed at a time.'
			)
		}

		this._axiosCancelJourneysToken = axios.CancelToken.source()

		if( !value.trim().length )
		{
			this.setState({
				journeysIsLoading: false,
				journeys         : [],
			})

			return false
		}

		this.setState({
			journeysIsLoading: true,
		})

		let params = {
			search     : value,
			is_active  : 1,
			orderBy    : 'descricao:asc',
			cancelToken: this._axiosCancelJourneysToken.token,
		}

		journeysService.getAll(params)
		.then((response) => {
			this.setState({
				journeysIsLoading: false,
				journeys         : response.data.data,
			})
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null

			this.setState({
				journeysIsLoading: false,
			})

			Modal.error({
				title  : 'Ocorreu um erro!',
				content: String(data),
			})
		})
	}

	fetchJustifications = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				justificationsIsLoading: false,
				justifications: [],
			});

			return false;
		}

		this.setState({
			justificationsIsLoading: true,
		});

		justificationsService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					justificationsIsLoading: false,
					justifications: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					justificationsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onCompanyChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('empresas', value.filter((item) => item !== 'todos'))
		}

		this.setState({
			employees           : [],
			employeesIsLoading  : false,
			departments         : [],
			departmentsIsLoading: false,
		})

		this.form.setFieldValue('departamentos', ['todos'])
		this.form.setFieldValue('funcionarios', ['todos'])
		this.form.setFieldValue('jornadas', ['todos'])
	}

	onCompanySelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('empresas', ['todos'])
		}
	}

	onDepartmentChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('departamentos', value.filter((item) => item !== 'todos'))
		}
	}

	onDepartmentSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('departamentos', ['todos'])
		}
	}

	onEmployeeChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('funcionarios', value.filter((item) => item !== 'todos'))
		}
	}

	onJourneyChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('jornadas', value.filter((item) => item !== 'todos'))
		}
	}

	onEmployeeSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('funcionarios', ['todos'])
		}
	}

	onJourneysSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('jornadas', ['todos'])
		}
	}

	onJustificationChange = (value) => {
		if( value.length > 1 && value.includes('todos') )
		{
			this.form.setFieldValue('justificativas', value.filter((item) => item !== 'todos'))
		}
	}

	onJustificationSelect = (value) => {
		if( value === 'todos' )
		{
			this.form.setFieldValue('justificativas', ['todos'])
		}
	}

	componentDidMount() {
		if( !this.state.companies.length )
		{
			this.fetchCompanies();
		}
	}

	resetFields = () => {
		this.setState({
			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,
			employees: [],
			employeesIsLoading: false,
			justifications: [],
			justificationsIsLoading: false,
		})
	}

	onFinish = (values) => {
		let params = new URLSearchParams();

		if (values.empresas) {
			let nomeEmpresas = [];
	
			values?.empresas?.map(empresa => {
				if (empresa === "todos") {
					nomeEmpresas = this.state?.companies?.map(_empresa => (_empresa.nome));
					return;
				}

				const findedCompany = this.state.companies.find(_empresa => _empresa.id === empresa);
				nomeEmpresas.push(findedCompany.nome);
			});

			params.set("empresa_nome", nomeEmpresas?.join(","));
			params.set("empresas", values?.empresas?.join(","));
		}

		if (values.departamentos) {
			params.set("departamentos", values?.departamentos?.join(","));
		}

		if (values.funcionarios) {
			params.set("funcionarios", values.funcionarios.join(","));
		}

		if (values.jornadas) {
			params.set("jornadas", values?.jornadas?.join(","));
		}

		if (values.justificativas) {
			params.set("justificativas", values.justificativas.join(","));
		}

		if (values.data_inicio) {
			params.set("data_inicio", moment(values.data_inicio).format("YYYY-MM-DD"));
		}

		if (values.data_fim) {
			params.set("data_fim", moment(values.data_fim).format("YYYY-MM-DD"));
		}

		if (this.state.selectedGroupBy) {
			params.set("groupBy", values.groupBy);
		}

		if (this.state.includeMissedHoursAbsence) {
			params.set("includeMissedHoursAbsence", true);
		}

		if (this.state.includePartialJustifications) {
			params.set("includePartialJustifications", true);
		}

		if (this.state.includeInactiveEmployees) {
			params.set("includeInactiveEmployees", true);
		}

		window.open(`/impressao/absenteeism?${params.toString()}`, '_blank');
	};

	handleGroupByChange = (value) => {
		this.setState({ selectedGroupBy: value });
	};

	render() {
		const {
				  isSending,
				  companies,
				  companiesIsLoading,
				  departments,
				  departmentsIsLoading,
				  employees,
				  employeesIsLoading,
				  journeys,
				  journeysIsLoading,
				  justificationsIsLoading,
				  justifications,
				  dateError,
				  selectedGroupBy,
			  } = this.state

		return (
			<QueueAnim className="site-content-inner alternative-wrapper">
				<div className="page-content fixed-header" key="1">
					<h1 className="page-title">Absenteísmo</h1>
					<Form
						ref={(el) => (this.form = el)}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						initialValues={{
							empresas: ['todos'],
							departamentos: ['todos'],
							funcionarios: ['todos'],
							jornadas: ['todos'],
							justificativas: ['todos'],
							includeMissedHoursAbsence: false,
							includeInactiveEmployees: false,
							includePartialJustifications: false,
							groupBy: '1',
						}}>
						<Row gutter={16} align="middle">
							<Col xs={24} md={12} lg={12} xl={12} xxl={6}>
								<Form.Item
									name="empresas"
									label="Empresa"
									hasFeedback
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										ref={(el) => (this.empresas = el)}
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione a(s) empresa(s)"
										notFoundContent={
											companiesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onChange={this.onCompanyChange}
										onSelect={this.onCompanySelect}
										showSearch
									>
										<Select.Option value="todos">Todas</Select.Option>
										{companies.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12} xl={12} xxl={6}>
								<Form.Item
									name="departamentos"
									label="Departamento"
									hasFeedback
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										mode="multiple"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o(s) departamento(s)"
										notFoundContent={
											departmentsIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchDepartments}
										onChange={this.onDepartmentChange}
										onSelect={selectedGroupBy === "2" || selectedGroupBy === "3" 
											? this.form?.setFieldValue('departamentos', ['todos']) 
											: this.onDepartmentSelect}
										showSearch
										disabled={selectedGroupBy === "2" || selectedGroupBy === "3"}
									>
										<Select.Option value="todos">Todos</Select.Option>
										{departments.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12} xl={12} xxl={6}>
								<Form.Item
									name="funcionarios"
									label="Funcionário"
									hasFeedback
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										mode="multiple"
										filterOption={false}
										allowClear
										placeholder="Selecione o(s) funcionário(s)"
										notFoundContent={
											employeesIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchEmployees}
										onChange={this.onEmployeeChange}
										onSelect={selectedGroupBy === "1" || selectedGroupBy === "3"
											? this.form?.setFieldValue('funcionarios', ['todos']) 
											: this.onEmployeeSelect}										
										showSearch
										disabled={selectedGroupBy === "1" || selectedGroupBy === "3"}
									>
										<Select.Option value="todos">Todos</Select.Option>
										{employees.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12} xl={12} xxl={6}>
								<Form.Item 
								name="jornadas" 
								label="Horário" 
								hasFeedback
								rules={[{required: true, message: 'Campo obrigatório.'}]}>
									<Select
										mode="multiple"
										filterOption={false}
										allowClear
										placeholder="Selecione o(s) horário(s) (jornada)"
										notFoundContent={
											journeysIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchJourneys}
										onChange={this.onJourneyChange}
										onSelect={selectedGroupBy === "1" || selectedGroupBy === "2"
										? this.form?.setFieldValue('jornadas', ['todos']) 
										: this.onJourneysSelect}
										showSearch
										disabled={selectedGroupBy === "1" || selectedGroupBy === "2"}
									>

										<Select.Option value="todos">Todos</Select.Option>
										{journeys.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.descricao}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={12} xl={12} xxl={6}>
								<Form.Item
									name="justificativas"
									label="Justificativa"
								>
									<Select
										mode="multiple"
										filterOption={false}
										allowClear
										placeholder="Selecione a(s) justificativa(s)"
										notFoundContent={
											justificationsIsLoading ? (
												<Spin
													indicator={
														<i className="fad fa-spinner-third fa-spin" />
													}
												/>
											) : null
										}
										onSearch={this.fetchJustifications}
										onChange={this.onJustificationChange}
										onSelect={this.onJustificationSelect}
										showSearch
									>
										<Select.Option value="todos">Todas</Select.Option>
										{justifications.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={6} xxl={4}>
                <DatePickerWithMask
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 9) {
                      this.form.data_fim.focus()
                    }
                  }}
                  label="Data Início"
                  name="data_inicio"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                    },
                    {
                      validator: async (_, data_inicio) => {
                        var data_fim = this.form.getFieldValue('data_fim')
                        if (data_fim !== null) {
                          if (data_inicio > data_fim) {
														this.setState(({
															dateError: true,
														}))
                            return Promise.reject(
                              'A data inicial deve ser menor ou igual a data final.',
                            )
                          }
													this.setState(({
														dateError: false,
													}))
                        }
                      },
                    },
                  ]}
                />
              </Col>
              <Col xs={24} md={12} lg={6} xxl={4}>
                <DatePickerWithMask
                  label="Data Fim"
                  name="data_fim"
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 9) {
                      this.form.data_inicio.focus()
                    }
                  }}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                    },
                    {
                      validator: async (_, data_fim) => {
                        var data_inicio = this.form.getFieldValue('data_inicio')
                        if (data_inicio !== null) {
                          if (data_fim < data_inicio) {
														this.setState(({
															dateError: true,
														}))
                            return Promise.reject(
                              'A data final deve ser maior ou igual a data inicial.',
                            )
                          }
													this.setState(({
														dateError: false,
													}))
                        }
                      },
                    },
                  ]}
                />
              </Col>
					</Row>
						<Row gutter={16} align='middle'>
							<Col xs={24} md={8} lg={6} xl={6} xxl={6}>
								<Form.Item
									name="includeInactiveEmployees"
									label="Incluir funcionários inativos"
								>
									<Switch
										onChange={(value) => {
											this.setState({ includeInactiveEmployees: value });
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8} lg={6} xl={6} xxl={6}>
								<Form.Item
									name="includeMissedHoursAbsence"
									label="Incluir horas falta como ausência"
								>
									<Switch
										onChange={(value) => {
											this.setState({ includeMissedHoursAbsence: value });
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8} lg={6} xl={6} xxl={6}>
								<Form.Item
									name="includePartialJustifications"
									label="Incluir Justificativas Parciais"
								>
									<Switch
										onChange={(value) => {
											this.setState({ includePartialJustifications: value });
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16} align='middle'>
							<Col xs={24} md={12} lg={8} xl={8} xxl={4}>
								<Form.Item
									name="groupBy"
									label="Agrupar Por"
									rules={[{required: true, message: 'Campo obrigatório.'}]}
								>
									<Select
										optionFilterProp="children"
										filterOption={(input, option) =>
											(typeof option.children === 'string'
													? option.children
													: option.children.props.children
											)
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										placeholder="Selecione o agrupamento"
										showSearch
										onChange={this.handleGroupByChange}
									>
										<Select.Option value="1">Departamento</Select.Option>
										<Select.Option value="2">Funcionário</Select.Option>
										<Select.Option value="3">Horário</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginLeft: '10px' }}>
								<div>
									<Button
										type="primary"
										htmlType="submit"
										shape="round"
										className="btn-save"
										icon={<i className="far fa-check" />}
										loading={isSending}
										disabled={isSending || dateError}
										block
									>
										{isSending ? 'Carregando' : 'Gerar'}
									</Button>
								</div>
							</div>
						</Row>
					</Form>
				</div>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	}
}

export default connect(mapStateToProps)(Index)
