import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Row, Tabs, Card } from "antd";

import moment from "moment";

import { journeysService } from "../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "../../components";

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			uuid: 0,
			item: {},
			general_key: null
		};
	}

	onOpen = (departments, key) => {
		this.setState({
			departmentsHistory: departments,
			general_key: key,
		});

	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { departmentsHistory } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={false}
				showBtnSave={false}
				title={`Visualizar Histórico de Departamentos`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info.Gerais" key="info_gerais">
							{departmentsHistory?.reverse().map((deparment) => (
								<Card style={{ marginBottom: '20px' }}>
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item style={{ marginBottom: '0px' }} label="Departamento">
												{deparment.departamento.nome ?? "-"}
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item style={{ marginBottom: '0px' }} label="Data início">
												{moment(deparment.data_inicio).format("DD/MM/YYYY") ?? "-"}
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item style={{ marginBottom: '0px' }} label="Data fim">
												{(deparment.data_fim && moment(deparment.data_fim).format("DD/MM/YYYY")) ?? "Até o momento"}
											</Form.Item>
										</Col>
									</Row>
								</Card>
							))
							}
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
