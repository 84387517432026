import React, { Component } from "react";
import axios from "axios";
import { debounce } from 'lodash';
import * as PropTypes from "prop-types";
import { Input, Modal, Row, Col, Form, Select, message, Spin } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../../config/general";

import { justificationsService, timeCardService } from "./../../../redux/services";

import * as floatbox from "./../../../helpers/floatbox";

import moment from "moment";

class ModalEdit extends Component {
	static propTypes = {
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			visible       : false,
			isLoading     : true,
			isSending     : false,
			item          : null,
			itemIndex     : 0,
			batidaIndex   : -1,
			sequence      : 0,
			type          : '',
			totalBatidas  : 0,
			justifications: [],
		}

		this._cancelToken = null;
		this.debouncedFetchJustifications = debounce(this.fetchJustifications, 500);
	}

	componentWillUnmount() {
		this._cancelToken && this._cancelToken.cancel("Only one request allowed at a time.");
	}

	onOpen = (item, itemIndex, batidaIndex, sequence, type, totalBatidas) => {
		this.setState({
			isLoading: true,
			visible  : true,
			item,
			itemIndex,
			batidaIndex,
			sequence,
			type,
			totalBatidas,
		});

		this._cancelToken = axios.CancelToken.source();

		justificationsService.getAll({
			ativo      : 1,
			orderBy    : "nome:asc",
			cancelToken: this._cancelToken.token,
		})
		.then((response) => {
			this.setState({
				isLoading     : false,
				justifications: response.data.data,
			}, () => {
				setTimeout(() => {
					this.form.setFieldsValue({
						edit_empresa     : item.contrato?.empresa?.nome ?? '-',
						edit_departamento: item.departamento?.nome ?? '-',
						edit_funcionario : item.funcionario?.nome ?? '-',
						edit_data        : moment(item.data).format('DD/MM/YYYY'),
					});
				}, 100);
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				visible: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	onClose = () => {
		this._cancelToken && this._cancelToken.cancel("Only one request allowed at a time.");

		this.setState({
			visible: false,
		});

		// Hide all float box
		floatbox.hideAll();

		// Reset input
		floatbox.resetInput(this.state.item.id, this.state.itemIndex, this.state.sequence, this.state.type);

		// Reset fields
		this.resetFields();

		// Callback
		if( typeof this.props.onClose !== 'undefined' )
		{
			this.props.onClose();
		}
	};

	resetFields = () => {
		this.setState({
			item       : null,
			itemIndex  : 0,
			batidaIndex: -1,
			sequence   : 0,
			type       : '',
		});
	};

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			const {item, itemIndex, batidaIndex, type, sequence} = this.state;

			const data = {
				cartao_ponto_id : item.id,
				justificativa_id: values.edit_justificativa,
				data            : item.data,
			}

			// Se tem batida
			if( batidaIndex !== -1 )
			{
				data.cartao_ponto_batida_id = item.cartaopontobatida[batidaIndex].id;
			}

			if( values.edit_tipo === 'dia_todo' )
			{
				data.dia_todo = true;
			}
			else
			{
				data.sequencia = values.edit_tipo;
			}

			timeCardService.justificarFalta(data)
			.then((response) => {
				this.setState({
					visible  : false,
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success('Justificativa cadastrada com sucesso!');

				// Callback
				this.props.onComplete(item.id, itemIndex, sequence, type, response.data.data[0]);
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: String(data),
				});
			});
		});
	}

	fetchJustifications = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				justificationsIsLoading: false,
				justifications: [],
			});

			return false;
		}

		this.setState({
			justificationsIsLoading: true,
		});

		justificationsService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					justificationsIsLoading: false,
					justifications: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					justificationsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible, isLoading, isSending, item, type, sequence, justifications, totalBatidas, justificationsIsLoading } = this.state;

		return (
			<Modal
				visible={visible}
				title="Cadastrar Justificativa Falta"
				destroyOnClose
				width={450}
				onCancel={this.onClose}
				maskClosable={!isLoading}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{disabled: isLoading || isSending}}
				onOk={this.onSubmit}
				okButtonProps={{disabled: isLoading}}
				confirmLoading={isSending}
				keyboard={!isLoading}
				closable={!isLoading && !isSending}
				centered
				focusTriggerAfterClose={false}>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError
					initialValues={{
						edit_tipo: sequence,
					}}>
					<Form.Item label="Empresa" name="edit_empresa">
						<Input disabled />
					</Form.Item>
					<Form.Item label="Departamento" name="edit_departamento">
						<Input disabled />
					</Form.Item>
					<Form.Item label="Funcionário" name="edit_funcionario">
						<Input disabled />
					</Form.Item>
					<Form.Item
						label="Justificativa"
						name="edit_justificativa"
						rules={[{ required: true, message: "Campo obrigatório." }]}
					>
						<Select
							placeholder="Selecione"
							notFoundContent={
								justificationsIsLoading ? (
									<Spin
										indicator={
											<i className="fad fa-spinner-third fa-spin" />
										}
									/>
								) : null
							}
							showSearch
							optionFilterProp="children"
							onSearch={this.debouncedFetchJustifications}
						>
							{justifications.map((item, index) => (
								<Select.Option key={index} value={item.id}>
									{item.nome}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Tipo" name="edit_tipo" rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select placeholder="Selecione">
							<Select.Option value="dia_todo">Dia Todo</Select.Option>
							{[...Array(totalBatidas).keys()].map((num) => (
								<Select.Option key={num + 1} value={num + 1}>{`Período - Entrada ${num + 1} e Saída ${num + 1}`}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<Form.Item label="Data" name="edit_data">
								<Input disabled />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default ModalEdit;
