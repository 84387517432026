import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Checkbox } from "antd";

import moment from "moment";

import { partialJustificationsService } from "./../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "./../../components";

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null
		};
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		partialJustificationsService.show({ uuid })
			.then((response) => {
				let item = response.data;
				this.setState({
					isLoading: false,
					item: item,
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { uuid, isLoading, item } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Justificativa Parcial`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item label="Empresa(s)">
										{item.empresas?.length > 0 ?
											item.empresas.map((empresa, index) => {
												return <span key={empresa.id}>{empresa.nome} {index < item.empresas?.length - 1 ? <br /> : ""}</span>;
											})
											: "Todas"}
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item label="Departamento(s)">
										{item.departamentos?.length > 0 ?
											item.departamentos.map((departamento, index) => {
												return <span key={departamento.id}>{departamento.nome} {index < item.departamentos?.length - 1 ? <br /> : ""}</span>;
											})
											: "Todos"}
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item label="Funcionário(s)">
										{item.funcionarios?.length > 0 ?
											item.funcionarios.map((funcionario, index) => {
												return <span key={funcionario.id}>{funcionario.nome} {index < item.funcionarios?.length - 1 ? <br /> : ""}</span>;
											})
											: "Todos"}
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item label="Justificativa">
										{item.justificativa?.nome ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Data">
										{moment(item.data).format("DD/MM/YYYY") ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Horário início">
										{item.hora_inicio ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Horário fim">
										{item.hora_fim ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Ativo" valuePropName="checked">
										<Switch disabled checked={item.status} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar() ?? "-"}
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
