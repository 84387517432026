import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Checkbox } from "antd";

import moment from "moment";

import { overtimeService, overtimeDadosService, overtimeFaixasService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

import DayTable from "./Table/show";

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null
		};
	}

	getFaixas = async (uuid) => {
		const overtimeDados = (await overtimeDadosService.show({ uuid })).data.data;

		let dados = {
			uteis       : { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			sabado      : { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			domingo     : { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			feriado     : { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			folga       : { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
			dia_especial: { diurno: { tipo: "", faixas: [] }, noturno: { tipo: "", faixas: [] }, intervalo: { tipo: "", faixas: [] } },
		};
		
		Promise.all(overtimeDados.map(async (item) => {
			await overtimeFaixasService.show({ uuid: item.uuid }).then((response) => {
				if(item.incidencia) {
					dados[item.incidencia][item.periodo].tipo = item.tipo;
					response.data.data.map(async (faixa) => {
						await dados[item.incidencia][item.periodo].faixas.push({
							id: faixa.uuid,
							key: faixa.uuid,
							tipo: item.periodo,
							inicial: faixa.inicial,
							final: faixa.final,	
							percentual: faixa.percentual,
						});
					});
				}
			});
		})
		).then(() => {
			this.uteis.initData(dados.uteis);
			this.sabado.initData(dados.sabado);
			this.domingo.initData(dados.domingo);
			this.feriado.initData(dados.feriado);
			this.folga.initData(dados.folga);
			this.dia_especial.initData(dados.dia_especial);
		});
	}

	onOpen = async (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		try {
			const overtime = (await overtimeService.show({ uuid })).data.data;
			
			this.getFaixas(uuid);

			this.setState({
				isLoading: false,
				item: {
					...overtime,
				},
			});
		} catch( error ) {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title: "Ocorreu um erro!",
				content: String(error),
				onOk: () => {
					// Force close
					return this.onClose();
				}
			});

			return false;
		}
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { uuid, isLoading, item, faixas } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Hora Extra`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general" >
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<Form.Item label="Nome">
										{item.nome ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item label="Controle de horas">
										<p style={{ textTransform: "capitalize" }}>{item.controle_horas ?? "-"}</p>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar() ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Ativo">
										<Switch disabled checked={item.is_active} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={12}>
									<Form.Item>
										<Checkbox disabled checked={item.agrupar_mesma_porcentagem}>Agrupar extras de mesma porcentagem</Checkbox>
									</Form.Item>
								</Col>
								<Col sm={12}>
									<Form.Item>
										<Checkbox disabled checked={item.marcar_feriado_como_hora_extra}>Marcar feriados como hora extra</Checkbox>
									</Form.Item>
								</Col>
								<Col sm={12}>
									<Form.Item>
										<Checkbox disabled checked={item.separar_extras_noturnas_de_extras_normais}>Separar extras noturnas de extras normais</Checkbox>
									</Form.Item>
								</Col>
								<Col sm={12}>
									<Form.Item>
										<Checkbox disabled checked={item.separar_extras_intervalos_de_extras_normais}>Separar extras intervalos de extras normais</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item>
										<Checkbox disabled checked={item.calcular_faltas_somente_para_dia_inteiro_horas_e_dias}>Calcular faltas somente para o dia inteiro horas e dias</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item>
										<Checkbox disabled checked={item.marcar_falta_dias_em_branco}>Marcar como falta dias em branco</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item>
										<Checkbox disabled checked={item.compensacao_hora_mensal_por_faixa_hora_extra}>Marcar compensação hora mensal por faixa de hora extra</Checkbox>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24}>
									<Tabs>
										<Tabs.TabPane forceRender tab="Úteis" key="uteis">
											<DayTable ref={el => this.uteis = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Sábado" key="sabado">
											<DayTable ref={el => this.sabado = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Domingo" key="domingo">
											<DayTable ref={el => this.domingo = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Feriado" key="feriado">
											<DayTable ref={el => this.feriado = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Folga" key="folga">
											<DayTable ref={el => this.folga = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Dia especial" key="dia_especial">
											<DayTable ref={el => this.dia_especial = el} />
										</Tabs.TabPane>
										<Tabs.TabPane forceRender tab="Periodo noturno" key="periodo_noturno">
											<Row gutter={16}>
												<Col xs={24} md={8}>
													<Form.Item label="Hora início">
														{item.periodo_noturno_hora_inicio?.substring(0, 5) ?? "-"}
													</Form.Item>
												</Col>
												<Col xs={24} md={8}>
													<Form.Item label="Hora fim">
														{item.periodo_noturno_hora_fim?.substring(0, 5) ?? "-"}
													</Form.Item>
												</Col>
												<Col xs={24} md={8}>
													<Form.Item label="Adicional noturno reduzido">
														{item?.periodo_noturno_reduzido ?? "-"}
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={16}>
												<Col xs={24}>
													<Form.Item>
														<Checkbox disabled checked={item.periodo_noturno_ate_ultima_batida}>Período noturno até a ultima batida</Checkbox>
													</Form.Item>
												</Col>
												<Col xs={24}>
													<Form.Item>
														<Checkbox disabled checked={item.dividir_jornada_feriado_antes_ou_apos_meia_noite}>Dividir jornadas quando houver feriado antes ou após a meia noite</Checkbox>
													</Form.Item>
												</Col>
											</Row>
										</Tabs.TabPane>
									</Tabs>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
