import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Table, Form, InputNumber, Row, Select, Tabs, Button, Popconfirm } from "antd";

import {
	HourPickerWithMask,
} from "./../../../components";

class DayTable extends Component {
	static propTypes = {
		incidencia: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			diurnoId: "",
			noturnoId: "",
			intervaloId: "",
			diurnoTipo: null,
			noturnoTipo: null,
			intervaloTipo: null,
			diurno: [],
			noturno: [],
			intervalo: [],
			diurnoDeleted: [],
			noturnoDeleted: [],
			intervaloDeleted: [],
		};
	}

	componentDidUpdate(prevProps) {
		const { diurnoTipo: diurnoTipoOriginal, noturnoTipo: noturnoTipoOriginal, intervaloTipo: intervaloTipoOriginal } = this.state;
		const { tipo_faixa } = this.props;
		
		if (JSON.stringify(prevProps.tipo_faixa) !== JSON.stringify(tipo_faixa)) {
			const { value } = tipo_faixa[0];
			const diurnoTipo = tipo_faixa.findIndex(item => item.value === diurnoTipoOriginal) > -1 ? diurnoTipoOriginal : value;
			const noturnoTipo = tipo_faixa.findIndex(item => item.value === noturnoTipoOriginal) > -1 ? noturnoTipoOriginal : value;
			const intervaloTipo = tipo_faixa.findIndex(item => item.value === intervaloTipoOriginal) > -1 ? intervaloTipoOriginal : value;

			this.onSelectTipo("diurno", diurnoTipo)
			this.onSelectTipo("noturno", noturnoTipo)
			this.onSelectTipo("intervalo", intervaloTipo)
		}
	}

	initData = (dados) => {
		if(dados.diurno && dados.noturno && dados.intervalo) {
			this.setState({
				diurnoId: dados?.diurno?.id,
				noturnoId: dados?.noturno?.id,
				intervaloId: dados?.intervalo?.id,
				diurnoTipo: dados?.diurno?.tipo === "" ? null : dados?.diurno?.tipo,
				noturnoTipo: dados?.noturno?.tipo === "" ? null : dados?.noturno?.tipo,
				intervaloTipo: dados?.intervalo?.tipo === "" ? null : dados?.intervalo?.tipo,
				diurno: dados?.diurno?.faixas,
				noturno: dados?.noturno?.faixas,
				intervalo: dados?.intervalo?.faixas,
				isLoading: false,
			});
		}
	}

	onCreateRow = (tipo) => {
		this.setState({
			[tipo]: [
				...this.state[tipo],
				{
					id: Math.floor(Math.random() * 10001),
					key: Math.floor(Math.random() * 10001),
					tipo: tipo,
					inicial: "",
					final: "",
					percentual: this.getDefaultPercent(this.state[tipo].length, tipo),
					acao: "criar",
				},
			],
		});
	}

	onRemoveRow = async (tipo, id, acao, index) => {
		if(acao === "editar") {
			const stateName = tipo + "Deleted";
			await this.setState({
				[stateName]: [
					...this.state[stateName],
					this.state[tipo].filter(item => item.id === id)[0],
				],
			});
		}

		const items = this.state[tipo].filter((item) => item.id !== id);
		
		const previusIndex = index - 1;
		if (previusIndex > -1) {
			if (items[index]) items[index].inicial = items[previusIndex]?.final;
		}
		
		const lastIndex = (items.length - 1);
		if (lastIndex > -1 && items[lastIndex]) items[lastIndex].final = '';

		this.setState({ [tipo]: items });
	}

	// Quantidade
	onBlurAte = (value, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].final = value.replaceAll('_', '0');
		if (newData[index + 1]) newData[index + 1].inicial = value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	// Intervalo
	onBlurIntervaloDe = (value, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].inicial = value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	onBlurIntervaloAte = (value, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].final = value.replaceAll('_', '0');

		this.setState({
			[tipo]: newData,
		});
	}

	onChangePercent = (value, tipo, index) => {
		const newData = [...this.state[tipo]];
		newData[index].percentual = value;

		this.setState({
			[tipo]: newData,
		});
	}

	getDefaultPercent = (index, tipo) => {
		let percent = null;

		if(index === 0) {
			if(tipo === "intervalo") {
				percent = 0;
			} else if(this.props.incidencia === "uteis" || this.props.incidencia === "sabado") {
				percent = 50;
			} else {
				percent = 100;
			}
		}

		return percent;
	}

	validatePercent = () => {
		const { diurno, noturno, intervalo } = this.state;
		let error = null;

		try {
			diurno.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "diurno",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
	
			noturno.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "noturno",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
	
			intervalo.forEach((item, index) => {
				if(!this[`perc_${item.id}`].value) {
					error = {
						incidencia: this.props.incidencia,
						campo: `perc_${item.id}`,
						tipo: "intervalo",
					};
	
					throw new Error("Campo obrigatório");
				}
			});
		} catch(e) {
			return error;
		}

		return error;
	}

	focusInput = (campo, tipo) => {
		this.onTabChange(tipo);

		setTimeout(() => {
			this[campo].focus();
		}, 100);
	}

	onSelectTipo = (tipo, value) => {
    const { tipo_faixa } = this.props;
    const typeStateName = `${tipo}Tipo`;
    const newState = {
			[tipo]: [
				// ...this.state[tipo + "Deleted"],
				...this.state[tipo].filter(item => item.acao === "editar"),
			],
    };

    const DEFAULT = {
			id: Math.floor(Math.random() * 10001),
			key: Math.floor(Math.random() * 10001),
			tipo,
			inicial: value === "quantidade" ? "00:00" : "",
			final: '',
			percentual: this.getDefaultPercent(0, tipo),
			acao: "criar",
    };

    let original = this.state[tipo] || [DEFAULT];

    const hasQuantidade = tipo_faixa.some(faixa => faixa.value === 'quantidade');
    if (hasQuantidade && original.length > 0) {
			original[original.length - 1].final = '';
    }

    this.setState({
        ...newState,
        [typeStateName]: value,
        [tipo]: original,
    }, () => {
			const { [tipo]: dados } = this.state;

			const eventFinals = {
				'quantidade': this.onBlurAte,
				'intervalo': this.onBlurIntervaloAte
			};

			const eventFinal = eventFinals[value];
			dados.forEach((item, index) => {
				if (eventFinal) eventFinal(item.final, tipo, index);
				if (hasQuantidade && index === dados.length - 1) {
					item.final = '';
				}
			});

			this.setState({ [tipo]: dados });
    });
	}

	returnData = (periodo) => {
		const { incidencia, compensacao_hora_mensal_por_faixa_hora_extra = false } = this.props;
		let faixas = [];

		if(this.state[periodo].length > 0) {
			this.state[periodo].forEach((item, index) => {
				if(item) {
					faixas.push({
						id: item.id,
						acao: item.acao,
						sequencia: index,
						inicial: item.inicial === "" ? null : item.inicial,
						final: item.final === "" ? null : item.final,
						percentual: item.percentual,
					});
				}
			});
		}

		let stateName = periodo + "Deleted";
		if(this.state[stateName].length > 0) {
			this.state[stateName].forEach(item => {
				if(item) {
					faixas.push({
						id: item.id,
						acao: "deletar",
					});
				}
			});
		}

		return {
			id: this.state[`${periodo}Id`],
			incidencia,
			periodo,
			tipo: this.state[`${periodo}Tipo`],
			// TODO - Receber compensação quando adicionar no front
			compensacao_hora_mensal_por_faixa_hora_extra,
			faixas,
		};
	}

	render() {
		const { isLoading, diurnoTipo: diurnoTipoOriginal, noturnoTipo: noturnoTipoOriginal, intervaloTipo: intervaloTipoOriginal, diurno, noturno, intervalo } = this.state;
		const { tipo_faixa, tab, onTabChange } = this.props;

		const diurnoTipo = tipo_faixa
			.findIndex(item => item.value === diurnoTipoOriginal) > -1 ? diurnoTipoOriginal : tipo_faixa[0];
		const noturnoTipo = tipo_faixa
			.findIndex(item => item.value === noturnoTipoOriginal) > -1 ? noturnoTipoOriginal : tipo_faixa[0];
		const intervaloTipo = tipo_faixa
			.findIndex(item => item.value === intervaloTipoOriginal) > -1 ? intervaloTipoOriginal : tipo_faixa[0];

		const columnsQuantidade = [
			{
				title: 'De',
				dataIndex: 'inicial',
				key: 'inicial',
				render: (_, { id, tipo, inicial }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`de_${id}`] = el} 
						value={index === 0 ? "00:00" : inicial} 
						disabled 
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Até',
				dataIndex: 'final',
				key: 'final',
				render: (_, { id, tipo, final }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`ate_${id}`] = el}
						value={index !== this.state[tipo]?.length - 1 ? final : null} 
						onBlur={(e) => this.onBlurAte(e.target.value, tipo, index)} 
						disabled={index === this.state[tipo]?.length - 1} 
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Coluna/Perc.',
				dataIndex: 'coluna_perc',
				key: 'coluna_perc',
				render: (_, { id, tipo, percentual }, index) => (
					<InputNumber
						ref={el => this[`perc_${id}`] = el}
						defaultValue={percentual}
						onChange={(value) => this.onChangePercent(value, tipo, index)}
						min={0}
						controls={false}
						precision={2}
						decimalSeparator=","
						style={{ maxWidth: 120 }}
						prefix="%"
					/>
				),
			},
			{
				title: 'Ações',
				dataIndex: 'acoes',
				key: 'acoes',
				render: (_, { id, tipo, acao }, index) => (
					<Row gutter={5}>
						<Col span={12}>
							<Popconfirm placement="topRight" title="Deseja excluir essa faixa?" onConfirm={() => this.onRemoveRow(tipo, id, acao, index)} okText="Sim" cancelText="Não">
								<Button danger shape="circle" icon={<i className="fal fa-trash"></i>} />
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		const columnsIntervalo = [
			{
				title: 'De',
				dataIndex: 'inicial',
				key: 'inicial',
				render: (_, { id, tipo, inicial }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`de_${id}`] = el}
						defaultValue={inicial} 
						onBlur={(e) => this.onBlurIntervaloDe(e.target.value, tipo, index)} 
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Até',
				dataIndex: 'final',
				key: 'final',
				render: (_, { id, tipo, final }, index) => (
					<HourPickerWithMask 
						form_={false} 
						ref={el => this[`ate_${id}`] = el} 
						defaultValue={final} 
						onBlur={(e) => this.onBlurIntervaloAte(e.target.value, tipo, index)} 
						style={{ maxWidth: 140 }} 
					/>
				),
			},
			{
				title: 'Coluna/Perc.',
				dataIndex: 'coluna_perc',
				key: 'coluna_perc',
				render: (_, { id, tipo, percentual }, index) => (
					<InputNumber
						ref={el => this[`perc_${id}`] = el}
						defaultValue={percentual}
						onChange={(value) => this.onChangePercent(value, tipo, index)}
						min={0}
						controls={false}
						precision={2}
						decimalSeparator=","
						style={{ maxWidth: 120 }}
						prefix="%"
					/>
				),
			},
			{
				title: 'Ações',
				dataIndex: 'acoes',
				key: 'acoes',
				render: (_, { id, tipo, acao }, index) => (
					<Row gutter={5}>
						<Col span={12}>
							<Popconfirm placement="topRight" title="Deseja excluir essa faixa?" onConfirm={() => this.onRemoveRow(tipo, id, acao, index)} okText="Sim" cancelText="Não">
								<Button danger shape="circle" icon={<i className="fal fa-trash"></i>} />
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Tabs activeKey={tab} onChange={onTabChange} style={{ paddingLeft: "35px" }}>
				<Tabs.TabPane forceRender tab="Diurno" key="diurno">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={diurnoTipo} loading={isLoading} disabled={isLoading} onSelect={(value) => this.onSelectTipo("diurno", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table loading={isLoading} columns={diurnoTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={diurno} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('diurno')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane forceRender tab="Noturno" key="noturno">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={noturnoTipo} loading={isLoading} disabled={isLoading} onSelect={(value) => this.onSelectTipo("noturno", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table loading={isLoading} columns={noturnoTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={noturno} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('noturno')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane forceRender tab="Intervalo" key="intervalo">
					<Row>
						<Col span={12}>
							<Form.Item label="Tipo das faixas">
								<Select value={intervaloTipo} loading={isLoading} disabled={isLoading} onSelect={(value) => this.onSelectTipo("intervalo", value)}>
									{tipo_faixa?.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Table loading={isLoading} columns={intervaloTipo === "quantidade" ? columnsQuantidade : columnsIntervalo} dataSource={intervalo} pagination={false} />
					<div className="text-right">
						<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow('intervalo')}>Adicionar nova faixa</Button>
					</div>
				</Tabs.TabPane>
			</Tabs>
		);
	}
}

export default DayTable;