import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd'
import QueueAnim from 'rc-queue-anim'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import { API_ERRO_TYPE_CANCEL } from './../../config/general'

import {
  companiesService,
  departmentsService,
  employeesService,
  journeyExceptionService,
  timeCardService,
} from '../../redux/services'
import { DatePickerWithMask } from '../../components'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSending: false,
      nextOrder: 1,
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: [],
      employees: [],
      employeesIsLoading: false,
      journeys: [],
      journeysIsLoading: false,
      overtime: [],
      overtimeIsLoading: false,
      selectedType: '',
    }

    this._axiosCancelCompaniesToken = null
    this._axiosCancelDepartmentsToken = null
    this._axiosCancelEmployeesToken = null
    this._axiosCancelJourneysToken = null
    this._axiosCancelOvertimeToken = null
  }

  fetchCompanies = () => {
    if (this._axiosCancelCompaniesToken) {
      this._axiosCancelCompaniesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelCompaniesToken = axios.CancelToken.source()

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAutocomplete({
        orderBy: 'nome:asc',
        ativo: 1,
        cancelToken: this._axiosCancelCompaniesToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelDepartmentsToken) {
      this._axiosCancelDepartmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelDepartmentsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelDepartmentsToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
    }

    departmentsService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelEmployeesToken) {
      this._axiosCancelEmployeesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEmployeesToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      })

      return false
    }

    this.setState({
      employeesIsLoading: true,
    })

    let params = {
      search: value,
      ativo: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelEmployeesToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
      departamentos_ids: this.form
        .getFieldValue('departamentos')
        .includes('todos')
        ? []
        : this.form.getFieldValue('departamentos'),
    }

    employeesService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          employeesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchJourneys = (value) => {
    if (this._axiosCancelJourneysToken) {
      this._axiosCancelJourneysToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelJourneysToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        journeysIsLoading: false,
        journeys: [],
      })

      return false
    }

    this.setState({
      journeysIsLoading: true,
    })

    let params = {
      search: value,
      ativo: 1,
      orderBy: 'descricao:asc',
      cancelToken: this._axiosCancelJourneysToken.token,
    }

    timeCardService
      .autocompleteJornada({
        ...params,
      })
      .then((response) => {
        this.setState({
          journeysIsLoading: false,
          journeys: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          journeysIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchOvertime = (value) => {
    if (this._axiosCancelOvertimeToken) {
      this._axiosCancelOvertimeToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelOvertimeToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        overtimeIsLoading: false,
        overtime: [],
      })

      return false
    }

    this.setState({
      overtimeIsLoading: true,
    })

    let params = {
      search: value,
      ativo: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelOvertimeToken.token,
    }

    timeCardService
      .autocompleteHorasExtras({
        ...params,
      })
      .then((response) => {
        this.setState({
          overtimeIsLoading: false,
          overtime: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          overtimeIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onTypeSelect = (value) => {
    this.setState({
      selectedType: value,
    })
  }

  onCompanyChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'empresas',
        value.filter((item) => item !== 'todos')
      )
    }

    this.setState({
      employees: [],
      employeesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
    })

    this.form.setFieldValue('departamentos', ['todos'])
    this.form.setFieldValue('funcionarios', ['todos'])
  }

  onCompanySelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('empresas', ['todos'])
    }
  }

  onDepartmentChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'departamentos',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onDepartmentSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('departamentos', ['todos'])
    }
  }

  onEmployeeChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'funcionarios',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onEmployeeSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('funcionarios', ['todos'])
    }
  }

  componentDidMount() {
    if (!this.state.companies.length) {
      this.fetchCompanies()
    }
  }

  resetFields = () => {
    this.setState({
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      journeys: [],
      journeysIsLoading: false,
    })
  }

  doJourneyException = (data) => {
    this.setState({
      isSending: true,
    })

    journeyExceptionService
      .create(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        Modal.success({
          content: 'Ação realizada com sucesso!',
          okText: 'Ok',
          onOk: () => {
            return window.location.reload(false)
          },
        })
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onFinish = (values) => {
    const data = { ...values }

    if (!(data.empresas instanceof Array)) {
      data.empresas = [data.empresas]
    }

    if (!(data.departamentos instanceof Array)) {
      data.departamentos = [data.departamentos]
    }

    if (!(data.funcionarios instanceof Array)) {
      data.funcionarios = [data.funcionarios]
    }

    if (data.empresas.includes('todos')) {
      data.empresas = null
    }

    if (data.departamentos.includes('todos')) {
      data.departamentos = null
    }

    if (data.funcionarios.includes('todos')) {
      data.funcionarios = null
    }

    data.data_excecao_inicio = moment(data.data_excecao_inicio).format(
      'YYYY-MM-DD'
    )
    data.data_excecao_fim = moment(data.data_excecao_fim).format('YYYY-MM-DD')

    const dataExcecaoInicio = moment(data.data_excecao_inicio).format(
      'DD/MM/YYYY'
    )
    const dataExcecaoFim = moment(data.data_excecao_fim).format('DD/MM/YYYY')
    const titleContent =
      data.tipo === 'execao_jornada'
        ? `Confirma a alteração de horário no período de ${dataExcecaoInicio} a ${dataExcecaoFim}?`
        : `Confirma a alteração do padrão de hora extra no período de ${dataExcecaoInicio} a ${dataExcecaoFim}?`

    Modal.confirm({
      content: titleContent,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      autoFocusButton: null,
      onOk: () => {
        return this.doJourneyException(data)
      },
    })
  }

  render() {
    const {
      isSending,
      nextOrder,
      companiesIsLoading,
      companies,
      departments,
      departmentsIsLoading,
      employees,
      employeesIsLoading,
      journeys,
      journeysIsLoading,
      overtime,
      overtimeIsLoading,
    } = this.state

    return (
      <QueueAnim className="site-content-inner alternative-wrapper">
        <div className="page-content fixed-header" key="1">
          <h1 className="page-title">Exceção de Horário/HE</h1>
          <Form
            ref={(el) => (this.form = el)}
            layout="vertical"
            scrollToFirstError
            onFinish={this.onFinish}
            initialValues={{
              order: nextOrder,
              required: true,
              empresas: ['todos'],
              departamentos: ['todos'],
              funcionarios: ['todos'],
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="empresas"
                  label="Empresa"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    ref={(el) => (this.empresas = el)}
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (typeof option.children === 'string'
                        ? option.children
                        : option.children.props.children
                      )
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Selecione a(s) empresa(s)"
                    notFoundContent={
                      companiesIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onChange={this.onCompanyChange}
                    onSelect={this.onCompanySelect}
                    showSearch
                  >
                    <Select.Option value="todos">Todas</Select.Option>
                    {companies.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="departamentos"
                  label="Departamento"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (typeof option.children === 'string'
                        ? option.children
                        : option.children.props.children
                      )
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Selecione o(s) departamento(s)"
                    notFoundContent={
                      departmentsIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onSearch={this.fetchDepartments}
                    onChange={this.onDepartmentChange}
                    onSelect={this.onDepartmentSelect}
                    showSearch
                  >
                    <Select.Option value="todos">Todos</Select.Option>
                    {departments.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="funcionarios"
                  label="Funcionário"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    mode="multiple"
                    filterOption={false}
                    allowClear
                    placeholder="Selecione o(s) funcionário(s)"
                    notFoundContent={
                      employeesIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onSearch={this.fetchEmployees}
                    onChange={this.onEmployeeChange}
                    onSelect={this.onEmployeeSelect}
                    showSearch
                  >
                    <Select.Option value="todos">Todos</Select.Option>
                    {employees.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <DatePickerWithMask
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 9) {
                      this.form.data_excecao_fim.focus()
                    }
                  }}
                  label="Data Início"
                  name="data_excecao_inicio"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                    },
                    {
                      validator: async (_, data_excecao_inicio) => {
                        var data_excecao_fim =
                          this.form.getFieldValue('data_excecao_fim')
                        if (data_excecao_fim !== null) {
                          if (data_excecao_inicio > data_excecao_fim) {
                            return Promise.reject(
                              'A data inicial deve ser menor ou igual a data final.'
                            )
                          }
                        }
                      },
                    },
                  ]}
                />
              </Col>
              <Col xs={24} md={6}>
                <DatePickerWithMask
                  label="Data Final"
                  name="data_excecao_fim"
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 9) {
                      this.form.data_excecao_inicio.focus()
                    }
                  }}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                    },
                    {
                      validator: async (_, data_excecao_fim) => {
                        var data_excecao_inicio = this.form.getFieldValue(
                          'data_excecao_inicio'
                        )
                        if (data_excecao_inicio !== null) {
                          if (data_excecao_fim < data_excecao_inicio) {
                            return Promise.reject(
                              'A data final deve ser maior ou igual a data inicial.'
                            )
                          }
                        }
                      },
                    },
                  ]}
                />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Tipo"
                  name="tipo"
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    placeholder="Selecione o tipo"
                    onSelect={this.onTypeSelect}
                  >
                    <Select.Option value="execao_jornada">
                      Exceção de horário
                    </Select.Option>
                    <Select.Option value="execao_hora_extra">
                      Exceção de hora extra
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                {this.state.selectedType === 'execao_jornada' && (
                  <Form.Item
                    label="Horário"
                    name="jornada_id"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      filterOption={false}
                      allowClear
                      placeholder="Selecione o horário"
                      notFoundContent={
                        journeysIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onSearch={this.fetchJourneys}
                      showSearch
                    >
                      {journeys.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.descricao}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {this.state.selectedType === 'execao_hora_extra' && (
                  <Form.Item
                    label="Hora extra"
                    name="hora_extra_id"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      filterOption={false}
                      allowClear
                      placeholder="Selecione a hora extra"
                      notFoundContent={
                        overtimeIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onSearch={this.fetchOvertime}
                      showSearch
                    >
                      {overtime.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-save"
                  shape="round"
                  icon={<i className="far fa-check" />}
                  loading={isSending}
                  disabled={isSending}
                >
                  {isSending ? 'Gerando' : 'Iniciar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </QueueAnim>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
  }
}

export default connect(mapStateToProps)(Index)
