
import { api } from "./../../config/api";
import { toFormData, toQueryString } from "../../helpers/form";

const basePath = "cartaoponto";

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
	return api.post(`${basePath}/colunacartoes/${options.empresa_id}`, options);
};

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
	return api.get(`${basePath}/colunacartao/${options.empresa_id}`);
};