import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Select } from "antd";

import moment from "moment";

import { companiesService } from "./../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "./../../components";

import Employees from "./Employees";
import Adresses from "./Adresses";
import Contacts from "./Contacts";


const config = {
	externalName: "paciente",
};

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null,
			settings: null,
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		companiesService.show({ uuid })
			.then((response) => {
				let item = response.data.data;
				this.setState({
					settings: {
						app_funcionario: response.data.configuracao.app_funcionario,
						app_empresa: response.data.configuracao.app_empresa,
					},
					isLoading: false,
					item: item,
				}, () => {
					// Upload
					if (item.avatar) {
						this.upload.setFiles([
							{
								uuid: item.uuid,
								url: item.avatar,
								type: 'image/jpeg',
							}
						]);
					}

					// Load options
					this.tabEmployees && this.tabEmployees.init(item);
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};


	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}

	render() {
		const { visible } = this.props;
		const { 
			isLoading, 
			item, 
			settings,
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Empresa`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey={this.state.general_key != null ? this.state.general_key : "general"} >
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={6}>
									<Form.Item label="Empresa Principal">
										<Switch disabled checked={item.cnpj_principal == 1 ? true : false} />
									</Form.Item>
								</Col>
								<Col xs={24} md={6}>
									<Form.Item label="Nome">
										{item?.nome ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} md={6}>
									<Form.Item label="Tipo Pessoa">
										{item.tipo_pessoa ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item label="CEI">
										{item.cei ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							{item.tipo_pessoa == "Jurídica" ? (
								<Row gutter={16}>
									<Col sm={6}>
										<Form.Item label="CNPJ">
											{item.cnpj ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="CNO">
											{item.cno ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="IE">
											{item.ie ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="IM">
											{item.im ?? "-"}
										</Form.Item>
									</Col>
								</Row>
							) : (
								<Row gutter={16}>
									<Col sm={6}>
										<Form.Item label="RG">
											{item.rg ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="CPF">
											{item.cpf ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="CAEPF">
											{item.caepf ?? "-"}
										</Form.Item>
									</Col>
									<Col sm={6}>
										<Form.Item label="Data de nascimento">
											{item.data_nascimento ? moment(item.data_nascimento).calendar() : "-"}
										</Form.Item>
									</Col>
								</Row>

							)}
							<Row gutter={16}>
								<Col xs={24} sm={8}>
									<Form.Item label="Número da Folha">
										{item.numero_folha ?? "-"}
									</Form.Item>

								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Nome do Responsável">
										{item.nome_responsavel ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={8}>
									<Form.Item label="Cargo do Responsável">
										{item.cargo_responsavel ?? "-"}
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={6}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar() ?? "-"}
									</Form.Item>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item label="Ativo">
										<Switch disabled checked={item.ativo == 1 ? true : false} />
									</Form.Item>
								</Col>
								{
									displayParamsREPP && (
										<>
										{settings && settings.app_empresa && displayAppCompany && (<Col xs={24} sm={6}>
											<Form.Item label="App empresa">
												<Switch disabled checked={item.app_empresa == 1 ? true : false} />
											</Form.Item>
										</Col>)}
										{settings && settings.app_funcionario && displayAppEmployee && (<Col xs={24} sm={6}>
											<Form.Item label="App funcionário">
												<Switch disabled checked={item.app_funcionario == 1 ? true : false} />
											</Form.Item>
										</Col>)}	
										</>
									)
								}
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Funcionários" key="employees">
							<Employees
								ref={el => this.tabEmployees = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
