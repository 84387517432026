import React, { Component, Fragment } from "react";
import { Col, Modal, Row, Spin, Empty, Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";

import { reportService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			data     : [],
			maior_sequencia: 0,

			columnsList: [],

			dataInicial : "",
			dataFinal   : "",
			empresa_nome: "",
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print", "page-print-espelho-ponto");
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print", "page-print-espelho-ponto");
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = new URLSearchParams(this.props.location.search);

		const data = {};

		if( params.has("empresas") )
		{
			if( params.get("empresas") !== "todos" )
			{
				data.empresas = params.get("empresas").split(",");
			}
		}
		else
		{
			Modal.error({
				title       : "Ocorreu um erro!",
				content     : "Empresa não encontrada!",
				maskClosable: false,
				keyboard    : false,
				closable    : false,
				onOk        : () => {
					window.close();
				},
			});
			return false;
		}

		if( params.has("departamentos") )
		{
			if( params.get("departamentos") !== "todos" )
			{
				data.departamentos = params.get("departamentos").split(",");
			}
		}

		if( params.has("funcionarios") )
		{
			if( params.get("funcionarios") !== "todos" )
			{
				data.funcionarios = params.get("funcionarios").split(",");
			}
		}

		if( params.has("data_inicio") )
		{
			data.data_inicio = params.get("data_inicio");
			this.setState({
				dataInicial: params.get("data_inicio"),
			});
		}
		else
		{
			Modal.error({
				title       : "Ocorreu um erro!",
				content     : "Data inicial não encontrada!",
				maskClosable: false,
				keyboard    : false,
				closable    : false,
				onOk        : () => {
					window.close();
				},
			});
			return false;
		}

		if( params.has("data_final") )
		{
			data.data_final = params.get("data_final");
			this.setState({
				dataFinal: params.get("data_final"),
			});
		}
		else
		{
			Modal.error({
				title       : "Ocorreu um erro!",
				content     : "Data final não encontrada!",
				maskClosable: false,
				keyboard    : false,
				closable    : false,
				onOk        : () => {
					window.close();
				},
			});
			return false;
		}

		reportService
			.mirrorPoint(data)
				.then((response) => {
					const data = Object.entries(response.data.cartao).map(([key, value]) => {
						const contratos = value?.contrato || [];
						const cartoes   = value?.cartao || [];
						const total     = value?.total || [];
						let max = 0;

						_.filter(cartoes, (item) => {
							let totalBatidasJornada = item.jornadadiasemana?.jornada_batidas?.length;
							let totalBatidasCartao  = item.cartaopontobatida?.length;

							if (totalBatidasJornada) {
								const maxJornada = item.jornadadiasemana?.jornada_batidas[totalBatidasJornada - 1]?.sequencia;
								if (maxJornada > max) {
									max = maxJornada;
								}
							}

							if (totalBatidasCartao) {
								const maxCartao = item.cartaopontobatida[totalBatidasCartao - 1]?.sequencia;
								if (maxCartao > max) {
									max = maxCartao;
								}
							}
						})
		
						const response  = contratos.map((contrato, index) => {
							return {
								...contrato,
								cartoes: _.filter(cartoes, (item) => item.contrato_id === contrato.id),
								total,
								total_sequencias: max
							}
						});

						return {
							response
						}
					});

					const dataChunk = [];
					for(const chunks of data) {
						for(const chunk of chunks.response) {
							dataChunk.push(chunk);
						}
					}

					this.setState({
						isLoading	 : false,
						data     	 : dataChunk,
						maior_sequencia: response.data.maior_sequencia
					}, () => {
						setTimeout(() => {
							// Print
							document.title = 'Espelho Ponto';
							if( this.state.data.length > 0 )
							{
								window.print();
								// window.close();
							}
						}, 1000);
					});
				})
				.catch((data) => {
					this.setState({
						isLoading: false,
					});
					Modal.error({
						title  : "Ocorreu um erro!",
						content: String(data),
					});
				});
	};

	render() {
		const { isLoading, data, maior_sequencia } = this.state;
		const { user } = this.props;
		const { name: userName } = user || {};

		const style = `
			@page {
				size: A4 landscape;
				margin:0px;
			}
		`;
		const pageBreakAfter = data?.length > 1;

		const list = [
			'Dia',
			'Marcações Registradas no Ponto Eletrônico',
			'Jornada Realizada',
			'Duração',
			'CH',
			'Horário',
			'Occ.',
			'Motivo',
		];

		const getColumnClass = (columnIndex, totalRow) => {
			if (columnIndex === 0) {
				return 'cell-first';
			} else if (columnIndex >= 1 && columnIndex <= maior_sequencia * 2) {
				return 'cell-sequence';
			} else if (columnIndex > maior_sequencia * 2 && columnIndex <= (maior_sequencia * 2 + maior_sequencia * 2)) {
				return 'cell-sequence';
			} else if (columnIndex > (maior_sequencia * 2 + maior_sequencia * 2) && columnIndex !== totalRow.length - 2 && columnIndex !== totalRow.length - 1) {
				return 'cell-sequence-end';
			} else if (columnIndex === (maior_sequencia * 2 + maior_sequencia * 2)) {
				return 'cell-sequence-single';
			} else if (columnIndex === totalRow.length - 2) {
				return 'cell-penultimate';
			} else if (columnIndex === totalRow.length - 1) {
				return 'cell-last';
			} else {
				return 'cell-base';
			}
		};		
		
		const criarHashJornadas = (jornadaBatidas) => {
			if (!jornadaBatidas) {
				throw new Error('Erro: O parâmetro fornecido é inválido e não pode ser processado.');
			}
	
			jornadaBatidas.forEach(element => {
				if (!element) return;

				if (!element.jornada_batidas) {
					element.identificador = '';
					return;
				}

				element.identificador = element.jornada_batidas.reduce((acc, curr, index) => {
					const entrada = curr?.entrada?.split(' ')[1];
					const saida = curr?.saida?.split(' ')[1];
					const text = `ent_${index}_${entrada}_sai_${index}_${saida}_`;
					return acc + text;
				}, '');
			});
	
			return jornadaBatidas;
		};

		const formatHashs = (identificadores) => {
			const mapHashCode = new Map();
			let currentCode = 1;
		
			const normalizedIdentifiers = identificadores.map(identificador =>
				identificador.replace(/ent_\d+_undefined_sai_\d+_undefined_/g, '')
			);
		
			[...new Set(normalizedIdentifiers)].forEach(identificador => {
				if (identificador === '') {
					mapHashCode.set(identificador, ' ');
				} else {
					mapHashCode.set(identificador, String(currentCode++).padStart(3, '0'));
				}
			});
		
			return identificadores.map(identificador => {
				const normalizedIdentifier = identificador.replace(/ent_\d+_undefined_sai_\d+_undefined_/g, '');
				return {
					identificador,
					codigoFormatado: mapHashCode.get(normalizedIdentifier) || ' '
				};
			});
		};			
		
		const formatScheduleByCode = (hashArray) => {
			const scheduleByCode = {};
		
			hashArray.forEach(hash => {
				const { codigoFormatado, identificador } = hash;
				if (!scheduleByCode[codigoFormatado]) {
					scheduleByCode[codigoFormatado] = [];
				}
		
				const times = identificador.match(/ent_\d+_(\d{2}:\d{2}:\d{2})_sai_\d+_(\d{2}:\d{2}:\d{2})/g);
		
				if (times) {
					times.forEach(time => {
						const [_, entrada, saida] = time.match(/ent_\d+_(\d{2}:\d{2}:\d{2})_sai_\d+_(\d{2}:\d{2}:\d{2})/);
						if (entrada.trim() !== '00:00' && saida.trim() !== '00:00') {
							scheduleByCode[codigoFormatado].push({
								entrada: entrada.substr(0, 5),
								saida: saida.substr(0, 5),
							});
						}
					});
				}
			});
		
			Object.keys(scheduleByCode).forEach(code => {
				if (scheduleByCode[code].length === 0 || scheduleByCode[code].every(timeSlot => Object.keys(timeSlot).length === 0)) {
					delete scheduleByCode[code];
				}
			});

			return scheduleByCode;
		}
		
		return (
			<Fragment>
				{isLoading ? (
					<div
						style={{
							position : "fixed",
							top      : "150px",
							left     : "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spin
							spinning={isLoading}
							indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
						/>
					</div>
				) : (
					data.length > 0 ? (
						<Fragment>
							{data?.map((group, i) => {
								const taxVar = group?.empresa?.cnpj ? 'CNPJ' : 'CPF';
    						const taxValue = group?.empresa?.cnpj || group?.empresa?.cpf;

								const hashs = group?.cartoes?.flatMap((item) => {
									const jornadasWithHash = criarHashJornadas([item?.jornadadiasemana]);
									const identificadores = jornadasWithHash
										?.map(jornada => jornada?.identificador)
										.filter(identificador => identificador !== undefined); 
									return identificadores;	
								});

								const listData = group?.cartoes
									?.filter(item =>
										item.carga_horaria_jornada !== "00:00:00" ||
										item.cartaopontobatida.some(batida =>
											(batida.entrada_batida && batida.entrada_batida !== '00:00') ||
											(batida.saida_batida && batida.saida_batida !== '00:00')
										) ||
										item.ponto_registro.some(registro => registro?.hora && registro.hora !== '00:00')
									) 
									?.map((item, j) => {
									const jornada = item.carga_horaria_jornada;
									const [horas, minutos] = jornada.split(':');
									let jornadaFormatada = `${horas}:${minutos}`;

									jornadaFormatada = jornadaFormatada === '00:00' ? '' : jornadaFormatada;
									
									const ajustes = item.cartaopontobatida.reduce((acc, batida) => {
										const values = Object.keys(batida).filter(key => key.includes('ajuste') && !key.includes('_id'))
										.map(key => batida[key])
										.filter(value => !!value);
										if (values.length) {
											acc = acc.concat(values);
										}
										return acc
									}, []);

									const ajusteHorarios = ajustes.map(ajuste => <div>{moment(ajuste.data).format('HH:mm')}</div>);
									const ajusteOccur = ajustes.map(ajuste => <div>{ajuste.tipo}</div>);
									const ajusteMotivo = ajustes.map(ajuste => <div>{ajuste.motivo}</div>);

									const batidas = Array.from({ length: this.state.maior_sequencia }).reduce((acc, _, index) => {
										const batida = item.cartaopontobatida[index];
										return [
											...acc,
											`${batida?.entrada_batida ? moment.utc(batida?.entrada_batida).format('HH:mm') : ''}${batida?.ponto_ajuste_entrada ? '*' : ''}`,
											`${batida?.saida_batida ? moment.utc(batida?.saida_batida).format('HH:mm') : ''}${batida?.ponto_ajuste_saida ? '*' : ''}`
										];
									}, []);

									const registros = Array.from({ length: this.state.maior_sequencia * 2 }).reduce((acc, _, index) => {
										const registro = item.ponto_registro[index];
										return [
											...acc,
											registro?.hora || '',
										];
									}, []);

									const hash = formatHashs(hashs);

									return [
										moment(item.data).format('DD/MM'),
										...registros,
										...batidas,
										jornadaFormatada,
										[hash[j]?.codigoFormatado],	
										ajusteHorarios,
										ajusteOccur,
										ajusteMotivo
									];
						
								}, []);

								const hash = formatHashs(hashs);
								const schedule = formatScheduleByCode(hash);
								const tableClass = `table-impressao cartao-ponto-${group.id} ${pageBreakAfter ? 'page-break-after' : ''}`;

								return (
									<table key={i} className={tableClass}>
										<thead>
											<tr>
												<td className="mirror-point" style={{ margin: 0 }}>
													<header>
														<div className="logo-container">
															<div>
																<img
																	src="/images/logos/logo-complete.svg"
																	alt="ByPonto"
																	width="150"
																/>
															</div>
															<div className="logo-title">
																<div>
																	<h1>Espelho de Ponto Eletrônico</h1>
																</div>
																<div>
																	<div>Período: {moment(this.state.dataInicial).format('DD/MM/YYYY')} até {moment(this.state.dataFinal).format('DD/MM/YYYY')}</div>																
																</div>
																<div>
																	<h4>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h4>
																</div>
															</div>
														</div>
													</header>
												</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td style={{ padding: '5px' }}>
													<div className="header-dados">
														<Row gutter={16} className="row-section">
															<Col span={12}>
																<div><b>EMPRESA: </b></div>
																<div>{group?.empresa?.nome}</div>
															</Col>
															<Col span={6}>
																<div><b>{taxVar}: </b></div>
                                <div>{taxValue}</div>
															</Col>
															<Col span={6}>
																<div><b>CEI: </b></div>
																<div>{group?.empresa?.ie}</div>
															</Col>
														</Row>
														<Row gutter={16} className="row-section">
															<Col span={24}>
																<div><b>ENDEREÇO: </b></div>
																<div>{group?.empresa?.endereco_principal?.logradouro}</div>
															</Col>
														</Row>
														<Row gutter={16} className="row-section">
															<Col span={12}>
																<div><b>NOME: </b></div>
																<div>{group?.funcionario?.nome}</div>
															</Col>
															<Col span={3}>
																<div><b>Nº PIS/PASEP: </b></div>
																<div>{group?.funcionario?.pis_pasep}</div>
															</Col>
															<Col span={3}>
																<div><b>Nº CPF: </b></div>
																<div>{group?.funcionario?.cpf}</div>
															</Col>
															<Col span={3}>
																<div><b>ADMISSÃO: </b></div>
																<div>
																	{
																		moment(group?.cartoes[0]?.contrato?.data_admissao).isValid()
																			? moment(group?.cartoes[0]?.contrato?.data_admissao).format('DD/MM/YYYY')
																			: ''
																	}
																</div>
															</Col>
															<Col span={3}>
																<div><b>FUNÇÃO: </b></div>
																<div>{group?.funcoes?.nome}</div>
															</Col>
														</Row>
														{/* <div className="linha">
															<div><b>EMPRESA: </b>{group?.empresa?.nome}</div>
															<div style={{width: "50%"}}><b>{group?.empresa?.documento_tipo}: </b>{group?.empresa?.documento}</div>
															<div style={{width: "50%"}}><b>CEI: </b>{group?.empresa?.ie}</div>
														</div>
														<div className="linha">
															<div>
																<b>ENDEREÇO: </b>{group?.empresa?.endereco}
															</div>
														</div>
														<div className="linha">
															<div><b>NOME: </b>{group?.funcionario?.nome}</div>
															<div style={{width: "50%"}}><b>PIS/PASEP: </b>{group?.funcionario?.pis_pasep}</div>
															<div style={{width: "50%"}}><b>ADMISSÃO: </b>{moment(group?.funcionario?.data_admissao).format('DD/MM/YYYY')}</div>
														</div>
														<div className="linha">
															<div><b>CENTRO DE CUSTO: </b>{}</div>
															<div style={{width: "50%"}}><b>MATRÍCULA: </b> {}</div>
														</div>
														<div className="linha">
															<div><b>DEPARTAMENTO: </b>{group?.departamento?.nome}</div>
															<div style={{width: "50%"}}><b>CARGO: </b> {group?.funcoes?.nome}</div>
														</div> */}
													</div>
													{group?.cartoes?.length ? (
														<div className="table-container">
															<table className="responsive-table" style={{ fontSize: '9px' }}>
																<thead>
																	<tr>
																		<th colSpan={1} style={{ fontSize: '9px' }}>Dia</th>
																		<th 
																			colSpan={maior_sequencia * 2}
																			className="th-tratamentos"
																			style={{ width: '150px' }}
																		>
																			Marcações Registradas no Ponto Eletrônico
																		</th>
																		<th 
																			colSpan={maior_sequencia * 2}
																			className="th-tratamentos"
																		>
																			Jornada Realizada
																		</th>
																		<th colSpan={1} style={{ fontSize: '9px',  width: '10px' }}>Duração</th>
																		<th colSpan={1} style={{ fontSize: '9px' }}>CH</th>
																		<th 
																			colSpan={3}
																			className="th-tratamentos"
																			style={{ fontSize: '9px' }}
																		>
																			Tratamento Efetuados sobre os Dados Originais:
																		</th>
																	</tr>
																	<tr>
																		<th className="th-color"></th>
																		<th className="th-color" colSpan={maior_sequencia * 2}></th>
																		<th className="th-color" colSpan={maior_sequencia * 2}></th>
																		<th className="th-color"></th>
																		<th className="th-color"></th>
																		<th className="th-color" style={{ fontSize: '9px' }}>
																			<div style={{ fontSize: '9px' }}>
																				Horário
																			</div>
																		</th>
																		<th className="th-color" style={{ fontSize: '9px', width: 'auto' }}>
																			<div style={{ fontSize: '9px' }}>
																				Occ.
																			</div>
																		</th>
																		<th className="th-color" style={{ fontSize: '9px', width: 'auto' }}>
																			<div style={{ fontSize: '9px' }}>
																				Motivo
																			</div>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{listData.map((row, rowIndex) => (
																		<tr key={rowIndex}>
																			{row.map((columnValue, columnIndex) => (
																				<td 
																					key={columnIndex} 
																					className={getColumnClass(columnIndex, row)}
																				>
																					<div className={getColumnClass(columnIndex, row)}>
																						{columnValue}
																					</div>
																				</td>
																			))}
																		</tr>
																	))}
            										</tbody>
															</table>
														</div>
													) : (
														<div>Não existem tratamento</div>
													)}

													<Row className="mt-1rem horarios-contratuais">
														<Col xs={24}>
															<div className="content-center">
																<div className="title">
																	HORÁRIOS CONTRATUAIS DO EMPREGADO
																</div>
																<div className="jornadas">
																	<div className="jornada-row">
																		{Object.keys(schedule).map((code, index) => (
																			<div key={index} className="codigo-horario">
																				<div style={{ display: 'flex' }}>
																					<div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
																						<span style={{ textAlign: 'center' }}>CÓDIGO DO HORÁRIO (CH)</span>
																						<span style={{ textAlign: 'center' }}>{code}</span>
																					</div>
																					{schedule[code].slice(0, 2).map((timeSlot, slotIndex) => (
																						<div key={slotIndex}>
																							<div style={{ display: 'flex', gap: '5px' }}>
																								<div className="entrada-saida">
																									<span>ENTRADA</span>
																									<div>{timeSlot.entrada}</div>
																								</div>
																								<div className="entrada-saida">
																									<span>SAÍDA</span>
																									<div>{timeSlot.saida}</div>
																								</div>
																							</div>
																						</div>
																					))}
																				</div>
																			</div>
																		))}
																	</div>
																</div>
															</div>
														</Col>
													</Row>

													<Row className="assinaturas mt-3rem">
														<Col xs={12}>
															<div>{group.empresa.nome}</div>
														</Col>
														<Col xs={12}>
															<div>{group.funcionario.nome}</div>
														</Col>
													</Row>
												</td>
											</tr>
										</tbody>
									</table>
								)
							})}
						</Fragment>
					) : (
						<Empty description="Nenhum registro encontrado com esses parâmetros." style={{padding: "20px", position: "absolute", top: "20px", left: "50%", transform: "translateX(-50%)"}}>
							<Button type="primary" onClick={() => window.close()}>Fechar</Button>
						</Empty>
					)
				)}
				<style dangerouslySetInnerHTML={{__html: style}} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
