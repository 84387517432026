import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { companiesAdressesService, webserviceService } from "../../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	UIDrawerForm,
	UILabelHelp,
} from "../../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
		adressesCount: PropTypes.number,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,
			isSendingZipcode: false,
			cities: [],
			citiesIsLoading: false,
			cep: null,
		};
	}

	onOpen = () => {
		this.setState({
			isLoading: false,
		});

		setTimeout(() => {
			this.cep && this.cep.inputElement && this.cep.inputElement.focus()

		}, 100)

	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	fetchCities = (value) => {
		if (this._axiosCancelToken) {
			this._axiosCancelToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				citiesIsLoading: false,
				cities: [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search: value,
			cancelToken: this._axiosCancelToken.token,
		})
			.then((response) => {
				this.setState({
					citiesIsLoading: false,
					cities: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					citiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		}

		if (data.endereco_principal == null) {
			data.endereco_principal = false
		}

		// uuid
		data.uuid = uuid;
		companiesAdressesService.create(this.props.client_uuid, data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	zipcodeKeyUp = (e) => {
		// Ctrl + A, Ctrl + C, 9 tab, 16 shift, 18 alt, 17 Ctrl, 37 left, 39 right, 38 up, 40 down
		if ((e.ctrlKey && e.keyCode === 65) || (e.ctrlKey && e.keyCode === 67) || e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 18 || e.keyCode === 17 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
			return false;
		}

		let zipcode = e.currentTarget.value.replace(/[^0-9]/g, "");

		if (zipcode.length < 8) {
			return false;
		}

		this.setState({
			isSendingZipcode: true,
		});

		webserviceService.findZipcode({
			zipcode: zipcode
		})
			.then((response) => {
				this.setState({
					isSendingZipcode: false,
					cep: zipcode,
					cities: [
						{
							id: response.data.city_id,
							state_id: response.data.state_id,
							name: response.data.city_name,
							full_name: response.data.city_full_name,
							state_abbr: response.data.state_abbr,
							state_name: response.data.state_name,
						}
					],
				}, () => {
					this.form.setFieldsValue({
						logradouro: response.data.street,
						bairro: response.data.district,
						cidade_id: response.data.city_id,
					});

					this.numero.focus();
				});
			})
			.catch((data) => {
				this.setState({
					isSendingZipcode: false,
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, isSendingZipcode, cities, citiesIsLoading, cep } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Novo Endereço">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						endereco_principal: this.props.adressesCount == 0 ? true : false ,
					}}>	
					<Row gutter={16}>
						<Col xs={24} sm={6} >
							<Form.Item name="cep" label="CEP" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<MaskedInput
									mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
									className="ant-input"
									onKeyUp={this.zipcodeKeyUp}
									tabIndex={1}
									ref={el => this.cep = el}
								/>
							</Form.Item>
							{/* {isSendingZipcode && <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} style={{ position: "absolute", top: "37px", left: "92%" }} />} */}
						</Col>
						<Col xs={24} sm={18}>
							<Form.Item name="logradouro" label="Logradouro" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input tabIndex={cep > 0 ? 3 : 2} />
							</Form.Item>
						</Col>

					</Row>
					<Row gutter={16}>
						<Col xs={10} sm={6}>
							<Form.Item name="numero" label="Número" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<InputNumber ref={el => this.numero = el} tabIndex={cep > 0 ? 2 : 3} min={1} maxLength={7} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={18}>
							<Form.Item name="complemento" label="Complemento">
								<Input tabIndex={4} maxLength={50} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={10}>
							<Form.Item name="bairro" label="Bairro" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input tabIndex={5} maxLength={50} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={14}>
							<Form.Item name="cidade_id" label="Cidade" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Select
									tabIndex={6}
									filterOption={false}
									allowClear
									placeholder="Pesquise a cidade"
									notFoundContent={citiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
									onSearch={this.fetchCities}
									showSearch>
									{cities.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.full_name}</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col md={12}>
							<Form.Item name="endereco_principal" label="Endereço principal" valuePropName="checked">
								<Switch disabled={this.props.adressesCount == 0 ? true : false} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
