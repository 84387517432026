import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Form, Input, message } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../../../config/general";

import moment from "moment";

import {
	timeCardService,
} from "./../../../../redux/services";

import {
	DatePickerWithMask,
} from "./../../../../components";

class ModalObservacao extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
		}
	}

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {
		this.setState({
			isLoading: true,
		});

		timeCardService
			.getObservacao({
				cartao_ponto_id: this.props.cartaoPontoId,
			})
			.then((response) => {
				this.setState({
					isLoading: false,
				});

				if (this.form) {
					this.form.setFieldsValue({
						observacao: response.data.observacao
					});
				}
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			timeCardService
				.updateObservacao({
					cartao_ponto_id: this.props.cartaoPontoId,
					observacao: values.observacao,
				})
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Observação do cartão ponto atualizada com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	}

	render() {
		const { visible } = this.props;

		const { isLoading, isSending } = this.state;
	
		return (
			<Modal
				open={visible}
				title="Observação do cartão ponto"
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={580}
				onCancel={this.onClose}
				maskClosable={false}
				okText={isSending ? "Salvando" : "Salvar"}
				cancelButtonProps={{disabled: isLoading || isSending}}
				onOk={this.onSubmit}
				confirmLoading={isSending}
				keyboard={!isSending}
				closable={!isSending && !isLoading}
				centered
			>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					disabled={isLoading || isSending}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label="Data" style={{ marginBottom: 15 }}>
								{moment(this.props.dataDia).format("DD/MM/YYYY") ?? "-"}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Observação" name="observacao" style={{ marginBottom: 15 }}>
								<Input.TextArea rows={8} maxLength={500} showCount />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default ModalObservacao;