import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { companiesService } from "../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};


	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,
			tipo_pessoa: null,
			cnpj_principal: null,
			settings: null,
			displayParamsREPP: true,
			displayAppCompany: true,
			displayAppEmployee: true
		};
	}

	componentDidMount() {
		this.handleCheckCompanyHasAccessREPP();
	}

	fetchCompanies = () => {
		companiesService.getAll({
			orderBy: "nome:asc",
		})
		.then((response) => {
			this.setState({
				settings: {
					app_funcionario: response.data.configuracao.app_funcionario,
					app_empresa: response.data.configuracao.app_empresa,
				},
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onOpen = () => {
		this.setState({
			tipo_pessoa: null,
		});

		this.fetchCompanies();

		setTimeout(() => {
			this.nome && this.nome.focus()

		}, 100)
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		if (data.app_empresa == null) {
			data.app_empresa = 0;
		}

		if (data.app_funcionario == null) {
			data.app_funcionario = 0;
		}

		if (data.cliente_id == null) {
			data.cliente_id = 1;
		}

		if (data.cnpj_principal == null) {
			data.cnpj_principal = false;
		}

		if (data.data_nascimento) {
			data.data_nascimento = data.data_nascimento.format("YYYY-MM-DD");
		} else {
			delete data.data_nascimento;
		}

		companiesService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Empresa cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	handleCheckCompanyHasAccessREPP = () => {
		const companyData = JSON.parse(localStorage.getItem('@mainCompany'));

		this.setState({
			displayParamsREPP: !(!companyData?.app_empresa && !companyData?.app_funcionario),
			displayAppCompany: companyData?.app_empresa,
			displayAppEmployee: companyData?.app_funcionario
		});
	}

	render() {
		const { visible } = this.props;

		const { 
			isLoading, 
			isSending, 
			settings, 
			nextOrder, 
			tipo_pessoa, 
			displayParamsREPP,
			displayAppCompany,
			displayAppEmployee 
		} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Nova Empresa">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						order: nextOrder,
						required: true,
						ativo: true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col sm={6}>
									<Form.Item name="cnpj_principal" label="Empresa Principal" valuePropName="checked">
										<Switch onChange={(evnt) => { this.setState({ cnpj_principal: evnt }) }} />
									</Form.Item>
								</Col>
								<Col xs={18}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de Pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="cei" label="CEI" hasFeedback >
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="rg" label="RG" hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="cpf" rules={[{ required: true, message: "Campo obrigatório." }]} label="CPF" hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<DatePickerWithMask onKeyUp={(e) => {
												if (e.keyCode === 13 || e.keyCode === 9) {
													this.tipo_contrato.focus()
												}
											}} label="Data de nascimento" name="data_nascimento" disableDateAfter={true} />
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="caepf" label="CAEPF" hasFeedback >
												<Input />
											</Form.Item>
										</Col>
									</Row>
								</>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="cnpj" label="CNPJ" rules={[{ required: true, message: "Campo obrigatório." }]} hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="cno" label="CNO" hasFeedback >
												<Input />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
								</>
							))}

							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="numero_folha" label="Número da Folha" >
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="nome_responsavel" label="Nome do Responsável" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="cargo_responsavel" label="Cargo do Responsável" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="ativo" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Switch />
									</Form.Item>
								</Col>
								{
									displayParamsREPP && (
										<>
											{settings && settings.app_empresa && displayAppCompany && (<Col sm={8}>
												<Form.Item name="app_empresa" valuePropName="checked" label="App empresa">
													<Switch />
												</Form.Item>
											</Col>)}
											{settings && settings.app_funcionario && displayAppEmployee && (<Col sm={8}>
												<Form.Item name="app_funcionario" valuePropName="checked" label="App funcionario">
													<Switch />
												</Form.Item>
											</Col>)}
										</>
									)
								}
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="contacts">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Funcionários" key="employees">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
