import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs, Select } from "antd";

import moment from "moment";

import { perimetersService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";


const config = {
	externalName: "paciente",
};

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid: 0,
			item: {},
			general_key: null
		};
	}

	onOpen = (uuid, key) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
			item: {},
			general_key: key,
		});

		perimetersService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				this.setState({
					isLoading: false,
					item: item,
				}, () => {
					// Upload
					if (item.avatar) {
						this.upload.setFiles([
							{
								uuid: item.uuid,
								url: item.avatar,
								type: 'image/jpeg',
							}
						]);
					}

					// Load options
					this.tabEquipments && this.tabEquipments.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const { visible } = this.props;
		const { uuid, isLoading, item } = this.state;

		return (
      <UIDrawerForm
        visible={visible}
        width={700}
        onClose={this.onClose}
        isLoading={isLoading}
        showBtnSave={false}
        title={`Visualizar Perímetro`}
      >
        <Form layout="vertical">
          <Tabs
            defaultActiveKey={
              this.state.general_key != null
                ? this.state.general_key
                : 'general'
            }
          >
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Form.Item label="Descrição">{item.nome ?? '-'}</Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item label="Endereço">{item.endereco ?? '-'}</Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item label="Raio (em metros)">{item.raio ?? '-'}</Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Criação">
                    {moment(item.created_at).calendar() ?? '-'}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Última atualização">
                    {moment(item.updated_at).calendar() ?? '-'}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item label="Status">
                    <Switch disabled checked={item.status == 1 ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
	}
}

export default Show;
