import { api } from '../../config/api'
import { appendToFormData } from '../../helpers/form'

const basePath = 'horario'

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
	const options_default = {}

	// Merge config
	options = Object.assign({}, options_default, options)

	let params    = []
	let params_qs = ''

	if( options.hasOwnProperty('page') )
	{
		params.push(`page=${options.page}`)
	}

	if( options.hasOwnProperty('limit') )
	{
		params.push(`limit=${options.limit}`)
	}

	if( options.hasOwnProperty('search') )
	{
		params.push(`search=${options.search}`)
	}

	if( options.hasOwnProperty('orderBy') )
	{
		params.push(`orderBy=${options.orderBy}`)
	}

	if( options.hasOwnProperty('is_active') )
	{
		params.push(`is_active=${options.is_active}`)
	}

	if( options.hasOwnProperty('empresa_id') )
	{
		params.push(`empresa_id=${options.empresa_id}`)
	}

	if( options.hasOwnProperty('cidade_id') )
	{
		params.push(`cidade_id=${options.cidade_id}`)
	}

	if( options.hasOwnProperty('numero_folha') )
	{
		params.push(`numero_folha=${options.numero_folha}`)
	}

	if( options.hasOwnProperty('numero_identificador') )
	{
		params.push(`numero_identificador=${options.numero_identificador}`)
	}

	if( options.hasOwnProperty('pis_pasep') )
	{
		params.push(`pis_pasep=${options.pis_pasep}`)
	}

	if( options.hasOwnProperty('created_at') )
	{
		options.created_at.forEach((item, index) => {
			params.push(`created_at[${index}]=${item}`)
		})
	}

	if( params.length )
	{
		params_qs = `?${params.join('&')}`
	}

	let data = {};

	if( options.hasOwnProperty("cancelToken") )
	{
		data.cancelToken = options.cancelToken;
	}

	if( options.exportItems )
	{
		return api.get(`${basePath}/export${params_qs}`, data)
	}
	else
	{
		return api.get(`${basePath}${options.client_uuid ? `/${options.client_uuid}` : ''}${params_qs}`, data)
	}
}

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
	const formData = new FormData()

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key])
		}
	}

	return api.post(basePath, formData)
}

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
	const formData = new FormData()

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key])
		}
	}

	return api.post(`${basePath}/${options.uuid}`, formData)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
	return api.get(`${basePath}/${options.uuid}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
	return api.delete(`${basePath}/${options.uuid}`)
}

/**
 * Resend password
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const resendPassword = (options) => {
	return api.post(`${basePath}/${options.uuid}/resend-password`, options)
}

/**
 * Autocomplete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAutocomplete = (options) => {
	const options_default = {}

	// Merge config
	options = Object.assign({}, options_default, options)

	let params    = []
	let params_qs = ''

	if( options.hasOwnProperty('search') )
	{
		params.push(`search=${options.search}`)
	}

	if( options.hasOwnProperty('orderBy') )
	{
		params.push(`orderBy=${options.orderBy}`)
	}

	if( options.hasOwnProperty('empresas_ids') )
	{
		options.empresas_ids.forEach((item, index) => {
			params.push(`empresas_ids[${index}]=${item}`)
		})
	}

	if( options.hasOwnProperty('is_active') )
	{
		params.push(`is_active=${options.is_active}`)
	}

	if( params.length )
	{
		params_qs = `?${params.join('&')}`
	}

	let data = {}

	if( options.hasOwnProperty('cancelToken') )
	{
		data.cancelToken = options.cancelToken
	}

	return api.get(`${basePath}/autocomplete${params_qs}`, data)
}
