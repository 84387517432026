//! moment.js locale configuration
//! locale : Portuguese (Brazil) [pt-br]
//! author : Caio Ribeiro Pereira : https://github.com/caio-ribeiro-pereira

(function (global, factory) {
	typeof exports === "object" &&
	typeof module !== "undefined" &&
	typeof require === "function"
		? factory(require("moment"))
		: typeof define === "function" && define.amd
		? define(["moment"], factory)
		: factory(global.moment);
})(this, function (moment) {
	"use strict";

	//! moment.js locale configuration

	var ptBr = moment.defineLocale("pt-br", {
		months:
			"janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split(
				"_"
			),
		monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
		weekdays:
			"domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split(
				"_"
			),
		weekdaysShort: "Dom_Seg_Ter_Qua_Qui_Sex_Sáb".split("_"),
		weekdaysMin: "do_2ª_3ª_4ª_5ª_6ª_sá".split("_"),
		weekdaysParseExact: true,
		longDateFormat: {
			LT: "HH:mm",
			LTS: "HH:mm:ss",
			L: "DD/MM/YYYY",
			LL: "D [de] MMMM [de] YYYY",
			LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
			LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm",
		},
		calendar: {
			sameDay: "[Hoje às] LT",
			nextDay: "[Amanhã às] LT",
			nextWeek: "dddd [às] LT",
			lastDay: "[Ontem às] LT",
			lastWeek: function () {
				return this.day() === 0 || this.day() === 6
					? "[Último] dddd [às] LT" // Saturday + Sunday
					: "[Última] dddd [às] LT"; // Monday - Friday
			},
			sameElse: "L",
		},
		relativeTime: {
			future: "em %s",
			past: "há %s",
			s: "poucos segundos",
			ss: "%d segundos",
			m: "um minuto",
			mm: "%d minutos",
			h: "uma hora",
			hh: "%d horas",
			d: "um dia",
			dd: "%d dias",
			M: "um mês",
			MM: "%d meses",
			y: "um ano",
			yy: "%d anos",
		},
		dayOfMonthOrdinalParse: /\d{1,2}º/,
		ordinal: "%dº",
		invalidDate: "Data inválida",
	});

	return ptBr;
});
