import { api } from "../../config/api";
import { toFormData, toQueryString } from "../../helpers/form";

const basePath = "configuracoes-gerais";

/**
 * Get all ponto diario/cartão ponto status
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAllTimeCardStatus = (options) => {
	return api.get(`${basePath}/cartao-ponto/status`, options);
};